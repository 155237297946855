/** @jsx jsx */

import { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import {  FormikActions } from 'formik';

import { usePost } from 'hooks/async';
import { reducer as startReducer } from 'reducers/asyncGlobal';
import { reducer as counterReducer } from 'reducers/asyncGlobal';
import { reducer as acceptReducer } from 'reducers/asyncGlobal';
import { CounterOfferFields } from 'types/pages/Daily_Deals_View/dailyDealsView';
import styles from 'styles/DailyDealsView';
import history from 'utils/history';
import OfferStart from './OfferStart';
import OfferNegotiation from './OfferNegotiation';
import OfferFinal from './OfferFinal';
import ShareIcons from 'components/ShareIcons';

const OfferInteraction = ({ id }) => {

  const [started, setStarted] = useState(false);
  const [final, setFinal] = useState(false);

  const [time, setTime] = useState('');
  const [ourOffer, setOurOffer] = useState('');
  const [yourOffer, setYourOffer] = useState('');
  const [counterMessage, setCounterMessage] = useState('');
  const [makeOfferId, setMakeOfferId] = useState('');

  const [{ data: startResponse, isLoading: starting, errors: startError }, startDailyDeal] = usePost(`/dailydeals/${id}/start`, startReducer);
  const [{ data: counterResponse, isLoading: countering, errors }, counterOffer] = usePost(`/dailydeals/${makeOfferId}/counter`, counterReducer);
  const [{ data: acceptResponse, isLoading: accepting, errors: acceptError }, acceptOffer] = usePost(`/dailydeals/${makeOfferId}/accept`, acceptReducer);

  const onStartClick = () => {
    startDailyDeal(true);
  };

  useEffect(() => {
    if(startResponse){
      setStarted(true);
      setTime(startResponse.time);
      setMakeOfferId(startResponse.make_offer_id);
    }
    if(startError && startError.message){
      setCounterMessage(startError.message);
    }
  }, [startResponse, startError]);

  useEffect(() => {
    if(counterResponse){
      setOurOffer(counterResponse.counter_offer);
      if(counterResponse.counter_offer === counterResponse.your_offer){
        setFinal(true);
      }
    }
  }, [counterResponse]);

  useEffect(() => {
    if(acceptResponse){
      history.push('/my-wins');
    }
    if(acceptError && acceptError.message){
      setCounterMessage(acceptError.message);
    }
  }, [acceptResponse, acceptError]);

  useEffect(() => {
    if(errors && errors.message){
      setCounterMessage(errors.message);
    }
  }, [errors]);

  const onAcceptOffer = () => {
    acceptOffer({
      amount: ourOffer
    });
  };

  const onRejectOffer = () => {
    history.push('/dailydeals');
  };

  const onSubmitOffer = (fields: CounterOfferFields, actions: FormikActions<CounterOfferFields>) => {
    setCounterMessage("");
    actions.resetForm();
    setYourOffer(fields.input);
    counterOffer({
      sco_rule_id: startResponse.sco_rule_id,
      amount: fields.input
    });
  };

  const onEnded = () => {
    setFinal(true);
  };

  const startingState = !started
    ? <OfferStart onStartClick={ onStartClick } loading={ starting } errorMessage={ counterMessage } />
    : null;

  const negotiation = started && !final ?
    <OfferNegotiation
      onAcceptOffer={onAcceptOffer}
      onSubmitOffer={onSubmitOffer}
      ourOffer={ourOffer}
      yourOffer={yourOffer}
      countering={countering}
      started={started}
      starting={starting}
      accepting={accepting}
      message={counterMessage}
      endTime={parseInt(time)}
      onEnded={onEnded}
    /> : null;

  const last = started && final
    ? <OfferFinal
        onAcceptOffer={ onAcceptOffer }
        onRejectOffer={ onRejectOffer }
        price={ourOffer}
        accepting={accepting}
    />: null;

  return (
    <div css={styles}>
      {startingState}
      {negotiation}
      {last}
      <ShareIcons />
    </div>
  );
};

export default OfferInteraction;