/** @jsx jsx */

import React, { useContext } from 'react';
import { css, jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import moment from "moment";

import { AppContext } from 'context/AppProvider';
import Card from 'components/card/Card';

const TestimonialSlider: React.FC = () => {
  const { state: { preloadData: { testimonials } } } = useContext(AppContext);

  const results = testimonials
    ? testimonials.slice(0, 20).map((model,index) => (
        <div className={`item-${(index + 1)} animation`} key={`testimonial-${model.id}`}>
          <div className="statement">
            <p className="testimonial">
              { (model.testimonial.length > 80)
                ? model.testimonial.substring(0, 80) + '...'
                : model.testimonial }
            </p>
            <p>- by <strong>{ model.real_name }</strong> on { moment(model.create_time).format("MMM Do YYYY") }</p>
          </div>
        </div>
      ))
    : null;

  return (
    <div css={ style }>
      <Card headerTitle="Over 88,872 Testimonials">
        <div className="slide-area">
          { results }
        </div>
        <Link to="/testimonials">
          See More
        </Link>
      </Card>
    </div>
  )
};

export default TestimonialSlider;

const style = css`

  overflow: hidden;

  .statement {
    margin-top: 1rem;
  }

  .testimonial {
    color: #3b5998;
  }

  .slide-area {
    position: relative;
    height: 12rem;
    margin-top: -1rem;
    margin-right: 1.5rem;
  }

  .animation {
    position: absolute;
    display: block;
    width: 100%;
    animation-duration: 130s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .item-1 {
    animation-name: anim-1;
  }

  .item-2 {
    animation-name: anim-2;
  }

  .item-3 {
    animation-name: anim-3;
  }

  .item-4 {
    animation-name: anim-4;
  }

  .item-5 {
    animation-name: anim-5;
  }

  .item-6 {
    animation-name: anim-6;
  }

  .item-7 {
    animation-name: anim-7;
  }

  .item-8 {
    animation-name: anim-8;
  }

  .item-9 {
    animation-name: anim-9;
  }

  .item-10 {
    animation-name: anim-10;
  }

  .item-11 {
    animation-name: anim-11;
  }

  .item-12 {
    animation-name: anim-12;
  }

  .item-13 {
    animation-name: anim-13;
  }

  .item-14 {
    animation-name: anim-14;
  }

  .item-15 {
    animation-name: anim-15;
  }

  .item-16 {
    animation-name: anim-16;
  }

  .item-17 {
    animation-name: anim-17;
  }

  .item-18 {
    animation-name: anim-18;
  }

  .item-19 {
    animation-name: anim-19;
  }

  .item-20 {
    animation-name: anim-20;
  }


  @keyframes anim-1 {
    0%, 1% { left: -100%; opacity: 0; }
    1%, 4% { left: 5%; opacity: 1; } 
    5%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-2 {
    0%, 5% { left: -100%; opacity: 0; }
    6%, 9% { left: 5%; opacity: 1; }
    10%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-3 {
    0%, 10% { left: -100%; opacity: 0; }
    11%, 14% { left: 5%; opacity: 1; }
    15%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-4 {
    0%, 15% { left: -100%; opacity: 0; }
    16%, 19% { left: 5%; opacity: 1; }
    20%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-5 {
    0%, 20% { left: -100%; opacity: 0; }
    21%, 24% { left: 5%; opacity: 1; }
    25%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-6 {
    0%, 25% { left: -100%; opacity: 0; }
    26%, 29% { left: 5%; opacity: 1; }
    30%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-7 {
    0%, 30% { left: -100%; opacity: 0; }
    31%, 34% { left: 5%; opacity: 1; }
    35%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-8 {
    0%, 35% { left: -100%; opacity: 0; }
    36%, 39% { left: 5%; opacity: 1; }
    40%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-9 {
    0%, 40% { left: -100%; opacity: 0; }
    41%, 44% { left: 5%; opacity: 1; }
    45%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-10 {
    0%, 45% { left: -100%; opacity: 0; }
    46%, 49% { left: 5%; opacity: 1; }
    50%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-11 {
    0%, 50% { left: -100%; opacity: 0; }
    51%, 54% { left: 5%; opacity: 1; }
    55%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-12 {
    0%, 55% { left: -100%; opacity: 0; }
    56%, 59% { left: 5%; opacity: 1; }
    60%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-13 {
    0%, 60% { left: -100%; opacity: 0; }
    61%, 64% { left: 5%; opacity: 1; }
    65%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-14 {
    0%, 65% { left: -100%; opacity: 0; }
    66%, 69% { left: 5%; opacity: 1; }
    70%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-15 {
    0%, 70% { left: -100%; opacity: 0; }
    71%, 74% { left: 5%; opacity: 1; }
    75%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-16 {
    0%, 75% { left: -100%; opacity: 0; }
    76%, 79% { left: 5%; opacity: 1; }
    80%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-17 {
    0%, 80% { left: -100%; opacity: 0; }
    81%, 84% { left: 5%; opacity: 1; }
    85%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-18 {
    0%, 85% { left: -100%; opacity: 0; }
    86%, 89% { left: 5%; opacity: 1; }
    90%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-19 {
    0%, 90% { left: -100%; opacity: 0; }
    91%, 94% { left: 5%; opacity: 1; }
    95%, 100% { left: 110%; opacity: 0; }
  }

  @keyframes anim-20 {
    0%, 95% { left: -100%; opacity: 0; }
    96%, 99% { left: 5%; opacity: 1; }
    100%, 100% { left: 110%; opacity: 0; }
  }
`;