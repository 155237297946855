/** @jsx jsx */

import React from 'react';
import { NavItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import { withTheme } from 'emotion-theming';

type Props = {
  href: string;
  name: string;
  notification?: boolean | number;
  value?: string;
  onClick: Function;
};

const LinkItem: React.FC<Props> = ({ href, name, notification, value, onClick }) => {
  // Prevent badge showing when alerts count is 0 or less
  const notificationBadge = notification && notification > 0 ? (
    <Badge className="link-badge" color="danger" pill={true}>
      {value}
    </Badge>
  ) : null;
  
  return (
    <NavItem>
      <Link to={href} css={style} className="nav-link" onClick={() => onClick()}>
        {name}{' '}
        { notificationBadge }
      </Link>
    </NavItem>
  );
}

export default withTheme(LinkItem);

const style = ({ colors }) => css`
  font-size: 1.4rem;
  color: ${colors.black};
  font-weight: 300;

  &:hover {
    color: #00008b;
    transition: color ease-in-out 0.2s;
    text-decoration: none;
  }

  .link-badge {
    font-size: 1.15rem;
  }
`;
