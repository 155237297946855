/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import { Row, Col } from 'reactstrap';

import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { CardSco } from 'components';
import filterPrimaryType from 'utils/filterImageType';

const SecondChanceOffers: React.FC = () => {

  const [{ data, isLoading }] = useFetch('/sco', reducer);

  const filterTypeId = (idArr, idNumber) => idArr.filter(id => id.image_type_id === idNumber);

  const display = !isLoading && data && data.length > 0
    ? <React.Fragment>
        <Row>
        <div className="title-container">
          <h1 className="h1-title">You Have Second Chance Offers!</h1>
        </div>
        <hr/>
        </Row>
        <Row>
          {data
            ? data.map(sco => (
                <Col sm="12" md="6" lg="4" xl="3" style={{ marginBottom: '2rem' }} key={sco.id}>
                  <CardSco
                    id={sco.id}
                    headerTitle={sco.product_description.short_name}
                    price={sco.offer_price}
                    image={`https://images.serrf.com/${sco.product_description.category_id}/5/${sco.product_description_id}.jpg`}
                  />
                </Col>
              ))
            : null}
        </Row>
      </React.Fragment>
    : null;

  return (
    <React.Fragment>
      { display }
    </React.Fragment>
  )
}

export default SecondChanceOffers;