/** @jsx jsx */

import React, { useContext } from 'react';
import { css, jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ROUTE_PUSH } from 'constants/appContext';

import { AppContext } from 'context/AppProvider';

type FeaturedAuction = {
  external_auction_id: BigInteger;
  short_description: string;
}

type Props = {
  item: FeaturedAuction;
}

const FeaturedGovRow: React.FC<Props> = ({ item: { short_description, external_auction_id } }) => {
  const { dispatch } = useContext(AppContext);

  const onClick = () => {
    dispatch({ type: ROUTE_PUSH, payload: `/externalauction/${external_auction_id}` });
  };

  return (
    <Row css={style} onClick={ onClick }>
      <Col sm={12} lg={9}>
        <img
          src={`https://images.serrf.com/externalauctions/${external_auction_id}.jpg`}
          onError={onError}
          className="thumbnail"
          alt="item thumbnail"
          />
        <div className="description">{short_description}</div>
      </Col>
      <Col sm={12} lg={3} className="btn-container">
        <Link to={`/externalauction/${external_auction_id}`} className="btn more-btn">
          Get More Information!
        </Link>
      </Col>
    </Row>
  );
}

const onError = (element) => {
  element.target.onerror = null;
  element.target.src="https://cdn.policeauctions.com/images/no_car_image.png"
}

const FeaturedGovAuction: React.FC = () => {
  const { state: { preloadData: { featured } } } = useContext(AppContext);
  return (
    <React.Fragment>
      <h3>Featured Government Auctions</h3>
      <Col>
      { featured ? featured.map((item, index) => (
          <FeaturedGovRow key={ index } item={ item } />
        ))
      : null }
      </Col>
    </React.Fragment>
  )
}

export default FeaturedGovAuction;

const style = css`
  cursor: pointer;
  min-height: 10rem;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 1rem;
  box-shadow: 0 1px 1px rgba(0,0,0,0.05);
  margin-bottom: 2rem;
  padding-top: 1rem;

  .description {
    margin-left: 16rem;
    padding-right: 1rem;
    font-size: 14px;
    font-weight: 300;
  }

  .thumbnail {
    display: block;
    max-width: 15rem;
    float: left;
    padding: 4px;
    margin-bottom: 20px;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }

  .btn-container {
    text-align: center;
    padding: 1rem;
  }

  .more-btn {
    color: #333333;
    background-color: #F5F5F5;
    border-color: #DDDDDD;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
  }
`;
