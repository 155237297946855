import React from 'react';
import { Alert } from 'reactstrap';

const Synced: React.FC<{ manufactureName: string }> = ({ manufactureName }) => (
  <Alert color="success">
    <p>Your login has already synced with {manufactureName}.</p>
  </Alert>
);

export default Synced;
