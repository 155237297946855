/** @jsx jsx */

import { useEffect, useState } from 'react';
import { jsx, css } from '@emotion/core';

export type AppleSignInSuccess = {
  authorization: {
    code: string;
    id_token: string;
  };
  user?: {
    email?: string;
    name?: string;
  };
};

export type AppleSignInResponse = (args: AppleSignInSuccess) => void;

//TODO: Handle the types later
type AppleSignIn = {
  clientId: string;
  scope?: string;
  redirectURI: string;
  responseType?: string;
  responseMode?: string;
  usePopup?: boolean;
  appleSignInResponse?: AppleSignInResponse;
};

type AppleID = {
  auth: {
    init: (options: AppleSignIn) => void;
  };
};

type AppleStyleProps = {
  buttonStyle?: {
    width?: string;
    height?: string;
    color?: 'black' | 'white';
    border?: boolean;
  };
};

type Styles = Pick<NonNullable<AppleStyleProps['buttonStyle']>, 'width' | 'height'>;

type Props = AppleSignIn & AppleStyleProps;

const AppleSignIn: React.FC<Props> = ({
  clientId,
  scope,
  redirectURI,
  responseType,
  responseMode,
  usePopup = false,
  appleSignInResponse,
  buttonStyle = {
    width: '21rem',
    height: '4.1rem',
    color: 'black',
    border: true
  }
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const appleLoadHandler: EventListener = () => {
    setIsLoaded(true);
    (window as unknown as Window & { AppleID: AppleID }).AppleID.auth.init({
      clientId,
      scope,
      redirectURI,
      responseType,
      responseMode,
      usePopup //or false defaults to false
    });
  };

  const onAppleSignInOnSuccess = (data: CustomEvent<AppleSignInSuccess>) => {
    appleSignInResponse!(data.detail);
  };

  useEffect(() => {
    const source = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    const scriptSrc = document.createElement('script');
    scriptSrc.src = source;
    scriptSrc.async = true;

    document.body.appendChild(scriptSrc);

    scriptSrc.addEventListener('load', appleLoadHandler);

    document.addEventListener('AppleIDSignInOnSuccess', onAppleSignInOnSuccess as EventListener);

    return () => {
      document.removeEventListener('load', appleLoadHandler);
      document.body.removeChild(scriptSrc);
    };
  }, []);

  return isLoaded ? (
    <div
      id="appleid-signin"
      css={styles(buttonStyle)}
      data-color={buttonStyle.color}
      data-border={buttonStyle.border}
      data-type="sign in"
    />
  ) : null;
};

const styles = ({ width, height }: Styles) => css`
  width: ${width};
  height: ${height};
`;

export default AppleSignIn;
