import * as React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Input, Checkbox, LoadingButton, Loading } from 'components';
import { Props } from 'types/pages/Register/partials/registerForm';
import { BlockButton } from 'components';

const validation = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirm Password does not match')
    .required('Confirm password is required'),
  terms: Yup.bool().oneOf([true], 'You must accept the Terms and Conditions')
});

export const RegisterForm: React.FC<Props> = ({ onSubmit, onErrors, isLoading }) => {
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const onRegisterSubmit = async (values: any) => {
    // if (!executeRecaptcha) {
    //   return;
    // }
    // await executeRecaptcha("register");

    onSubmit(values);
  };
  const LoadButton = <LoadingButton>Please wait...</LoadingButton>;
  const SubmitButton = (
    <BlockButton type="submit" color="green">
      Create My Account
    </BlockButton>
  );
  const button = isLoading ? LoadButton : SubmitButton;
  return (
    <Formik
      css={styles}
      initialValues={{
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        terms: false,
        newsletter: false
      }}
      validationSchema={validation}
      validateOnChange={false}
      onSubmit={onRegisterSubmit}
      render={({ handleChange, touched, errors }) => (
        <Form onClick={onErrors(errors)}>
          <Input
            label="Email Address"
            placeholder="Your Email"
            name="email"
            onChange={handleChange}
            isError={touched.email && errors.email}
            errorMessage={errors.email}
            invalid={errors.email ? true : false}
          />
          <Input
            label="Username"
            placeholder="Username"
            name="username"
            onChange={handleChange}
            isError={touched.username && errors.username}
            errorMessage={errors.username}
            invalid={errors.username ? true : false}
          />
          <Input
            label="Password"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            isError={touched.password && errors.password}
            errorMessage={errors.password}
            invalid={errors.password ? true : false}
            type="password"
          />
          <Input
            label="Repeat Password"
            placeholder="Password"
            name="confirmPassword"
            onChange={handleChange}
            isError={touched.confirmPassword && errors.confirmPassword}
            errorMessage={errors.confirmPassword}
            invalid={errors.confirmPassword ? true : false}
            type="password"
          />
          <Checkbox
            name="terms"
            onChange={handleChange}
            isError={touched.terms && errors.terms}
            errorMessage={errors.terms}
            value="terms"
            textElement={
              <p>
                I agree to the <Link to="/terms">Terms of Service</Link> &{' '}
                <Link to="/privacy">Privacy Policy</Link>
              </p>
            }
          />
          <Checkbox
            textElement="Subscribe to our Newsletter"
            name="newsletter"
            onChange={handleChange}
            value="0"
          />
          { button }
        </Form>
      )}
    />
  );
}

const styles = css`
  .form-wrapper {
    margin-bottom: 1.2rem;
  }
`;
