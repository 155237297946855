import React from 'react';
import { Alert } from 'reactstrap';

const AuctionActiveAlert: React.FC<{ isActive: boolean }> = ({ isActive, children }) =>
  isActive ? (
    <Alert color="warning">
      { children }
    </Alert>
  ) : null;

export default AuctionActiveAlert;
