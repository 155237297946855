import React, { useReducer } from 'react';
import { Formik } from 'formik';

import MethodForm from './partials/MethodForm';
import history from 'utils/history';
import { Col } from 'reactstrap';

const PaymentMethods = ({ match }) => {
  document.title = 'Police Auctions - Payment Methods';
  const _onSubmit = ({ method }) => {
    history.push(`/payment/address/${method}/${match.params.id}`);
  };
  return (
    <Col lg={4} md={6} sm={6}>
      <Formik initialValues={{ method: 'paypal', auction: match.params.id }} onSubmit={_onSubmit} render={props => <MethodForm {...props} />} />
    </Col>
  );
};

export default PaymentMethods;
