/** @jsx jsx */

import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Link, withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';

import history from 'utils/history';
import { Layout } from 'components';
import styles from 'styles/ForgotPasswordReset';

const ForgotPasswordSent: React.FC = () => {
  document.title = 'Police Auctions - Forgot Password Sent';
  useEffect(() => {
    if (history.action !== 'PUSH') {
      history.replace('/forgot');
    }
  }, []);

  return (
    <Layout css={styles}>
      <h2>Forgot Password Email Sent To Your Account</h2>
      <p>
        Please check your email, then <Link to="/login">login here</Link>
      </p>
      <Alert color="success">
        <p>Please check your email for the reset password link.</p>
        <br />
        <p>
          Your reset password email should be received quickly, but it may arrive in your spam folder, so please check
          for that. Also, please note that over-aggressive email filtering by the email providers may even result in
          failure to receive the email.
        </p>
        <br />
        <p>
          If you do not receive the reset password email, please contact us by phone at{' '}
          <span className="contact">714 556-4900</span> during support hours.
        </p>
        <br />
        <p>
          Our hours of support are Monday through Friday 8-4 pm pacific time, not including holidays. Our friendly staff
          will be happy to help you
        </p>
      </Alert>
    </Layout>
  );
};

export default withRouter(ForgotPasswordSent);
