/** @jsx jsx */

import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import style from 'styles/Phone';
import { Container, Col, Row } from 'reactstrap';
const Phone: React.FC = () => {
  document.title = 'Police Auctions - Phone Apps';

  const showCurrentYear = () => {
      return new Date().getFullYear();
  }

  useEffect(() => {
    if (document && typeof document !== 'undefined') {
      ((d, s, id) => {
        const fjs = d.getElementsByTagName(s)[d.getElementsByTagName(s).length - 1];
        if (d.getElementById(id)) return;
        const js = d.createElement(s);
        (js as any).id = id;
        (js as any).src = `//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.11&appId=302184056577324`;
        if(fjs != null && fjs.parentNode != null)
          fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  });;

  return (
    <div css={style}>
      <section id="hero" className="hero">
        <div className="hero-inner">
          <Container>
            <header className="header">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 logo">
                  <a href="https://policeauctions.com/" title="Police Auctions"><img src="https://cdn.policeauctions.com/images/policeauction12-half.png" alt="Police Auctions Logo" className="header-logo"/></a>
                </div>
                <nav className="col-lg-4 menu offset-lg-4 col-md-5 offset-md-3 col-sm-8">
                  <ul>
                    <li>
                      <div className="fb-like" data-href="https://www.facebook.com/PoliceAuctions1/" data-layout="standard" data-action="like" data-size="small" data-show-faces="true" data-share="true"></div>
                    </li>
                  </ul>
                </nav>
              </div>
            </header>
            <div className="row">
              <div className="col-lg-6 col-md-7 offset-lg-1 col-xs-12 intro">
                <h1 className="title">Join Free. Bid Free. Bids Start at $1</h1>
                <h2 className="subtitle">Download Police Auctions now and bid on Certified Diamonds, Jewelry, Collectible Coins, Comics, and so much more! Bidding starts at $1. And Bidding is Free! Join now.</h2>
                <a href="https://itunes.apple.com/us/app/police-auctions/id1436071596" target="_blank" className="app-store">
                  <img src="https://cdn.policeauctions.com/images/mobile-app/img/appstore-button.png" data-at2x="https://cdn.policeauctions.com/images/mobile-app/img/appstore-button@2x.png" alt="App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.policeauctions.android" target="_blank" className="play-store">
                  <img src="https://cdn.policeauctions.com/images/mobile-app/img/playstore-button.png" data-at2x="https://cdn.policeauctions.com/images/mobile-app/img/playstore-button@2x.png" alt="Play Store" />
                </a>
              </div>
              <div className="col-lg-5 col-md-5  col-xs-12 phone">
                  <img className="phone-img app-screenhot" src="https://cdn.policeauctions.com/images/landing/shot-main.png" alt="First App Screen" />
              </div>
            </div>
          </Container>
        </div> 
      </section>
      <section id="features" className="features">
        <div className="container">
          <div className="row feature">
            <div className="offset-lg-2 col-lg-3 col-lg-pull-5 col-md-5 col-md-pull-7 col-sm-4 col-sm-pull-8 col-xs-12 device ">
                  <img className="phone-img app-screenhot" src="https://cdn.policeauctions.com/images/landing/shot-1.png" alt="Second App Screen" />
            </div>
            <div className="col-lg-5 col-lg-push-5 col-md-7 col-md-push-5 col-sm-8 col-sm-push-4 col-xs-12 feature-info">
              <h3>Search Certified Diamonds, Jewelry, Collector coins, and More!</h3>
              <hr className="seperator" />
              <h4>Search through our live auctions!</h4>
              <p>Browse our exciting $1 No Reserve Auctions, sorting them by category, end time, lowest price, highest price, however you chose. We make finding what your looking for so easy.</p>
            </div>
          </div>
          <hr className="feature-seperator" />
          <div className="row feature">
            <div className="offset-lg-2 col-lg-5 col-md-7 col-sm-8 col-xs-12 feature-info">
              <h3>Our alert system makes winning easy, and keeps you up to date on all things Police Auctions.</h3>
              <hr className="seperator" />
              <h4>You’ll have to chose to lose.</h4>
              <p>Our state of the alert system keeps you up to date on all your bidding wherever you go! Get alerts about all the auctions your bidding on, get outbid we’ll let you know so you can bid again!</p>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-4  col-xs-12 device ">
            <img className="phone-img app-screenhot" src="https://cdn.policeauctions.com/images/landing/shot-2.png" alt="First App Screen" />
            </div>
          </div>
          <hr className="feature-seperator" />
          <div className="row feature">
            <div className="offset-lg-2 col-lg-3 col-lg-pull-5 col-md-5 col-md-pull-7 col-sm-4 col-sm-pull-8 col-xs-12 device">

            <img className="phone-img app-screenhot" src="https://cdn.policeauctions.com/images/landing/shot-3.png" alt="First App Screen" />
            </div>
            <div className="col-lg-5 col-lg-push-5 col-md-7 col-md-push-5 col-sm-8 col-sm-push-4 col-xs-12 feature-info">
              <h3>Joining is Free and Easy!</h3>
              <hr className="seperator" />
              <h4>One click Facebook sign up, and you can start bidding!</h4>
              <p>Quickly and securely create your account with your Facebook or an email and you’re off! Joining and bidding is free and easy, get started today! </p>
            </div>
          </div>
        </div>
      </section>
      <section id="quote" className="quote">
        <div className="container">
          <div className="col-lg-12">
            <blockquote>
                <cite>Excellent. Shop on this site often and have never been disappointed .</cite>
                - by Mary on 10-23-2018
            </blockquote>
          </div>
        </div>
      </section>
      <section id="bottom" className="bottom">
        <div className="container">
          <div className="col-lg-6 offset-lg-3">
            <a href="https://policeauctions.com/" title="Police Auctions"><img src="https://cdn.policeauctions.com/images/policeauction12-half.png" alt="Police Auctions"/></a>
            <p>The Police Auctions app is available in the App Store and the Play store Download today for free!</p>
            <img src="https://cdn.policeauctions.com/images/mobile-app/img/app-icon.png" className="app-icon" alt="Police Auction App Icon" />
            <a href="https://itunes.apple.com/us/app/police-auctions/id1436071596" target="_blank" className="app-store">
                <img src="https://cdn.policeauctions.com/images/mobile-app/img/appstore-button.png" data-at2x="https://cdn.policeauctions.com/images/mobile-app/img/appstore-button@2x.png" alt="App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.policeauctions.android" target="_blank" className="play-store">
                <img src="https://cdn.policeauctions.com/images/mobile-app/img/playstore-button.png" data-at2x="https://cdn.policeauctions.com/images/mobile-app/img/playstore-button@2x.png" alt="Play Store" />
            </a>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="container">
          <div className="col-lg-6 menu offset-lg-3">
            <ul>
              <li><a href="https://www.policeauctions.com/terms" target="self">Terms of use</a></li>
              <li>
                <div className="fb-like" data-href="https://www.facebook.com/PoliceAuctions1/" data-layout="button_count" data-action="like" data-size="small" data-show-faces="true" data-share="false"></div>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 offset-lg-3 col-sm-12">
            <p>Copyright © 2001-{showCurrentYear()}, PoliceAuctions.com all rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  )
};

export default Phone;
