import React, { useState } from 'react';
import { range, map } from 'lodash/fp';

import { usePost } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import Star from './Star';

type Props = {
  id: number
  currentRating: null | number
}

const StarRating: React.FC<Props> = ({id, currentRating}) => {
  const [rating, setRating] = useState(0);
  const [postData, setPostData] = usePost('/review', reducer);

  const handleRating = (ratingNum: number) => {
    setRating(ratingNum);
    setPostData({schedule_id: id, rating: ratingNum});
  }

  // Build all five stars for rating
  const realRating = (rating ? rating : (currentRating ? currentRating : rating));
  const stars = map((num: number) => (
    <Star key={`star-rating-${num}`} rating={num} actualRating={realRating} handleClick={handleRating} />
  ))(range(1, 6));

  return (
    <div className="star-rating-row">
      <p>How would you rate this product you received?</p>
      { stars }
    </div>
  );
};

export default StarRating;