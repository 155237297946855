/** @jsx jsx */

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { css, jsx } from '@emotion/core';

import { BREAKPOINTS as BP } from 'constants/emotionConfig';

type Props = {
  showAd?: boolean;
}

export const PhonePage: React.FC<Props> = ({ children, showAd = true }) => {
  useEffect(() => {
    window.scrollTo(0,0);
  });

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>{children}</Col>
      </Row>
    </React.Fragment>
  );
}

const style = css`
  padding-top: 2rem;
  min-height: 75vh;
  padding-left: 8rem;
  padding-right: 8rem;

  @media (max-width: ${BP.MD}) {
    padding: 2rem 2rem;
    width: 100%;
  }
`;
