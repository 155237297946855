import React, { useContext } from 'react';
import { Col } from 'reactstrap';

import CategoryListing, { CardList } from './CategoryListing';
import TestimonialSlider from './TestimonialSlider';
import HotSpot from './HotSpots';
import Spotlight from './Spotlight';
import TwitterTimeline from './TwitterTimeline';
import { AppContext } from 'context/AppProvider';

type Props = {
  categories: CardList[];
}

const Sidebar: React.FC<Props> = ({ categories }) => {
  const {state: { user }} = useContext(AppContext);
  const isPaidUser = user.user_status_id == 3 || user.user_status_id == 4;

  return (
    <Col lg={3} md={3} xl={2} className="d-none d-lg-block">
      <TestimonialSlider />
      <CategoryListing data={categories} />
      <HotSpot isPaidUser={isPaidUser} />
      <Spotlight />
      <TwitterTimeline />
    </Col>
  );
};

export default Sidebar;
