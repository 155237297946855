/** @jsx jsx */
import React, { useContext, useState } from 'react';
import { jsx } from '@emotion/core';
import { Card, CardBody, Button, Col, Alert } from 'reactstrap';
import { map, get } from 'lodash/fp';

import { reviewStyles } from 'styles/Payment';
import { CreditCardDropIn } from 'components';
import history from 'utils/history';
import { AppContext } from 'context/AppProvider';
import CheckPayment from './partials/CheckPayment';

const StandardReview = ({ match }) => {
  document.title = 'Police Auctions - Payment Review';
  const [errors, setErrors] = useState(null);
  const { state } = useContext(AppContext);

  // Verify the state contains the selected address otherwise, reroute to the MyWins page
  if (!state.payment.selected_address.id) {
    history.push('/my-wins');
  }

  // Handle the edit address button click and route back to address with the URL params
  const _editAddress = () => {
    history.push(`/payment/address/${match.params.method}/${match.params.id}`);
  };

  const { selected_address } = state.payment;

  const phone = (selected_address.phone && selected_address.phone.length > 0) ? selected_address.phone : 'N/A';
  const email = (selected_address.email && selected_address.email.length > 0) ? selected_address.email : 'N/A';

  // Generate the Alert for any errors that happen from paying
  const reviewErrors = errors ? <Alert color="danger">{map((message: string) => <p>{message}</p>)(errors)}</Alert> : null;
  return (
    <Col lg={4} md={6} sm={6}>
      <div css={reviewStyles}>
        {reviewErrors}
        <Card>
          <CardBody>
            <p className="review-name">
              {selected_address.first_name} {selected_address.last_name}
            </p>
            <p>{selected_address.street1}</p>
            {selected_address.street2 ? <p>{selected_address.street2}</p> : null}
            <p>
              {selected_address.city} {selected_address.state.code}, {selected_address.country.country_code}{' '}
              {selected_address.zip_code}
            </p>
            <hr />
            <p>Phone Number: {phone}</p>
            <p>Email: {email}</p>
            <div className="edit-block">
              <Button color="primary" onClick={_editAddress}>
                <p>Edit Address</p>
              </Button>
            </div>
          </CardBody>
        </Card>
        {match.params.method === 'credit-card' ? (
          <div>
            <CreditCardDropIn id={match.params.id} payment={state.payment} setErrors={setErrors} />
          </div>
        ) : (
          <CheckPayment id={match.params.id} payment={state.payment} setErrors={setErrors} />
        )}
      </div>
    </Col>
  );
};

export default StandardReview;
