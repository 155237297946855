import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { map, find, filter, get } from 'lodash/fp';

import { AlertMessage, Input, BlockButton } from 'components';

const AddressForm: React.FC<any> = ({ values, handleChange, setFieldValue, touched, errors }) => {
  const address = find(({ id }) => id == values.id)(values.current_user_addresses);
  useEffect(() => {
    // TODO: Find a better way to do this with Formik if possible.
    setFieldValue('first_name', '');
    setFieldValue('last_name', '');
    setFieldValue('street1', '');
    setFieldValue('street2', '');
    setFieldValue('city', '');
    setFieldValue('zip_code', '');
    setFieldValue('email', '');
    setFieldValue('phone', '');
    setFieldValue('country_codes_id', 2);
    setFieldValue('state_code_id', 1);
    if (address && get('address_label')(address) !== 'New') {
      setFieldValue('first_name', get('first_name')(address));
      setFieldValue('last_name', get('last_name')(address));
      setFieldValue('street1', get('street1')(address));
      setFieldValue('street2', get('street2')(address) || '');
      setFieldValue('city', get('city')(address));
      setFieldValue('zip_code', get('zip_code')(address));
      setFieldValue('email', get('email')(address) || '');
      setFieldValue('phone', get('phone')(address) || '');
      setFieldValue('country_codes_id', get('country_codes_id')(address));
      setFieldValue('state_code_id', get('state_code_id')(address));
    }
  }, [values.id]);
  // Override the default handle change so errors can be reset on dropdown change
  const _handleChange = (e) => {
    values.resetErrors();
    handleChange(e);
  }

  return (
    <Form>
      <AlertMessage color="info" show={values.method === 'check'}>
        <p>
          Address must match credit card billing address. We cannot accept check payments from outside the United States.
        </p>
      </AlertMessage>
      <Input type="select" label="Choose an Existing Address, or Enter a New One" value={values.id} name="id" onChange={_handleChange}>
        {
          map(({id, address_label}) => (
            <option key={`address-${address_label}`} value={id}>{address_label}</option>
          ))(values.current_user_addresses)
        }
      </Input>
      <Input type="text" label="First Name" name="first_name" value={values.first_name} onChange={handleChange} />
      <Input type="text" label="Last Name" name="last_name" value={values.last_name} onChange={handleChange} />
      <Input type="select" label="Country" name="country_codes_id" value={values.country_codes_id} onChange={handleChange}>
        {
          map(({id, country}) => (
            <option key={`country-${id}`} value={id}>{country}</option>
          ))(values.countries)
        }
      </Input>
      <Input type="text" label="Address Line 1" name="street1" value={values.street1} onChange={handleChange} />
      <Input type="text" label="Address Line 2" name="street2" value={values.street2} onChange={handleChange} />
      <Input type="text" label="City/Town" name="city" value={values.city} onChange={handleChange} />
      <Input type="select" label="State" name="state_code_id" value={values.state_code_id} onChange={handleChange}>
        {
          map(({id, state}) => (
            <option key={`state-${id}`} value={id}>{state}</option>
          ))(filter(({ country_id }) => country_id == values.country_codes_id )(values.states))
        }
      </Input>
      <Input type="text" label="Zip Code" name="zip_code" value={values.zip_code} onChange={handleChange} />
      <Input
        type="text"
        label="Phone Number"
        name="phone"
        value={values.phone}
        onChange={handleChange}
        isError={touched.phone && errors.phone}
        errorMessage={errors.phone}
        invalid={errors.phone ? true : false}
      />
      <Input type="text" label="Email" name="email" value={values.email} onChange={handleChange} />
      <BlockButton type="submit" color="green">
        Continue
      </BlockButton>
    </Form>
  )
}


export default AddressForm;