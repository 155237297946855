/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Alert } from 'reactstrap';

import { SupportHintProps, KnowledgeBaseRecord } from 'types/pages/Support/support';
import { CollapsibleCard } from 'components';

const SupportHints: React.FC<SupportHintProps> = ({ knowledge_base }) => {

  const list = (knowledge_base && knowledge_base.length > 0)
    ? knowledge_base.map((hint: KnowledgeBaseRecord, index: number) => (
        <CollapsibleCard title={ hint.question } key={ `collapse-key-${index}` } showIcon={true} opened={false}>
          { hint.answer }
        </CollapsibleCard>
      ))
    : null;

  return (
    <div css={ style }>
      <Alert color="info">
        <p>Click on a question below to see the answer. If you don't find what you were looking for you can contact us below.</p>
      </Alert>
      { list }
    </div>
  )
}

export default SupportHints;

const style = css`
  .alert {
    margin-top: .75rem;
  }

  .card {
    margin-top: 1rem;
  }
`;