import actions from 'constants/alerts';

const alerts = (state, action) => {
  switch (action.type) {
    case actions.NOTIFICATION_REQUEST: {
      return {
        ...state,
        errors: null,
        isLoading: true,
        data: false,
        total: 0,
      };
    }
    case actions.NOTIFICATION_SUCCESS: {
      const { data, total } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: data,
        total: total,
        pagination: action.payload
      };
    }
    case actions.NOTIFICATION_FAILURE: {
      const { message, errors } = action.payload;
      return {
        ...state,
        isLoading: false,
        errors: {
          message,
          errors
        }
      };
    }

    case actions.MARK_READ_REQUEST: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case actions.MARK_READ_SUCCESS: {
      return {
        ...state,
        isSaving: false,
      };
    }
    case actions.MARK_READ_FAILURE: {
      const { message, errors } = action.payload;
      return {
        ...state,
        isSaving: false,
        errors: {
          message,
          errors
        }
      };
    }

    case actions.MARK_ALL_REQUEST: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case actions.MARK_ALL_SUCCESS: {
      return {
        ...state,
        data: [],
        total: 0,
        pagination: false,
      };
    }
    case actions.MARK_ALL_FAILURE: {
      const { message, errors } = action.payload;
      return {
        ...state,
        isSaving: false,
        errors: {
          message,
          errors
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default alerts;
