import React from 'react';
import { Formik, Form, FormikActions } from 'formik';
import * as Yup from 'yup';

import AsyncButton from './AsyncButton';
import { LoadingButton, Input, Loading } from 'components';

const validate = Yup.object().shape({
  username: Yup.string().required('Username is required'),
});

type Fields = {
  username: string;
};

type OnSubmit = (values: Fields, actions: FormikActions<Fields>) => void;

type Props = {
  onSubmit: OnSubmit;
  isSubmitting: boolean;
  userIdExists: number | null;
};

const EditUserNameForm: React.FC<Props> = ({ userIdExists, onSubmit, isSubmitting }) =>
  userIdExists ? (
    <Formik
      initialValues={{ username: '' }}
      validationSchema={validate}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={({ errors, handleChange, touched }) => (
        <Form>
          <Input
            label="Choose your Username"
            placeholder="Your Username"
            isError={touched.username && errors.username}
            name="username"
            invalid={errors.username ? true : false}
            onChange={handleChange}
            errorMessage={errors.username}
          />
          <AsyncButton
            isSubmitting={isSubmitting}
            loadButton={<LoadingButton> saving...</LoadingButton>} />
        </Form>
      )}
    />
  ) : <Loading />;

export default EditUserNameForm;
