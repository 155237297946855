/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ListGroupItem } from 'reactstrap';

import { SectionProps } from 'types/pages/Knowledge_Base/KnowledgeBase';

const SkipAhead: React.FC<SectionProps> = ({ category }) =>  {
  
  const scrollToSection = (id) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
  }
  
  return (
    <div
      css={style}
      className="list-link"
      key={`list-link-${category.id}`}
      onClick={() => scrollToSection(category.id)}
    >
      <ListGroupItem >{ category.name }</ListGroupItem>
    </div>
  )
};

const style = css`
  cursor: pointer;
  li:hover {
    text-decoration: underline;
  }
`;
export default SkipAhead;