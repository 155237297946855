import React from 'react';

import { Props as LoadingButtonProps } from 'components/form/LoadingButton';
import { BlockButton } from 'components';

type Props = {
  isSubmitting: boolean;
  loadButton: React.ReactElement<LoadingButtonProps>;
};

const SaveButton = () => (
  <BlockButton color="green" type="submit">
    Save
  </BlockButton>
);

const AsyncButton: React.FC<Props> = ({ isSubmitting, loadButton }) => (isSubmitting ? loadButton : <SaveButton />);

export default AsyncButton;
