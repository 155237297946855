import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from 'context/AppProvider';
import { withPagination } from 'utils/withPagination';
import useTimeStamp from 'hooks/timeStamp';
import { CardAuctionList } from 'components';
import AuctionActiveAlert from './partials/AuctionActiveAlert';
import useBidStream from 'hooks/bidStream';

const MyBids: React.FC<any> = ({ data }) => {
  const {
    state: { user }
  } = useContext(AppContext);
  const bidStream = useBidStream(data, user);
  const [timeStamp] = useTimeStamp();

  return (
    <React.Fragment>
      <p>
        <b>Please note:</b>&nbsp;Won items do not appear on this list. Please check your <Link to="/my-wins">My Wins</Link>{' '}
        list for won items and tracking.
      </p>
      <br />
      <br />
      <br />
      <AuctionActiveAlert isActive={data && !data.length} />
      <CardAuctionList
        data={bidStream}
        serverTime={timeStamp}
        enableStatusAlert={true}
        userId={user.id}
        username={user.username}
      />
    </React.Fragment>
  );
};

export default withPagination('/user/bids', ['My Bids', 'fas fa-list'])(MyBids);
