import React, { useState } from 'react';
import { Button } from 'reactstrap';

import { usePost } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';

type Props = {
  isWatching: boolean;
  item_id: number;
  watchlist_type_id: number;
  isLoggedIn: boolean;
};

const WatchlistToggleButton: React.FC<Props> = ({ isWatching, item_id, isLoggedIn }) => {
  const [watching, setWatching] = useState(isWatching);

  const [postData, setPostData] = usePost('/watchlist', reducer);

  const onClick = () => {
    setWatching((prevWatching) => !prevWatching);

    if (!isLoggedIn) {
      setWatching(false);
      window.location.href = '/login';
      return;
    }

    setPostData({
      id: item_id,
      type: 9,
    });
  };

  return item_id ? (
    <Button onClick={() => onClick()} className="btn-watchlist">
      {watching ? 'Remove from' : 'Add to'} Watchlist
      <i className={`${watching ? 'fas' : 'far'} fa-heart heart-icon-watchlist`}></i>
    </Button>
  ) : null;
};

export default WatchlistToggleButton;
