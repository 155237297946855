import React, { useState } from 'react';
import { get } from 'lodash/fp';

import { BlockButton } from 'components';
import history from 'utils/history';
import request from 'utils/request';
import GoogleTagManager from 'utils/googleTagManager';
import { PIXEL_ID } from 'constants/config';

const CheckPayment = ({ id, payment, setErrors }) => {
  const [isLoading, setIsLoading] = useState(false);
  const _handlePayment = () => {
    const { amount, tax, shipping_cost, selected_address } = payment;
    setIsLoading(true);
    request
      .postPayment('/check/payment', {
        auction_id: id,
        amount: parseFloat(amount + tax + shipping_cost),
        site_id: 1,
        id: selected_address.id,
        pixel_id: PIXEL_ID
      })
      .then(
        ({ data }) => {
          setIsLoading(false);
          GoogleTagManager.conversion({
            eventID: id,
            id: data.id,
            productName: get('auction.control.product_description.short_name')(payment),
            productCode: get('auction.control.product_description.item_code.item_code')(payment),
            productCategory: get('auction.control.product_description.category.category')(payment),
            amount: amount.toFixed(2),
            tax: tax.toFixed(2),
            shipping: shipping_cost.toFixed(2),
            total: (tax + amount + shipping_cost).toFixed(2)
          });
          history.push(`/payment/check/receipt/${id}`);
        },
        error => {
          setIsLoading(false);
          setErrors(error.response.data);
        }
      );
  };
  return (
    <div className="button-block">
      <BlockButton type="button" color="green" onClick={_handlePayment}>
        {isLoading ? <i className="fa fa-spin fa-spinner"></i> : 'Submit Payment'}
      </BlockButton>
    </div>
  );
};

export default CheckPayment;
