/** @jsx jsx */

import { ReactNode } from 'react';
import { jsx, css } from '@emotion/core';
import { Link } from 'react-router-dom';

import Card from './Card';
import BidButton from 'components/BidButton';
import Timer from 'components/timer/Timer';
import SoldAuction from 'components/SoldAuction';
import AuctionPrice from 'components/AuctionPrice';
import BidStatusAlert from 'components/BidStatusAlert';

import useTimer from 'hooks/timer';

type Timer = {
  serverTime: number;
  endTime: Date;
};

type Props = {
  headerTitle: string;
  price: string;
  image: string;
  timerData: Timer;
  url: string;
  headerTitleRight?: ReactNode;
  bdp: string;
  enableStatusAlert?: boolean;
  statusAlertMessage?: string;
  statusAlertColor?: string;
  auctionId: string;
  showBdp: boolean;
};

const CardAuction: React.FC<Props> = ({
  headerTitle,
  headerTitleRight,
  bdp,
  auctionId,
  price,
  image,
  timerData,
  url,
  enableStatusAlert,
  statusAlertMessage,
  statusAlertColor,
  showBdp,
  ...rest
}) => {
  const [timer] = useTimer(timerData.serverTime, timerData.endTime);

  return (
    <Card
      headerTitle={headerTitle}
      style={{ height: '100%' }}
      headerTitleRight={<SoldAuction showBdp={showBdp} bdp={bdp} timer={timer} auctionId={auctionId} />}
      isSold={timer <= 0}
      {...rest}
    >
      <div css={style} className="auction-container item-col">
        <AuctionPrice price={price} />
        <div className="row-fluid text-center product-image-container">
          <div className="product-image-row">
            <div className="product-image-cell">
              <Link to={url}>
                <img alt="some_img" src={image} className="img-responsive center-block preview-image" />
              </Link>
            </div>
          </div>
        </div>
        <div className="bottom-container">
          <div className="timer-container">
            <Timer serverTime={timerData!.serverTime} endTime={timerData!.endTime} />
          </div>
          <BidStatusAlert
            isOpen={enableStatusAlert}
            statusAlertColor={statusAlertColor}
            statusAlertMessage={statusAlertMessage}
          />
          <Link to={url} className="bid-buttons">
            <BidButton />
          </Link>
        </div>
      </div>
    </Card>
  );
};

const style = css`
  .auction-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    text-align: center;
  }

  .card-body {
    height: 100%;
  }

  .bottom-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .h2-timer {
      font-weight: 300;
      font-size: 2.5rem;
    }
  }

  .bid-buttons {
    width: 100%;
    font-size: 1.43rem;
    padding: 0;
  }

  .item-col * {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .product-image-container {
    display: table;
    min-height: 400px;
    text-align: center;
    width: 100%;
    padding-bottom: 5px;
  }

  img {
    max-height: 375px;
    max-width: 375px;
    margin: 0 auto;
  }

  .product-image-row {
    display: table-row;
    height: 100%;
    text-align: center;
  }

  .product-image-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  @media screen and (max-width: 2080px) {
    img {
      max-height: 300px;
      max-width: 345px;
    }
    .product-image-container {
      min-height: 323px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 2013px) {
    img {
      max-height: 300px;
      max-width: 265px;
    }
  }

  @media screen and (max-width: 1646px) {
    img {
      max-height: 260px;
      max-width: 230px;
    }
    .product-image-container {
      min-height: 309px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 1448px) {
    img {
      max-height: 216px;
      max-width: 230px;
    }
    .product-image-container {
      min-height: 270px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 1448px) and (min-width: 1199px) {
    .item-col.col-lg-3 {
      width: 33.333%;
    }
  }

  @media screen and (max-width: 1333px) {
    img {
      max-height: 216px;
      max-width: 188px;
    }
    .product-image-container {
      min-height: 230px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 1199px) {
    img {
      max-height: 375px;
      max-width: 202px;
    }
    .product-image-container {
      min-height: 306px;
    }
  }

  @media screen and (max-width: 767px) {
    img {
      max-height: none;
      max-width: 355px;
    }

    .product-image-container {
      min-height: 100%;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 780px) {
    .navbar-nav > li > a {
      padding: 13px 12px;
    }
  }

  @media screen and (max-width: 400px) {
    img {
      max-height: none;
      max-width: 290px;
    }
  }

  @media screen and (max-width: 370px) {
    img {
      max-height: none;
      max-width: 260px;
    }
  }
`;

export default CardAuction;
