
import { css } from '@emotion/core';

export default css`

color: #222;
font-size: 1em;
line-height: 1.4;
font-size: 16px;
background: #fff;

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

p, h1, h2, h3, h4, h5, a {
    font-family: "Lato", sans-serif;
    color: #393e46;
    margin: 0 0 16px 0;
}

p {
    color: #455a73;
}

h1 {
    color: #7b5db4;
    font-size: 48px;
    line-height: 64px;
    font-weight: 700;
}

h2 {
    color: #303840;
    font-size: 20.8px;
    line-height: 32px;
}

h3 {
    font-size: 28.8px;
    line-height: 40px;
    font-weight: 700;
    color: #2670c0;
}

h4 {
    font-size: 20.8px;
    font-weight: 300;
    color: #303840;
}

img {
    max-width: 100%;
    height: auto;
}

header {
    padding: 32px 0;
    width: 100%;
    background: none;
}

header nav ul {
    text-align: right;
    margin-top: 12px;
}

header nav ul li {
    list-style: none;
    display: inline-block;
    margin-right: 32px;
}

header nav ul li a {
    color: rgba(0, 0, 0, 0.8);
}

.hero {
    width: 100%;
    background: url("https://cdn.policeauctions.com/images/mobile-app/img/bg.jpg") no-repeat left top;
    background-size: cover;
    background-position: center;
}

.hero .intro {
    margin-top: 96px;
}

.hero .phone .app-screen {
    position: absolute;
    left: 77px;
    width: 276px;
    top: 127px;
}

.hero .hero-inner {
    padding: 32px;
    background-color: rgba(255, 255, 255, 0.9);
}

.features {
    width: 100%;
    z-index: 999;
    position: relative;
    margin-top: -200px;
    background: #fff;
    padding: 80px 0 32px 0;
}

.features .device .app-screen {
    position: absolute;
    left: 52px;
    width: 182px;
    top: 69px;
}

.features .feature {
    max-height: 400px;
    margin-bottom: 96px;
}

.features .feature .feature-info {
    position: relative;
    top: 40px;
}

.features .feature .feature-info .seperator {
    width: 150px;
    border: 3px solid #f79453;
    background: #f79453;
}

.quote {
    width: 100%;
}

.quote .container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 64px;
}

.quote blockquote {
    border: 0px;
    padding: 0 32px;
    text-align: center;
    background-image: url("https://cdn.policeauctions.com/images/mobile-app/img/quote-left@2x.png"), url("https://cdn.policeauctions.com/images/mobile-app/img/quote-right@2x.png");
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    background-size: 25px;
    font-weight: 300;
    font-style: italic;
    color: #303840;
}

.quote blockquote cite {
    display: block;
    font-size: 24px;
    font-weight: 700;
    font-style: inherit;
    margin-bottom: 16px;
}

.bottom {
    width: 100%;
    text-align: center;
    padding: 64px 0;
}

.bottom .container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 64px;
}

.bottom p {
    padding: 16px;
    font-weight: 700;
    font-size: 21px;
}

.bottom .app-icon {
    border-radius: 20px;
    width: 100px;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
}

.footer {
    width: 100%;
    padding: 0;
    text-align: center;
}

.footer ul li {
    display: inline-block;
    margin-right: 16px;
}

.footer ul li a {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
}

.footer .menu {
    margin-bottom: 10px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .hero .phone .app-screen {
        position: absolute;
        // left: 74px;
        // width: 259px;
        // top: 120px;
        left: 62px;
        width: 188px;
        top: 120px;
    }

    .features .feature {
        max-height: 400px;
    }

    #features .feature .device .app-screen {
        position: absolute;
        left: 74px;
        width: 258px;
        top: 120px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .hero {
        text-align: center;
    }

    .hero .intro {
        margin: 0px;
    }

    .hero .phone .app-screen {
      left: 50%;
      margin-left: -91px;
      width: 185px;
      top: 86px;
    }

    .features .device {
        top: 0px;
    }

    .features .feature {
        margin-bottom: 105px;
    }

    .features .device .app-screen {
      position: absolute;
      left: 58px;
      width: 187px;
      top: 87px;
    }
}

@media (max-width: 768px) {
    header {
        padding: 16px 0;
    }

    header .logo {
        text-align: center;
    }

    header .logo img {
        max-width: 150px !important;
    }

    .hero {
        text-align: center;
    }

    .hero .intro {
        margin-top: 0;
    }

    .hero h1 {
        font-size: 32px;
        line-height: 40px;
    }

    .hero .phone img {
        max-width: 300px !important;
    }

    .hero .phone .app-screen {
        position: absolute;
        left: 50%;
        width: 50%;
        top: -20px;
        margin-top: 96px;
        margin-left: -103px;
    }

    .features {
        margin-top: 0px;
        top: -200px;
        padding: 48px 0 0 0;
    }

    .features .device {
        max-height: 400px;
        overflow: hidden;
        text-align:center;
    }

    .features .device img {
        max-width: 300px !important;
    }

    .features .device .app-screen {
        width: 209px;
        margin-top: 96px;
        margin-left: 5px;
        top: 0px;
        left: 18%;
    }

    .features .feature {
        max-height: 100%;
        margin-bottom: 0;
    }

    .features .feature-seperator {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .features .feature .feature-info .seperator {
      width: 80%;
      margin-left: 10%;
      margin-right: 14%;
    }

    .features .feature .feature-info {
        top: 0px;
        text-align: center;
    }

    .features .feature .feature-info h3 {
        font-size: 24px;
        line-height: 32px;
    }

    .quote, .bottom, footer {
        top: -200px;
        position: relative;
    }
}

.hidden {
    display: none !important;
}

.visuallyhidden {
    border: 0;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    display: inline-block;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
}


.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
}


.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}


@media only screen and (max-width: 768px) {
    nav.menu {
        margin-top: 50px;
    }
}

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }


    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
  }
`;