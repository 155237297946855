/** @jsx jsx */

import React from 'react';
import { Alert } from 'reactstrap';
import { jsx } from '@emotion/core';

import { BlockButton, LoadingButton } from 'components';
import { OfferFinalProps } from 'types/pages/Daily_Deals_View/dailyDealsView';

const OfferFinal: React.FC<OfferFinalProps> = ({ onRejectOffer, onAcceptOffer, price, accepting }) => {

  const acceptbutton = accepting
    ? <LoadingButton>Loading...</LoadingButton>
    : <BlockButton onClick={onAcceptOffer} color="green">
        Click to accept our offer of ${price}
      </BlockButton>

  return (
    <React.Fragment>
      {(parseInt(price) > 0)
        ? <React.Fragment>
            <Alert color="warning">
              <p>This is our final offer! Would you like to accept it?</p>
            </Alert>
            {acceptbutton}
            <BlockButton onClick={onRejectOffer} color="danger">
              Reject Final Offer
            </BlockButton>
          </React.Fragment>
        : <Alert color="warning">
            <p>You ran out of time to make an offer!</p>
          </Alert> }
    </React.Fragment>
  )
}

export default OfferFinal;