import { css } from '@emotion/core';
import { BREAKPOINTS as BP } from 'constants/emotionConfig';

const styles = ({ colors }) => css`
  .bid-button {
    &:disabled {
      background-color: #1e3966;
      opacity: 0.8;
    }
  }

  padding: 0;
  .auction-id {
    font-size: 1.4rem;
    font-weight: normal;
  }

  .bid-price-container {
    .price {
      color: green;
    }
  }

  .bid-input-container {
    margin-bottom: 1rem;
    padding-right: 3.3rem;
    .bid-input {
      height: 100%;
    }
  }

  .product-line {
    margin: 2rem 0;
  }

  .list-header {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .product-list {
    font-size: 1.4rem;
    padding-left: 4rem;

    blockquote {
      padding: 1rem 2rem;
      margin: 0 0 2rem;
      font-size: 1.75rem;
      border-left: 0.5rem solid #eeeeee;
    }

    h1 {
      color: black;
      font-size: 20px;
      font-weight: normal;
    }
  }

  .alert-msg {
    padding: 1.7rem;
    font-size: 1.4rem;
    margin-bottom: 2rem;

    .alert-msg-span {
      padding-left: 0.7rem;
    }
  }

  .heart-icon-watchlist {
    color: white;
    font-size: 2rem;
    margin-left: 1rem;
  }

  .pay-now-btn {
    margin-bottom: 2rem;
  }

  .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .show>.btn-secondary.dropdown-toggle {
    background-color: #36b4c2;
    outline: none;
  }

  .btn-watchlist {
    color: white;
    background-color: #43e8fa;
    border-color: transparent;
    width: 100%;
    font-size: 1.5rem;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.2rem;
    border-radius: 0.4rem;
    outline: none;
    font-weight: bold;

    &:active {
      background-color: #36b4c2;
      outline: none;
    }
  }

  .animator {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .a-0 {
    transition-delay: 0s;
  }

  .a-1 {
    transition-delay: .10s;
  }

  .a-2 {
    transition-delay: .20s;
  }

  .a-3 {
    transition-delay: .30s;
  }

  .a-4 {
    transition-delay: .40s;
  }

  .animate:first-of-type {
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  .animate {
    -webkit-transform: scale(.95);
    -moz-transform: scale(.95);
    -o-transform: scale(.95);
    transform: scale(.95);
    will-change: transform;
  }

  .bid-history {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    .bid-history-user {
      padding: 1rem 1.5rem;
      font-size: 2rem;
      font-weight: normal;
      display: inline-flex;
      justify-content: space-between;
    }
    .badge {
      line-height: inherit;
      background-color: ${colors.blue};
      font-weight: normal;
      border-radius: 0.3rem;
    }
  }

  .bid-history-container {
    .p-inline {
      display: inline;
    }
    .max-bid-alert {
      font-size: 1.3rem;
      padding: 0.3rem;
    }
  }

  .tutorial {
    color: orange;
    font-size: 1.4rem;
  }

  .share-container {
    margin: 1rem; 0;
    .share {
      font-size: 4.4rem;
      padding: 0 1rem;
      &:hover {
        cursor: pointer;
      }
    }

    .share-ico {
      color: #6ebe2c;
    }

    .share-fb {
      color: #46579a;
    }

    .share-twitter {
      color: #69acea;
    }

    .share-linkedin {
      color: #4c7ab3;
    }

    .share-pinterest {
      color: #af473b;
    }

    .share-email {
      color: #b8c0c1;
    }

    @media (max-width: ${BP.LG}) {
      .share {
        font-size: 3.4rem;
      }
    }
  }

  @media (max-width: ${BP.MD}) {
    max-width: none;

    .image-container {
      height: 70rem;
    }

    .tutorial {
      display: none;
    }
  }

  @media (max-width: ${BP.LG}) {
    .product-body {
      font-weight: 300;
    }
  }
`;

export default styles;
