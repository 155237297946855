/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import { Nav } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SettingsNavItem } from 'components';

const WatchlistSidebar: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <Nav vertical={true} pills={true} css={style}>
    <SettingsNavItem title="Auctions I'm Watching" path={`${path}`} iconClassName="fa fa-user" />
    <SettingsNavItem title="Auctions I'm Winning" path={`${path}/winning`} iconClassName="fa fa-lock" />
    <SettingsNavItem title="Auctions I'm Losing" path={`${path}/losing`} iconClassName="fa fa-bullhorn" />
    <SettingsNavItem title="Daily Deals" path={`${path}/dailydeals`} iconClassName="fa fa-dollar-sign" />
    {/*<SettingsNavItem title="Unpaid Items" path={`${path}/unpaid`} iconClassName="far fa-credit-card" />*/}
    <SettingsNavItem title="Auction Listings" path={`${path}/listings`} iconClassName="fa fa-dollar-sign" />
  </Nav>
);

const style = css`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

export default withRouter(WatchlistSidebar);
