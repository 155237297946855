import { css } from '@emotion/core';

export default css`
  .title {
    font-size: 2.2rem;
  }

  .check {
    display: block;
    float: left;
    color: #8196a4;
    width: 2em;
    height: 2em;
    font-size: 1.7em;
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    text-align: center;
    padding: .4em .25em 0;
    margin-right: .5em;
    margin-top: .5rem;
  }

  .content  {
    font-size: 1.2em;
  }

  .time-stamp {
    color: #8196a4;
    font-size: 1rem;
    padding-left: 1rem;
  }

  .time {
    font-size: 1em;
  }

  .notice-row {
    padding-top: .5rem;
    padding-bottom: .5rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .rounded-circle {
    position: relative;
    border: 1px solid #dddddd;
  }

  .message {
    padding-left: 2rem;
  }
`;
