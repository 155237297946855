/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';

const PaymentInfo = () => (
  <React.Fragment>
    <img src="https://cdn.policeauctions.com/images/paypal.gif" alt="Paypal Payment"/>
    <img src="https://cdn.policeauctions.com/images/paymenttypes.jpg" alt="Support Payment Methods" />
    <p>Payment is due at the end of the offer session!</p>
    <p>Items not paid for within 20 minutes of agreed offer price shall be deemed forfeit.</p>
    <p>Items not paid for within 48 hours of auction end shall be deemed forfeit.</p>
    <p>
      Please note that items paid by credit card can only be shipped to the credit card billing address or an address
      that is verified by the credit card issuer. If you need to add a verified address to your credit card, use the 800
      number on the back of your credit card to make the addition.
    </p>
    <br />
  </React.Fragment>
);

export default PaymentInfo;
