import React from 'react';
import { Row, Col } from 'reactstrap';

import CardAuction from './CardAuction';
import dynamicAlertMessage from 'utils/dynamicAlertMessage';
import toUrl from 'utils/toUrlFromProductName';
import filterPrimaryType from 'utils/filterImageType';

//TODO: Add types for this file

const CardAuctionList: React.FC<any> = ({
  data,
  userId,
  serverTime,
  bidAlertStatus,
  enableStatusAlert,
  statusAlertMessage,
  username,
  ...rest
}) => (
  <Row>
    {data
      ? data.map(product => {
        /**
         * If there's a product is updated with a bid, the winning username will exist
         * if there's a username that exists, pass in username prop, otherwise userid prop
         */
        const user = product.username ? username : userId;
          return (
            <Col sm="12" md="6" lg="4" xl="3" style={{ marginBottom: '2rem' }} key={product.id}>
              <CardAuction
                headerTitle={product.control.product_description.short_name}
                price={
                  parseInt(product.opening_bid) > 1 && parseInt(product.sale_price) < 1
                    ? product.opening_bid
                    : product.sale_price
                }
                timerData={{ serverTime: serverTime, endTime: product.eventEnd }}
                image={`https://images.serrf.com/${product.control.product_description.category_id}/5/${product.control.product_description_id}.jpg`}
                url={`/auctions/${product.id}/${toUrl(product)}`}
                bdp={product.bdp}
                enableStatusAlert={userId ? enableStatusAlert : false}
                statusAlertColor={dynamicAlertMessage(user, product).color}
                statusAlertMessage={dynamicAlertMessage(user, product).message}
                auctionId={product.id}
                showBdp={parseInt(product.opening_bid) <= 1 && parseInt(product.bdp) > 0}
                {...rest}
              />
            </Col>
          );
        })
      : null}
  </Row>
);

export default CardAuctionList;
