import { css } from '@emotion/core';

export default css`
  .forgotPassword-wrapper {
    height: 73%;
    padding: 2rem 0;
    max-width: 80rem;
  }

  .btn-submit {
    background-color: #337ab7;
    border-color: #2e6da4;
    font-size: 1.8rem;
    border-radius: 0.5rem;
    padding: 1rem 1.6rem;
    margin-bottom: 1rem;
  }

  .btn-submit:hover {
    background-color: #2e6da4;
  }

  @media screen and (max-width: 768px) {
    .forgotPassword-wrapper {
      padding: 0 2rem;
    }
  }
`;
