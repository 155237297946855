import React from 'react';
import { Row, Col } from 'reactstrap';

import CardDailyDeal from './CardDailyDeal';
import filterPrimaryType from 'utils/filterImageType';

const CardDailyDealsList = ({ data }) => (
    <Row>
      {data
        ? data.map(dailydeal => (
            <Col sm="12" md="6" lg="4" xl="3" style={{ marginBottom: '2rem' }} key={dailydeal.id}>
              <CardDailyDeal
                headerTitle={dailydeal.product_description.short_name}
                image={filterPrimaryType(dailydeal.product_description.images).url}
                url={`/dailydeals/${dailydeal.id}`}
              />
            </Col>
          ))
        : null}
    </Row>
  );

export default CardDailyDealsList;
