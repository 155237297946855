/** @jsx jsx */

import React from 'react';
import { Badge, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/core';

type Props = {
  href: string;
  name: string;
  hasItem?: boolean;
  value?: string;
  bgColor: string;
};

const LinkItemDropdown: React.FC<Props> = ({ href, name, hasItem, value, bgColor }) => {
  const countBadge = hasItem ? (
    <Badge className="link-badge" color={bgColor} pill={true}>
      {value}
    </Badge>
  ) : null;
  return (
    <Link to={href} css={style}>
      <DropdownItem className="dropdownmenu-item">
        {name} {" "}
        { countBadge }
      </DropdownItem>
    </Link>
  );
}

export default LinkItemDropdown;

const style = css`
  font-size: 1.4rem;
  color: #000000;
  font-weight: 300;
  padding: 0;
  text-decoration: none;

  &:hover {
    color: #000000;
    text-decoration: none;
  }

  .link-badge {
    font-size: 1.15rem;
  }
  
  .dropdownmenu-item {
    padding-left: 0;
  }
`;
