import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';

type Props = {
  color: string;
}

export const BidAlert: React.FC<Props> = ({ children, ...rest }) => {
  const [isVisable, setIsVisable] = useState(true);

  useEffect(() => {
    const alertTimer = setTimeout(() => {
      setIsVisable(false);
    }, 10000);

    return () => {
      clearTimeout(alertTimer);
    };
  }, []);

  return (
    <Alert {...rest} isOpen={isVisable}>
      {children}
    </Alert>
  );
};
