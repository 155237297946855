import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { ReturnFormProps } from 'types/pages/Support_Return/form';
import { Input, SaveButton } from 'components';

const validation = Yup.object().shape({
  auction: Yup.string().required('Auction number is required'),
  type: Yup.string().required('Return type is required'),
  reason: Yup.string().required('Return reason is required'),
  description: Yup.string().required('Description is required'),
});

const ReturnForm: React.FC<ReturnFormProps> = ({ onSubmit, loading, auctionNumber }) => {
  return (
    <Formik
      initialValues={{ description: '', auction: auctionNumber, type: 2, reason: 1 }}
      enableReinitialize={ true }
      validationSchema={ validation }
      validateOnChange={ false }
      onSubmit={ onSubmit }
      render={({
        handleChange,
        touched,
        errors,
        values: { description, auction, type, reason }
      }) => (
        <Form>
          <Input
            label="Type of Request"
            name="type"
            type="select"
            onChange={ handleChange }
            value={ type }
            isError={ touched.type && errors.type }
            errorMessage={ errors.type }
            invalid={ errors.type ? true : false }
          >
            <option value="2">Refund</option>
            <option value="1">Replacement</option>
          </Input>
          <Input
            label="Auction #"
            name="auction"
            type="text"
            onChange={ handleChange }
            value={ auction }
            isError={ touched.auction && errors.auction }
            errorMessage={ errors.auction }
            invalid={ errors.auction ? true : false }
          />
          <Input
            label="Reason"
            name="reason"
            type="select"
            onChange={ handleChange }
            value={ reason }
            isError={ touched.reason && errors.reason }
            errorMessage={ errors.reason }
            invalid={ errors.reason ? true : false }
          >
            <option value="1">Unsatisfied</option>
            <option value="2">Defective</option>
            <option value="3">Wrong Item</option>
          </Input>
          <Input
            label="Description"
            name="description"
            type="textarea"
            onChange={ handleChange }
            value={ description }
            isError={ touched.description && errors.description }
            errorMessage={ errors.description }
            invalid={ errors.description ? true : false }
          />
          <SaveButton type="submit" disabled={ loading }>Submit Form</SaveButton>
        </Form>
      )}
    />
  );
}

export default ReturnForm;