import React from 'react';
import Synced from './Synced';

type Props = {
  isSynced: boolean;
  name: string;
};

const ButtonSyncContainer = ({ children }) => (
  <div className="d-flex justify-content-center align-items-center flex-column mb-3">{children}</div>
);

export const ButtonSync: React.FC<Props> = ({ isSynced, name, children }) =>
  isSynced ? <Synced manufactureName={name} /> : <ButtonSyncContainer>{children}</ButtonSyncContainer>;
