import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { TwoRowColumn, BlockButton } from 'components';
import { itemStatus, insuranceClaimStatus } from 'constants/auction';
import InternationalTrackingTooltip from './InternationalTrackingTooltip';
import ExpectedDeliveryTooltip from './ExpectedDeliveryTooltip';
import history from 'utils/history';

const RightDetails = ({ item }) => {
  const isShipped = item.shipping && !['0000-00-00', null].includes(item.ship_date);
  const shipped = isShipped ? (
    <TwoRowColumn title="Shipped On">
      <span>{item.shipping.ship_date}</span>
    </TwoRowColumn>
  ) : null;

  // Show the delivery dates for either expected or date delivered
  const hasTracking = item.shipping && item.shipping.shipping_tracking;
  const notDelivered = isShipped && hasTracking && item.shipping.shipping_tracking.tracking_status_id !== 2;
  const expectedDelivery =
    isShipped && notDelivered ? (
      <React.Fragment>
        <TwoRowColumn title="Est Delivery" tooltip={<ExpectedDeliveryTooltip />}>
          <span>
            {item.shipping.shipping_tracking.expected_delivery_window_start} to{' '}
            {item.shipping.shipping_tracking.expected_delivery_window_end}
          </span>
        </TwoRowColumn>
      </React.Fragment>
    ) : item.shipping && item.shipping.shipping_tracking ? (
      <TwoRowColumn title="Delivered">
        <span>{item.shipping.shipping_tracking.delivery_date}</span>
      </TwoRowColumn>
    ) : null;

  const claimReceived = insuranceClaim => {
    const daysSinceFormSent = insuranceClaim ? moment().diff(moment(insuranceClaim.date_form_sent), 'days') : 999;
    return insuranceClaim.insurance_claim_status_id === insuranceClaimStatus.FILE_SENT && daysSinceFormSent < 60 ? (
      <a href="js:void();" onClick={() => {history.push(`/support/claim/${insuranceClaim.id}`)}} title="File Claim">
        File Claim
      </a>
    ) : (
      <span>{get(insuranceClaim, 'date_form_returned', 'Pending')}</span>
    );
  };

  const insuranceClaim = item.insurance_claim && item.insurance_claim.insurance_claim_status_id !== insuranceClaimStatus.CANCELLED ? (
    <React.Fragment>
      <TwoRowColumn title="Claim Sent">
        <span>{item.insurance_claim.date_form_sent}</span>
      </TwoRowColumn>
      <TwoRowColumn title="Claim Received">{item.insurance_claim ? claimReceived(item.insurance_claim) : null}</TwoRowColumn>
    </React.Fragment>
  ) : null;

  const tracking =
    item.shipping && item.shipping.tracking ? (
      <TwoRowColumn title="Tracking #">
        <a href={item.shipping.tracking_url} title="Track Package" target="_blank" rel="noopener noreferrer">
          {item.shipping.tracking}
        </a>
      </TwoRowColumn>
    ) : null;

  const internationalTracking =
    item.shipping && item.shipping.international ? (
      <TwoRowColumn title="Intl. #" tooltip={<InternationalTrackingTooltip />}>
        {item.shipping.international.tracking_url.indexOf('http') > -1 ?
        <a
          href={item.shipping.international.tracking_url}
          title="Track International Package"
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.shipping.international.cn22_id}
        </a>
        : <p>{item.shipping.international.tracking_url}</p>}
      </TwoRowColumn>
    ) : null;

  const payButton =
    item.item_status_id === itemStatus.UNCLAIMED ? (
      <Row className="mt-5">
        <Col lg="12">
          <BlockButton color="green" onClick={() => {history.push(`/payment/methods/${item.id}`)}}>
            <p>Pay for this item</p>
          </BlockButton>
        </Col>
      </Row>
    ) : null;

  return (
    <Col lg="5" xs="12">
      <TwoRowColumn title="Won On">
        <span>{moment(item.event_end).format('YYYY-MM-DD')}</span>
      </TwoRowColumn>
      {shipped}
      {expectedDelivery}
      {insuranceClaim}
      {tracking}
      {internationalTracking}
      {payButton}
    </Col>
  );
};

export default RightDetails;
