/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/core';
import { Progress } from 'reactstrap';

type Props = {
  color?: string
  value: string
}

const ProgressBar: React.FC<Props> = ({ color, value, children}) =>
  <Progress color={color} value={value} css={style}><p>{children}</p></Progress>

const style = css`
  margin-bottom: 1rem;
  height: 2rem;
`;

export default ProgressBar;