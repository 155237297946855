import React from 'react';
import { Alert } from 'reactstrap';

const ThankYou: React.FC = () => (
  <Alert color="success">
    <p>Thank you for completing your Insurance Claim form. Customer service has been
    notified, and will take the required steps to complete this process.</p>
  </Alert>
);

export default ThankYou;