import { useEffect } from 'react';
import { ROUTE_PUSH } from 'constants/appContext';
import { Action } from 'types/actions';

const useRedirect = <T extends {}>(dispatch: React.Dispatch<Action>, state: T, to: string) => {
  useEffect(() => {
    if (state) {
      dispatch({ type: ROUTE_PUSH, payload: to });
    }
  }, [state]);
};

export default useRedirect;
