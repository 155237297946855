/** @jsx jsx */

import { useContext } from 'react';
import { jsx } from '@emotion/core';

import { withPagination } from 'utils/withPagination';
import { AuctionActiveAlert, CardAuctionList } from 'components';
import useBidStream from 'hooks/bidStream';
import useTimeStamp from 'hooks/timeStamp';
import { AppContext } from 'context/AppProvider';

const WatchlistWinning: React.FC<any> = ({ data }) => {
  document.title = 'Police Auctions - Watchlist Winning';

  const {
    state: { user }
  } = useContext(AppContext);
  const bidStream = useBidStream(data, user);
  const [timeStamp] = useTimeStamp();

  return (
    <div>
      <br />
      <AuctionActiveAlert isActive={data && !data.length}>
        <p><b>Oh no!</b>&nbsp;You are not winning any auctions right now.</p>
      </AuctionActiveAlert>;
      <CardAuctionList
        data={bidStream}
        serverTime={timeStamp}
        enableStatusAlert={true}
        userId={user.id}
      />
    </div>
  );
};

export default withPagination('/watchlist/winning', ["Winning Auctions", 'far fa-thumbs-up'])(WatchlistWinning);
