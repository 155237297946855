import actions from 'constants/async';

const about = (state, action) => {
  switch (action.type) {
    case actions.REQUEST: {
      return {
        ...state,
        errors: null,
        isLoading: true
      };
    }
    case actions.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    }
    case actions.FAILURE: {
      const { message, errors } = action.payload;
      return {
        ...state,
        isLoading: false,
        errors: {
          message,
          errors
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default about;
