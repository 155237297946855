import filterNullValues from 'utils/filterNullValues';
import { UserPayload } from 'types/authentication';
import { SocketData } from 'context/SocketProvider';

//TODO: Add more types for this file and do a little more refactoring

type Status = 'ending' | 'ended';

type AuctionData = {
  bids: Array<{ id: number | string; amount: string | number }>;
  ended: {
    schedule_id: number;
    status: Status;
    user_id: number;
  };
  schedule: {
    user_id: number;
  };
  hasEnded: boolean;
};

type PropExists<T extends object> = (data: T) =>
  <K extends keyof T>(propName: K) =>
    (name: string) => string | null;

type IsEnding<T> = T extends PropExists<infer R> ?
  <K extends keyof R>(statusValue: K) =>
   (data: R) => boolean : never;

//This transforms derived data to properly align the response from the server and socket updates.
const bidsTransform = ({ bids }) => bids.reduce((acc, curr) => acc.concat({ ...curr }), []);

const propExists: PropExists<AuctionData> = (data) => (propName) => {
  const getStreamingProp = data ? data[propName] : null;

  return (name) => (getStreamingProp ? getStreamingProp[name] : null);
};

const isEnding: IsEnding<typeof propExists> = (statusValue) => (data) => {
  const endingStreamObj = propExists(data)('ended');

  return endingStreamObj('status') === statusValue ? true : false;
};

const checkStreamingStatus = (statusValue) => (data) => {
  const isStreaming = isEnding(statusValue);

  return isStreaming(data) ? isStreaming(data) : data.hasEnded;
};

const greatestPrice: (amountData) => string = (amountData) =>
  bidsTransform(amountData).reduce((acc, { amount }) =>
    parseInt(amount) > parseInt(acc) ? amount : acc, 0)

const getWinningUserId = (data: AuctionData) => {
  const hasEnded = isEnding('ended');

  return hasEnded(data) ? data.ended.user_id : data.schedule.user_id;
}

const mergeObjects = (auctionData: any, socketData: SocketData["streamData"]) => {
  const auctionDataId = auctionData ? auctionData.schedule.id : -1;
  const socketIdMatchAuctionID = parseInt(socketData.id as string) === auctionDataId;
  const scheduleIDMatch = auctionData && auctionData.schedule.id === socketData.ended.schedule_id;
  const getEndedStream = auctionData && scheduleIDMatch ? socketData.ended : {};
  const updatedStream = socketIdMatchAuctionID ? filterNullValues({...socketData, ...getEndedStream }) : {}

  /**
   * The filterNullValues(...) return if the socketIdMatchAuctionID is true. The socketIdMatchAuctionID
   * determines whether the bid is made from a user, who matches with the current auction id.
   * 
   * If socketIdMatchAuctionID is false, the filterNullValues() where it returns the ended stream
   * and the socket data, it doesn't get returned. That's why we're unable to see ending being displayed.
   */

  return {
    ...auctionData,
    ...filterNullValues({...socketData, ...getEndedStream })
  }
};

/**
 * @description Takes in either server data or stream data to filter out other bids except for
 * current user bid(s). Returns the current user bid(s) so it can be used later to get the user's
 * highest price.
 *
 * @argument { object, object }
 *
 * @return { array }
 */
const getUserBids = (user: UserPayload, auction) =>
  auction && auction.bids
    ? auction.bids.filter(bid => user.id === bid.user_id || user.username === bid.username)
    : null;

/**
 * @description Gets the highest amount that was bid for the current user,
 * which takes in the returned array of objects from the getUserBids function to get the user's setmax
 *
 * @argument { array }
 *
 * @return { number }
 */
const getHighestUserBid = (userBids) =>
  userBids ? userBids.reduce((acc, curr) => Math.max(acc, curr.amount), 0) : null;

/**
 * @description Returns the highest bid for the user from either server or stream. Since the stream
 * data is updated after the server stream gets returned, the server will get returned by default.
 *
 * @argument { object, object, object }
 *
 * @return { number }
 */
const highestBidPrice = (server, stream, user) => {
  const serverBids = getUserBids(user, server);
  const streambids = getUserBids(user, stream);

  return streambids ? getHighestUserBid(streambids) : getHighestUserBid(serverBids);
};

export default {
  bidsTransform,
  greatestPrice,
  hasEnded: checkStreamingStatus('ended'),
  isEnding: isEnding('ending' as any),
  getWinningUserId: getWinningUserId,
  mergeObjects,
  highestBidPrice
};