/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Button
} from 'reactstrap';
import { Result } from 'types/External_Auctions/types';

type Props = {
  record: Result;
  isAutomotive: boolean;
};

const LeftColumn: React.FC<Props> = ({ isAutomotive, record }) => {
  const asset = isAutomotive ? 'no_car_image' : 'no_auction_image';
  const image = record.has_image
    ? `https://images.serrf.com/externalauctions/${record.id}.jpg`
    : `https://cdn.policeauctions.com/images/${asset}.png`;

  return (
    <Col xs="12" sm="4">
      <img
        className="image"
        src={image}
        alt="No Resource Available"
      />
    </Col>
  )
}

const CenterColumn: React.FC<Props> = ({ record, isAutomotive }) => {
  const type = isAutomotive ? 'Car' : 'External';
  return (
    <Col xs="12" sm="4">
      <h4 className="list-group-item-heading">
        { type } Auction #{ record.id }
      </h4>
      <p className="description">
        { record.name }<br/>
        {`${record.city}, ${record.state}`}<br/>
        { record.content_tag }<br/>
      </p>
    </Col>
  )
}

const RightColumn: React.FC<Pick<Props, "record">> = ({ record }) =>
  <Col xs="12" sm="4">
    { record.distance ?
      <h2 className="text-center">
        { record.distance } Miles Away
      </h2>
    : null }
    <Button color="primary" block className="view-btn">
      Get More Information!
    </Button>
  </Col>

const SearchResult: React.FC<Props> = ({ record, isAutomotive }) => {
  const route = isAutomotive ? 'carauction' : 'externalauction';
  return (
    <Link to={`/${route}/${record.id}`} className="result-link">
      <Row>
        <LeftColumn isAutomotive={ isAutomotive } record={ record } />
        <CenterColumn record={ record } isAutomotive={ isAutomotive } />
        <RightColumn record={ record } />
      </Row>
    </Link>
  );
};

export default SearchResult;