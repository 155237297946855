/** @jsx jsx */

import { useContext } from 'react';
import { jsx } from '@emotion/core';
import { Table } from 'reactstrap';

import { UpgradeLink, WatchlistToggleButton } from 'components';
import { AppContext } from 'context/AppProvider';

const PrimaryTable = ({ data }) => {

  const {
    state: { user }
  } = useContext(AppContext);

  return (
    <Table striped>
      <tbody>
        { user !== null ?
          <tr>
            <td className="title">Watching</td>
            <td className="info">
              <WatchlistToggleButton
                item_id={data.id}
                watchlist_type_id={10}
                isWatching={data.isWatching}
                isLoggedIn={user.id != null}
              />
            </td>
          </tr> : null }
        <tr>
          <td className="title">Recurring</td>
          <td className="info">{ data.recurring ? 'Yes' : 'No' }</td>
        </tr>
        <tr>
          <td className="title">Address</td>
          <td className="info">{ data.address }</td>
        </tr>
        <tr>
          <td className="title">City</td>
          <td className="info">{ data.city }</td>
        </tr>
        <tr>
          <td className="title">State</td>
          <td className="info">{ data.state ? data.state.state : '' }</td>
        </tr>
        <tr>
          <td className="title">Map</td>
          <td className="info">
            <UpgradeLink url={ data.mapUrl } />
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default PrimaryTable;