/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useFetch } from 'hooks/async';
import styles from 'styles/Unclaimed';
import reducer from 'reducers/unclaimed';
import { Loading } from 'components';
import UnclaimedTable from './partials/UnclaimedTable';

const Unclaimed: React.FC = () => {
  document.title = 'Police Auctions - Unclaimed';
  const [{ data, isLoading }] = useFetch('/unclaimed-money', reducer);

  const results = isLoading ? <Loading /> : <UnclaimedTable data={data} />;

  return (
    <div css={styles}>
      <div className="header">
        <h1 className="overlay">Unclaimed Money</h1>
      </div>

      <div className="description">
        <p>
          The Department of Treasury has millions of dollars in lost or forgotten assets from dormant bank accounts,
          uncashed checks, valuables left in safe deposit boxes and stock certificates. Because these properties were
          considered abandoned and unclaimed by the bank or company entrusted with them, they are turned over to the
          state, as required by law. The Department of Treasury is the custodian of these assets and returns them to
          their owners (or the owners' heirs) when they are rightfully claimed. Search for your state to claim your lost
          money!
        </p>
      </div>

      { results }
    </div>
  );
};

export default Unclaimed;
