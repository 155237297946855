import React from 'react';
import { ListGroupItem } from 'reactstrap';
import styled from '@emotion/styled';

type PlanListType = {
  children: string;
  checked?: boolean;
};

const CheckIconStyled = styled('i')({
  color: 'green',
  marginLeft: '1rem',
  '@media (max-width: 992px)': {
    marginLeft: 0
  }
});

const PlanListItem: React.FC<PlanListType> = ({ checked, children }) => (
  <React.Fragment>
    <ListGroupItem>
      {children}
      {checked && <CheckIconStyled className="fas fa-check hide-check" />}
    </ListGroupItem>
    <ListGroupItem className="d-lg-none" style={{ height: '3.8rem' }}>
      {checked && <CheckIconStyled className="fas fa-check" />}
    </ListGroupItem>
  </React.Fragment>
);

export default PlanListItem;
