import { useEffect, useContext } from 'react';
import { TimeStamp } from 'types/hooks';
import { AppContext } from 'context/AppProvider';

const useTimeStamp: TimeStamp = () => {
  const {
    state: { timeStamp },
    actions
  } = useContext(AppContext);

  useEffect(() => {
    actions.getServerTime();

    const updateTimer = setInterval(() => {
      actions.getServerTime();
    }, 60000);

    return () => clearInterval(updateTimer);
  }, []);

  return [timeStamp ? timeStamp.time : 0];
};

export default useTimeStamp;
