import React from "react";

import { Tooltip } from 'components';

const InternationalTrackingTooltip = () => (
  <Tooltip text="Your package will be handed off to your local post office for final delivery- this tracking code was assigned to your shipment and will work with your local post office.">
    <i className="fas fa-question-circle"></i>
  </Tooltip>
);

export default InternationalTrackingTooltip;