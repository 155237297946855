import generateQueryParam from 'utils/generateQueryParam';

//TODO: Do a little bit of refactoring

const getLastDirectoryPathName = (pathName: string) => pathName.match(/\w+([0-9+&%]+?\w+)+|(\w+)$/g)!.toString();

const replaceStr = (str: string) => str.toLowerCase().replace(/\s/g, '+').replace(/&/, '%26');

export const locationContext = modifyPathStr => (pathName, categories) =>
  categories.reduce((acc, curr) => modifyPathStr(pathName) === replaceStr(curr.name) ?
    { state: { name: curr.name, params: { [generateQueryParam(curr)]: curr.id } } } : acc, { });

export const createLocation = (location, locationState, context) => {
  const getLocationAlt = locationState(getLastDirectoryPathName)(location.pathname, context.categories);

  return location.params ? location : getLocationAlt;
}

export const featuredLocation = () => ({ pathname: '/auction/featured', state: { name: 'Featured', params: { featured: 1 } } });
