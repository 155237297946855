import { css } from '@emotion/core';

export default css`
  .header {
    background-image: url("https://cdn.policeauctions.com/images/old-money-blue.jpg");
    background-position: 0% 80%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.75rem;
    height: 15rem;
    text-shadow: black 0.3em 0.3em 0.3em;
    padding: 5rem 0;
  }

  .overlay {
    color: white;
    font-size: 2rem;
    margin: 2rem 1rem;
    padding-left: 5rem;
  }

  .description {
    padding: 1rem 0rem;
  }

  .state-link {
    font-size: 1.4rem;
    font-weight: 400;
  }
`;
