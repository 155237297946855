/** @jsx jsx */

import React, { useState, useContext, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import { get } from 'lodash';

import { FeaturedGovAuction, Paginator, DropdownSort, CardAuctionList, TestimonialSlider } from 'components';
import styles from 'styles/Home';
import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { AppContext } from 'context/AppProvider';
import Banner from './partials/Banner';
import useTimeStamp from 'hooks/timeStamp';
import SecondChanceOffers from './partials/SecondChanceOffers';
import useBidStream from 'hooks/bidStream';

const initialState = {
  page: 1,
  size: 20,
  order: 'asc'
};

const Home: React.FC<RouteComponentProps> = ({ location, history }) => {
  document.title = 'Police Auctions - Home';
  const [urlState, setUrlState] = useState('auctions/home');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [timeStamp] = useTimeStamp();
  const [urlParams, setUrlParams] = useState(initialState);
  const [{ data, isLoading }, setReload] = useFetch(urlState, reducer, {
    ...urlParams,
    ...queryString.parse(location.search)
  });
  const auctionData = get(data, 'data');
  const {
    state: { user }
  } = useContext(AppContext);
  const bidStream = useBidStream(auctionData, user);
  const [isUpdated, setIsUpdated] = useState(false);

  const onDropdownToggle = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const onPageClick = (page: number) => {
    if (urlParams.page !== 1) {
      setUrlState('auctions');
    }

    setUrlParams({ ...urlParams, page });
    setIsUpdated(true);
  };

  useEffect(() => {
    if (location.search) {
      setUrlParams({ ...urlParams, ...queryString.parse(location.search) });
    }

    if(!location.search) {
      setUrlParams({ ...urlParams, page: 1 });
      setReload(true);
    }

  }, [location]);

  useEffect(() => {
    const toQueryStr = queryString.stringify(urlParams);

    if (isUpdated) {
      history.push({
        search: `?${new URLSearchParams(toQueryStr)}`
      });
      setIsUpdated(false);
    }
  }, [urlParams]);

  useEffect(() => {
    if (location.search) {
      setReload(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (urlParams.page === 1) {
      setUrlState('auctions');
    }
  }, []);

  const onDropdownClick = event => {
    setUrlState('auctions');
    setUrlParams({ ...initialState, ...queryString.parse(event.target.value) });
    setIsUpdated(true);
  };

  return (
    <div css={styles}>
      {user.id ? <SecondChanceOffers /> : <Banner />}
      <div className="d-lg-none testimonial">
        <TestimonialSlider />
      </div>
      <div className="title-container">
        <h1 className="h1-title">$1 Auctions</h1>
        <DropdownSort isOpen={dropdownOpen} toggle={onDropdownToggle} onClick={onDropdownClick} />
      </div>
      <hr className="border-line" />
      <CardAuctionList data={bidStream} serverTime={timeStamp} />
      <div className="paginator-container">
        {data ? <Paginator isLoading={isLoading} pages={data} onClick={onPageClick} /> : null}
      </div>
      {data ? <FeaturedGovAuction /> : null}
    </div>
  );
};

export default withRouter(Home);
