/** @jsx jsx */

import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import AuctionSlider from 'components/auction_slider/AuctionSlider';

import 'react-awesome-slider/src/core/styles.scss';

const Carousel: React.FC = () => (
  <div className="image-contaner d-flex align-items-center w-100 h-100">
    <AuctionSlider autoSlide={true} duration="2.2s">
      <Link to="/register">
        <img src="https://cdn.policeauctions.com/images/carousel/slide-1.jpg" />
      </Link>
      <Link to="/register">
        <img src="https://cdn.policeauctions.com/images/carousel/slide-2.jpg" />
      </Link>
      <Link to="/register">
        <img src="https://cdn.policeauctions.com/images/carousel/slide-3.jpg" />
      </Link>
      <Link to="/register">
        <img src="https://cdn.policeauctions.com/images/carousel/slide-4.jpg" />
      </Link>
    </AuctionSlider>
  </div>
);

export default Carousel;
