import { UserPayload } from 'types/authentication';

const viewAuctionStream = (url: string, user: UserPayload) => {
  const itemId = url.match(/(?!auctions\/)\d+/);

  if(itemId) {
    return {
      user: user.id,
      items: [{ id: itemId[0], endTime: '' }]
    };
  }
};

export default viewAuctionStream;
