import React from 'react';

import Card from 'components/card/Card';
import CardList from 'components/card/CardList';
import ShowCardList from 'components/card/ShowCardList';

/**
 * TODO: Refactor the components by splitting presentation and
 * logic
 */

export type CardList = {
  name: string;
  display: string;
  is_fire: boolean;
  id: number;
  is_virtual: boolean;
  subList: CardList[];
};

type Props = {
  data: CardList[];
};

const CategoryListing: React.FC<Props> = ({ data }) => (
  <Card headerTitle="Live $1 Auctions">
    <CardList name="All Auctions" url={`/`} />
    <CardList
      name="Featured"
      isFire={true}
      url={{ pathname: '/auction/featured', state: { name: 'Featured', params: { featured: 1 } } }}
    />
    <ShowCardList data={data} />
  </Card>
);

export default CategoryListing;
