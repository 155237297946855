/** @jsx jsx */

import React, { useEffect, useReducer, useContext } from 'react';
import { css, jsx } from '@emotion/core';

import actions from 'constants/alerts';
import { Notification } from 'types/Alerts/row';
import history from 'utils/history';
import reducer from 'reducers/alerts';
import { Loading, AlertMessage, Paginator } from 'components';
import AlertList from './partials/AlertList';
import {
  fetchNotifications,
  markRead,
  markAllRead
} from 'actions/alerts';
import {
  Row,
  Col
} from 'reactstrap';
import { AppContext, decrementAlerts } from 'context/AppProvider';
import toUrl from 'utils/toUrlFromProductName';

const Alerts: React.FC = () => {
  document.title = 'Police Auctions - Alerts';
  const initialState = {
    isLoading: false,
    isSaving: false,
    data: false,
    errors: null,
    pagination: false,
    total: 0,
    page: 1
  }

  const { dispatch: contextDispatch } = useContext(AppContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const { data, total, isLoading, pagination, isSaving } = state;

  const onClick = (page: number) => {
    fetchNotifications(dispatch, page);
  }

  const markReadClick = (notification: Notification) => {
    markRead(dispatch, notification);
    navigateToReference(notification);
    decrementAlerts(contextDispatch, 1);
  }

  const markAllReadClick = () => {
    markAllRead(dispatch);
    decrementAlerts(contextDispatch, data.length);
  }

  const navigateToReference = (notification: Notification)  => {
    const { user_notification_type } = notification;

    //Re-shape the data to match the toUrl method
    const data = {
      id: notification.reference_id,
      control: {
        product_description: {
          name: notification.short_name
         }
      }
    };

    let href =  `/auctions/${data.id}/${toUrl(data)}`;

    if(user_notification_type.user_notification_reference_id === actions.REFERENCE_SUPPORT){
      href = `/support/tickets/${notification.reference_id}`
    }
    history.push(href);
  }

  useEffect(() => {
    fetchNotifications(dispatch, state.page);
  }, [state.page]);

  const loading = isLoading
    ? <Loading />
    : null;

  const empty = (data && total === 0)
    ? <AlertMessage
        color="warning"
        show={( data && total === 0 )}>
          There are no new notifications to see yet.
      </AlertMessage>
    : null;

  const alerts = (data && total > 0)
    ? <AlertList
        data={ data }
        disabled={ isSaving }
        markAllRead={ markAllReadClick }
        markRead={ markReadClick } />
    : null;

  return (
    <React.Fragment>
      <h2>Notifications</h2>
      <hr />
      <Row css={ style }>
        <Col>
          { loading }
          { empty }
          { alerts }
          <Paginator
            isLoading={ isLoading }
            pages={ pagination }
            onClick={ onClick }
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Alerts;

const style = css`
  .list-group-item {
    padding: 15px;
    cursor: pointer;
  }

  .mark-row {
    padding-bottom: 15px;
  }
`;