import { maxBy } from 'lodash';
import { pipe } from 'lodash/fp';

type BidResponse = {
  id: null | string;

  bids: Array<{
    id: number;
    amount: number;
    username: string;
  }>;
};

type ExtractBids<T> = T extends { bids: infer R } ? R : never;

type BidUpdateTransform = (bidResponse: BidResponse) => { id: null | string; bids: ExtractBids<BidResponse> };

type UpdateBidResponse = ReturnType<BidUpdateTransform>;

const bidUpdateTransform: BidUpdateTransform = bidsRes => ({ id: bidsRes.id, bids: bidsRes.bids });

const getLatestBidAmount = (bids: UpdateBidResponse) => maxBy(bids.bids, bid => bid.amount)!.amount;

const getUsername = (bids: UpdateBidResponse) => maxBy(bids.bids, bid => bid.amount)!.username;

const hasBidBefore = (bids: BidResponse, username) => bids.bids.some(bid => bid.username === username);

const recentAmount = (bidRes: BidResponse) => {
  const amount = pipe(bidUpdateTransform, getLatestBidAmount)(bidRes);

  return bidRes ? amount : null;
};

const transformBidRes = (bidRes: BidResponse) => (amount: ReturnType<typeof getLatestBidAmount> | null) => {
  return {
    id: bidRes.id,
    amount
  }
};

//TODO: Add some types for "aucgtionsData"
const updateBidAmount = (bidResponse: BidResponse, username: string | null = null) => auctionsData => {
  const amount = recentAmount(bidResponse);
  const bidRecentUpdate = transformBidRes(bidResponse)(amount);

  return (
    bidRecentUpdate &&
    auctionsData.reduce((acc, curr) => {
      if (curr.id.toString() === bidRecentUpdate.id) {
        curr.sale_price = bidRecentUpdate.amount;
        curr.username = getUsername(bidResponse)
      }

      if(curr.hasBidBefore !== true) {
        curr.hasBidBefore = hasBidBefore(bidResponse, username)
      }

      return acc.concat({ ...curr });
    }, [])
  );
};

export default updateBidAmount;
