import styled from '@emotion/styled';

const PlanCostText: React.FC = styled('h3')({
  textTransform: 'uppercase',
  '@media (min-width: 500px)': {
    fontSize: '1.3rem'
  },
  '@media (min-width: 768px)': {
    fontSize: '2rem'
  }
});

export default PlanCostText;