import React, { useReducer, useEffect, useState } from 'react';
import queryString from 'query-string';

import appReducer from 'reducers/appProvider';
import request from 'utils/request';
import { authActions } from 'actions/auth';
import { serverTimeActions, fetchServerTime } from 'actions/serverTime';
import { Loading } from 'components';
import checkUserLoading from 'utils/checkUserLoading';
import actions from 'constants/appContext';

export const initialState: any = {
  isLoading: false,
  user: {
    isLoading: null,
    id: null
  },
  errors: null,
  preloadData: {
    isLoading: null,
    featured: null
  },
  currencyFlag: { id: 2, imgName: 'United-States', name: 'US' },
  payment: {
    selected_address: {
      id: null,
      first_name: '',
      last_name: '',
      street1: '',
      street2: '',
      city: '',
      zip_code: '',
      country: { id: '', country_code: '' },
      state: { id: '', code: '' },
      phone: '',
      email: ''
    },
    tax: 0.0,
    amount: 0.0,
    shipping_cost: 0.0,
    auction: null,
    paypal: null
  },
  timeStamp: null,
  urlHistory: []
};

export const fetchUser = dispatch => {
  dispatch({ type: actions.USER_REQUEST });
  return request
    .get('/user')
    .then(({ data }) => {
      dispatch({ type: actions.USER_SUCCESS, payload: data });
    })
    .catch(error => {
      dispatch({ type: actions.USER_FAILURE, payload: { ...error } });
    });
};

export const fetchPreload = dispatch => {
  dispatch({ type: actions.PRELOAD_REQUEST });
  return request
    .get('/preload')
    .then(({ data }) => {
      dispatch({ type: actions.PRELOAD_SUCCESS, payload: data });
    })
    .catch(error => {
      dispatch({ type: actions.PRELOAD_FAILURE, payload: { ...error } });
    });
};

export const clearErrors = dispatch => {
  dispatch({ type: actions.CLEAR_ERRORS });
}

export const decrementAlerts = (dispatch, num) => {
  dispatch({ type: actions.DECREMENT_ALERTS, payload: {num} });
}

export const updateNewsletter = (dispatch, isSubscribed) => {
  dispatch({ type: actions.UPDATE_NEWSLETTER, payload: { isSubscribed } });
}

export const checkAffiliateTracking = () => {
  const key = "affiliate";
  if (localStorage.hasOwnProperty(key)){
    return;
  }
  const parsed = queryString.parse(window.location.search);
  const data = {
    affiliateId: parsed.afid,
    affiliateReferCodeId: parsed.aid ? parsed.aid : parsed.Refer,
    affiliateReferCode: parsed._REFER_
  }
  localStorage.setItem(key, JSON.stringify(data));
};

export const AppContext = React.createContext(initialState);

const isPreloading = ({ preloadData: { isLoading } }) => isLoading !== null && isLoading;

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [userLoading, setUserLoading] = useState(true);
  const storage = localStorage.getItem('token');
  const actions = { ...authActions(dispatch), ...serverTimeActions(dispatch) };
  const value = { state, dispatch, actions };
  const contentLoading = userLoading || isPreloading(state);

  useEffect(() => {
    fetchServerTime(dispatch);
    fetchPreload(dispatch);
    checkAffiliateTracking();
  }, []);

  useEffect(() => {
    if (!storage) {
      return;
    }

    fetchUser(dispatch);
  }, [storage]);

  checkUserLoading(state, storage)(setUserLoading);


  return contentLoading ? <Loading /> : <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
