import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Badge
} from 'reactstrap';

import status from 'constants/support';
import { TicketViewProps } from 'types/pages/Support_Tickets/supportTicket';
import { SaveButton } from 'components';

const Ticket: React.FC<TicketViewProps> = ({ record }) => {

  const badge = record.support_ticket_status_id === status.CLOSED
    ? <Badge color="success">Closed</Badge>
    : <Badge color="primary">Open</Badge>;

  const unread = record.read
    ? null
    : <Badge color="warning">Unread Message</Badge>;

  return (
    <Card className="ticket-card">
      <CardHeader className="size">
          <span className="bold">{`Ticket #${record.id}`}</span>
          <span className="float-right">
            { unread } { badge }
          </span>
      </CardHeader>
      <CardBody>
        <div className="size">
          { record.initial_message.message }
          <hr/>
          {`Opened on ${record.create_time}`}
          <Link to={`/support/tickets/${record.id}`} className="float-right">
            <SaveButton size="lg">See Responses</SaveButton>
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};

export default Ticket;