export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const USER_RESET = 'USER_RESET';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const IMPERSONATE_REQUEST = 'IMPERSONATE_REQUEST';
export const IMPERSONATE_SUCCESS = 'IMPERSONATE_SUCCESS';
export const IMPERSONATE_FAILURE = 'IMPERSONATE_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const APPLE_LOGIN_REQUEST = 'FB_LOGIN_REQUEST';
export const APPLE_LOGIN_SUCCESS = 'FB_LOGIN_SUCCESS';
export const APPLE_LOGIN_FAILURE = 'FB_LOGIN_FAILURE';

export const FB_LOGIN_REQUEST = 'FB_LOGIN_REQUEST';
export const FB_LOGIN_SUCCESS = 'FB_LOGIN_SUCCESS';
export const FB_LOGIN_FAILURE = 'FB_LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// Payment actions
export const CURRENCY_FLAG_UPDATE = 'CURRENCY_FLAG_UPDATE';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';

export const TIME_REQUEST = 'TIME_REQUEST';
export const TIME_SUCCESS = 'TIME_SUCCESS';
export const TIME_FAILURE = 'TIME_FAILURE';

export const PRELOAD_REQUEST = 'PRELOAD_REQUEST';
export const PRELOAD_SUCCESS = 'PRELOAD_SUCCESS';
export const PRELOAD_FAILURE = 'PRELOAD_FAILURE';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const DECREMENT_ALERTS = 'DECREMENT_ALERTS';

export const UPDATE_NEWSLETTER = 'UPDATE_NEWSLETTER';

export const ROUTE_PUSH = '@@ROUTE/PUSH';

export const HISTORY_PUSH = 'HISTORY_PUSH';

export default {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  USER_RESET,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  IMPERSONATE_REQUEST,
  IMPERSONATE_SUCCESS,
  IMPERSONATE_FAILURE,
  FB_LOGIN_REQUEST,
  FB_LOGIN_SUCCESS,
  FB_LOGIN_FAILURE,
  APPLE_LOGIN_REQUEST,
  APPLE_LOGIN_SUCCESS,
  APPLE_LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CURRENCY_FLAG_UPDATE,
  UPDATE_PAYMENT,
  CLEAR_PAYMENT,
  TIME_REQUEST,
  TIME_SUCCESS,
  TIME_FAILURE,
  PRELOAD_REQUEST,
  PRELOAD_SUCCESS,
  PRELOAD_FAILURE,
  CLEAR_ERRORS,
  DECREMENT_ALERTS,
  UPDATE_NEWSLETTER,
  ROUTE_PUSH,
  HISTORY_PUSH
};
