/**@jsx jsx */

import { Fragment } from 'react';
import { DropdownItem } from 'reactstrap';
import { css, jsx } from '@emotion/core';

type CountryType = {
  id: number;
  imgName: string;
  name: string;
};

type Props = {
  items: CountryType[];
  handleCountrySelection: (item: CountryType) => void;
}

export const DropdownCountryItems = ({ items, handleCountrySelection }: Props) => {
  const imgUrl =
    'https://cdn.policeauctions.com/images/flags/flags/flat/24';

  return (
    <Fragment>
        {
          items.map((item, index) => (
            <DropdownItem key={`${item}${index}`} onClick={() => handleCountrySelection(item)}>
              <a css={style} href="#policeauctions.com">
                <img
                  src={`${imgUrl}/${item.imgName}.png`}
                  alt={item.imgName}
                />
                {`(${item.name})`}
              </a>
            </DropdownItem>)
          )
      }
    </Fragment>
  );
};

const style = css`
  color: #000000;
  font-size: 1.5rem;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;
