import React from 'react';

const addZero = (time: number) => (time < 10 ? `0${time}` : time.toString());

const ShowTimer: React.FC<{ timer: number }> = ({ timer }) => {
  const multiplier = 1 / 60;
  const hours = Math.floor(timer * multiplier * multiplier);
  const minutes = Math.floor(timer * multiplier) % 60;
  const seconds = Math.floor(timer % 60);
  const redFontUnderFiveMin = timer < 300 ? 'text-danger' : '';
  const countDown = `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;

  return (
    <h2 className={`font-weight-light text-center ${redFontUnderFiveMin}`}>{ timer < 0 ? "00:00:00" : countDown}</h2>
  );
};

export default ShowTimer;
