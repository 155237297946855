/** @jsx jsx */

import React, { useContext } from 'react';
import { jsx } from '@emotion/core';

import GovLink from './GovLink';
import { AppContext } from 'context/AppProvider';
import { UserPayload } from 'types/authentication';

type OnClick = (e: React.MouseEvent<HTMLAnchorElement>) => void;

type Props = {
  to: string;
  name: string;
  onClick: OnClick;
  requiredAuth?: boolean;
};

const ExternalLink: React.FC<Props> = ({ to, name, onClick, requiredAuth = false }) => {
  const {
    state: { user }
  } = useContext(AppContext);
  const checkUserLoggedIn = (user: UserPayload) => user.id ? to : '/login';
  const linkUrl = requiredAuth ? checkUserLoggedIn(user) : to;
  const isPaidUser = user.user_status_id == 3 || user.user_status_id == 4;

  return (
    <GovLink link={linkUrl} onClick={onClick} isPaidUser={isPaidUser}>
      {name}
    </GovLink>
  );
};

export default ExternalLink;