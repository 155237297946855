import { useEffect, useState, useCallback } from 'react';

import { Timer } from 'types/hooks';

type Unix = {
  unix: any;
};

//const getServerTime = ({ unix }: Unix) => (msTime: number) => unix(msTime / 1000);

const createTime = (serverTime, endTime) => {
  var timeOffset = new Date().getTime() - serverTime;

  var timeNowUnix = new Date().getTime();

  var start = endTime - timeNowUnix + timeOffset;

  var timeLeftDate = new Date(start);
  var seconds = timeLeftDate.getTime() / 1000;

  return serverTime ? seconds : 0;
};

const useTimer: Timer = (serverTime, endTime) => {
  var timeOffset = new Date().getTime() - serverTime;

  var timeNowUnix = new Date().getTime();

  var start = endTime - timeNowUnix + timeOffset;

  var timeLeftDate = new Date(start);
  var seconds = timeLeftDate.getTime() / 1000;

  const timeDifference = serverTime ? Math.floor(seconds) : 0;

  const [timerState, setTimerState] = useState(timeDifference);

  useEffect(() => {
    setTimerState(timeDifference);

    const timer = setInterval(() => {
      setTimerState(prevTimer => prevTimer - 1);
    }, 1000);

    if (timerState <= 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [timeDifference]);

  return [timerState];
};

export default useTimer;
