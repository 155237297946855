/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Container } from 'reactstrap';

import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import style from 'styles/ExternalAuctions';
import { Loading, ResultList, SearchForm } from 'components';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

const filterEmptyObjValues = obj =>
  Object.entries(obj).reduce((acc, [key, value]) => (value !== '' ? { ...acc, [key]: value } : acc), {});

const GovAuctions: React.FC<RouteComponentProps> = ({ location, history }) => {
  document.title = 'Police Auctions - Government Auctions';
  const [urlParams, setUrlParams] = useState({
    page: 1
  });

  const [isUpdated, setIsUpdated] = useState(false);

  const [{ data, isLoading }, fetch] = useFetch('/external-auction', reducer, {
    ...queryString.parse(location.search),
    car: false
  });

  useEffect(() => {
    if (location.search) {
      setUrlParams({ ...urlParams, ...queryString.parse(location.search) });
    }
  }, []);

  useEffect(() => {
    const toQueryStr = queryString.stringify(urlParams);

    if (isUpdated) {
      history.push({
        search: `?${new URLSearchParams(toQueryStr)}`
      });
      setIsUpdated(false);
    }
  }, [urlParams]);

  useEffect(() => {
    setUrlParams({ ...queryString.parse(location.search)} as any)
    fetch(true);
  }, [location.search]);

  const onSubmit = event => {
    event.preventDefault();
    const filterObjs = filterEmptyObjValues(urlParams);
    setUrlParams({ ...filterObjs, page: 1 });
    setIsUpdated(true);
  };

  const pageClick = (page: number) => {
    setUrlParams({
      ...urlParams,
      page
    });
    setIsUpdated(true);
  };

  const handleChange = ({ target: { value, name } }) => {
    setUrlParams(prevParams => ({ ...prevParams, [name]: value }));
  };

  const loading = isLoading ? <Loading /> : null;

  const empty = !isLoading && data && data.total === 0 ? <p>No records to show</p> : null;

  const results =
    data && data.data ? (
      <ResultList data={ data } isLoading={ isLoading } pageClick={ pageClick } isAutomotive={ false } />
    ) : null;

  return (
    <div css={style}>
      <div className="header header-government">
        <div className="overlay">
          <div className="container">
            <h1 className="title">Government Auction Search</h1>
            <h2 className="subtitle">1100 Active Listings</h2>
            <SearchForm
              onSubmit={ onSubmit }
              handleZipChange={ handleChange }
              handleTypeChange={ handleChange }
              disabled={ isLoading }
              isAutomotive={ false }
            />
          </div>
        </div>
      </div>

      <Container fluid={true}>
        { loading }
        { empty }
        { results }
      </Container>
    </div>
  );
};

export default GovAuctions;
