type StringRecords = Record<string, string>;

/**
 * --------------------------------------------------------------
 * Breakpoints
 * --------------------------------------------------------------
 */

export const BREAKPOINTS: StringRecords = {
  SM: '576px',
  MD: '768px',
  LG: '992px',
  XL: '1200px'
};

/**
 * --------------------------------------------------------------
 * Colors
 * --------------------------------------------------------------
 */

export const colors: StringRecords = {
  transparent: 'transparent',
  inherit: 'inherit',
  black: '#000000',
  blue: '#337ab7',
  blueDark: '#2E6DA4',
  blueDarkest: '#00008b',
  blueFacebook: '#3b5998',
  blueLink: '#337ab7',
  blueLinkDark: '#23527c',
  grayLightest: '#f8f8f8',
  grayLight: '#e7e7e7',
  gray: '#777777',
  green: '#5cb85c',
  greenDark: '#4cae4c',
  red: '#ff0000',
  tomato: '#d9534f',
  darkTomato: '#d43f3a',
  yellow: '#f0ad4e'
};

/**
 * --------------------------------------------------------------
 * Theme
 * --------------------------------------------------------------
 */

export const blockButtons = (color: string = 'blue', theme: { colors }) =>
  ({
    blue: {
      backgroundColor: colors.blue,
      borderColor: colors.blueDark,
      '&:hover': {
        backgroundColor: colors.blueDark,
        borderColor: colors.blueDark
      }
    },
    green: {
      backgroundColor: colors.green,
      borderColor: colors.greenDark,
      '&:hover': {
        backgroundColor: colors.greenDark,
        borderColor: colors.greenDark
      }
    },
    red: {
      backgroundColor: colors.tomato,
      borderColor: colors.darkTomato,
      '&:hover': {
        backgroundColor: colors.darkTomato,
        borderColor: colors.darkTomato
      }
    }
  }[color]);

/**
 * --------------------------------------------------------------
 * Gradients
 * --------------------------------------------------------------
 */

export const gradients = {
  gray: 'linear-gradient(#fff,#eee 50%,#e4e4e4)'
};

export default {
  colors,
  gradients,
  breakpoints: BREAKPOINTS,
  blockButtons
};
