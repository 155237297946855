import React, { useState } from "react";
import { map, take, reverse } from "lodash/fp";

import TrackingActivity from "./TrackingActivity";
import { TrackingHistoryProps, TrackingActivity as TrackingActivityProps  } from "types/pages/My_Wins/myWins";

const TrackingHistory: React.FC<TrackingHistoryProps> = ({ activities }) => {
  const [showAll, setShowAll] = useState(false);

  const sortedActivities = take(showAll ? activities.length : 3)(reverse(activities));
  const activityList = map((activity: TrackingActivityProps) => <TrackingActivity key={`activity-listing-${activity.id}`} {...activity} />)(sortedActivities);

  const toggleHistory = (e: any) => {
    e.preventDefault();
    setShowAll(!showAll);
  };

  const showFullTracking = activities.length > 3 ? (
    <a href="#!" onClick={toggleHistory}>
      {showAll ? "Hide Full Tracking" : "Show Full Tracking"}
    </a>
  ) : null;

  return activities.length > 0 ? (
    <React.Fragment>
      <h4 className="tracking-title">Tracking History:</h4>
      {activityList}
      {showFullTracking}
    </React.Fragment>
  ) : null;
};

export default TrackingHistory;
