import { Status } from "types/pages/Auction_View/partials/bidFormContainer";

export const setMaxMessage = (amount: number) => `Your max bid is $${amount}`;
export const isHighestBidder = (userBidAmount: string, itemAmount: number) =>
  parseInt(userBidAmount) >= itemAmount ? true : false;

export const getMaxNotification = (currentSetMax: number, salePrice: number) => currentSetMax - salePrice >= 1;

export const isProxy = (isLoggedIn: boolean, bidStatus: Status) => (match: Status) =>
    isLoggedIn ? bidStatus === match : false;
