/** @jsx jsx */

import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import { RouteComponentProps } from 'react-router';
import { Col, Row } from 'reactstrap';

import { useFetch } from 'hooks/async';
import reducer from 'reducers/govauction';
import style from 'styles/GovAuctionDetails';
import { Loading, AdsenseAd } from 'components';
import Breakdown from './partials/Breakdown';

const GovAuctionDetails: React.FC<RouteComponentProps<{ id: string }>> = ({ match: { params } }) => {
  document.title = 'Police Auctions - Government Auctions';
  const [{ data, isLoading }] = useFetch(`/external-auction/${params.id}`, reducer);

  const loading = isLoading ? <Loading /> : null;

  const empty = !data && !isLoading ? <p>No records to show</p> : null;

  const breakdown = data ? <Breakdown data={data} /> : null;

  return (
    <Row css={style}>
      <Col lg="9" md="12">
        { loading }
        { empty }
        { breakdown }
      </Col>
      {/* <Col lg="3" md="12" className="right-banner">
        <AdsenseAd
          type="rectangle-lg"
          slot="4656110724"
          client="ca-pub-5964452904144582"
        />

        <AdsenseAd
          type="skyscraper-lg"
          slot="7609577122"
          client="ca-pub-5964452904144582"
        />
      </Col> */}
    </Row>
  );
};

export default GovAuctionDetails;
