/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import style from 'styles/SupportTickets';
import { useFetch } from 'hooks/async';
import { reducer } from "reducers/asyncGlobal";
import { Loading, SaveButton, Paginator } from 'components';
import TicketList from './partials/TicketList';

const SupportTickets: React.FC = () => {
  document.title = 'Police Auctions - Support Tickets';
  const [page, setPage] = useState(1);
  const [{ data, isLoading }, fetch, reset] = useFetch('/support', reducer, { page });

  const pageClick = (page: number) => {
    reset();
    setPage(page);
    fetch(true);
  }

  const pagination = data
    ? <Paginator pages={ data } onClick={ pageClick } isLoading={ isLoading } />
    : null;

  const tickets = data ? <TicketList data={data} /> : null;
  const loading = isLoading ? <Loading /> : null;

  return (
    <Container css={style}>
      <h1 className="header">Customer Support Tickets</h1>
      <p>Have you had a chance to take a look at our <Link to="/knowledgebase">knowledge base?</Link></p>

      <Link to="/support" className="header-link">
        <SaveButton size="lg">Contact Support</SaveButton>
      </Link>

      <Link to="/support/return" className="header-link">
        <SaveButton size="lg">Return an Item</SaveButton>
      </Link>

      <hr/>
      { loading }
      { tickets }
      { pagination }
    </Container>
  );
};

export default SupportTickets;