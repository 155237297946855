/** @jsx jsx */

import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Row, Col } from 'reactstrap';
import { jsx } from '@emotion/core';
import _, { get } from 'lodash';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

import AuctionBidding from './partials/AuctionBidding';
import AuctionDetails from './partials/AuctionDetails';
import BidTutorial from './partials/BidTutorial';
import Container from 'reactstrap/lib/Container';
import styles from 'styles/AuctionView';
import { useFetch, usePost } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobalAuctionView';
import { reducer as postReducer } from 'reducers/asyncGlobal';
import { reducer as loggerReducer } from 'reducers/asyncGlobal';
import { AppContext } from 'context/AppProvider';
import { FormSubmit } from 'types/pages/Auction_View/partials/auctionBidding';
import getProductUrl from 'utils/getProductUrl';
import useTimeStamp from 'hooks/timeStamp';
import { CardAuctionStatus, Loading } from 'components';
import useTimer from 'hooks/timer';
import socketInit from 'utils/socketInit';
import viewAuctionStream from 'utils/viewAuctionStream';
import bidSocketUtils from 'utils/bidSocketUtils';
import { SocketContext } from 'context/SocketProvider';
import toUrl from 'utils/toUrlFromProductName';
import stepIniitalState from 'utils/stepInitialState';
import filterPrimaryType from 'utils/filterImageType';
import history from 'utils/history';

/**
 * TODO: Still needs a bit of refactoring
 */

const AuctionView: React.FC<RouteComponentProps> = ({ match }) => {
  document.title = 'Police Auctions - Auction';
  const [{ data }, setReload, _, auctionDispatch] = useFetch(`${getProductUrl(match.url)}?format=html`, reducer);
  const [{ data: bidStatus, isLoading, errors }, setSubmit] = usePost(`${getProductUrl(match.url)}/bid`, postReducer);
  const [, setLogger] = usePost('/logger', loggerReducer);

  const {
    greatestPrice,
    bidsTransform,
    hasEnded,
    isEnding,
    mergeObjects,
    highestBidPrice
  } = bidSocketUtils;
  const auctionData = data ? data : null;
  const updateBidAmount = get(bidStatus, 'max_bid_amount');

  const [maxBidState, setMaxbidState] = useState(updateBidAmount);
  const [stepState, setStepState] = useState(stepIniitalState);
  const [endingTimer, setEndingTimer] = useState<null | boolean>(false);

  const {
    actions,
    state: { user, urlHistory }
  } = useContext(AppContext);

  const {
    dispatch,
    state: { streamData, streamError },
    socket
  } = useContext(SocketContext);
  const [timeStamp] = useTimeStamp();
  const [timer] = useTimer(timeStamp, get(data, 'eventEnd'));

  const resUpdate = mergeObjects(auctionData, streamData);
  const openingBid = get(data, 'schedule.opening_bid');

  const serverAndStreamExists = (server, stream) => server && stream.id;
  const auctionIdNotChecked = (server, stream) => server.schedule.id !== parseInt(stream.id);

  const hitLogger = (server, stream) => {
    const auctionInfo = viewAuctionStream(match.url, user);

    if(auctionIdNotChecked(server, stream)) {
      setLogger({
        name: "BidList",
        data: JSON.stringify({
          history,
          urlHistory,
          auctionInfo,
          url: match.url,
          pageId: getProductUrl(match.url),
          streamId: streamData.id,
        })
      });
    }
  }

  useEffect(() => {
    if(socket.connected) {
      setReload(true)
      socketInit(socket, dispatch, viewAuctionStream(match.url, user));
    }

    return () => {
      auctionDispatch({ type:actions.RESET });
      if (socket) {
        socket.emit('unwatch', JSON.stringify(viewAuctionStream(match.url, user)));
      }
    };
  }, [socket.connected]);

  useEffect(() => {
    if (updateBidAmount) {
      setMaxbidState(parseInt(updateBidAmount));
    }
  }, [updateBidAmount]);

  useEffect(() => {
    if(isEnding(resUpdate)) {
      setEndingTimer(true);
    }
    if (hasEnded(resUpdate)) {
      setEndingTimer(true);

      setTimeout(() => {
        setEndingTimer(false);
      }, 2000);
    }
    return () => {
      clearTimeout(2000);
    };
  }, [isEnding(resUpdate), hasEnded(resUpdate)]);

  useEffect(() => {
    window.addEventListener('visibilitychange', onFocus);
    return () => {
      window.removeEventListener('visibilitychange', onFocus);
    };
  });

  // User has switched back to the tab
  const onFocus = () => {
    //setReload(true);
    if(document.visibilityState === 'visible') {
      actions.getServerTime();
    }
  };

  useEffect(() => {
    if (endingTimer === false && hasEnded(streamData)) {
      setReload(true);
    }
  }, [endingTimer]);

  useEffect(() => {
    const serverAndStream = serverAndStreamExists(auctionData, streamData);

    if(serverAndStream) {
      hitLogger(auctionData, streamData);
    }

    if(serverAndStream && auctionIdNotChecked(auctionData, streamData) && streamData.id) {
      socket.emit('unwatch', JSON.stringify(viewAuctionStream(streamData.id, user)));
    }
  }, [streamData.bids]);

  const onStepToggle = () => {
    setStepState(prevState => ({ ...prevState, stepsEnabled: true }));
  };

  const onStepClose = () => {
    setStepState(prevState => ({ ...prevState, stepsEnabled: false }));
  };

  const handleBidSubmit: FormSubmit = ({ bid }, { resetForm }) => {
    setSubmit({ amount: bid });
    resetForm();
  };

  const productUrl = data ? `https://www.policeauctions.com/auctions/${data.schedule.id}/${toUrl(data.schedule)}` : '';
  const imgUrl = data ? data.images.filter(img => img.image_type_id == 5 || img.image_type_id == 24)[0].url : '';

  const { stepsEnabled, initialStep, steps } = stepState;
  return data ? (
    <Container css={styles}>
      <Steps enabled={stepsEnabled} steps={steps} onExit={onStepClose} initialStep={initialStep} />
      <Helmet>
        <meta property="og:title" content="PoliceAuctions.com" />
        <meta property="og:url" content={productUrl} />
        <meta property="og:image" content={imgUrl} />
        <meta property="og:description" content={data.description.name} />
        <meta property="og:site_name" content="PoliceAuctions.com" />
      </Helmet>
      <Row>
        <Col>
          <h3 className="text-left product-title">{data ? data.description.short_name : ''}</h3>
        </Col>
        <Col>
          <div className="d-flex flex-md-column align-items-end justify-content-end">
            <CardAuctionStatus
              showBdp={openingBid <= 1 && parseInt(get(data, 'bdp')) > 0}
              bdp={get(data, 'schedule.bdp')}
              timer={timer}
              auctionId={data.schedule.id}
            />
            <BidTutorial show={!hasEnded(resUpdate)} onStepToggle={onStepToggle} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <AuctionBidding
            price={
              greatestPrice(resUpdate)
                ? greatestPrice(resUpdate)
                : get(data, 'schedule.user_id') === null && openingBid > 1
                ? openingBid
                : greatestPrice(resUpdate)
            }
            userBids={bidsTransform(resUpdate)}
            hasEnded={data.hasEnded}
            hasNotWon={data.hasEnded ? resUpdate.schedule.user_id !== user.id : null}
            hasWon={data.hasEnded ? resUpdate.schedule.user_id === user.id : null}
            images={get(data, 'images')}
            userLoggedIn={user.id !== null}
            onBidSubmit={handleBidSubmit}
            bidMessage={get(bidStatus, 'message')}
            bidStatus={get(bidStatus, 'status')}
            isBidding={isLoading}
            bidError={errors}
            serverTime={timeStamp}
            endTime={get(data, 'eventEnd')}
            isEnding={isEnding(resUpdate) || endingTimer}
            setMax={get(data, 'currentSetMax')}
            salePrice={get(data, 'schedule.sale_price')}
            id={get(data, 'schedule.id')}
            isWatching={get(data, 'isWatching')}
            postUpdateStatus={get(bidStatus, 'status')}
            maxBidAMount={maxBidState}
            myGreatestPrice={highestBidPrice(auctionData, streamData, user)}
            userName={user.id ? user.username : null}
            openingBid={openingBid}
            userId={get(data, 'schedule.user_id')}
            notStreaming={streamData.bids === null}
            hasHd={get(data, 'schedule.control.product_description.has_hd')}
            timer={timer}
            streamingError={streamError}
          />
          <AuctionDetails
            products={get(data, 'description')}
            name={get(data, 'description')}
            productName={get(data, 'description.name')}
            auctionId={get(data, 'schedule.id')}
            shippingInfo={parse(data.additional_info.shipping_info.text)}
            billingInfo={parse(data.additional_info.bidding_info_auction.text)}
            returnPolicy={parse(data.additional_info.return_policy.text)}
            endTime={get(data, 'eventEnd')}
            productId={get(data, 'schedule.control.product_description.category_id')}
          />
        </Col>
      </Row>
    </Container>
  ) : (
    <Loading />
  );
};

export default AuctionView;
