import { css } from '@emotion/core';

import { BREAKPOINTS as BP } from 'constants/emotionConfig';

const styles = ({ colors }) => css`
  .auction-id {
    font-size: 1.4rem;
    font-weight: normal;
  }

  .product-line {
    margin: 2rem 0;
  }

  .list-header {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .product-list {
    font-size: 1.4rem;
    padding-left: 4rem;
  }

  .alert-msg {
    padding: 1.7rem;
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  @media (max-width: ${BP.MD}) {
    max-width: none;

    .image-container {
      height: 70rem;
    }
  }
`;

export default styles;
