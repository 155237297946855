/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Layout, ErrorMessage } from 'components';
import styles from 'styles/EditUsername';
import { usePost } from 'hooks/async';
import reducer from 'reducers/updateProfile';
import { useEffect, useContext } from 'react';
import EditUserNameForm from './partials/EditUserNameForm';
import history from 'utils/history';
import { fetchUser, AppContext } from 'context/AppProvider';
import { User } from 'types/authentication';

const EditUsername: React.FC = () => {
  const [{ data: userResponse, errors, isLoading: isSubmitting }, saveUsername] = usePost('/user/username', reducer);
  const { dispatch, state: { user } } = useContext(AppContext);

  const handleSubmit = ({ username }: Pick<User, "username">) => {
    saveUsername({ username });
  };

  useEffect(() => {
    if (userResponse) {
      fetchUser(dispatch);
      history.push('/');
    }
  }, [userResponse]);

  return (
    <Layout css={styles}>
      <h2>Edit Username</h2>
      <hr className="form-border" />
      <ErrorMessage item={errors} />
      <EditUserNameForm
        onSubmit={handleSubmit}
        userIdExists={user.id}
        isSubmitting={isSubmitting}
      />
    </Layout>
  );
};

export default EditUsername;
