/** @jsx jsx */

import { jsx } from '@emotion/core';
import { Table } from 'reactstrap';
import { UpgradeLink } from 'components';
import boolToText from 'utils/boolToText';

const BottomTable = ({ data }) =>
  <Table striped>
    <tbody>
      <tr>
        <td className="title">Web Address</td>
        <td className="info">
          <UpgradeLink url={data.website_address} />
        </td>
      </tr>
      <tr>
        <td className="title">Government</td>
        <td className="info">{ boolToText(data.isGovernment) }</td>
      </tr>
      <tr>
        <td className="title">Online</td>
        <td className="info">{ boolToText(data.isOnline) }</td>
      </tr>
      <tr>
        <td className="title">Offline</td>
        <td className="info">{ boolToText(data.isOffline) }</td>
      </tr>
      <tr>
        <td className="title">Portal</td>
        <td className="info">{ boolToText(data.isPortal) }</td>
      </tr>
      <tr>
        <td className="title">Sale</td>
        <td className="info">{ boolToText(data.sale) }</td>
      </tr>
    </tbody>
  </Table>

export default BottomTable;