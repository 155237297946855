/** @jsx jsx */

import { useContext, useState, useEffect } from 'react';
import {
  Navbar,
  Nav,
  Container,
  Collapse,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import Badge from 'reactstrap/lib/Badge';
import { withTheme } from 'emotion-theming';

import { GovernmentDropdown } from './DropdownItems';
import { DropdownCountryItems } from './DropdodwnCountryItems';
import { DropdownHotNavItems } from './DropdownHotNavItems';
import LinkItem from './LinkItem';
import LinkItemDropdown from './LinkItemDropdown';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { AppContext } from 'context/AppProvider';
import { CURRENCY_FLAG_UPDATE } from 'constants/appContext';
import filterNullValues from 'utils/filterNullValues';

type CountryType = {
  id: number;
  imgName: string;
  name: string;
};

const countries: CountryType[] = [
  { id: 2, imgName: 'United-States', name: 'US' },
  { id: 3, imgName: 'Canada', name: 'CA' },
  { id: 1201, imgName: 'Australia', name: 'AU' },
  { id: 1340, imgName: 'New-Zealand', name: 'NZ' },
  { id: 1337, imgName: 'Netherlands', name: 'NL' },
  { id: 1391, imgName: 'Sweden', name: 'SE' },
  { id: 1347, imgName: 'Norway', name: 'NO' },
  { id: 1202, imgName: 'Austria', name: 'AT' },
  { id: 1268, imgName: 'Germany', name: 'DE' },
  { id: 1245, imgName: 'Denmark', name: 'DK' },
  { id: 1260, imgName: 'Finland', name: 'FI' },
  { id: 1410, imgName: 'United-Kingdom', name: 'UK' },
  { id: 1290, imgName: 'Ireland', name: 'IE' },
  { id: 1195, imgName: 'Anguilla', name: 'AI' },
  { id: 1204, imgName: 'Bahamas', name: 'BS' },
  { id: 1207, imgName: 'Barbados', name: 'BB' },
  { id: 1418, imgName: 'British-Virgin-Islands', name: 'VG' },
  { id: 1227, imgName: 'Cayman-Islands', name: 'KY' },
  { id: 1247, imgName: 'Dominica', name: 'DM' },
  { id: 1273, imgName: 'Grenada', name: 'GD' },
  { id: 1279, imgName: 'Guyana', name: 'GY' },
  { id: 1293, imgName: 'Jamaica', name: 'JM' },
  { id: 1330, imgName: 'Montserrat', name: 'MS' },
  { id: 1360, imgName: 'Puerto-Rico', name: 'PR' },
  { id: 1367, imgName: 'Saint-Kitts-and-Nevis', name: 'KN' },
  { id: 1368, imgName: 'Saint-Lucia', name: 'LC' },
  { id: 1426, imgName: 'Saint-Vincent-and-the-Grenadines', name: 'VC' },
  { id: 1401, imgName: 'Trinidad-and-Tobago', name: 'TT' },
  { id: 1405, imgName: 'Turks-and-Caicos-Islands', name: 'TC' },
  { id: 1419, imgName: 'US-Virgin-Islands', name: 'VI' }
];

const initialState = {
  categories: false,
  user: false,
  country: false,
  gov: false
};

const UserHeader = ({ user, logout, userStream, categoryListData }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedFlag, setSelectedFlag] = useState('United-States');
  const [dropdownOpen, setDropdownOpen] = useState(initialState);

  const { dispatch } = useContext(AppContext);

  const updateUser = { ...user, ...filterNullValues(userStream) };

  const onMenuOpen = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // Update the country selection
  const handleCountrySelection = (item: CountryType) => {
    localStorage.setItem('country', JSON.stringify(item));
    setSelectedFlag(item.imgName);
    closeMenu();
  };

  const logoutAndCloseMenu = () => {
    logout();
    closeMenu();
  };

  const dropdownClose = (evt: React.MouseEvent<HTMLSelectElement>) => {
    const dropdownDataset = evt.currentTarget.dataset.dropdown as string;

    //Set the other dropdowns to false, except the current dropdown
    const updateState = Object.keys(dropdownOpen)
      .filter(item => item !== dropdownDataset)
      .reduce((acc, curr) => ({ ...acc, [curr]: false }), {});

    setDropdownOpen(prevState => ({ ...prevState, ...updateState, [dropdownDataset]: !prevState[dropdownDataset] }));
  };

  //Should scroll to top of header when the categories dropdown is set to false
  useEffect(() => {
    if(!dropdownOpen.categories) {
      window.scrollTo(0, 0);
    }
  }, [dropdownOpen.categories]);

  // Check if the country selection is set in the localstorage and update the state
  useEffect(() => {
    const country = localStorage.getItem('country');
    if (country) {
      setSelectedFlag(JSON.parse(country).imgName);
      dispatch({ type: CURRENCY_FLAG_UPDATE, payload: JSON.parse(country) });
    }
  }, [selectedFlag]);

  const noticeBadge = updateUser.unread_announcements ? (
    <Badge className="link-badge" color="info" pill={true} style={{ fontSize: '1.15rem' }}>
      {updateUser.unread_announcements}
    </Badge>
  ) : null;

  const accountDropdown = () => {
    const accountLinkItem = (to, name) => (
      <Link to={to} className="dropdown-link">
        <DropdownItem className="dropdownmenu-item" onClick={() => closeMenu()}>
          {name}
        </DropdownItem>
      </Link>
    );
    const logoutLinkItem = () => (
      <DropdownItem className="dropdownmenu-item" onClick={() => logoutAndCloseMenu()}>
        Logout
      </DropdownItem>
    );
    return (
      <UncontrolledDropdown
        nav={true}
        inNavbar={true}
        data-dropdown="user"
        isOpen={dropdownOpen.user}
        onClick={dropdownClose}
      >
        <DropdownToggle nav caret className="link">
          {updateUser.username} {noticeBadge}
        </DropdownToggle>
        <DropdownMenu className="dropdownmenu-user" right={true}>
          {accountLinkItem('/upgrade', 'Upgrade')}
          <DropdownItem className="dropdownmenu-item">
            <LinkItemDropdown
              href="/my-wins"
              name="My Wins"
              hasItem={updateUser.myItems > parseInt('0')}
              value={updateUser.myItems}
              bgColor="danger"
            />
          </DropdownItem>
          {accountLinkItem('/account/mybids', 'My Bids')}
          {accountLinkItem('/account/watchlist', 'My Watchlist')}
          {accountLinkItem('/account/settings', 'My Settings')}
          <DropdownItem className="dropdownmenu-item">
            <LinkItemDropdown
              href="/notices"
              name="Notices"
              hasItem={updateUser.unread_announcements > parseInt('0')}
              value={updateUser.unread_announcements}
              bgColor="info"
            />
          </DropdownItem>
          {accountLinkItem('/support/tickets', 'Support')}
          {accountLinkItem('/account/settings/social', 'Sync With Facebook')}
          {accountLinkItem('/cancel', 'Cancel Account')}
          {logoutLinkItem()}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const countryDropdown = (countries, handleCountrySelection) => (
    <UncontrolledDropdown
      nav={true}
      inNavbar={true}
      data-dropdown="country"
      isOpen={dropdownOpen.country}
      onClick={dropdownClose}
    >
      <DropdownToggle nav caret className="link">
        <img src={`https://cdn.policeauctions.com/images/flags/flags/flat/24/${selectedFlag}.png`} alt="US" />
      </DropdownToggle>
      <DropdownMenu className="dropdownmenu-wrapper countries-wrapper" right={true}>
        <DropdownCountryItems items={countries} handleCountrySelection={handleCountrySelection} />
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const categoryDropdown = () => (
    <UncontrolledDropdown
      nav={true}
      inNavbar={true}
      data-dropdown="categories"
      isOpen={dropdownOpen.categories}
      onClick={dropdownClose}
    >
      <DropdownToggle nav caret className="link">
        Categories
      </DropdownToggle>
      <DropdownHotNavItems onClick={() => closeMenu()} categoryListData={categoryListData} />
    </UncontrolledDropdown>
  );

  const governmentDropdown = () => (
    <UncontrolledDropdown
      nav={true}
      inNavbar={true}
      data-dropdown="gov"
      isOpen={dropdownOpen.gov}
      onClick={dropdownClose}
    >
      <DropdownToggle nav caret className="link">
        Gov Listings
      </DropdownToggle>
      <GovernmentDropdown onClick={() => closeMenu()} />
    </UncontrolledDropdown>
  );

  return (
    <header css={style}>
      <Navbar expand="md" light={true}>
        <Container fluid={true}>
          <a href="/">
            <img
              style={{ width: '200px', height: '50px' }}
              src="https://cdn.policeauctions.com/images/policeauction12-half.png"
              alt="police_auction"
            />
          </a>
          <NavbarToggler onClick={onMenuOpen} />
          <Collapse isOpen={isOpen} navbar={true}>
            <Nav className="nav-items ml-auto" navbar={true}>
              {categoryDropdown()}
              {governmentDropdown()}
              <LinkItem href="/dailydeals" name="Daily Deals" onClick={closeMenu} />
              <LinkItem
                href="/my-wins"
                name="My Wins"
                notification={updateUser.myItems > parseInt('0') ? updateUser.myItems : false}
                value={updateUser.myItems}
                onClick={closeMenu}
              />
              <LinkItem
                href="/account/notifications"
                name="Alerts"
                notification={updateUser.notifications || false}
                value={updateUser.notifications}
                onClick={closeMenu}
              />
              {accountDropdown()}
              {countryDropdown(countries, handleCountrySelection)}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default withTheme(UserHeader);

const style = ({ colors, gradients }) => css`
  background-color: ${colors.grayLightest};
  border-color: ${colors.grayLight};
  background-image: ${gradients.gray};

  .link {
    font-size: 1.4rem;
    color: ${colors.gray};
    font-weight: 300;
  }

  .link:hover {
    color: ${colors.blueDarkest};
    transition: color ease-in-out 0.2s;
    text-decoration: none;
  }

  .nav-item {
    padding: 0 1.1rem;
  }

  .dropdownmenu-user {
    width: 16.5rem;
    max-height: 50.6rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    overflow: scroll;
    font-size: 1.4rem;
    &:hover {
      text-decoration: none;
    }
  }

  .dropdownmenu-wrapper {
    width: 21.46rem;
    max-height: none;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    overflow: scroll;
    font-size: 1.4rem;
    z-index: 94035;
  }

  .countries-wrapper {
    max-width: 2rem;
  }

  .hot-item-i {
    color: ${colors.red};
  }

  .dropdownmenu-item {
    font-size: 1.4rem;
    text-decoration: none;
  }

  .dropdown-link:hover {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .dropdownmenu-wrapper {
      box-shadow: none;
      width: 100%;
    }

    .countries-wrapper {
      width: 100%;
      max-width: unset;
    }

    .dropdownmenu-user {
      width: 100%;
      box-shadow: none;
    }

    .dropdown-item {
      color: #777777;
      text-decoration: none;
      font-size: 2rem;
      &:hover {
        text-decoration: none;
      }
    }

    .nav-item {
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
    }
  }
`;
