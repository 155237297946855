import React, { useState, useContext, useReducer, useEffect } from 'react';
import { Formik } from 'formik';
import Alert from 'reactstrap/lib/Alert';
import { map, pick, get } from 'lodash/fp';
import { Col } from 'reactstrap';

import AddressForm from './partials/AddressForm';
import { usePaymentFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { Loading } from 'components';
import request from 'utils/request';
import history from 'utils/history';
import { AppContext } from 'context/AppProvider';
import { UPDATE_PAYMENT } from 'constants/appContext';

type Errors = {
  errors: string[] | null;
  message: string | null;
};

const ShippingAddress = ({ match }) => {
  document.title = 'Police Auctions - Shipping Address';
  const [errors, setErrors] = useState<Errors | null>(null);
  const { dispatch } = useContext(AppContext);
  // Manage loading so there is no flicker with the form
  const [defaultLoading, setDefaultLoading] = useState(true);
  const [{ data, isLoading }] = usePaymentFetch('/addresses', reducer);
  const _onSubmit = formik => {
    const postData = pick([
      'id',
      'first_name',
      'last_name',
      'street1',
      'street2',
      'city',
      'zip_code',
      'state_code_id',
      'country_codes_id',
      'phone',
      'email'
    ])(formik);
    setDefaultLoading(true);

    request.postPayment(`/${match.params.method}/address`, { ...postData, auction_id: match.params.id }).then(
      ({ data }) => {
        setDefaultLoading(false);
        dispatch({ type: UPDATE_PAYMENT, payload: { ...data.updated_data, selected_address: data.selected_address } })
        history.push(`/payment/review/${match.params.method}/${match.params.id}`);
      },
      error => {
        setDefaultLoading(false);
        setErrors(error.response.data);
      }
    );
  };

  // Make sure the default loading is set to the result of the isLoading from fetch
  useEffect(() => {
    setDefaultLoading(isLoading)
  }, [data]);

  const formErrors = errors && errors.errors ? <Alert color="danger">{map((message: string) => <p>{message}</p>)(errors.errors)}</Alert> : null;
  const formErrorMessage = errors && errors.message ? <Alert color="danger"><p>{errors.message}</p></Alert> : null;

  const addressFields = {
    id: get('current_user_addresses[0].id')(data) || 'New',
    first_name: '',
    last_name: '',
    street1: '',
    street2: '',
    city: '',
    zip_code: '',
    phone: '',
    email: '',
    state_code_id: 1,
    country_codes_id: 2
  };

  const renderForm = (isLoading || defaultLoading) ? <Loading /> : (
    <React.Fragment>
      {formErrors}
      {formErrorMessage}
      <Formik
        initialValues={{ ...data, resetErrors: () => setErrors(null), method: match.params.method, ...addressFields }}
        validateOnChange={false}
        onSubmit={_onSubmit}
        validate={(values) => {
          const errors: { phone?: string } = {};

          if(values.country_codes_id !== '2' && !values.phone) {
            errors.phone = 'Required for FedEX / Customs.';
          }
          return errors;
        }}
        render={props => <AddressForm {...props} />}
      />
    </React.Fragment>
  );

  return (
    <Col lg={4} md={6} sm={6}>
      { renderForm }
    </Col>
  );
};

export default ShippingAddress;
