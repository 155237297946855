import React from 'react';

type Props = {
  onStepToggle: () => void;
  show: boolean;
};

const BidTutorialButton: React.FC<Pick<Props, 'onStepToggle'>> = ({ onStepToggle }) => (
  <h3 className="tutorial" onClick={onStepToggle}>
    Start Tutorial <i className="fas fa-question-circle"></i>
  </h3>
);

const BidTutorial: React.FC<Props> = ({ show, onStepToggle }) => (show ? <BidTutorialButton onStepToggle={onStepToggle} /> : null);

export default BidTutorial;
