/** @jsx jsx */

import { css, jsx } from '@emotion/core';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { BREAKPOINTS as BP } from 'constants/emotionConfig';

type Props = {
  toggle: () => void;
  isOpen: boolean;
  onClick: React.EventHandler<React.MouseEvent>;
}

const DropdownSort: React.FC<Props> = ({ isOpen, toggle, onClick }) => (
  <Dropdown isOpen={isOpen} toggle={toggle} css={style}>
    <DropdownToggle caret={true}>Sort Auctions</DropdownToggle>
    <DropdownMenu>
      <DropdownItem className="item-sort" value="sort=sale_price&order=asc" onClick={onClick}>
        Lowest Price
      </DropdownItem>
      <DropdownItem className="item-sort" value="sort=sale_price&order=desc" onClick={onClick}>
        Highest Price
      </DropdownItem>
      <DropdownItem className="item-sort" value="sort=date&order=asc" onClick={onClick}>
        Ending Soonest
      </DropdownItem>
      <DropdownItem className="item-sort" value="sort=date&order=desc" onClick={onClick}>
        Ending Latest
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
);

const style = css`
  .dropdown-toggle {
    color: #333333;
    font-size: 1.4rem;
    padding: 0.6rem 1.2rem;
    background-color: #f5f5f5;
    border-color: #dddddd;

    .show:active {
      background-color: #f5f5f5;
    }
  }

  .item-sort {
    font-size: 1.4rem;
  }

  @media(max-width: ${BP.MD}) {
    .dropdown-menu {
      background-color: #ffffff;
      border: 1px solid rgba(0,0,0,.15);
    }
  }
`;

export default DropdownSort;
