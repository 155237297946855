/** @jsx jsx */

import { css, jsx } from '@emotion/core';
import BlockButton from 'components/BlockButton';

const BidButton = () => (
  <BlockButton css={style}>Bid Now</BlockButton>
);

const style = css`
  height: 3.4rem;
  font-size: 1.43rem;
  padding: 0;
  margin-bottom: 0;
`

export default BidButton;
