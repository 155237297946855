/** @jsx jsx */

import { jsx } from '@emotion/core'

import { AlertMessage } from 'components';

type Props = {
  message: string;
  code: number;
  show: boolean;
};

const PayPalErrorAlert: React.FC<Props> = ({ show, message, code }) => (
  <AlertMessage
    color="danger"
    show={show}
    css={{
      maxWidth: '50rem',
      maxHeight: '20rem'
    }}
  >
    <p>{message}</p>
    <br />
    <p>Code: {code}</p>
  </AlertMessage>
);

export default PayPalErrorAlert;
