/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { RouteComponentProps } from 'react-router';
import { Container, Col, Alert } from 'reactstrap';
import {  FormikActions } from 'formik';

import { usePost, useFetch } from 'hooks/async';
import { reducer, reducer as postReducer } from 'reducers/asyncGlobal';
import { Loading } from 'components';
import { ClaimFormFields, InsuranceClaim, RouteProps } from 'types/pages/Support_Claim/supportClaim';
import style from 'styles/SupportClaim';
import ItemInfo from './partials/ItemInfo';
import ThankYou from './partials/ThankYou';
import ClaimForm from './partials/ClaimForm';

const SupportClaim: React.FC<RouteComponentProps<RouteProps>> = ({ match: { params } }) => {
  document.title = 'Police Auctions - Support Claim';
  const { id } = params;

  const [claim, setClaim] = useState<InsuranceClaim>({
    date_form_returned: '0000-00-00',
  });

  const [{ data: response, isLoading: fetching }] = useFetch(`/support/claim/id/${id}`, reducer);

  const [{ data, isLoading, errors }, submit] = usePost(`/support/claim/id/${id}`, postReducer);

  const onSubmit = (values: ClaimFormFields, actions: FormikActions<ClaimFormFields>) => {
    submit({ ...values });
    actions.resetForm();
  };

  useEffect(() => {
    if(response){
      setClaim(response);
    }
  }, [response]);

  useEffect(() => {
    if(data){
      setClaim(data);
    }
  }, [data]);

  const form_was_filed = (claim && claim.date_form_returned !== '0000-00-00');
  const claim_not_found = !fetching && !form_was_filed && !response;
  const info = response && !form_was_filed ? <ItemInfo data={response} /> : null;
  const thanks = form_was_filed ? <ThankYou /> : null;
  const failure = errors && errors.message ? <Alert color="danger"><p>{ errors.message }</p></Alert> : null;
  const not_found = claim_not_found ? <Alert color="danger"><p>This claim was not found, or has already been filed.</p></Alert> : null;
  const loading = (fetching || isLoading) ? <Loading /> : null;

  const form = fetching || form_was_filed || claim_not_found
    ? null
    : <ClaimForm onSubmit={ onSubmit } loading={ isLoading } />;

  return (
    <Container css={ style }>
      <Col sm={{ size: 8, offset: 2 }}>
        <h1 className="header">
          Lost Package Form
          <small className="subtitle">
            {response ? `(Auction #${response.auction_id})` : ''}
          </small>
        </h1>
        <hr/>
        { loading }
        { failure }
        { not_found }
        { thanks }
        { info }
        { form }
      </Col>
    </Container>
  );
};

export default SupportClaim;