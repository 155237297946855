/** @jsx jsx */

import React from 'react';
import { jsx, Interpolation } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { Button, ButtonProps } from 'reactstrap';

const ButtonStyled = styled(Button)({
  fontSize: '1.8rem',
  borderRadius: '0.5rem',
  padding: '1rem 1.6rem',
  marginBottom: '1rem'
});

//TODO: Make a better type
type Props = ButtonProps & {
  color?: string;
  theme: {
    blockButtons: (color?: string, theme?: object) => Interpolation;
  };
};

const BlockButton: React.FC<Props> = ({ color, children, theme, theme: { blockButtons }, ...rest }) => (
  <ButtonStyled block={true} {...rest} css={blockButtons(color, theme)}>
    {children}
  </ButtonStyled>
);

export default withTheme(BlockButton);
