import styled from '@emotion/styled';
import { ReactNode } from 'react';

type Props = {
  transitioning: boolean;
  direction: string;
  children: ReactNode[];
  duration?: string;
};

const AuctionSliderContainer = styled.div<Props>`
  display: flex;
  display: -ms-flexbox;
  transition: ${({ transitioning, duration = '0.5s' }) => (transitioning ? 'none' : `transform ${duration} ease`)};
  transform: ${({ transitioning, direction, children }) => {
    if (!children.length) {
      return;
    }
    if (!transitioning) {
      return 'translateX(-100%)';
    }
    if (direction === 'prev') {
      return 'translateX(-200%)';
    }
    return 'translateX(0%)';
  }};
`;

export default AuctionSliderContainer;
