/** @jsx jsx */
import { useState, useEffect, useContext } from 'react';
import { jsx, css } from '@emotion/core';
import { Card, CardBody, Col } from 'reactstrap';
import { get } from 'lodash/fp';
import { Formik, Form } from 'formik';

import { reviewStyles } from 'styles/Payment';
import { BlockButton, Loading, LoadingButton, Input } from 'components';
import request from 'utils/request';
import { AppContext } from 'context/AppProvider';
import history from 'utils/history';
import GoogleTagManager from 'utils/googleTagManager';
import Alert from 'reactstrap/lib/Alert';
import PayPalErrorAlert from './partials/PayPalErrorAlert';
import { UPDATE_PAYMENT } from 'constants/appContext';
import { PIXEL_ID } from 'constants/config';

type PayPalError = {
  code: number;
  message: string;
  error: boolean;
};

const InternationalAlert = ({ country }) =>
  country !== 'US' ? (
    <Alert color="warning" className="alert-msg">
      IMPORTANT: To pre-populate the phone # field, please update your PayPal account with your current phone #
    </Alert>
  ) : null;

const PayButton = ({ isPaying, onSubmit }) =>
  isPaying ? (
    <LoadingButton />
  ) : (
    <BlockButton type="submit" color="blue" onSubmit={onSubmit}>
      <i className="fab fa-paypal"></i> Pay Now
    </BlockButton>
  );

const PayPalReview = ({ match }) => {
  document.title = 'Police Auctions - Payment Review';
  const [isLoading, setIsLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({
    recipient_name: '',
    street1: '',
    street2: '',
    city: '',
    state: {
      state: '',
    },
    country: {
      country_code: '',
    },
    zip_code: '',
    phone: '',
    email: '',
  });
  const [errorMessage, setErrorMessage] = useState<null | PayPalError>(null);
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (
      state.payment.paypal &&
      state.payment.auction &&
      state.payment.auction.id &&
      state.payment.paypal &&
      state.payment.paypal.orderID
    ) {
      const orderID = state.payment.paypal.orderID;
      const auction = state.payment.auction.id;

      request.postPayment('/paypal/orders/update-payment', { orderID, auction }).then(({ data: response }) => {
        if (response.error) {
          setIsLoading(false);
          setErrorMessage({ ...response });
          return false;
        }

        const { shipping_cost, tax, shipping_address } = response;

        setShippingAddress((prev) => ({
          ...prev,
          ...shipping_address,
          recipient_name: shipping_address.first_name + ' ' + shipping_address.last_name,
        }));

        dispatch({
          type: UPDATE_PAYMENT,
          payload: {
            tax: parseFloat(tax),
            shipping_cost: parseFloat(shipping_cost),
            auction: { ...state.payment.auction },
          },
        });
        setIsLoading(false);
      });
    }
  }, [state.payment.paypal]);

  const _handlePayment = (values) => {
    setIsPaying(true);
    // Request to the API for executing PayPal Payment then routing to receipt
    const { sale_price, shipping_cost, tax } = state.payment.paypal;

    request
      .postPayment('/paypal/orders/capture-payment', {
        orderID: state.payment.paypal.orderID,
        phone: values.phone,
        pixel_id: PIXEL_ID
      })
      .then(({ data: response }) => {
        if (typeof response.error != 'undefined') {
          history.push('/my-wins');
          return false;
        }

        GoogleTagManager.conversion({
          eventID: state.payment.auction.id,
          id: state.payment.paypal.orderID,
          productName: get('auction.control.product_description.short_name')(state.payment),
          productCode: get('auction.control.product_description.item_code.item_code')(state.payment),
          productCategory: get('auction.control.product_description.category.category')(state.payment),
          amount: parseFloat(sale_price),
          tax: parseFloat(tax),
          shipping: parseFloat(shipping_cost),
          total: parseFloat(sale_price) + parseFloat(tax) + parseFloat(shipping_cost),
        });
        history.push(`/payment/receipt/paypal/${match.params.id}`);
        dispatch({ type: UPDATE_PAYMENT, payload: { paypal: response } });
      });
  };

  const getState = shippingAddress.state && shippingAddress.state.state ? shippingAddress.state.state : null;

  return isLoading ? (
    <Loading />
  ) : errorMessage && errorMessage.error ? (
    <PayPalErrorAlert
      show={true}
      message={errorMessage.message}
      code={errorMessage.code}
    />
  ) : (
    <Col lg={4} md={6} sm={6}>
      <div css={reviewStyles}>
        <InternationalAlert country={shippingAddress.country.country_code} />
        <Card className="address-card">
          <CardBody>
            <p className="review-name">{shippingAddress.recipient_name}</p>
            <p>{shippingAddress.street1}</p>
            {shippingAddress.street2 ? <p>{shippingAddress.street2}</p> : null}
            <p>
              {shippingAddress.city} {getState}, {shippingAddress.country.country_code}{' '}
              {shippingAddress.zip_code}
            </p>
            <hr />
            <p>Phone Number: {shippingAddress.phone ? shippingAddress.phone : 'N/A'}</p>
            <p>Email: {shippingAddress.email ? shippingAddress.email : 'N/A'}</p>
          </CardBody>
        </Card>
        <Formik
          initialValues={{ phone: '' }}
          validateOnChange={false}
          validate={(values) => {
            const errors: { phone?: string } = {};
            if (!values.phone && shippingAddress.country.country_code !== 'US') {
              errors.phone = 'Required for FedEX / Customs..';
            }
            return errors;
          }}
          onSubmit={_handlePayment}
          render={({ touched, errors, handleSubmit, handleChange }) => (
            <Form>
              <Input
                label="Phone number"
                name="phone"
                placeholder="enter your phone number"
                isError={touched.phone && errors.phone}
                invalid={errors.phone ? true : false}
                errorMessage={errors.phone}
                onChange={handleChange}
              />
              <div className="button-block">
                <PayButton isPaying={isPaying} onSubmit={handleSubmit} />
              </div>
            </Form>
          )}
        />
      </div>
    </Col>
  );
};

export default PayPalReview;
