const isObjectNested = <T>(value: T) => Object(value) === value && !Array.isArray(value);

const filterNullValues = <T extends object>(obj: T): T =>
  Object.entries(obj).reduce(
    (acc, [key, value]) =>
      value === null ? acc : { ...acc, [key]: isObjectNested(value) ? filterNullValues<T>(value) : value },
    {} as T
  );

export default filterNullValues;
