//@ts-nocheck

import React, { useState } from 'react';

import { ListHead, ListGroupStyled, PlanCostText, PlanDateText, PlanListItem, ButtonStyled } from 'components';
import history from 'utils/history';
import { UpgradeProps } from 'types/pages/Upgrade/upgrade';
import request from 'utils/request';

const PlanTable = (props: UpgradeProps) => {
  const items = [
    '$1 No Reserve Auctions',
    'Daily Deals',
    'Customer Support',
    'Government Listings',
    'Government Loans',
    'Government Benefits',
    'Find Unclaimed Money Owed To You',
    'Government Car Auctions',
    'Seized Boats',
    'Unclaimed Pensions',
    '50% Membership Discount'
  ];
  const {
    planType: {
      name,
      cost,
      buttonAction
    },
    checkedItems,
    bgColors,
    buttonStyles,
    subscriptionData,
    hasCreditCard
  } = props;

  const [subscriptionInfo, setSubscriptionInfo] = useState(subscriptionData);
  const [submitting, setSubmitting] = useState(false);
  const formName = subscriptionData['item_name'] === 'Monthly Membership' ? 'monthly-subscription-form' : 'yearly-subscription-form';

  const onSubmit = (e) => {
    e.preventDefault();
    const isMonthly = subscriptionData['item_name'] === 'Monthly Membership';
    setSubmitting(true);
    request.postPayment('/subscription/paypal/submit', { isMonthly }).then(async ({data}) => {
      const itemNumber = '1-' + data.paypal_transaction_id.toString();
      await setSubscriptionInfo({
        ...subscriptionInfo,
        item_number: itemNumber
      });
      document.forms[formName].submit();
      setSubmitting(false);
    });
  }

  const creditCardButton = (
    <ButtonStyled
      bgColor={buttonStyles.bgColor}
      borderColor={buttonStyles.borderColor}
      bgColorOnHover={buttonStyles.bgColorOnHover}
      onClick={() => {history.push('/subscription/credit-card')}}>
        Credit Card
    </ButtonStyled>
  )

  const freeButton = (
    <ButtonStyled
      bgColor={buttonStyles.bgColor}
      borderColor={buttonStyles.borderColor}
      bgColorOnHover={buttonStyles.bgColorOnHover}
      onClick={() => {history.push('/')}}>
        { buttonAction }
    </ButtonStyled>
  )

  const payPalForm = (
    <form name={formName} method="POST" action="https://www.paypal.com/cgi-bin/webscr" style={{width: '100%'}} onSubmit={onSubmit}>
      { Object.keys(subscriptionInfo).map((key, idx) => (
        <input key={`${idx}-${key}`} type="hidden" name={key} value={subscriptionInfo[key]} />
      ))}
      <ButtonStyled
      bgColor={buttonStyles.bgColor}
      borderColor={buttonStyles.borderColor}
      bgColorOnHover={buttonStyles.bgColorOnHover}
      disabled={submitting}>
        { submitting ? <i className="fas fa-spinner fa-pulse"></i> : null } PayPal
      </ButtonStyled>
    </form>
  );

  return (
    <React.Fragment>
      <ListHead bgColor={bgColors.head}>
        <PlanDateText>{ name }</PlanDateText>
        <PlanCostText>{ cost }</PlanCostText>
        { hasCreditCard ? creditCardButton : null }
        { Object.keys(subscriptionData).length ? payPalForm : null }
        { buttonAction ? freeButton : null }
      </ListHead>
      <ListGroupStyled bgColor={bgColors.body}>
        { items.map((item, idx) => (
          <PlanListItem key={`${idx}-${item}`} checked={checkedItems.indexOf(idx) > -1}>{ item }</PlanListItem>
        )) }
      </ListGroupStyled>
    </React.Fragment>
  );
}

export default PlanTable;
