import React from 'react';
import { Row, Col } from 'reactstrap';

const AdditionalInfo = ({ data }) =>
  <Row>
    <Col>
      <p>Additional Information:</p>
      <p>{ data.description ? data.description.description : '' }</p>
    </Col>
  </Row>

export default AdditionalInfo;