/** @jsx jsx */

import React from 'react';
import { Nav } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { css, jsx } from '@emotion/core';

import { SettingsNavItem } from 'components';
import { BREAKPOINTS as BP } from 'constants/emotionConfig';

const SettingsNav: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <Nav vertical={true} pills={true} css={style}>
    <SettingsNavItem path={`${path}/`} title="Profile Settings" iconClassName="fa fa-user" />
    <SettingsNavItem path={`${path}/password`} title="Change Password" iconClassName="fa fa-lock" />
    <SettingsNavItem path={`${path}/notifications`} title="Notifications" iconClassName="fa fa-bullhorn" />
    <SettingsNavItem path={`${path}/social`} title="Social Settings" iconClassName="fa fa-dollar-sign" />
    <SettingsNavItem path={`${path}/manage_payments`} title="Payment Methods" iconClassName="fas fa-credit-card" />
  </Nav>
);

export default withRouter(SettingsNav);

const style = css`
  max-width: 30rem;

  @media (max-width: ${BP.MD}) {
    max-width: 100%;
  }
`;
