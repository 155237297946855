import React from 'react';
import parse from 'html-react-parser';

import { Loading } from 'components';
import { useFetch } from 'hooks/async';
import reducer from 'reducers/about';

const About: React.FC = () => {
  document.title = 'Police Auctions - About';
  const [{ data }] = useFetch('/page/3/1', reducer);
  return (
    <React.Fragment>
      {data ? parse(data.text) : <Loading />}
    </React.Fragment>
  );
};

export default About;
