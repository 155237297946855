const UPDATE_BID_LIST = 'UPDATE_BID_LIST';
const STREAMING_TIME = 'STREAMING_TIME';
const AUCTION_ENDING = 'AUCTION_ENDING';
const USER_EVENT = 'USER_EVENT';
const UNWATCH_BID_LIST = 'UNWATCH_BID_LIST';
const STREAMING_SUCCESS = 'STREAMING_SUCCESS';
const STREAMING_ERROR = 'STREAMING_ERROR';

export default {
  UPDATE_BID_LIST,
  STREAMING_TIME,
  AUCTION_ENDING,
  USER_EVENT,
  UNWATCH_BID_LIST,
  STREAMING_SUCCESS,
  STREAMING_ERROR,
};
