/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { ClaimFormProps } from 'types/pages/Support_Claim/supportClaim';
import { Input, SaveButton } from 'components';

const validation = Yup.object().shape({
  reason: Yup.string().required('Claim reason is required'),
  phone: Yup.string().required('Phone number is required'),
  signature: Yup.string().required('E-Signature is required'),
});

const ClaimForm: React.FC<ClaimFormProps> = ({ onSubmit, loading }) => (
  <Formik
    initialValues={{ reason: 4, description: '', phone: '', signature: '' }}
    onSubmit={onSubmit}
    validationSchema={validation}
    validateOnChange={false}
    render={({ handleChange, touched, errors, values }) => (
      <Form>
        <Input
          label="Reason:"
          name="reason"
          type="select"
          onChange={handleChange}
          isError={touched.reason && errors.reason}
          errorMessage={errors.reason}
          invalid={errors.reason ? true : false}
          value={values.reason}
        >
          <option value={ 4 }>Lost (Never Delivered)</option>
          <option value={ 2 }>Damaged</option>
        </Input>
        <Input
          label="If damaged please specify:"
          name="description"
          type="textarea"
          className="textarea"
          onChange={handleChange}
          isError={touched.description && errors.description}
          errorMessage={errors.description}
          invalid={errors.description ? true : false}
          value={values.description}
        />
        <Input
          label="Phone Number:"
          name="phone"
          type="text"
          onChange={handleChange}
          isError={touched.phone && errors.phone}
          errorMessage={errors.phone}
          invalid={errors.phone ? true : false}
          value={values.phone}
        />
        <Input
          label="E-Signature: (Please fill out the name as it appears in the shipped to section)"
          name="signature"
          type="text"
          onChange={handleChange}
          isError={touched.signature && errors.signature}
          errorMessage={errors.signature}
          invalid={errors.signature ? true : false}
          value={values.signature}
        />
        <SaveButton type="submit" disabled={loading ? true: false } block>Submit Claim</SaveButton>
      </Form>
    )}
  />
);

export default ClaimForm;