/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { Alert } from 'reactstrap';

import { useFetch } from 'hooks/async';
import { Loading, ResultList } from 'components';
import { reducer } from 'reducers/asyncGlobal';
import style from 'styles/WatchlistGovernment';

const WatchlistGovernment: React.FC = () => {
  document.title = 'Police Auctions - Watchlist Government';
  const [page, setPage] = useState(1);

  const [{ data, isLoading }, fetch, reset] = useFetch('/watchlist/government', reducer, {
    page
  });

  const pageClick = (page: number) => {
    reset();
    setPage(page);
    fetch(true);
  };

  const loading = isLoading ? <Loading /> : null;

  const empty =
    !isLoading && data && data.total === 0 ? (
      <Alert color="warning">
        <p>You are not watching any active government listings.</p>
      </Alert>
    ) : null;

  const results =
    data && data.data ? (
      <ResultList data={data} isLoading={isLoading} pageClick={pageClick} isAutomotive={false} />
    ) : null;

  return (
    <div css={style}>
      <h2>
        <i className="fas fa-list" /> Auction Listings
      </h2>
      <hr />
      {loading}
      {empty}
      {results}
    </div>
  );
};

export default WatchlistGovernment;
