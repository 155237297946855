import React, { useContext, useEffect } from "react";
import _ from "lodash/fp";
import { Link } from 'react-router-dom';
import Alert from "reactstrap/lib/Alert";

import { withPagination } from "utils/withPagination";
import Item from "./partials/Item";
import { Auction } from 'types/pages/My_Wins/myWins';
import { Pagination } from "types/types";
import { AppContext } from "context/AppProvider";
import actions from 'constants/appContext';
import CovidAlert from "./partials/CovidAlert";

const MyWins: React.FC<Pagination<Auction>> = ({ data }) => {
  const {dispatch} = useContext(AppContext);

  useEffect(() => {
    dispatch({ type: actions.CLEAR_PAYMENT });
  }, []);

  const noItemAlert = (
    <Alert color="warning" className="alert-msg">
      <p>You have no won item. Once you win an auction you can pay for it here!</p>
    </Alert>
  );

  return (
    <React.Fragment>
      <p>Looking for refunded items? <Link to="/my-refunds">Click Here</Link></p>
      {(data && data.length) ? _.map((item: any) => <Item key={`my-win-${item.id}`} item={item} />)(data) : noItemAlert}
    </React.Fragment>
  );
}

export default withPagination("/user/items", ["My Wins", "fas fa-shopping-cart"])(MyWins);