import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Input, CancelButton, SaveButton } from 'components';
import { ResponseFormProps } from 'types/pages/SupportMessages/messages';

const validation = Yup.object().shape({
  message: Yup.string().required('Message is required'),
});

const ResponseForm: React.FC<ResponseFormProps> = ({ onSubmit, loading }) => (
  <Formik
    initialValues={{ message: '' }}
    enableReinitialize={ true }
    validationSchema={ validation }
    validateOnChange={ false }
    onSubmit={ onSubmit }
    render={({
      handleChange,
      touched,
      errors,
      handleReset,
      values: { message }
    }) => (
      <Form>
        <Input
          label="Message"
          name="message"
          type="textarea"
          onChange={ handleChange }
          value={ message }
          isError={ touched.message && errors.message }
          errorMessage={ errors.message }
          invalid={ errors.message ? true : false }
        />
        <SaveButton type="submit" disabled={ loading }>Save Changes</SaveButton>
        <CancelButton className="btn-cancel" onClick={ handleReset }>
          Cancel
        </CancelButton>
      </Form>
    )}
  />
);

export default ResponseForm;