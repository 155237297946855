/** @jsx jsx */

import React from 'react';
import parse from 'html-react-parser';
import { Row, Col } from 'reactstrap';
import { jsx } from '@emotion/core';

import style from 'styles/SitePage';
import { Loading } from 'components';
import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';

const BiddingRules: React.FC = () => {
  document.title = 'Police Auctions - Bidding Rules';
  const [{ data }] = useFetch('/page/4/1', reducer);
  return (
    <Row css={style}>
      <Col lg={8}>
        <h1>PoliceAuctions.com Bidding Rules</h1>
        <hr/>
        {data ? parse(data.text) : <Loading />}
      </Col>
    </Row>
  );
};

export default BiddingRules;