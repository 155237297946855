export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const RESET = 'RESET';

export default {
  REQUEST,
  SUCCESS,
  FAILURE,
  RESET
}
