import React from 'react';

type Props = {
  rating: number;
  actualRating: number;
  handleClick: (ratingNum: number) => void;
};

const Star: React.FC<Props> = ({ rating, actualRating, handleClick }) => {
  const starClass = rating <= actualRating ? "fa fa-star" : "far fa-star";
  return (
    <a href="js:void()" className="star-clickable" onClick={() => handleClick(rating)}>
      <i className={`${starClass} fa-2x`}></i>
    </a>
  );
};

export default Star;
