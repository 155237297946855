import React from 'react';

import { TicketListProps } from 'types/pages/Support_Tickets/supportTicket';
import Ticket from './Ticket';

const TicketList: React.FC<TicketListProps> = ({ data: { total, data: records } }) => {

  const results = (total > 0)
    ? records.map((record, index) => (
        <Ticket record={ record } key={ index } />
      ))
    : null;

  return (
    <React.Fragment>
      { results }
    </React.Fragment>
  );
};

export default TicketList;