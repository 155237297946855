import React from 'react';
import { map, isNumber } from 'lodash';
import parse from 'html-react-parser';

import formatStrToTitle from 'utils/formatStrToTitle';

type Products = {
  products: Record<string | number, string> | string[];
  name?: string;
};

const listProductHeaderName = (productTitle) => (productTitle ? Object.keys(productTitle).slice(2) : null);

const ListProductHeader: React.FC<{ headerName: string }> = ({ headerName }) => {
  const lastChar = parseInt(headerName.substr(headerName.length - 1));
  const name =
    typeof lastChar === 'number' && !isNaN(lastChar) ? headerName.substr(0, headerName.length - 1) : headerName;
  return <strong className="list-header">{formatStrToTitle(name)}</strong>;
};

const removeZeros = (key: string | number) => (isNumber(key) ? '' : `${key}:`);

const ListNote: React.FC<{}> = () => (
  <ul className="product-list">
    <li>
      Vendor supplied description thought to be accurate but not guaranteed. We spot check gemstones with a stone-tester
      that measures heat retention to see if the stone is consistent with the stone-type indicated. The stone-tester
      indicates a specific type of stone and that matches the description. No atomic analysis is performed. We cannot
      guarantee 3rd party opinions or atomic structure will match the description. This item is fully returnable,
      including original shipping.
    </li>
  </ul>
);

const filterOutSecondaryMaterials = (products: Pick<Products, 'products'>['products']) =>
  Object.entries(products)
    .filter(([key]) => (/Secondary Material(\s\w+)?/.test(key) ? false : true))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

const ProductInfoList: React.FC<Products> = ({ products }) => {
  const isKeyPlatingMaterial = Object.keys(products).some((p) => p === 'Plating Material');
  const productFilter = isKeyPlatingMaterial ? filterOutSecondaryMaterials(products) : products;

  return (
    <ul className="product-list">
      {map(productFilter, (value, key) => (
        <li key={key}>
          {key ? removeZeros(key) : ''} {parse(value as string)}
        </li>
      ))}
    </ul>
  );
};

const ProductDescription = ({ productId, products, name }) => (
  <React.Fragment>
    {map(products, (product, key) => (
      <React.Fragment key={key}>
        <hr className="product-line" />
        <ListProductHeader headerName={listProductHeaderName(name)![key]} />
        <ProductInfoList products={product} />
      </React.Fragment>
    ))}
    {productId === 53 ? <ListNote /> : null}
  </React.Fragment>
);

export default ProductDescription;
