import { css } from '@emotion/core';

export default css`
  padding-top: 2rem;

  .header {
    text-align:center;
    font-size: 2rem;
    font-weight: normal;
  }

  .subtitle {
    font-size: 1.2rem;
    font-weight: 100;
    padding-left: .5rem;
  }

  .well {
    font-size: 1.4rem;
    min-height: 2rem;
    padding: 2rem;
    margin-bottom: 2rem;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: .25rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  .well > p {
    margin: 0;
  }

  .textarea {
    height: 10rem;
    font-size: 1.2rem;
  }
`;