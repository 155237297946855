/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/core';
import { Col, Row } from 'reactstrap';

import AdsenseAd from './AdsenseAd';
import { BREAKPOINTS as BP } from 'constants/emotionConfig';

const FooterBanner: React.FC = () => {

  return (
    <Row css={style}>
      <Col className="col-lg-12 banner-ad">
        <AdsenseAd
          type="banner"
          slot="9225911126"
          client="ca-pub-5964452904144582"
        />
      </Col>

      <Col className="col-lg-12 mobile-ad">
        <AdsenseAd
          type="mobile"
          slot="3179377521"
          client="ca-pub-5964452904144582"
         />
      </Col>
    </Row>
  );
}

export default FooterBanner;

const style = css`
  text-align: center;
  display: inherit;

  .mobile-ad {
    display: none;
  }

  .banner-ad {
    display: inline-block;
  }

  @media (max-width: ${BP.SM}) {
    .mobile-ad {
      display: inline-block;
      height: 300px;
      width: 300px;
      text-align: center;
    }

    .banner-ad {
      display: none;
    }
  }
`;
