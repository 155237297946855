import React from 'react';
import { Alert } from 'reactstrap';

const AuctionActiveAlert: React.FC<{ isActive: boolean }> = ({ isActive }) =>
  isActive ? (
    <Alert color="warning">
      <p>
        <b>Oh no!</b> You are not bidding on any active auctions right now.
      </p>
    </Alert>
  ) : null;

export default AuctionActiveAlert;
