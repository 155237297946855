/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import {
  PaginationItem,
  PaginationLink
} from 'reactstrap';

type Props = {
  leftPage: number;
  rightPage: number;
  onClick: Function;
  first?: boolean;
  last?: boolean;
  next?: boolean;
  previous?: boolean;
}

const PageLink: React.FC<Props>= (props) => {

  const {
    leftPage,
    rightPage,
    first = false,
    last = false,
    next = false,
    previous = false,
    onClick
  } = props;

  return (
    <React.Fragment>
      <PaginationItem>
        <PaginationLink
          hidden={first}
          next={next}
          onClick={() => onClick(leftPage)}
        />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          hidden={last}
          previous={previous}
          onClick={() => onClick(rightPage)}
        />
      </PaginationItem>
    </React.Fragment>
  );
};

export default PageLink;