import React from 'react';

import TrackingHistory from './TrackingHistory';
import Review from 'components/product/Review';
import { ItemProps } from 'types/pages/My_Wins/myWins';
import { trackingStatus } from 'constants/auction';

const FooterDetails: React.FC<ItemProps> = ({item}) => {
  const activities = item.shipping && item.shipping.activity ? item.shipping.activity : [];
  const itemDelivered = item.shipping && item.shipping.shipping_tracking && item.shipping.shipping_tracking.tracking_status_id === trackingStatus.DELIVERED;
  const noClaim = item.insurance_claim === null;
  const productReview = (itemDelivered && noClaim) ? <Review item={item} /> : null;
  return (
    <React.Fragment>
      { productReview }
      <TrackingHistory activities={activities} />
    </React.Fragment>
  );
};

export default FooterDetails;