import { css } from '@emotion/core';

export default css`
  .title {
    font-size: 2.2rem;
  }

  .sub-title {
    font-size: 1.8rem;
  }

  .statement {
    margin-top: 1rem;
  }

  .testimonial {
    color: #3b5998;
  }
`;
