import React from "react";
import { Col } from "reactstrap";
import { ItemProps } from "types/pages/My_Wins/myWins";

const LeftDetails = ({ item }: ItemProps) => (
  <Col lg="2" xs="12">
    <img src={`https://images.serrf.com/${item.control.product_description.category_id}/7/${item.control.product_description.id}.jpg`} alt="" className="row-image" />
  </Col>
);

export default LeftDetails;
