import React, { useContext } from 'react';

import Card from 'components/card/Card';
import CardList from 'components/card/CardList';
import { AppContext } from 'context/AppProvider';

export const Spotlight: React.FC = () => {
  const { state: { preloadData: { featured } } } = useContext(AppContext);

  return (
      <Card headerTitle="Gov Auction Spotlight">
      { featured ? featured.map(list => (
          <CardList
            key={`external-auction-${list.external_auction_id}`}
            name={ list.trimmedDescription }
            url={`/externalauction/${list.external_auction_id}`}
          />
        ))
      : null }
      </Card>
  )
}

export default Spotlight;