import pipe from 'lodash/fp/pipe';

//TODO: Finish types to this file.

const mapData = <T extends object>(data: T) =>
  Object.keys(data)
    .filter(item => data[item] === true && !/is_fire$/.test(item))
    .join('');

const changeStringName = (str: string) => `${str.replace(/^is_/, '')}_id`;

const checkIfVirtual = (name: string) => (name === 'virtual_id' ? 'mount_id' : name);

export default pipe(mapData, changeStringName, checkIfVirtual) as <T extends object>(arg: T) => string;
