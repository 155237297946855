import React from 'react';
import { Alert } from 'reactstrap';

import { isHighestBidder, getMaxNotification, setMaxMessage, isProxy } from 'utils/setMaxNotification';
import { Status } from 'types/pages/Auction_View/partials/bidFormContainer';

type Props = {
  setMax: string;
  userLoggedIn: boolean;
  salePrice: string;
  currentSetMax: string;
  postUpdateStatus: Status;
  maxBidAmount: number;
  myGreatestPrice: string;
  itemAmount?: any;
};

const BidHistoryContainer: React.FC<Props> = ({
  children,
  currentSetMax,
  salePrice,
  userLoggedIn,
  postUpdateStatus,
  maxBidAmount,
  myGreatestPrice,
  itemAmount,
}) => {
  const amount = maxBidAmount || parseInt(currentSetMax);
  //const isServerProxy = isProxy(userLoggedIn, postUpdateStatus)('proxy');
  //const hasMaxNotification = getMaxNotification(parseInt(currentSetMax), parseInt(salePrice));
  const isHigherBidder = isHighestBidder(myGreatestPrice, parseInt(itemAmount));

  const bidServerMaxNotification = maxBidAmount;

  const showMaxNotification = bidServerMaxNotification ? bidServerMaxNotification : parseInt(currentSetMax);

  return (
    <div className="bid-history-container">
      <p className="p-inline my-0 font-weight-bold mr-2">Bid History</p>
      {showMaxNotification && userLoggedIn ? (
        <Alert color={isHigherBidder ? 'success' : 'danger'} className="d-inline max-bid-alert">
          {setMaxMessage(amount)}
        </Alert>
      ) : null}
      {children}
    </div>
  );
};

export default BidHistoryContainer;
