import React from 'react';

import SupportMessage from './SupportMessage';
import { SupportConversationProps } from 'types/pages/SupportMessages/messages';

const SupportConversation: React.FC<SupportConversationProps> = ({ data }) => (
  <React.Fragment>
    {data
      ?  data.map((message, index) => (
          <SupportMessage message={ message } key={ index } />
        ))
      : null }
  </React.Fragment>
);

export default SupportConversation;