import React from 'react';
import { Col, Row, Table, Alert } from 'reactstrap';

import CreditCardSubscriptionForm from './partials/CreditCardSubscriptionForm';

const BraintreeSubscription = ({ match }) => {
  document.title = 'Police Auctions - Subscription';
  return (
      <div className="page-body">
        <h3>One Year Membership</h3>
        <hr />
        <Row>
          <Col lg={4} md={6} sm={6}></Col>
          <Col lg={4} md={6} sm={6} >
            <Alert color="warning">
              <p>PoliceAuctions.com One Year Membership</p>
              <p>Your Credit Card Will be billed $39.95. This is a one time charge and you will not be rebilled.</p>
            </Alert>
            <CreditCardSubscriptionForm />
          </Col>
        </Row>
      </div>
    );
};

export default BraintreeSubscription;