/** @jsx jsx */

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { css, jsx } from '@emotion/core';

import { Sidebar, Footer, Header, FooterBanner, CookieBar } from 'components';
import { useTimerFetch } from 'hooks/async';
import category from 'reducers/cateogry';

export const MainPage: React.FC = ({ children }) => {
  //3600000ms = 60 minutes
  const [{ data }] = useTimerFetch('/category', category, 3600000);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <React.Fragment>
      <Header categoryListData={data} />
      <Container css={style} fluid={true}>
        <Row>
          <Sidebar categories={data} />
          <Col>
            { children }
          </Col>
        </Row>
        {/* <FooterBanner /> */}
      </Container>
      <Footer />
      <CookieBar />
    </React.Fragment>
  );
}

const style = css`
  padding-top: 2rem;
  min-height: 75vh;
`
