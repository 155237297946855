import axios from 'axios';
import { API_URL, PAYMENT_API_URL } from 'constants/config';
import history from 'utils/history';

axios.defaults.headers.common['SiteId'] = 1;

/**
 * Add the request interceptor for handling
 * cancelling of the request and specific
 * status codes and the global rejection
 * of the promise.
 */
axios.interceptors.response.use(
  response => response,
  error => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    if (error.response.status === 403) {
      history.push('/');
      error.response.data.errors = {};
    }

    if (error.response.status === 401) {
      error.response.data.errors = {};
    }
    return Promise.reject(error);
  }
);

/**
 * Taking in the source argument, determine
 * if the request should be cancelled or
 * if it just needs a cancelToken created
 * and returning the source back to the
 * variable that was originally passed in.
 *
 * @param {object} source
 */
const manageCancelToken = source => {
  if (source) {
    source.cancel();
  }
  let cancelToken = axios.CancelToken;
  return cancelToken.source();
};

/**
 * Called to set the authorization headers for the
 * app when contacting the API.
 */
const authorizationHeaders = async () => {
  try {
    const token = await localStorage.getItem('token');
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`;
  } catch (error) {
    // todo handle error
  }
};

/**
 * Return the axios config with the method and url
 * being passed in as arguments.
 *
 * @param {string} method
 * @param {string} url
 * @param {string|null} optionalBaseUrl
 */
const getAxiosConfig = (method, url, optionalBaseUrl) => {
  return {
    method,
    url,
    baseURL: (optionalBaseUrl) ? optionalBaseUrl : API_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  };
};

/**
 * Call the axios API passing in a config
 * for the request parameters required accepting
 * cancellation of the request as well as passing
 * data to the POST request ONLY!!
 *
 * @param {*} url
 * @param {*} method
 * @param {*} data
 * @param {*} cancelSource
 */
const request = async (
  url,
  method = 'GET',
  data = null,
  cancelSource = null,
  optionalBaseUrl = null
) => {
  await authorizationHeaders();

  //If there are any parameters for a GET request, append them to the url
  if (method === 'GET' && data) {
    const query = Object.keys(data)
      .map(key =>  key + '=' + encodeURI(data[key]))
      .join('&');

    url = `${url}?${query}`;
  }

  let axiosConfig = getAxiosConfig(method, url, optionalBaseUrl);

  // If there is data and this is a POST request, set it in the config for the request
  if (method === 'POST' && data) {
    axiosConfig['data'] = data;
  }

  // If there is a cancelSource set this in the request config to eliminate duplicate requests
  if (cancelSource) {
    axiosConfig['cancelToken'] = cancelSource.token;
  }
  return axios(axiosConfig);
};

export default {
  get: (url, data = null, cancelSource = null) =>
    request(url, 'GET', data, cancelSource),
  getPayment: (url, data = null, cancelSource = null) =>
    request(url, 'GET', data, cancelSource, PAYMENT_API_URL),
  post: (url, data, cancelSource = null) =>
    request(url, 'POST', data, cancelSource),
  postPayment: (url, data, cancelSource = null) =>
    request(url, 'POST', data, cancelSource, PAYMENT_API_URL),
  manageCancelToken
};