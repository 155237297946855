/** @jsx jsx */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { jsx } from '@emotion/core';
import { get } from 'lodash';

import { Loading } from 'components';
import { receiptStyles } from 'styles/MyWins';
import request from 'utils/request';
import history from 'utils/history';

const PrintReceipt = ({ match }) => {
  document.title = 'Police Auctions - Receipt';
  const [data, setData] = useState(null);
  useEffect(() => {
    request.get(`/auctions/${match.params.id}/receipt`).then(({data}) => {
      setData(data);
    }, error => {
      history.push('/my-wins');
    });
  }, [])

  const [tax, transaction_time, name, amount, freight_revenue, grand_total, id, category] = [
    get(data, 'tax'),
    get(data, 'transaction_time'),
    get(data, 'name', ''),
    get(data, 'amount'),
    get(data, 'freight_revenue'),
    get(data, 'grand_total'),
    get(data, 'id'),
    get(data, 'category')
  ];

  const parsedName = (name.length > 22) ? name.substring(0, 21) + '...' : name;

  // Tax row if tax was included in the payment
  const taxRow = () =>
    tax !== '0.00' ? (
      <div className="summary-row summary-shipping">
        <div className="summary-column">Tax</div>
        <div className="summary-column"> </div>
        <div className="summary-column">${tax}</div>
      </div>
    ) : null;

  return !data ? (
    <Loading />
  ) : (
    <div css={receiptStyles} style={{ maxWidth: '700px' }}>
      <div className="modal-content">
        <div className="modal-body">
          <h2 className="receipt-header">
            <span>S</span>errf <span>C</span>orp.
          </h2>
          <hr />
          <h3 className="receipt-com">
            www.<span>P</span>olice<span>A</span>uctions.com
          </h3>
          <p className="receipt-date">{moment(transaction_time).format('LLLL')}</p>
          <div className="summary">
            <div className="summary-row summary-header">
              <div className="summary-column">Item Name</div>
              <div className="summary-column">Qty</div>
              <div className="summary-column">Price</div>
            </div>
            <div className="summary-row">
              <div className="summary-column">{parsedName}</div>
              <div className="summary-column">1</div>
              <div className="summary-column">${amount}</div>
            </div>
            {taxRow()}
            <div className="summary-row summary-shipping">
              <div className="summary-column">Shipping & Handling</div>
              <div className="summary-column"> </div>
              <div className="summary-column">${freight_revenue}</div>
            </div>
            <div className="summary-row summary-total">
              <div className="summary-column">Grand Total</div>
              <div className="summary-column"> </div>
              <div className="summary-column">${grand_total}</div>
            </div>
            <div className="receipt-thanks">
              <h3>Thank you for shopping at PoliceAuctions.com!</h3>
            </div>
            <div className="receipt-reference">
              <div>Reference # {id}</div>
              <div>Category: {category}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintReceipt;
