/** @jsx jsx */

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { jsx } from '@emotion/core';
import { withTheme } from 'emotion-theming';

import { Input } from 'components';
import { BlockButton } from 'components';

const validate = Yup.object().shape({
  user_id: Yup.string().required('User is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required')
});

export const ImpersonateForm = withTheme(({ onSubmit, userId, isLoading }) => (
  <Formik
    initialValues={{ user_id: userId, username: '', password: '' }}
    validationSchema={validate}
    onSubmit={onSubmit}
    validateOnChange={false}
    render={({ errors, touched, handleChange }) => (
      <Form>
        <Input
          label="User"
          placeholder="User"
          invalid={errors.user_id ? true : false}
          name="user_id"
          value={ userId }
          onChange={handleChange}
          disabled
        />
        <Input
          label="Your Username"
          placeholder="Your Username"
          isError={touched.username && errors.username}
          errorMessage={errors.username}
          invalid={errors.username ? true : false}
          name="username"
          onChange={handleChange}
        />
        <Input
          label="Password"
          type="password"
          placeholder="Password"
          isError={touched.password && errors.password}
          errorMessage={errors.password}
          invalid={errors.password ? true : false}
          name="password"
          onChange={handleChange}
        />
        <BlockButton type="submit" value="Login" color="green" disabled={ isLoading }>
          Login
        </BlockButton>
      </Form>
    )}
  />
));