/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import Paginator from 'components/pagination/Paginator';
import SearchResult from './SearchResult'

export type Result = {
  id: number;
  name: string;
  city: string;
  state: string;
  distance: number;
  has_image: boolean;
  content_tag: string;
};

type ResultResponse = {
  data: Array<Result>;
  total: number;
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
};

type Props = {
  data: ResultResponse;
  isAutomotive: boolean;
  isLoading: boolean;
  pageClick: (page: number) => void;
};


const ResultList: React.FC<Props> = ( props ) => {

  const { data, pageClick, isLoading, isAutomotive } = props;

  const results = (data.data && data.total > 0)
    ? data.data.map((record, index) => (
        <SearchResult
          record={ record }
          key={ index }
          isAutomotive={ isAutomotive }
        />
      ))
    : null;

  return (
    <React.Fragment>
      { results }
      <Paginator
        pages={ data }
        onClick={ pageClick }
        isLoading={ isLoading }
      />
    </React.Fragment>
  )
}

export default ResultList;
