export default {
  NOTIFICATION_REQUEST: 'NOTIFICATION_REQUEST',
  NOTIFICATION_SUCCESS: 'NOTIFICATION_SUCCESS',
  NOTIFICATION_FAILURE: 'NOTIFICATION_FAILURE',

  MARK_READ_REQUEST: 'MARK_READ_REQUEST',
  MARK_READ_SUCCESS: 'MARK_READ_SUCCESS',
  MARK_READ_FAILURE: 'MARK_READ_FAILURE',

  MARK_ALL_REQUEST: 'MARK_ALL_REQUEST',
  MARK_ALL_SUCCESS: 'MARK_ALL_SUCCESS',
  MARK_ALL_FAILURE: 'MARK_ALL_FAILURE',

  REFERENCE_AUCTION: 1,
  REFERENCE_SUPPORT: 2,
}