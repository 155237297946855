import React, { useEffect, useState, useRef, useLayoutEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import BraintreeWebDropIn from 'braintree-web-drop-in';

import { BlockButton, Loading } from 'components';
import request from 'utils/request';
import { initialInstanceState } from 'components/CreditCardDropIn';
import history from 'utils/history';
import { AppContext } from 'context/AppProvider';
import { UPDATE_PAYMENT } from 'constants/appContext';

const CreditCardSubscriptionForm = () => {
  const [token, setToken] = useState<string | null>(null);
  const [element, setElement] = useState(useRef());
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const [instance, setInstance] = useState(initialInstanceState);
  const [braintreePayload, setBraintreePayload] = useState<any>(null);
  const {dispatch} = useContext(AppContext);

  useEffect(() => {
    // Get the client ID
    request.postPayment('/subscription/credit-card/token', {}).then(({ data }) => {
      setToken(decodeURIComponent(data.client_token));
    });
  }, [])

  useLayoutEffect(() => {
    if (token) {
      BraintreeWebDropIn.create(
        {
          authorization: token,
          selector: ReactDOM.findDOMNode(element.current)
        },
        (err, instance) => {
          setInstance(instance);
          setIsLoading(false);
        }
      );
    }
  }, [token])

  useEffect(() => {
    if (!braintreePayload) {
      return;
    }
    request
      .postPayment('/subscription/credit-card/process', {message: { ...braintreePayload }}).then(
        ({ data }) => {
          dispatch({ type: UPDATE_PAYMENT, payload: { amount: data.amount } });
          history.push(`/subscription/receipt`);
        },
        error => {
          setErrors(error.response.data);
          setIsLoading(false);
        }
      );
  }, [braintreePayload]);

  const _updateRef = ref => {
    if (!element.current) {
      element.current = ref;
      setElement(element);
    }
  };

  const _handlePayment = () => {
    setIsLoading(true)
    instance.requestPaymentMethod((err, payload) => {
      if (err) {
        setIsLoading(false);
        return;
      }
      setBraintreePayload(payload);
    });
  };

  return (
    <div>
        <div id="dropin-container" style={{display: (isLoading ? 'none' : 'block')}} ref={_updateRef}></div>
        {isLoading ? <Loading /> : (
          <BlockButton type="submit" color="green" onClick={_handlePayment}>
            Submit Payment
          </BlockButton>
        ) }
    </div>
  )
}

export default CreditCardSubscriptionForm;