/** @jsx jsx */

import { jsx } from '@emotion/core';
import { Alert } from 'reactstrap';
import { useEffect, useContext } from 'react';

import { Loading } from 'components';
import style from 'styles/Notices';
import NoticeRow from './partials/NoticeRow';
import { AppContext } from 'context/AppProvider';

const Notices: React.FC = () => {
  document.title = 'Police Auctions - Notices';

  const { state: { preloadData: { isLoading, notices } } } = useContext(AppContext);

  const loading = isLoading ? <Loading /> : null;

  const empty = (notices && notices.length === 0)
    ? <Alert color="warning"><p>There are no new notices to see yet.</p></Alert>
    : null;

  return (
    <div css={style}>
      <h1 className="title">Notices</h1>
      <hr/>
      { loading }
      { empty }
      <ul className="list-unstyled">
        {notices
          ? notices.map(notice => (
              <NoticeRow
                notice={ notice }
                wasRead={ notice.user ? true : false }
                key={`notice-row-${notice.id}`} />
            ))
          : null}
      </ul>
    </div>
  );
};

export default Notices;