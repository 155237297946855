/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import { RouteComponentProps } from "react-router";
import { Container, Alert } from 'reactstrap';
import { FormikActions } from 'formik';
import parse from 'html-react-parser';

import { reducer } from 'reducers/asyncGlobal';
import { usePost } from 'hooks/async';
import { ReturnFormFields } from 'types/pages/Support_Return/form';
import style from 'styles/SupportReturn';
import ReturnForm from './partials/ReturnForm';
import RMAInstructions from './partials/RMAInstructions';
import filterEmojisOnStr from 'utils/filterEmojisOnStr';

const SupportReturn: React.FC<RouteComponentProps<any>> = ({ match: { params } }) => {
  document.title = 'Police Auctions - Support Return';
  const [{ data, isLoading, errors }, submit] = usePost(`/support/return`, reducer);

  const success = data ?
    <Alert color="success">
      <p>{ parse(data.success_message) }</p>
      <br />
      <RMAInstructions rma_number={data.rma_number} />
    </Alert> : null;

  const error = errors ? <Alert color="danger"><p>{ errors.message }</p></Alert> : null;

  const onSubmit = (values: ReturnFormFields, actions: FormikActions<ReturnFormFields>) => {
    submit({ ...values, description: filterEmojisOnStr(values.description) });
    actions.resetForm();
  };

  const { id } = params;

  return (
    <Container css={ style }>
      <h1 className="header">Police Auctions Item Return Request Form</h1>
      <p>
        We're sorry you are unhappy with the item you have received.
        Please fill out the form below to request an item return or replacement.
        You will receive instructions once you have filled out the form and submitted it for processing.
      </p>
      { success }
      { error }
      <ReturnForm onSubmit={ onSubmit } loading={ isLoading }  auctionNumber={ id == null ? '' : id }/>
    </Container>
  );
};

export default SupportReturn;