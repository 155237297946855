import React from 'react';

import { Auction } from 'types/pages/My_Wins/myWins';
import StarRating from './StarRating';
import Testimonial from './Testimonial';

type Props = {
  item: Auction;
};

const Review: React.FC<Props> = ({ item }) => (
  <div className="testimonial-section">
    <StarRating id={item.id} currentRating={item.product_review ? item.product_review.rating : null} />
    <Testimonial id={item.id} hasTestimonial={item.testimonials && item.testimonials.length > 0} />
  </div>
);

export default Review;
