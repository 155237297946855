import React, { useContext, useEffect } from 'react';

import NotificationsForm from './partials/NotificationsForm';
import { useFetch, usePost } from 'hooks/async';
import notificationReducer from 'reducers/notifications';
import newsletterReducer from 'reducers/newsletter';
import { AppContext, updateNewsletter } from 'context/AppProvider';
import { Loading, ErrorMessage } from 'components';

const Notifications: React.FC = () => {
  document.title = 'Police Auctions - Notifications';
  const {
    state: { user },
    dispatch
  } = useContext(AppContext);
  const [{ data }, setReload] = useFetch(
    '/notification-settings', 
    notificationReducer
  );
  const [{ data: notificationData, errors }, setNotification] = usePost(
    'notification-settings', 
    notificationReducer
  );
  const [{ data: newsletterData, errors: newsletterErrors }, setNewsletter] = usePost(
    'user/newsletter',
    newsletterReducer
  );
  
  useEffect(() => {
    if (notificationData) {
      setReload(true);
    }
  }, [notificationData]);

  useEffect(() => {
    if (newsletterData) {
      updateNewsletter(dispatch, !user.newsletter);
    }
  }, [newsletterData]);

  return (
    <React.Fragment>
      <h2>Notifications Settings</h2>
      <p>Change your notification preferences here</p>
      <ErrorMessage item={errors || newsletterErrors} />
      {data ? (
        <NotificationsForm
          notificationList={data}
          user={user}
          updateNotifications={setNotification}
          updateNewsletter={setNewsletter}
        />
      ) : (
        <Loading />
      )}
    </React.Fragment>
  );
};

export default Notifications;
