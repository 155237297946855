import React, { useState } from 'react';
import { Collapse, CardBody, Col } from 'reactstrap';

import CardCollapse from './CardCollapse';
import { CardListing } from 'components';

const CardCollapseContent = ({ headerTitle, children, ...rest }) => {
  const [isCardOpen, setIsCardOpen] = useState(true);

  const handleOnTitleClick = () => {
    setIsCardOpen(prevClick => !prevClick);
  };

  return (
    <Col xs={12}>
      <div className="mb-2">
        <CardCollapse onTitleClick={handleOnTitleClick} headerTitle={headerTitle} {...rest}>
          <Collapse isOpen={isCardOpen}>
            <CardBody className="product-body">
              <CardListing>{children}</CardListing>
            </CardBody>
          </Collapse>
        </CardCollapse>
      </div>
    </Col>
  );
};

export default CardCollapseContent;
