import { css } from "@emotion/core";

export const style = css`
  .row-image {
    max-height: 15rem;
  }
  .link-button {
    color: #ffffff;
    text-decoration: none;
  }
  .footer-row.row {
    margin-top: 4rem;
    flex-direction: column;
  }
  .activity-row {
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    line-height: 1.4rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
    align-items: center;
    margin-bottom: 10px;
  }
  .tracking-column {
    width: 33%;
  }
  .row {
    margin: 0;
  }
  // TestimonialRating Component
  .testimonial-section {
    margin-bottom: 2rem;
  }

  // TestimonialReview Component

  // StarRating Component
  .far.fa-star {
    color: black;
  }
  .fa.fa-star,
  .fas.fa-star-half-alt {
    color: gold;
  }
  .star-rating-row {
    margin-bottom: 1rem;
  }
  .star-clickable {
    margin-right: 0.5rem;
  }
`;

export const receiptStyles = css`
  .modal-body {
    font-weight: 300;
    text-align: center;
  }
  .receipt-header,
  .receipt-com {
    text-transform: uppercase;
  }
  .receipt-header span {
    font-weight: normal;
    font-size: 3.2rem;
  }
  .receipt-com {
    overflow: hidden;
    letter-spacing: 0.7rem;
  }

  .receipt-com span {
    font-size: 2.6rem;
  }
  .receipt-date {
    padding-bottom: 2.5rem;
  }
  .summary {
    font-size: 1.2rem;
  }
  .summary-row {
    line-height: 2.2rem;
    display: flex;
    flex-direction: row;
    font-weight: 600;
  }
  .summary-row:first-of-type {
    border-bottom: 1px solid #000;
    padding-bottom: 0.5rem;
  }
  .summary-column:first-of-type {
    width: 60%;
    justify-content: start;
    display: inline-flex;
  }
  .summary-column {
    width: 20%;
  }
  .summary-shipping {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #000;
  }
  .summary-total {
    padding-top: 1.5rem;
  }
  .receipt-thanks {
    margin: 6rem 10rem;
  }
  .receipt-reference {
    text-align: left;
    width: 100%;
  }
`;
