/** @jsx jsx */

import { ButtonHTMLAttributes } from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { css, jsx } from '@emotion/core';

type Events = {
  onSubmit?: (event: React.FormEvent) => void;
};

type ButtonType = ButtonHTMLAttributes<Events>;

type Props = ButtonProps<ButtonType>;

const SaveButton: React.FC<Props> = ({ children, ...rest }) => (
  <Button {...rest} css={styles}>
    {children}
  </Button>
);

const styles = ({ colors }) => css`
  background-color: ${colors.blue};
  padding: 0.6rem 1.2rem;
  border: 1px solid ${colors.blueDark};
  font-size: 1.4rem;
  border-radius: 0.4rem;
  font-weight: lighter;

  :hover {
    background-color: ${colors.blueDark};
    border: 1px solid ${colors.blueDark};
  }
`;

export default SaveButton;
