import React from 'react';
import moment from 'moment';

import { TrackingActivity as Props } from 'types/pages/My_Wins/myWins';

const TrackingActivity: React.FC<Props> = props => {
  const city = props.city ? `${props.city},` : '';
  const country = props.country ? props.country.country : ''
  return (
    <div key={`activity-${props.id}`} className="activity-row">
      <div className="tracking-column">{moment(props.scan_date).format("LLLL")}</div>
      <div className="tracking-column">{ `${city} ${country}` }</div>
      <div className="tracking-column">{ props.description ? props.description.description : '' }</div>
    </div>
  );
}

export default TrackingActivity;