/** @jsx jsx */

import { jsx, css } from '@emotion/core';
import { Link } from 'react-router-dom';

import Card from './Card';
import BlockButton from 'components/BlockButton';

type Props = {
  headerTitle: string;
  image: string;
  url: string;
};

const CardDailyDeal: React.FC<Props> = ({ headerTitle, image, url, ...rest }) => (
  <Card headerTitle={headerTitle} css={style} {...rest}>
    <div className="dailydeal-container item-col">
      <div className="row-fluid text-center product-image-container">
        <div className="product-image-row">
            <div className="product-image-cell">
              <Link to={url}>
                <img alt="some_img" src={image} className="img-responsive center-block preview-image" />
              </Link>
            </div>
        </div>
      </div>
      <div className="bottom-container">
        <Link to={url} className="offer-buttons">
          <BlockButton>Submit an Offer</BlockButton>
        </Link>
      </div>
    </div>
  </Card>
);

const style = css`
  .dailydeal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    text-align: center;
  }

  .card-body {
    height: 100%;
  }

  .bottom-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .item-col * {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .product-image-container {
    display: table;
    min-height: 400px;
    text-align: center;
    width: 100%;
    padding-bottom: 5px;
  }

  img {
    max-height: 375px;
    max-width: 375px;
    margin: 0 auto;
  }

  .product-image-row {
    display: table-row;
    height: 100%;
    text-align: center;
  }

  .product-image-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  @media screen and (max-width: 2080px) {
    img {
      max-height: 300px;
      max-width: 345px;
    }
    .product-image-container {
      min-height: 323px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 2013px) {
    img {
      max-height: 300px;
      max-width: 265px;
    }
  }

  @media screen and (max-width: 1646px) {
    img {
      max-height: 260px;
      max-width: 230px;
    }
    .product-image-container {
      min-height: 309px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 1448px) {
    img {
      max-height: 216px;
      max-width: 230px;
    }
    .product-image-container {
      min-height: 270px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 1448px) and (min-width: 1199px){
    .item-col.col-lg-3 {
      width: 33.333%
    }
  }

  @media screen and (max-width: 1333px) {
    img {
      max-height: 216px;
      max-width: 188px;
    }
    .product-image-container {
      min-height: 230px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 1199px) {
    img {
      max-height: 375px;
      max-width: 202px;
    }
    .product-image-container {
      min-height: 306px;
    }
  }

  @media screen and (max-width: 767px) {
    img {
      max-height: none;
      max-width: 355px;
    }
    .product-image-container {
      min-height: 100%;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 780px) {
    .navbar-nav > li > a {
      padding: 13px 12px;
    }
  }

  @media screen and (max-width: 400px) {
    img {
      max-height: none;
      max-width: 290px;
    }
  }

  @media screen and (max-width: 370px) {
    img {
      max-height: none;
      max-width: 260px;
    }
  }
`;

export default CardDailyDeal;
