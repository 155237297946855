import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  name: string;
  url: string;
}

const FooterLink: React.FC<Props> = ({ name, url }) => (
  <li className="footer-list">
    <Link to={url} className="footer-link">
      {name}
    </Link>
  </li>
);

export default FooterLink;