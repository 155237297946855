/** @jsx jsx */

import { useContext } from 'react';
import { jsx } from '@emotion/core';

import { withPagination } from 'utils/withPagination';
import { AuctionActiveAlert, CardAuctionList } from 'components';
import useBidStream from 'hooks/bidStream';
import useTimeStamp from 'hooks/timeStamp';
import { AppContext } from 'context/AppProvider';

const Watchlist: React.FC<any> = ({ data }) => {
  document.title = 'Police Auctions - Watchlist';

  const {
    state: { user }
  } = useContext(AppContext);
  const bidStream = useBidStream(data, user);
  const [timeStamp] = useTimeStamp();

  return (
    <div>
      <br />
      <AuctionActiveAlert isActive={data && !data.length}>
        <p>No Auction items are being watched</p>
      </AuctionActiveAlert>
      <CardAuctionList
        data={bidStream}
        serverTime={timeStamp}
        enableStatusAlert={true}
        userId={user.id}
        username={user.username}
      />
    </div>
  );
};

export default withPagination('/watchlist', ["Auctions I'm  Watching", 'fas fa-eye'])(Watchlist);
