/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Col, Row, Container, Alert, ListGroup } from 'reactstrap';

import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { Loading } from 'components';
import style from 'styles/KnowledgeBase';
import Section from "./partials/Section";
import SkipAhead from "./partials/SkipAhead";

const KnowledgeBase: React.FC = () => {
  document.title = 'Police Auctions - Knowledge Base';
  const [{ data: response, isLoading }] = useFetch('/knowledgebase', reducer);

  const loading = isLoading ? <Loading /> : null;

  return (
    <Container css={ style }>
      <h1 className="header">PoliceAuctions.com KnowlegeBase</h1>
      <hr/>
      <Alert color="info">
        <p>
          Click on a question to see the answer.
          If you need further support you submit a ticket by clicking
          <Link to="/support"> here.</Link>
        </p>
      </Alert>
      <Row>
        <Col lg={ 4 } sm={ 12 }>
          <p>Click a category to skip ahead.</p>
          <ListGroup>
            {response
              ? response.data.map(category => (
                  <SkipAhead category={ category } />
                ))
              : null}
          </ListGroup>
        </Col>
        <Col lg={ 8 } sm={ 12 }>
          { loading }
          {response
            ? response.data.map(category => (
                <Section category={ category } />
              ))
            : null}
        </Col>
      </Row>

    </Container>
  )
}

export default KnowledgeBase;