import React from 'react';
import { Col } from 'reactstrap';

import { TwoRowColumn } from 'components';

const ProductInfo: React.FC = () => (
  <Col lg="5" xs="12">
    <TwoRowColumn title="Auction #:">
      <a href="/auction/${item.id}" title="Auction #${item.id}">
        2149501
      </a>
    </TwoRowColumn>
    <TwoRowColumn title="Your Bid:">
      <span>$30.00</span>
    </TwoRowColumn>
    <TwoRowColumn title="Status:">
      <span>Unpaid</span>
    </TwoRowColumn>
    <TwoRowColumn title="Return:">
      <a>Return this item</a>
    </TwoRowColumn>
  </Col>
);

export default ProductInfo;