/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/core';
import {
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';

import PageLink from './PageLink'
import { BREAKPOINTS as BP } from 'constants/emotionConfig';

type PaginationParams = {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

type Props = {
  pages: PaginationParams;
  onClick: Function;
  isLoading: boolean;
}

const Paginator: React.FC<Props>= (props) => {

  const {
    pages,
    onClick,
    isLoading
  } = props;

  const {
    current_page,
    last_page,
    total
  } = pages

  if( ! total) {
    return null;
  }

  const pageClick = (page: number) => {
    // can't be removed even inside template that has scroll to top
    // behavior = 'auto' - default makes page load more smoothly
    window.scroll(0,0);
    onClick(page);
  }

  //Ideal number of links to show on each side. Needs to be even.
  const target_link_count = 6; // was 10

  //X should show on the left and right side of the current page (5)
  const links_count_each_side = target_link_count / 2;

  //Number of pages that remain before the last page is hit.
  const remaining_pages = last_page - current_page;

  //If we are approaching the last page, the number of links on the right side is going to go down.
  const missing_right_side = (links_count_each_side - remaining_pages < 0) ? 0 : links_count_each_side - remaining_pages;

  //This is the where our range of links start at. It will grow further away from current_page the closer we are to the end.
  const start_position = current_page - missing_right_side - links_count_each_side;

  //Range start will guard start position to ensure its never less than 1.
  const range_start = (start_position > 1) ? start_position : 1;

  //Generate up to 11 clickable links between those bounds. 5 left, 5 right, 1 active.
  const max_length = target_link_count + 1;

  //Actual length of pages. Caps out at max_length. Minimised by actual number of pages that exist.
  const length = (max_length > last_page) ? last_page : max_length;

  //last page relative to current position
  const previous_page = current_page - 1;

  //Next page relatve to current position
  const next_page = current_page + 1;

  //Fill in the range of pagination of 10 links.
  let range = Array(length)
    .fill(range_start)
    .map((x, y) => x + y)

  const renderedFirstPrevious = (previous_page >= 1)
    ? <PageLink first previous leftPage={ previous_page } rightPage={ previous_page } onClick={ () => pageClick(previous_page) } />
    : null;

  const renderedNextLast = (next_page <= last_page)
    ? <PageLink next last leftPage={ next_page } rightPage={ next_page } onClick={ () => pageClick(next_page) } />
    : null;

  //Show the first two pages constantly if they dont exist in the range.
  const firstPages = range.length > 0 && range[0] > 2
    ? <React.Fragment>
        <PaginationItem key={ 1 } disabled={ isLoading } active={ current_page == 1 }>
          <PaginationLink onClick={() => pageClick(1)}>1</PaginationLink>
        </PaginationItem>
        <PaginationItem key={ 2 } disabled={ isLoading } active={ current_page == 2 }>
          <PaginationLink onClick={() => pageClick(2)}>2</PaginationLink>
        </PaginationItem>
        <PaginationItem key={ -1 } disabled={ true } active={ false }>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      </React.Fragment>
    : null;

  //Show the last 2 pages if the last element in the range is not sowing the last page.
  const lastPages = range.length > 2 && range[range.length - 1] != last_page
    ? <React.Fragment>
        <PaginationItem key={ -2 } disabled={ true } active={ false }>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
        <PaginationItem key={ last_page - 1 } disabled={ isLoading } active={ current_page == last_page - 1 }>
          <PaginationLink onClick={() => pageClick(last_page - 1)}>{ last_page - 1 }</PaginationLink>
        </PaginationItem>
        <PaginationItem key={ last_page } disabled={ isLoading } active={ current_page == last_page }>
          <PaginationLink onClick={() => pageClick(last_page)}>{ last_page }</PaginationLink>
        </PaginationItem>
      </React.Fragment>
    : null;

  return range && last_page > 1 ? (
    <Pagination css={ style }>
      { renderedFirstPrevious }
      { firstPages }
      { range.map(page => (
          <PaginationItem
            active={ page === current_page }
            key={ page }
            disabled={ isLoading }
          >
            <PaginationLink onClick={() => pageClick(page)} >
              { page }
            </PaginationLink>
          </PaginationItem>
        ))  }
      { lastPages }
      { renderedNextLast }
    </Pagination>
  ) : null;
};

export default Paginator;

const style = css`
  .pagination {
    padding-top: 1.5rem;
  }

  .page-link {
    height: 4rem;
    padding: 1.2rem 1.5rem;
    font-size: 1.4rem;
  }

  @media (max-width: ${BP.SM}) {
    .page-link {
      height: 4rem;
      padding: .75rem 1rem;
      font-size: 1.4rem;
    }
  }
`;