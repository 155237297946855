import React, { useContext, useEffect, useState } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { default as jwt_decode } from 'jwt-decode';

import { FacebookButton, ErrorMessage, SuccessMessage } from 'components';
import { usePost } from 'hooks/async';
import reducer from 'reducers/socialSettings';
import { AppContext } from 'context/AppProvider';
import { ButtonSync } from './partials/ButtonSync';
import AppleSignIn, { AppleSignInResponse } from 'components/AppleSignInButton';

const SocialSettings: React.FC = () => {
  document.title = 'Police Auctions - Social Settings';

  const [{ data: fbData, errors: fbErrors }, setFBLogin] = usePost('/social/login', reducer);
  const [{ data: appleData, errors: appleErrors }, setAppleLogin] = usePost('/social/login/apple', reducer);

  const {
    state: {
      user: { is_synced_fb, is_synced_apple }
    }
  } = useContext(AppContext);

  const onAppleSignIn: AppleSignInResponse = appleRes => {
    const { id_token, code } = appleRes.authorization;
    const { email, sub } = jwt_decode<{ email: string; sub: string }>(id_token);

    if(!appleData) {
      setAppleLogin({
        ...appleRes.user,
        authorizationCode: code,
        identityToken: id_token,
        email,
        user: sub
      });
    }
  };

  const onFacebookLogin = ({ email, id, accessToken }: ReactFacebookLoginInfo) => {
    setFBLogin({ email, social_provider_user_id: id, access_token: accessToken });
  };

  return (
    <React.Fragment>
      <h2>Social Settings</h2>
      <p>Use this button if you want to use your facebook account or apple-sign-in to log in from now on.</p>
      <ErrorMessage item={fbErrors} />
      <ErrorMessage item={appleErrors} />
      <SuccessMessage message={fbData ? fbData.message : null} />
      {/*Maybe an apple-sign-in response has a message that comes back when synced successfully?*/}
      <SuccessMessage message={appleData ? appleData.message : null} />
      {/*state.appleMessage is temporary for now*/}
      <ButtonSync isSynced={is_synced_apple || appleData} name="Apple">
        <AppleSignIn
          clientId="com.policeauctions.web"
          redirectURI="https://www.policeauctions.com/apple-sign-in"
          usePopup={true}
          scope="email"
          appleSignInResponse={onAppleSignIn}
          buttonStyle={{
            width: '37.5rem',
            height: '4.1rem',
          }}
        />
      </ButtonSync>
      <ButtonSync isSynced={is_synced_fb || fbData} name="Facebook">
        <FacebookButton
          value="Sync with Facebook"
          onFBCallback={onFacebookLogin}
          buttonStyle={{
            maxWidth: '37.5rem',
            height: '4.1rem',
            borderRadius: '0.7rem',
            marginTop: '1.2rem',
            fontSize: '1.7rem',
          }}
          iconStyle={{
            fontSize: '2rem',
            marginRight: '0.7rem',
          }}
        />
      </ButtonSync>
    </React.Fragment>
  );
};

export default SocialSettings;
