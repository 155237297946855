import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AppContext } from 'context/AppProvider';

type AuthRouteType = {
  component: FunctionComponent<any>;
};

type Props = AuthRouteType & RouteProps;

const AuthRoute: React.FC<Props> = ({ component, ...rest }) => {
  const { state: { user }} = useContext(AppContext);
  const userData = user.id ? true : false;
  const storage = localStorage.getItem('token');
  const [userExists, setuserExists] = useState<boolean>(userData);

  useEffect(() => {
    setuserExists(userData);
  }, [user, userData]);

  const Component: React.ElementType = component;

  return (
    <Route
      {...rest}
      render={props =>
        (userExists || storage) ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default AuthRoute;
