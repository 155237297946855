import { css } from '@emotion/core';

export const styles = css`
  .form-group {
    padding: 1.5rem 0;
  }
  .form-check,
  .radio-label {
    width: 100%;
  }
  .radio-input {
    margin-left: 0;
  }
  .radio-label span {
    font-weight: 400;
    padding-left: 2rem;
  }
  .radio-label img {
    float: right;
  }
  .btn {
    margin-top: 2rem;
  }
  img {
    height: 36px;
  }
`;

export const reviewStyles = css`
  .review-name {
    font-weight: bold;
  }
  .edit-block {
    width: 100%;
  }
  .edit-block button {
    float: right;
  }
  .button-block {
    margin-top: 2rem;
  }
  p {
    font-weight: 300;
  }
  .alert-msg {
    font-size: 1.7rem;
  }
  .address-card {
    margin-bottom: 2rem;
  }
`;

export const receiptStyles = css`
  background-color: #F5F5F5;
  border: 1px solid #E3E3E3;
  border-radius: .4rem;
  padding: 1rem;
  width: 100%;
  overflow: hidden;
  .receipt-row {
    clear: both;
    width: 100%;
  }
  .receipt-half {
    float: left;
    width: 50%;
  }
  .receipt-row .receipt-half:nth-child(2) {
    text-align: right;
  }
  .table-row {
    overflow: hidden;
    clear: both;
    width: 100%;
  }
  .product-column {
    width: 50%;
    float: left;
  }
  .misc-column {
    text-align: right;
    float: left;
    width: 16%;
  }
  .total-text {
    font-size: 1.8rem;
  }
  .text-bold {
    font-weight: bold;
  }
  .padding-top {
    padding-top: 2rem;
  }
`;