import React from 'react';
import AlertRow from './AlertRow';
import { ListGroup } from 'reactstrap';
import { Props } from 'types/Alerts/list';
import {
  Button,
  Row,
  Col
} from 'reactstrap';

const AlertList: React.FC<Props> = (props) => {

  const { data, markAllRead, markRead, disabled } = props;

  return (
    <React.Fragment>
      <Row className="mark-row">
        <Col>
          <Button
            color="primary"
            size="lg"
            className="float-right mark-btn"
            disabled={ disabled }
            onClick={ () => markAllRead() }>
              Mark All Read
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            {data
              ? data.map((notification, index) => (
                  <AlertRow
                    key={`${notification}-${index}`}
                    notification={notification}
                    markRead={ markRead } />
                ))
              : false }
          </ListGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AlertList;