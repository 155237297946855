import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import EditProfileForm from './partials/EditProfileForm';
import { usePost, useFetch } from 'hooks/async';
import reducer from 'reducers/updateProfile';
import { reducer as userReducer } from 'reducers/asyncGlobal';
import { Loading, ErrorMessage, SuccessMessage } from 'components';
import checkMessage from 'utils/checkMessage';
import { User } from 'types/authentication';

/**
 * Note: EnableReinitialize is causing the page not to redirect on logout
 * when using the generateUserFields utility method. For now, use the
 * loading component instead when the user data is still loading.
 */

const EditProfile: React.FC = () => {
  document.title = 'Police Auctions - Edit Profile';

  const onSubmit = (values: User) => {
    setSubmit({ ...values });
  };
  const [{ errors, data }, setSubmit] = usePost('/user', reducer);
  const [message, setMessage] = useState<null | string>(null);
  const [{ data: getUser }, setReload] = useFetch('/user', userReducer);

  useEffect(() => {
    checkMessage(setMessage, 'profile')(data);

    setReload(true);
  }, [data]);

  return (
    <Container>
      <h2>Edit Profile</h2>
      <p>
        Here you can change your email address, username and your name. Usernames will be displayed when placing a bid.
      </p>
      <ErrorMessage item={errors} />
      <SuccessMessage message={message} />
      <div className="d-flex justify-content-center">
        {getUser ? <EditProfileForm user={getUser} updateProfile={onSubmit} /> : <Loading />}
      </div>
    </Container>
  );
};

export default EditProfile;
