/** @jsx jsx */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { css, jsx } from '@emotion/core';

import { WatchlistSidebar, Header, Footer, FooterBanner } from 'components';
import { useTimerFetch } from 'hooks/async';
import category from 'reducers/cateogry';

export const WatchlistLayout: React.FC = ({ children }) => {
  //3600000ms = 60 minutes
  const [{ data }] = useTimerFetch('/category', category, 3600000);
  return (
    <React.Fragment>
      <Header categoryListData={data} />
      <Container css={style} fluid={true}>
        <Row>
          <Col lg={2}>
            <WatchlistSidebar />
          </Col>
          <Col lg={10}>{children}</Col>
        </Row>
        {/* <FooterBanner /> */}
      </Container>
      <Footer />
    </React.Fragment>
  );
};

const style = css`
  padding-top: 1rem;
  min-height: 75vh;
`;
