/** @jsx jsx */

import React, { useState, useEffect, ReactNode } from 'react';
import { Swipeable } from 'react-swipeable';
import { jsx } from '@emotion/core';

import AuctionSliderContainer from './AuctionSliderContainer';
import ImageContainer from './ImageContainer';
import Wrapper from './Wrapper';
import { LeftArrow, RightArrow, ButtonContainer } from './Buttons';
import { multipleChildren, getChildLength, generateChildren, setPosition } from 'utils/auctionSliderUtils';

type Props = {
  autoSlide?: boolean;
  duration?: string;
};

type State = {
  position: number;
  transitioning: boolean;
  order: number;
  direction: string;
  hideArrows?: boolean;
};

const initialState: State = {
  position: 0,
  transitioning: false,
  order: 0,
  direction: '',
  hideArrows: false
};

//TODO: Write types for this file

const AuctionSlider: React.FC<Props> = ({ autoSlide, duration, children }) => {
  const [sliderState, setSliderState] = useState(initialState);

  const getOrder = (itemIndex: number) => {
    const { position } = sliderState;
    const numItems = multipleChildren(children) ? getChildLength(children) : 1;

    return (numItems + 1 - position + itemIndex) % numItems;
  };

  const prevSlide = () => {
    const numItems = multipleChildren(children) ? getChildLength(children) : 1;
    const { position } = sliderState;
    const updatePosition = setPosition(children, numItems - 1);

    doSliding('prev', position === 0 ? updatePosition : position - 1);
  };

  const nextSlide = () => {
    const numItems = multipleChildren(children) ? getChildLength(children) : 1;
    const { position } = sliderState;
    const updatePosition = setPosition(children, 0);

    doSliding('next', position === numItems - 1 ? updatePosition : position + 1);
  };

  const doSliding = (direction: string, position: number) => {
    setSliderState(prevState => ({
      ...prevState,
      transitioning: true,
      position,
      direction
    }));
  };

  useEffect(() => {
    if (!multipleChildren(children)) {
      setSliderState(prevState => ({
        ...prevState,
        hideArrows: true
      }));
    }

    if (autoSlide && multipleChildren(children)) {
      setSliderState(prevState => ({
        ...prevState,
        hideArrows: true
      }));

      const nextSlideTimer = setTimeout(() => {
        prevSlide();
      }, 5000);

      return () => {
        clearTimeout(nextSlideTimer);
      };
    }
  }, [sliderState.position]);

  useEffect(() => {
    const transitionTimer = setTimeout(() => {
      setSliderState(prevState => ({
        ...prevState,
        transitioning: false
      }));
    }, 100);

    return () => {
      clearTimeout(transitionTimer);
    };
  }, [sliderState.position]);

  const { hideArrows } = sliderState;

  return (
    <Swipeable onSwipedLeft={nextSlide} onSwipedRight={prevSlide} style={{ width: '100%' }}>
      <Wrapper>
        <AuctionSliderContainer
          transitioning={sliderState.transitioning}
          direction={sliderState.direction}
          duration={duration}
        >
          {multipleChildren(children)
            ? generateChildren(children).map((item: ReactNode, index: number) => (
                <ImageContainer key={index} order={getOrder(index)}>
                  {item}
                </ImageContainer>
              ))
            : children}
        </AuctionSliderContainer>
        <ButtonContainer hideArrows={autoSlide ? true : hideArrows}>
          <LeftArrow onLeftArrowClick={prevSlide} />
          <RightArrow onRightArrowClick={nextSlide} />
        </ButtonContainer>
      </Wrapper>
    </Swipeable>
  );
};

export default AuctionSlider;
