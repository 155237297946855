/** @jsx jsx */

import React, { useContext } from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Layout, Input, BlockButton, ErrorMessage } from 'components';
import { usePost } from 'hooks/async';
import { AppContext } from 'context/AppProvider';
import { reducer } from 'reducers/asyncGlobal';
import styles from 'styles/ForgotPassword';
import useRedirect from 'hooks/redirect';

const validation = Yup.object().shape({
  email: Yup.string().required('Email Address is required')
});

const ForgotPassword: React.FC = () => {
  document.title = 'Police Auctions - Forgot Password';
  const [{ data, errors, isLoading }, setSubmit] = usePost('/password/recover', reducer);
  const { dispatch } = useContext(AppContext);

  const onSubmit = ({ email }: { email: string }) => {
    setSubmit({ email });
  };

  useRedirect(dispatch, data, '/forgot/sent');

  return (
    <Layout css={styles}>
      <h2>Forgot Your Password?</h2>
      <p>Enter your email address to recover your account.</p>
      <ErrorMessage item={errors} />
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validation}
        onSubmit={onSubmit}
        render={({ handleChange, errors, touched }) => (
          <Form>
            <Input
              label="Email Address"
              placeholder="Your Email"
              name="email"
              onChange={handleChange}
              isError={touched.email && errors.email}
              errorMessage={errors.email}
              invalid={errors.email ? true : false}
            />
            <BlockButton value="Submit" type="submit" disabled={ isLoading }>
              Submit
            </BlockButton>
          </Form>
        )}
      />
      <p>
        Don't have an account? <Link to="/register">Register an Account for FREE!</Link>
      </p>
      <p>
        Already have an account? &nbsp;
        <Link to="/login">Login to your Account!</Link>
      </p>
    </Layout>
  );
};

export default ForgotPassword;
