import { pipe } from 'lodash/fp';

const generateImgUrl = product => {
  const {
    id,
    control: {
      product_description: { name }
    }
  } = product;
  return `${name.toLowerCase().replace(/\s/g, '-')}-${id}`;
};

const toUrl = pipe(
    generateImgUrl,
    encodeURIComponent
);

export default toUrl;