/** @jsx jsx */

import { NavItem } from 'reactstrap';
import { css, jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';

type Props = {
  title: string;
  path: string;
  iconClassName?: string;
};

const SettingNavItem: React.FC<Props> = ({ title, path, iconClassName }) => (
  <NavItem css={style}>
    <NavLink className="nav-link settings-link" to={path} activeClassName="active" exact>
      <i className={ iconClassName } />
      &nbsp;&nbsp; {title}
    </NavLink>
  </NavItem>
);

export default SettingNavItem;

const style = ({ colors }) => css`
  .nav-link.active {
    background-color: ${colors.blue};
    border-radius: 0.4rem;
  }

  .settings-link {
    color: ${colors.blue};
    padding: 1rem 1.5rem;
  }
`;
