/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { Container } from 'reactstrap';

import { useFetch } from 'hooks/async';
import reducer from 'reducers/govauction';
import style from 'styles/ExternalAuctions';
import { Loading, ResultList, SearchForm } from 'components';

const CarAuctions: React.FC = () => {
  document.title = 'Police Auctions - Car Auctions';
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({
      zip : "",
      type : ""
  })

  const [{ data, isLoading }, fetch, reset] = useFetch('/external-auction', reducer, {
    ...filters,
    page,
    car: true,
  });

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    reset();
    setPage(1);
    fetch(true);
  }

  const pageClick = (page: number) => {
    reset();
    setPage(page);
    fetch(true);
  }

  const handleChange = ({ target: { value, name }}) => {
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  }

  const loading = isLoading
    ? <Loading />
    : null;

  const empty = (!isLoading && data && data.total === 0)
    ? <p>No records to show</p>
    : null;

  const results = (data && data.data)
    ? <ResultList
        data={ data }
        isLoading={ isLoading }
        pageClick={ pageClick }
        isAutomotive={ true }
      />
    : null;

  return (
    <div css={style}>
      <div className="header header-automotive">
        <div className="overlay">
          <div className="container">
            <h1 className="title">Car Auction Search</h1>
            <h2 className="subtitle">333 Active Listings</h2>
            <SearchForm
              onSubmit={ onSubmit }
              handleZipChange={ handleChange }
              handleTypeChange={ handleChange }
              disabled={ isLoading }
              isAutomotive={ true }
            />
          </div>
        </div>
      </div>

      <Container fluid={ true }>
        { loading }
        { empty }
        { results }
      </Container>
    </div>
  );
};

export default CarAuctions;