/** @jsx jsx */

import { useState } from 'react';
import { jsx } from '@emotion/core';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { get } from 'lodash';

import style from 'styles/DailyDealsListing';
import { CardDailyDealsList, AlertMessage, Paginator, Loading } from 'components';
import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { FilterType } from 'types/pages/Daily_Deals_Listing/dailyDealsListing';

const DailyDealsListing = () => {

  const [filters, setFilters] = useState<FilterType>({ page: 1, category_id: "" })

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [{ data }] = useFetch('/dailydeals/categories', reducer);

  const [{ data: response, isLoading }, fetch, reset] = useFetch('/dailydeals', reducer, {
    ...filters,
    pageSize: 16
  });

  const pageClick = (page: number) => {
    reset();
    setFilters({
      ...filters,
      page: page
    });
    fetch(true);
  }

  const categoryClick = (category) => {
    reset();
    setFilters({
      ...filters,
      category_id: category
    });
    fetch(true);
  }

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const alert = isLoading
    ? <Loading />
    : <AlertMessage color="warning" show={true}>There are no new daily deals at this time. Please check back soon.</AlertMessage>;

  return (
    <div css={style}>
      <div className="jumbotron">
        <div className="container">
          <h1><i className="fas fa-bullhorn"></i>Daily Deals</h1>
          <p className="subtitle">Offer your price on any of these Daily Deals, and get an instant response...and big savings!</p>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>
              Choose A Category
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => categoryClick("")}>
                All
              </DropdownItem>
              {data && data.length > 0
                ? data.map(category => (
                    <DropdownItem onClick={() => categoryClick(category.id)} key={`category-${category.id}`}>
                      {`${category.name} (${category.numItems})`}
                    </DropdownItem>
                  ))
                : null}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      {response && response.data.length > 0
        ? <CardDailyDealsList data={get(response, 'data')} />
        : alert }

      <div className="paginator-container">
        {response && response.data.length > 0 ? <Paginator isLoading={isLoading} pages={response} onClick={pageClick} /> : null}
      </div>
    </div>
  );
};

export default DailyDealsListing;
