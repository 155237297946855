import React from 'react';

const PaymentInformation = () => (
  <React.Fragment>
    <img src="https://cdn.policeauctions.com/images/paypal.gif" alt="Paypal Payment" />
    <img src="https://cdn.policeauctions.com/images/credit-card.gif" alt="Support Payment Methods" />
    <br />
    <p>Payment is due at the end of the auction!</p>
    <br />
    <p>Pay by PayPal or Credit Card for US and International Customers.</p>
    <br />
    <p>Cashier checks and money orders also accepted for US Customers only. No items ship until checks are cleared.</p>
    <br />
    <p>Items not paid for within 48 hours of auction end shall be deemed forfeit.</p>
    <br />
    <p>
      Please note that items paid by credit card can only be shipped to the credit card billing address or an address
      that is verified by the credit card issuer. If you need to add a verified address to your credit card, use the 800
      number on the back of your credit card to make the addition.
    </p>
  </React.Fragment>
);

export default PaymentInformation;
