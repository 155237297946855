import React from 'react';

const GenericLink: React.FC<{ url: string }> = ({ url }) => {
  return (
    <a
      className="btn btn-primary btn-lg"
      target="_blank"
      href={ url }
      rel="noopener noreferrer"
    >
      Click Here
    </a>
  );
};

export default GenericLink;