import React, { createContext, useReducer, useContext, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { AppContext } from './AppProvider';
import { reducer } from 'reducers/socketAsyncGlobal';
import { BID_SERVER_URL } from 'constants/config';
import socketEvents from 'sockets/events';
import { SocketActions } from 'types/actions';
import { Loading } from 'components';

/**
 * TODO: Place these types inside the type directory
 */
type BidList = Array<{ id: number; amount: number; username: string }>;

export type SocketData = {
  streamData: {
    bids: null | BidList;
    id: null | string;
    ended: {
      schedule_id: null | number;
      status: null | string;
      user_id: null | number;
    };
    userStream: {
      myItems: null | string;
      notifications: null | string;
      user_id: null | string;
    };
  };
  streamError: boolean;
};

type Value = {
  state: SocketData;
  socket: any;
  dispatch: React.Dispatch<SocketActions>; // AnyAction is temporary for now
};

export const initialState = {
  streamData: {
    bids: null,
    id: null,
    ended: {
      schedule_id: null,
      status: null,
      user_id: null
    },
    userStream: {
      myItems: null,
      notifications: null,
      user_id: null
    }
  },
  streamError: false
};

export const SocketContext = createContext({} as Value);

const SocketProvider: React.FC = ({ children }) => {
  const socket = useRef(io(BID_SERVER_URL, {
    autoConnect: false,
    reconnectionAttempts: 10
  }));
  const {
    state: { user },
  } = useContext(AppContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const value: Value = { socket: socket.current, state, dispatch };

  useEffect(() => {
    if(socket.current.disconnected) {
      socket.current.open();
      socketEvents(socket.current, dispatch, user);
    }
  }, []);

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
