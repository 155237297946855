//@ts-nocheck
/** @jsx jsx */

import React, { useEffect, useContext, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { css, jsx } from '@emotion/core';
import { get } from 'lodash/fp';

import { BREAKPOINTS as BP } from 'constants/emotionConfig';
import { Header, Loading } from 'components';
import AuctionSummary from 'components/payment/AuctionSummary';
import { AppContext } from 'context/AppProvider';
import request from 'utils/request';
import history from 'utils/history';
import { UPDATE_PAYMENT, USER_RESET } from 'constants/appContext';
import { useTimerFetch } from 'hooks/async';
import category from 'reducers/cateogry';

export const PaymentPage: React.FC = ({ children }) => {
  const path = window.location.pathname.split('/');
  const auctionId = path[path.length - 1];
  const page = path[path.length - 2] === 'paypal' ? path[path.length - 3] : path[path.length - 2];

  const [data, setData] = useState({ data: {}, tax: 0.0, isLoading: true });
  const { state, dispatch } = useContext(AppContext);
  const [{ data: categoryListData }] = useTimerFetch('/category', category, 3600000); //3600000ms = 60 minutes

  useEffect(() => {
    request.getPayment('/summary', { auctionId, currency: state.currencyFlag.id }).then(
      ({ data: responseData }) => {
        setData({ ...data, data: responseData, isLoading: false });
        dispatch({ type: UPDATE_PAYMENT, payload: { auction: responseData.summary.auction }});
      },
      () => {
        // If there was an error i.e. something timed out... redirect them back to my-wins
        history.push(`/login`);

        //Reset the user header back to guest header when redirected to the login page
        dispatch({ type: USER_RESET });
      }
    );
  }, [state.currencyFlag]);

  useEffect(() => {
    setData({ ...data, tax: state.payment.tax });
  }, [state.payment.tax]);

  const category_id = get('summary.auction.control.product_description.category_id')(data.data);
  const product_id = get('summary.auction.control.product_description_id')(data.data);
  return (
    <React.Fragment>
      <Header categoryListData={categoryListData} />
      <Container css={styles} fluid={true}>
        {data.isLoading ? (
          <Loading />
        ) : (
          <div className="page-body">
            <h3>Choose Method of Payment</h3>
            <hr />
            <Row>
              { page !== 'receipt' ?
              <Col lg={4} md={6} sm={6}>
                <AuctionSummary
                  id={get('summary.auction.id')(data.data)}
                  productImage={`https://images.serrf.com/${category_id}/7/${product_id}.jpg`}
                  title={get('summary.auction.control.product_description.short_name')(data.data)}
                  amount={parseFloat(get('summary.auction.sale_price')(data.data))}
                  shipping={parseFloat(get('summary.shipping_cost')(data.data))}
                  tax={data.tax}
                  currencyEstimate={get('summary.currency_estimate')(data.data)}
                />
              </Col> : null
              }
              {children}
            </Row>
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

const styles = css`
  padding-top: 2rem;

  .page-body {
    padding: 0 1rem;
  }

  @media (max-width: ${BP.MD}) {
    padding: 2rem 2rem;
    width: 100%;
  }
  @media screen and (max-width: 780px) and (min-width: 576px){
    .col-sm-6 {
      min-width: 100%
    }
  }
  @media screen and (max-width: 1160px) and (min-width: 992px){
    .col-sm-6 {
      min-width: 50%
    }
  }
  .braintree {
    margin-top: 25px;
  }
`;
