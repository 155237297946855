/** @jsx jsx */

import { useEffect } from 'react';
import Badge from 'reactstrap/lib/Badge';

import { css, jsx } from '@emotion/core';

const DeleteInstructions = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div css={style}>
      <h1>You can request your account deletion by following these steps:</h1>

      <p>1. Click the account dropdown.</p>
      <br />
      <p>
        2. Choose the <strong>"Support"</strong> option.
      </p>
      <br />
      <p>
        3. Click the <strong>"Contact Support"</strong> button.
      </p>
      <br />
      <p>
        4. Under <strong>"Choose A Topic"</strong> select the option <strong>"Account"</strong>
      </p>
      <br />
      <p>
        5. Tell us that you would like to request your account be <span style={{ color: 'red' }}>deleted</span>.
      </p>
      <br />
      <p className='p-danger-text'>
        <Badge color="danger" className='delete-badge'>
          *IMPORTANT: This is not reversible, we cannot bring back your account data. You will not be able to see your
          wins, purchase history, etc... If you feel this isn't what you want, you may just chooose to cancel your
          account.
        </Badge>
      </p>
      <br />
      <h2>To Cancel your account:</h2>
      <br />
      <p>
        Click on the account dropdown and select the option <strong>"Cancel Account"</strong>.
      </p>
      <br />
      <p>This will give your the option to come back using the same account and retain your purchase history.</p>
    </div>
  );
};

export default DeleteInstructions;

const style = css`
  width: 100%;

  .delete-badge {
    white-space: normal;
    line-height: 1.3;
  }
`;
