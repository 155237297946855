import { css } from '@emotion/core';
import { BREAKPOINTS as BP, colors } from 'constants/emotionConfig';

export default css`
  .card-li {
    padding: 0;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .h1-title {
      font-size: 2rem;
    }
  }

  .testimonial {
    margin-bottom: 3.5rem 0;
  }

  .border-line {
    margin: 2rem 0;
  }

  .paginator-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aws-sld__wrapper {
    max-height: 25rem;
  }

  button:focus {
    outline: none;
  }

  .aws-sld__content > img, .aws-sld__content > video {
    object-fit: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .aws-sld__controls button {
    outline: none;
  }

  .aws-sld__content {
    background-color: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .aws-sld {
    --loader-bar-color: ${colors.blue};
  }

  img {
    max-width: 100%;
  }

  @media (max-width: ${BP.MD}) {
    max-width: none;

    .image-container {
      height: 70rem;
    }

    .aws-sld__wrapper {
      max-height: 15rem;
    }

    .testimonial {
      margin-top: 0;
      margin-bottom: 3.5rem;
    }
  }

  .join-now {
    text-align: center;

    h1 {
      font-size: 1.8rem;
    }

    button {
      margin-bottom: 1.2rem;
    }
  }
`;
