//@ts-nocheck
import { useReducer, useEffect, useState } from 'react';

import request from 'utils/request';
import { REQUEST, SUCCESS, FAILURE, RESET } from 'constants/async';
import { AsyncHook } from 'types/hooks';

export const usePost: AsyncHook = (url, reducer) => {
  const [body, setBody] = useState();
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    data: null,
    errors: null
  });

  const reset = () => {
    dispatch({ type: RESET });
  };

  const asyncRequest = async () => {
    dispatch({ type: REQUEST });
    try {
      const { data } = await request.post(url, body);
      dispatch({ type: SUCCESS, payload: data });
    } catch (error) {
      const { errors, message } = error.response.data;
      dispatch({ type: FAILURE, payload: { errors, message } });
    }
  };

  useEffect(() => {
    if (!body) {
      return;
    }

    asyncRequest();

    return () => {
      reset();
    };
  }, [body]);

  //TODO: Add a type to the input param
  return [state, input => setBody(input), () => reset()];
};

const useGet: AsyncHook = (url, reducer, preload, requestGetter, options?) => {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    data: null,
    errors: null
  });

  const [isReload, setIsReload] = useState<boolean>(false);

  const reset = () => {
    dispatch({ type: RESET });
  };

  const asyncRequest = async () => {
    dispatch({ type: REQUEST });
    try {
      const { data } = await request[requestGetter](url, options);
      dispatch({ type: SUCCESS, payload: data });
    } catch (error) {
      const { errors, message } = error.response.data;
      dispatch({ type: FAILURE, payload: { errors, message } });
    }
  };

  useEffect(() => {
    if(url && preload) {
      asyncRequest();
    }

    return () => {
      reset();
    };
  }, [url]);

  useEffect(() => {
    if (isReload) {
      asyncRequest();
      setIsReload(false);
    }
  }, [isReload]);

  return [state, (param: boolean) => setIsReload(param), () => reset(), dispatch];
};

const useTimerGet: AsyncHook = (url, reducer, timer?, options?) => {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    data: null,
    errors: null
  });

  const reset = () => {
    dispatch({ type: RESET });
  };

  const asyncRequest = async () => {
    dispatch({ type: REQUEST });
    try {
      const { data } = await request.get(url, options);
      dispatch({ type: SUCCESS, payload: data });
    } catch (error) {
      if(error.response) {
        const { errors, message } = error.response.data;
        dispatch({ type: FAILURE, payload: { errors, message } });
      }
    }
  };

  useEffect(() => {

    if(url) {
      asyncRequest();
    }

    const refetchEvery = setInterval(() => {
      asyncRequest();
    }, timer);

    return () => {
      clearInterval(refetchEvery);
      reset();
    };
  }, [url]);

  return [state, () => reset(), dispatch];
};

// API Hooks
export const useFetch = (url, reducer, options?) => useGet(url, reducer, true, 'get', options);
export const useTimerFetch = (url, reducer, timer, options?) => useTimerGet(url, reducer, timer, options);
export const useControlFetch = (url, reducer, options?) => useGet(url, reducer, false, 'get', options);

// Payment Hooks
export const usePaymentFetch = (url, reducer, options?) => useGet(url, reducer, true, 'getPayment', options);
export const useControlPaymentFetch = (url, reducer, options?) => useGet(url, reducer, false, 'getPayment', options);
