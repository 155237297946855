import React from 'react';

import useTimer from 'hooks/timer';
import ShowTimer from './ShowTimer';

type Props = {
  serverTime: number;
  endTime: any;
};

const Timer: React.FC<Props> = ({ serverTime, endTime }) => {
  const [timer] = useTimer(serverTime, endTime);

  return endTime ? (
    <div className="timer-container">
      <ShowTimer timer={timer} />
    </div>
  ) : null;
};

export default Timer;
