import { css } from '@emotion/core';

const styles = css`
  .paginator-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default styles;