/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { Redirect } from 'react-router-dom';
import { NoticeRowProps } from 'types/pages/Notices/notices';
import moment from 'moment';

const NoticeRow: React.FC<NoticeRowProps> = ({ wasRead, notice: { id, title, expire_time }}) => {

  const [clicked, setClicked] = useState<boolean>(false);

  const navigate = clicked ? <Redirect to={`/notices/${id}`} /> : null;

  const readIcon = wasRead ? 'fa-check' : '';

  const onClick = () => {
    setClicked(true);
  };

  return (
    <li className="notice-row" onClick={() => onClick()}>
      { navigate }
      <span className="notification">
        <span className="check notifiyListener">
          <i className={`fa ${readIcon} fa-lg`}></i>
        </span>
        <p className="user">
          <span>By: PoliceAuctions.com</span>
          <span className="time-stamp">
              Expires: <span className="time"> { moment(expire_time).format("LLLL") }</span>
          </span>
        </p>
        <p className="content">{ title }</p>
      </span>
    </li>
  );
};

export default NoticeRow;