import { css } from '@emotion/core';

export default css`
  .header {
    font-size: 2.2rem;
    padding-top: 1rem;
  }

  .card {
    margin-bottom: .5rem;
  }

  .section {
    padding-bottom: 2rem;
  }

  .list-link {
    color: black;
    font-size: 1.5rem;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
