/** @jsx jsx */

import React from 'react';
import { Row } from 'reactstrap';
import { jsx } from '@emotion/core';

import LeftDetails from './LeftDetails';
import MiddleDetails from './MiddleDetails';
import RightDetails from './RightDetails';
import FooterDetails from './FooterDetails';
import { ItemProps } from 'types/pages/My_Wins/myWins';
import { style } from 'styles/MyWins';

const ItemDetails: React.FC<ItemProps> = ({ item }) => (
  <div css={style}>
    <Row>
      <LeftDetails item={item} />
      <MiddleDetails item={item} />
      <RightDetails item={item} />
    </Row>
    <Row className="footer-row">
      <FooterDetails item={item} />
    </Row>
  </div>
);

export default ItemDetails;
