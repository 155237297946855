import React from 'react';
import { Container } from 'reactstrap';
import { Formik, Form, FormikActions } from 'formik';
import * as Yup from 'yup';

import { Input, SaveButton, CancelButton, SuccessMessage } from 'components';
import { ChangePasswordFields } from 'types/pages/Change_Password/changePassword';
import { usePost } from 'hooks/async';
import reducer from 'reducers/changePassword';

const validation = Yup.object().shape({
  password: Yup.string().required('please enter a password'),
  repeat_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'password does not match')
    .required('please enter a password')
});

const ChangePassword: React.FC = () => {
  document.title = 'Police Auctions - Change Password';
  const [{ data }, setChangePassword] = usePost('/user/password', reducer);

  const onSubmit = (values: ChangePasswordFields, actions: FormikActions<ChangePasswordFields>) => {
    setChangePassword({ ...values });
    actions.resetForm();
  };

  return (
    <Container>
      <h2>Change Password</h2>
      <p>Use this page to change the password to your account</p>
      <SuccessMessage message={data ? data.message : null} />
      <Formik
        initialValues={{ password: '', repeat_password: '' }}
        onSubmit={onSubmit}
        validationSchema={validation}
        validateOnChange={false}
        render={({ handleChange, touched, errors, handleReset, values }) => (
          <Form>
            <Input
              label="New Password"
              name="password"
              type="password"
              onChange={handleChange}
              isError={touched.password && errors.password}
              errorMessage={errors.password}
              invalid={errors.password ? true : false}
              value={values.password}
            />
            <Input
              label="New Password Confirm"
              name="repeat_password"
              type="password"
              onChange={handleChange}
              isError={touched.repeat_password && errors.repeat_password}
              errorMessage={errors.repeat_password}
              invalid={errors.repeat_password ? true : false}
              value={values.repeat_password}
            />
            <SaveButton type="submit">Save Changes</SaveButton>
            <CancelButton onClick={handleReset}>Cancel</CancelButton>
          </Form>
        )}
      />
    </Container>
  );
};

export default ChangePassword;
