/** @jsx jsx */

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { css, jsx } from '@emotion/core';
import { withTheme } from 'emotion-theming';

import { Input, LoadingButton } from 'components';
import { BlockButton } from 'components';

const validate = Yup.object().shape({
  email: Yup.string().required('Email or Username is required'),
  password: Yup.string().required('Password is required')
});

export const LoginForm = withTheme(({ onSubmit, onErrors, isLoading }) => {
  const LoadButton = <LoadingButton>Please wait...</LoadingButton>;
  const SubmitButton = (
    <BlockButton type="submit" value="Login with Facebook" color="green">
      Login
    </BlockButton>
  );
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validate}
      onSubmit={onSubmit}
      validateOnChange={false}
      render={({ errors, touched, handleChange }) => (
        <Form css={style} onClick={onErrors(errors)}>
          <Input
            label="Email Address or Username"
            placeholder="Your Email"
            isError={touched.email && errors.email}
            errorMessage={errors.email}
            invalid={errors.email ? true : false}
            name="email"
            onChange={handleChange}
          />
          <Input
            label="Password"
            type="password"
            placeholder="Password"
            isError={touched.password && errors.password}
            errorMessage={errors.password}
            invalid={errors.password ? true : false}
            name="password"
            onChange={handleChange}
          />
          { isLoading ? LoadButton : SubmitButton }
        </Form>
      )}
    />
  )
});

const style = css`
  .form-wrapper {
    margin-bottom: 1.2rem;
  }
`;
