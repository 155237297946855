//@ts-nocheck

import React, { useState } from 'react';
import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';

import { Loading, Paginator } from 'components';


/**
 * Method to handle basic fetching on page load, and handing down the data
 * to the props of the WrappedComponent and giving the default loading
 * if isLoading is true.
 *
 * @param url
 * @param reducer
 * @param title
 */
export const withPagination = (url: string, [title, icon]: FetchOptions) => (WrappedComponent: React.FC<any>) => {
  document.title = `Police Auctions - ${title}`;
  return () => {
    const [page, setPage] = useState(1)
    const [{ data, isLoading }, fetch, reset] = useFetch(url, reducer, { page });
    const onPageClick = (page: number) => {
      setPage(page);
      fetch(true);
    };
    return (
      <React.Fragment>
        <h2>
          <i className={icon} /> {title}
        </h2>
        <hr />
        { isLoading ? <Loading /> : <WrappedComponent { ...data} fetch={fetch} reset={reset} /> }
        <div className="paginator-container">
          {data ? <Paginator isLoading={isLoading} pages={data} onClick={onPageClick} /> : null}
        </div>
      </React.Fragment>
    );
  };
};