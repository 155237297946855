import React from "react";

import { Tooltip } from 'components';

const ExpectedDeliveryTooltip = () => (
  <Tooltip text="Estimation is based off delivery history and is only an estimated delivery window. Items can be held in customs for varying amounts of time.">
    <i className="fas fa-question-circle"></i>
  </Tooltip>
);

export default ExpectedDeliveryTooltip;