/** @jsx jsx */

import { useContext } from 'react';
import { jsx } from '@emotion/core';

import { withPagination } from 'utils/withPagination';
import { AuctionActiveAlert, CardAuctionList } from 'components';
import useBidStream from 'hooks/bidStream';
import useTimeStamp from 'hooks/timeStamp';
import { AppContext } from 'context/AppProvider';

const WachlistLosing: React.FC<any> = ({ data }) => {
  document.title = 'Police Auctions - Watchlist Losing';

  const {
    state: { user }
  } = useContext(AppContext);
  const bidStream = useBidStream(data, user);
  const [timeStamp] = useTimeStamp();

  return (
    <div>
      <br />
      <AuctionActiveAlert isActive={data && !data.length}>
        <p>You are not losing on any items at the moment. Good job!</p>
      </AuctionActiveAlert>
      <CardAuctionList
        data={bidStream}
        serverTime={timeStamp}
        enableStatusAlert={true}
        userId={user.id}
      />
    </div>
  );
};

export default withPagination('/watchlist/losing', ["Losing Auctions", 'far fa-thumbs-down'])(WachlistLosing);
