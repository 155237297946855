import React from 'react';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import { DropdownItems, CategoryType } from './DropdownItems';

/**
 * This is going to be a temporary FSC
 */

type Props = {
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  categoryListData: CategoryType[];
}

export const DropdownHotNavItems: React.FC<Props> = ({ onClick, categoryListData }) => {
  return (
    <DropdownMenu className="dropdownmenu-wrapper" right={true}>
      <Link to="/" className="dropdown-link">
        <DropdownItem className="dropdownmenu-item" onClick={onClick}>
          All Auctions{' '}
        </DropdownItem>
      </Link>
      <Link
        className="dropdown-link"
        to={{ pathname: '/auction/featured', state: { name: 'Featured', params: { featured: 1 } } }}
      >
        <DropdownItem className="dropdownmenu-item" onClick={onClick}>
          Featured <i className="fa fa-fire hot-item-i" />
        </DropdownItem>
      </Link>
      {categoryListData ? <DropdownItems onClick={onClick} items={categoryListData} /> : null}
    </DropdownMenu>
  );
};
