/** @jsx jsx */

import { ReactNode } from 'react';
import { jsx, css } from '@emotion/core';

type Props = {
  hideArrows?: boolean;
  children: ReactNode;
};

type ArrowClickEvent = (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;

type ArrowProps = {
  onLeftArrowClick: ArrowClickEvent;
  onRightArrowClick: ArrowClickEvent;
};

export const LeftArrow = ({ onLeftArrowClick, ...rest }: Pick<ArrowProps, 'onLeftArrowClick'>) => (
  <i className="arrow fas fa-arrow-circle-left" onClick={onLeftArrowClick} {...rest} />
);

export const RightArrow = ({ onRightArrowClick, ...rest }: Pick<ArrowProps, 'onRightArrowClick'>) => (
  <i
    className="arrow fas fa-arrow-circle-right"
    onClick={onRightArrowClick}
    {...rest}
  />
);

export const ButtonContainer = ({ hideArrows, children }: Props) =>
  !hideArrows ? <div css={styles}>{children}</div> : null;

const styles = css`
  height: 100%;
  .arrow {
    position: absolute;
    top: 50%;
    font-size: 30px;
    color: #545454;
    cursor: pointer;
  }
  .fa-arrow-circle-left {
    left: 3rem;
  }
  .fa-arrow-circle-right {
    right: 3rem;
  }
`;
