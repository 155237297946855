// TODO: Do not commit changes to this file that will switch keys to dev.
// Always make sure you revert the API endpoint back to the live API.


// Production endpoints
export const API_URL = 'https://api.policeauctions.com/v1';
export const PAYMENT_API_URL = 'https://payments.policeauctions.com/v1';
// export const BID_SERVER_URL = 'https://bids.serrf.com:8444/';
export const RECAPTCHA_KEY = '6LeqjKkUAAAAAEa7408UGxki1lOa9-ZGngnkCxS2';
export const FB_APP_ID = '1660695564211032';
export const PAYPAL_ENVIRONMENT = 'production';
export const PAYPAL_KEY = 'AWRqQIAFzNNtfkwlyq4EVD0yRiDuMjPicFdauA905mwzKPQ3HYjy2yPMiCekIZXIjQmWyO6gP4FyjuQ1';
export const PIXEL_ID = 875570925835886;

// Local endpoints (testing)
// export const API_URL = 'http://localhost/v1';
// export const BID_SERVER_URL = 'http://127.0.0.1:8444';
// export const PAYMENT_API_URL = 'http://localhost:443/v1';
// export const RECAPTCHA_KEY = '6Lc32MsUAAAAAPfIgXGvpOETzsUdGCpaSqO6EzWq';
// export const FB_APP_ID = '167204583739515';
// export const PAYPAL_ENVIRONMENT = 'sandbox';
// export const PAYPAL_KEY = 'ASVjnHxeRuowjMucWBn7J-rBbe-rvA79giP-ID_VOy1bdjzQIsNf-0jh9hwNk9BGgO3OfB1AkmJyS28X';
// export const PAYMENT_API_URL = 'http://0.0.0.0:8000/v1';

// Staging endpoints
// export const API_URL = 'https://stagingapi.policeauctions.com/v1';
// export const PAYMENT_API_URL = 'https://stagingpayments.policeauctions.com/v1';
// export const BID_SERVER_URL = 'http://34.208.219.187:8444';
export const BID_SERVER_URL = 'https://staging-bids.serrf.com:8444';

// export const BID_SERVER_URL = 'http://127.0.0.1:8081'; //For shelby's personal bidServer working branch
