/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';

import { StatementProps } from 'types/pages/Testimonials/Testimonials';

const Statement: React.FC<StatementProps> = ({ statement: { testimonial, real_name, create_time } }) => (
  <div className="statement">
    <p className="testimonial">{ testimonial }</p>
    <p>- by <strong>{ real_name }</strong> on { moment(create_time).format("LLLL") }</p>
    <hr/>
  </div>
);

export default Statement;