import { css } from '@emotion/core';

export default css`
  .form-border {
    margin: 2rem 0;
  }

  .user-a {
    color: #337ab7;
  }

  .form-wrapper {
    margin-bottom: 1.2rem;
  }
  
  @media screen and (max-width: 768px) {
    .login-wrapper {
      padding: 0 2rem;
    }
  }
`;
