/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/core';
import { ListGroupItem } from 'reactstrap';
import { Props } from 'types/Alerts/row';

const AlertRow: React.FC<Props> = (props) => {

  const { notification, markRead } = props;
  return (
    <ListGroupItem
      css={ style }
      onClick={() => markRead(notification)} >
        <div>
          <i className="fa fa-star fa-star-icon" />
          <span>{ notification.human_time }</span>
          <button
            onClick={ () => markRead(notification) }
            className="btn btn-xs btn-info float-right" >
              View Item
          </button>
        </div>
        <p>{notification.description}</p>
    </ListGroupItem>
  );
};

export default AlertRow;

const style = css`
  .fa-star-icon {
    color: #5bc0de;
    padding-right: 10px;
    font-size: 15px;
  }
`;