import React from 'react';

import { WatchlistToggleButton } from 'components';

type Props = {
  userLoggedIn: boolean;
  id: number;
  isWatching: boolean;
};

const WatchListButton: React.FC<Props> = ({ userLoggedIn, isWatching, id }) =>
  userLoggedIn ? <WatchlistToggleButton item_id={id} watchlist_type_id={9} isWatching={isWatching} isLoggedIn={userLoggedIn} /> : null;

export default WatchListButton;
