/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';

import Card from 'components/card/Card';
import SummaryRow from './SummaryRow';

export type SummaryProps = {
  id: number;
  title: string;
  productImage: string;
  amount: number;
  tax: number;
  shipping: number;
  currencyEstimate: string | null;
};

const AuctionSummary = ({ id, title, productImage, amount, shipping, tax = 0.0, currencyEstimate }: SummaryProps) => (
  <Card headerTitle={title} css={styles}>
    <div className='summary-container'>
      <div className="row-fluid text-center product-image-container">
        <img alt="some_img" src={productImage} className="img-responsive center-block preview-image" />
      </div>
      <div className='summary-table-container'>
        <SummaryRow title="Item Number" value={id} />
        <SummaryRow title="Quantity" value={1} />
        <SummaryRow title="Item Total" value={`$${amount.toFixed(2)}`} />
        <SummaryRow title="Tax" value={`$${tax.toFixed(2)}`} predicate={tax > 0.0} />
        <SummaryRow title="Shipping and Handling" value={`$${shipping.toFixed(2)}`} />
        <SummaryRow title="Total" value={`$${(amount + shipping + tax).toFixed(2)}`} />
        <SummaryRow title="Estimated Currency" value={currencyEstimate} predicate={(currencyEstimate !== undefined && currencyEstimate !== null && currencyEstimate.length > 0)} />
      </div>
    </div>
  </Card>
);

export default AuctionSummary;

const styles = css`
  font-weight: 400;
  padding: 1rem;
  .row {
    border-top: 1px solid #ddd;
  }
  .row:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
  .row:last-of-type {
    font-weight: bold;
  }
  .card-body {
    padding-top: 0;
  }
  .item-col * {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .product-image-container {
    display: table;
    min-height: 230px;
    width: 100%;
    vertical-align: middle;
    text-align: center;
  }
  img {
    max-height: 375px;
    max-width: 375px;
    margin: 0 auto;
  }
  @media screen and (max-width: 2080px) {
    img {
      max-height: 300px;
      max-width: 345px;
    }
    .product-image-container {
      min-height: 323px;
    }
  }
  @media screen and (max-width: 2013px) {
    img {
      max-height: 300px;
      max-width: 265px;
    }
    .product-image-container {
      min-height: 250px;
      max-height: 400px;
    }
  }
  @media screen and (max-width: 1646px) {
    img {
      max-height: 260px;
      max-width: 230px;
    }
    .product-image-container {
      min-height: 230px;
      max-height: 400px;
    }
  }
  @media screen and (max-width: 1448px) {
    img {
      max-height: 375px;
      max-width: 225px;
    }
    .product-image-container {
      min-height: 230px;
      max-height: 400px;
    }
  }
  @media screen and (max-width: 1199px) {
    img {
      max-height: 375px;
      max-width: 202px;
    }
    .product-image-container {
      min-height: 200px;
    }
  }
  @media screen and (max-width: 767px) {
    img {
      max-width: 375px;
    }
    .product-image-container {
      min-height: 100%;
    }
  }
`;
