import React from 'react';
import { InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { Formik } from 'formik';

import { Props as AuctionProps } from 'types/pages/Auction_View/partials/auctionBidding';
import BidButton from './BidButton';

type Props = Pick<AuctionProps, 'onBidSubmit' | 'isBidding'>;

//TODO: Refactor these types
const BidForm: React.FC<Props> = ({ onBidSubmit, isBidding }) => (
  <Formik initialValues={{ bid: '' }} onSubmit={onBidSubmit}>
    {({ handleChange, values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-direction-row">
          <InputGroup className="bid-input-container" style={{paddingRight: '2rem'}}>
            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
            <Input
              name="bid"
              type="number"
              step="1"
              className="bid-input"
              min={0}
              value={values.bid}
              onChange={handleChange}
            />
            <InputGroupAddon addonType="append">.00</InputGroupAddon>
          </InputGroup>
          <BidButton isBidding={isBidding} values={values} />
        </div>
      </form>
    )}
  </Formik>
);

export default BidForm;
