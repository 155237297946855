import React from 'react';

import PlanTable from './PlanTable';
import { SubscriptionData } from 'types/pages/Upgrade/upgrade';

export const MonthlyPlanTable = (subscriptionData: SubscriptionData) => {
  
  const planTableData = {
    planType: {
      name: 'Monthly',
      cost: '$7.95 / Per Month',
      buttonAction: null
    },
    checkedItems: [0,1,2,3,4,5,6,7,8,9],
    bgColors: {
      head: "#8da153",
      body: "#e6eada"
    },
    buttonStyles: {
      bgColor: "#66743f",
      borderColor: "#3f491f",
      bgColorOnHover: "#3f491f"
    },
    subscriptionData,
    hasCreditCard: false
  };

  return (
    <PlanTable {...planTableData} />
  );
}
