/** @jsx jsx */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { css, jsx } from '@emotion/core';

import { SettingsNav, Footer, Header } from 'components';
import { BREAKPOINTS as BP } from 'constants/emotionConfig';
import { useTimerFetch } from 'hooks/async';
import category from 'reducers/cateogry';

export const Settings: React.FC = ({ children }) => {
  //3600000ms = 60 minutes
  const [{ data }] = useTimerFetch('/category', category, 3600000);

  return (
    <React.Fragment>
      <Header categoryListData={data} />
      <Container css={style} fluid={true}>
        <Row>
          <Col md={4}>
            <SettingsNav />
          </Col>
          <Col md={8}>{children}</Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

const style = css`
  padding-top: 2rem;
  min-height: 75vh;
  padding-left: 8rem;
  padding-right: 8rem;

  @media (max-width: ${BP.MD}) {
    padding: 2rem 2rem;
    width: 100%;
  }
`;
