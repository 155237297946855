
const filterPrimaryType = (images) => {
    // Returns PRIMARY type if it is available for thumb listing
  if (images.filter(img => img.image_type_id == 5).length > 0) {
    return images.filter(img => img.image_type_id == 5)[0];
  }
  // If there's no PRIMARY image, returns HD_SCALE type
  return images.filter(img => img.image_type_id == 28)[0];
};

export default filterPrimaryType;
