/** @jsx jsx */

import { ReactNode, ChangeEvent } from 'react';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { css, jsx } from '@emotion/core';

type Props = {
  textElement: ReactNode;
  name?: string;
  isError?: string | boolean;
  errorMessage?: string;
  onChange?: (evt: ChangeEvent) => void;
  value?: string | number;
  checked?: boolean;
  disabled?: boolean;
};

const Checkbox: React.FC<Props> = ({
  textElement,
  errorMessage,
  isError,
  ...rest
}) => (
  <FormGroup check={true} css={style}>
    <Label check={true} className="checkbox-label">
      <Input type="checkbox" className="checkbox-input" {...rest} />
      {textElement}
    </Label>
    {isError && <FormFeedback className="error-div">{errorMessage}</FormFeedback>}
  </FormGroup>
);

const style = css`
  font-size: 1.4rem;
  margin: 1rem; 0;
  .checkbox-input {
    padding-right: 1.2rem;
    margin-left: -2rem;
    margin-top: 0.5rem;
  }

  .checkbox-label {
    margin-left: 0.4rem;
  }

  .error-div {
    display: block;
  }
`;

export default Checkbox;