/** @jsx jsx */

import { useContext } from 'react';
import { jsx } from '@emotion/core';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import { Layout, ErrorMessage } from 'components';
import { ImpersonateForm } from './partials/ImpersonateForm';
import { AppContext } from 'context/AppProvider';
import { ImpersonateInput } from 'types/pages/Impersonate/impersonate';
import styles from 'styles/Impersonate';

const Impersonate: React.FC<RouteComponentProps> = (props) => {
  document.title = 'Police Auctions - Impersonate';
  const {
    actions,
    state: { errors, isLoading }
  } = useContext(AppContext);

  const query = queryString.parse(props.location.search);
  const user_id = query.user_id ? query.user_id : '';

  const onFormSubmit = (values: ImpersonateInput) => {
    actions.impersonate({ ...values, user_id });
  };

  const error = errors && errors.message
    ? <ErrorMessage item={ errors } />
    : null;

  return (
    <Layout css={styles}>
      <h2>Login</h2>
      <hr className="form-border" />
      { error }
      <ImpersonateForm
        onSubmit={ onFormSubmit }
        userId={ user_id }
        isLoading={ isLoading } />
    </Layout>
  );
};

export default Impersonate;
