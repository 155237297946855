/** @jsx jsx */

import { Row, Col } from 'reactstrap';
import { jsx } from '@emotion/core';
import { get } from 'lodash';
import parse from 'html-react-parser';
import { filter, isObject } from 'lodash';
import { Helmet } from 'react-helmet';

import { Container } from 'reactstrap';
import styles from 'styles/AuctionView';
import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { CollapsibleCard, Loading, ProductImageSlider, ProductDescription } from 'components';
import PageTitle from './partials/PageTitle';
import OfferInteraction from './partials/OfferInteraction';
import PaymentInfo from './partials/PaymentInfo';
import filterPrimaryType from 'utils/filterImageType';

const DailyDealsView = ({ match }) => {
  document.title = 'Police Auctions - Daily Deal';

  const [{ data }] = useFetch(`/dailydeals/${match.params.id}`, reducer);

  const onlyObjects = products => filter(products, product => isObject(product));

  const ProductFullName = ({ name }) => (name ? <span>{name}</span> : null);

  const productUrl = data ? `https://www.policeauctions.com/dailydeals/${data.item.id}` : '';
  const imgUrl = data ? filterPrimaryType(data.images).url : '';

  return data ? (
    <Container css={styles}>
      <Helmet>
        <meta property="og:title" content="PoliceAuctions.com" />
        <meta property="og:url" content={productUrl} />
        <meta property="og:image" content={imgUrl} />
        <meta property="og:description" content={data.description.name} />
        <meta property="og:site_name" content="PoliceAuctions.com" />
      </Helmet>
      <Row>
        <PageTitle title={get(data, 'description.short_name')} />
      </Row>
      <Row>
        <ProductImageSlider images={get(data, 'images')} hasHd={get(data, 'item.product_description.has_hd')} />
        <Col xs={12} md={6}>
          <OfferInteraction id={match.params.id} />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <CollapsibleCard title={"Product Information"} showIcon={false} opened={true}>
            <ProductFullName name={data.item.product_description.name} />
            <ProductDescription
              products={onlyObjects({ ...data.description })}
              name={data.description}
              productId={get(data, 'item.product_description.category_id')}
            />
          </CollapsibleCard>
        </Col>
        <Col lg={12}>
          <CollapsibleCard title={"Payment Information"} showIcon={false} opened={true}>
            <PaymentInfo />
          </CollapsibleCard>
        </Col>
        <Col lg={12}>
          <div className="mb-2">
            <CollapsibleCard title={"Shipping Information"} showIcon={false} opened={true}>
             { parse(data.additional_info.shipping_info.text) }
            </CollapsibleCard>
          </div>
        </Col>
        <Col lg={12}>
          <CollapsibleCard title={"Bidding Information"} showIcon={false} opened={true}>
            { parse(data.additional_info.bidding_info_non_auction.text) }
          </CollapsibleCard>
        </Col>
        <Col lg={12}>
          <CollapsibleCard title={"Return Policy"} showIcon={false} opened={true}>
            { parse(data.additional_info.return_policy.text) }
          </CollapsibleCard>
        </Col>
      </Row>
    </Container>
  ) : (
    <Loading />
  );
};

export default DailyDealsView;
