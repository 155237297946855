/** @jsx jsx */

import React from 'react';
import { Alert } from 'reactstrap';
import { css, jsx } from '@emotion/core';
import { Errors } from 'types/authentication';

type Props = {
  item: Errors | null;
};

const ErrorMessage: React.FC<Props> = ({ item }) => {
  const renderErrorLists = (errors: Errors) => (
    <ul className="errorLists">
      {Object.values(errors).map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  );

  return item ? (
    <Alert color="danger" css={style}>
      <p className="message">{item.message}</p>
      {item.errors ? renderErrorLists(item.errors) : null}
    </Alert>
  ) : null;
};

export default ErrorMessage;

const style = css`
  .message {
    font-weight: bold;
  }

  .errorLists {
    font-size: 1.3rem;
    li {
      margin-left: 20px;
    }
  }
`;
