import React from 'react';
import reducer from 'reducers/terms';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import { useFetch } from 'hooks/async';
import { Loading } from 'components';

const Terms: React.FC = () => {
  document.title = 'Police Auctions - Terms';
  const [{ data }] = useFetch('/page/2/1', reducer);

  return (
    <React.Fragment>
      <h2>PoliceAuctions.com Terms of Service</h2>
      <hr />
      {data ? parse(data.text) : <Loading />}
      <p>
        To request to{' '}
        <span style={{ color: 'red' }}>
          <strong>delete</strong>
        </span>{' '}
        your account, click <Link to="/delete-instructions">here</Link>
      </p>
    </React.Fragment>
  );
};

export default Terms;
