import React from 'react';
import { Table } from 'reactstrap';
import { Record } from 'types/pages/Unclaimed/unclaimed';
import { Link } from 'react-router-dom';
import { GenericLink } from 'components';

const UnclaimedTable: React.FC<{ data: Array<Record> }> = ({ data }) => {
  return (
    <Table striped>
      <tbody>
        {data
          ? data.map(record => (
              <tr>
                <td><span className="state-link">{ record.state.state }</span></td>
                <td><DynamicLink record={ record } /></td>
              </tr>
            ))
          : false }
      </tbody>
    </Table>
  )
};

const UpgradeLink = () =>
  <Link to="/upgrade" className="btn btn-primary btn-lg">
    Click Here
  </Link>

const RegularLink = ({ record }) =>
  <GenericLink url={ record.link }>
    Click Here
  </GenericLink>

const DynamicLink: React.FC<{ record: Record }> = ({ record }) =>
  record.link === '/upgrade' ? <UpgradeLink /> : <RegularLink record={record} />

export default UnclaimedTable;