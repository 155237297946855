import React from 'react';
import _ from "lodash/fp";
import Alert from "reactstrap/lib/Alert";

import { withPagination } from "utils/withPagination";
import Item from "./partials/Item";

const Refunds: React.FC<any> = ({ data }) => {
  document.title = 'Police Auctions - Refunds';
  const noItemAlert = (
    <Alert color="warning" className="alert-msg">
      <p>You have no refunded items.</p>
    </Alert>
  )
  return (
    <React.Fragment>
      {( data && data.length) ? _.map((item: any) => <Item key={`my-refunds-${item.id}`} item={item} />)(data) : noItemAlert}
    </React.Fragment>
  );
};
export default withPagination("/user/refunds", ["My Refunds", "fas fa-shopping-cart"])(Refunds);
