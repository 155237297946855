/** @jsx jsx */

import React from 'react';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import { jsx, css } from '@emotion/core';

import generateLinks from 'utils/generateSidebarLinks';
import generateQueryParam from 'utils/generateQueryParam';
import InternalLink from './InternalLink';
import ExternalLink from './ExternalLink';

type OnClick = (e: React.MouseEvent<HTMLAnchorElement>) => void;

export type CategoryType = {
  id: number;
  name: string;
  count: number;
  display: string;
  is_category: boolean;
  is_fire: boolean;
  is_tag: boolean;
  is_virtual: boolean;
};

export const GovernmentDropdown: React.FC<{onClick: OnClick}> = ({ onClick }) => (
  <DropdownMenu className="dropdown-gov" right={true} css={style}>
    <InternalLink
      to="/externalauction"
      name="Government Auctions"
      onClick={onClick}
    />
    <InternalLink
      to="/carauction"
      name="Car Auctions"
      onClick={onClick}
    />
    <ExternalLink
      to="https://www.pbgc.gov/search/unclaimed-pensions"
      name="Unclaimed Pension"
      onClick={onClick}
      requiredAuth={true}
    />
    <ExternalLink
      to="https://www.yachtauctions.com"
      name="Seized Boats"
      onClick={onClick}
      requiredAuth={true}
    />
    <InternalLink
      to="/unclaimed"
      name="Unclaimed Money"
      onClick={onClick}
      requiredAuth={true}
    />
    <ExternalLink
      to="https://www.govloans.gov"
      name="Government Loans"
      onClick={onClick}
      requiredAuth={true}
    />
    <ExternalLink
      to="https://www.benefits.gov"
      name="Government Benefits"
      onClick={onClick}
      requiredAuth={true}
    />
  </DropdownMenu>
);

export const DropdownItems: React.FC<{ items: CategoryType[]; onClick: OnClick }> = ({ items, onClick }) => (
  <React.Fragment>
    {items.map((item, index) => {
      const links = generateLinks(item)(item.name);
      const categoryType = generateQueryParam(item);
      const path = {
        pathname: links,
        state: { name: item.name, params: { [categoryType]: item.id } }
      };
      return (
        <Link className="dropdown-link" to={path} key={`${item.name}-${index}`}>
          <DropdownItem css={style} onClick={onClick}>
            {item.display}{" "}
            {item.is_fire ? <i className="fa fa-fire hot-item-i" /> : null}
          </DropdownItem>
        </Link>
      );
    })}
  </React.Fragment>
);

const style = css`
  font-size: 1.4rem;

  .dropdown-gov {
    width: 17.4rem;
    max-height: 50.6rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    overflow: scroll;
    font-size: 1.4rem;
  }

  .gov-link {
    text-decoration: none;
  }

  .dropdown-link {
    &:hover {
      text-decoration: none;
      color: red;
    }
  }
`;
