import socketActions from 'constants/sockets';
import { initialState } from 'context/SocketProvider';
import { Reducer } from 'react';
import { SocketActions } from 'types/actions';

export const reducer: Reducer<typeof initialState, SocketActions> = (state, action): typeof initialState => {
  switch (action.type) {
    case socketActions.UPDATE_BID_LIST: {
      const {
        bids: { bids },
        id
      } = action.payload;

      return {
        ...state,
        streamData: {
          ...state.streamData,
          bids,
          id
        }
      };
    }
    case socketActions.AUCTION_ENDING: {
      const { schedule_id, status, user_id } = action.payload;

      return {
        ...state,
        streamData: {
          ...state.streamData,
          ended: {
            schedule_id,
            status,
            user_id
          }
        }
      };
    }
    case socketActions.USER_EVENT: {
      const { myitems, user_id, notifications } = action.payload;
      return {
        ...state,
        streamData: {
          ...state.streamData,
          userStream: {
            myItems: myitems,
            user_id,
            notifications,
          }
        }
      };
    }
    case socketActions.UNWATCH_BID_LIST: {
      return initialState;
    }

    case socketActions.STREAMING_SUCCESS: {
      return {
        ...state,
        streamError: action.payload.streamError
      }
    }

    case socketActions.STREAMING_ERROR: {
      return {
        ...state,
        streamError: action.payload.streamError
      }
    }
    default: {
      return state;
    }
  }
};
