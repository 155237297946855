/** @jsx jsx */

import { useContext, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { ReactFacebookLoginInfo as FBLoginInfo } from 'react-facebook-login';
import AppleSignIn, { AppleSignInResponse } from 'components/AppleSignInButton';
import { default as jwt_decode } from 'jwt-decode';

import { FacebookButton } from 'components';
import { LoginForm } from './partials/LoginForm';
import styles from 'styles/Login';
import { Layout, ErrorMessage } from 'components';
import { AppContext, clearErrors } from 'context/AppProvider';
import { LoginInput } from 'types/authentication';

const Login: React.FC = () => {
  document.title = 'Police Auctions - Login';
  const {
    actions,
    dispatch,
    state: { errors, isLoading }
  } = useContext(AppContext);

  const [formErrors, setFormErrors] = useState({});

  const onAppleSignIn: AppleSignInResponse = appleRes => {
    const { id_token, code } = appleRes.authorization;
    const { email, sub } = jwt_decode<{ email: string; sub: string }>(id_token);

    actions.appleLogin({
      ...appleRes.user,
      authorizationCode: code,
      identityToken: id_token,
      email,
      user: sub
    });
  };

  const onFacebookLogin = ({ email, id, accessToken }: FBLoginInfo) => {
    actions.fbLogin({ email, social_provider_user_id: id, access_token: accessToken });
  };

  const onFormSubmit = (values: LoginInput) => {
    actions.login(values);
  };

  useEffect(() => {
    return () => {
      clearErrors(dispatch);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length || errors) {
      window.scrollTo(0, 0);
    }
  }, [formErrors, errors]);

  const handleOnErrors = errors => {
    setFormErrors(errors);
  };

  return (
    <Layout css={styles}>
      <h2>Login</h2>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <AppleSignIn
          clientId="com.policeauctions.web"
          redirectURI="https://www.policeauctions.com/apple-sign-in"
          usePopup={true}
          scope="email"
          appleSignInResponse={onAppleSignIn}
          buttonStyle={{
            width: '37.5rem',
            height: '4.1rem'
          }}
        />
        <FacebookButton
          onFBCallback={onFacebookLogin}
          value="Login With Facebook"
          buttonStyle={{
            maxWidth: '37.5rem',
            height: '4.1rem',
            borderRadius: '0.7rem',
            marginTop: '1.2rem',
            fontSize: '1.7rem'
          }}
          iconStyle={{
            fontSize: '2rem',
            marginRight: '0.7rem'
          }}
        />
      </div>
      <hr className="form-border" />
      <ErrorMessage item={errors} />
      <LoginForm onSubmit={onFormSubmit} isLoading={isLoading} onErrors={handleOnErrors} />
      <p>
        Don't have an account? &nbsp;
        <Link className="user-a" to="/register">
          Register your Account!
        </Link>
      </p>
      <p>
        Forgot your password? &nbsp;
        <Link to="/forgot" className="user-a">
          Recover your Account!
        </Link>
      </p>
    </Layout>
  );
};

export default Login;
