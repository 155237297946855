/** @jsx jsx */

import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { Row, Col, Container, Alert } from "reactstrap";
import { jsx } from "@emotion/core";
import _, { get } from 'lodash';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

import { CardAuctionStatus, ProductImageSlider, BlockButton, Loading, LoadingButton, ErrorMessage, ShareIcons } from "components";
import AuctionDetails from "pages/Auction_View/partials/AuctionDetails";
import styles from 'styles/BuyDirect';
import { useFetch } from "hooks/async";
import { reducer } from "reducers/asyncGlobal";
import history from 'utils/history';
import request from "utils/request";

const BuyDirect: React.FC<RouteComponentProps> = ({ match }) => {
  document.title = "Police Auctions - Buy Direct";
  const [{ data }] = useFetch(match.url, reducer);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const buyDirectButton = (user, bdp, auctionId, inventoryCount) => {
    const hasBdpText = user ? `Buy this item for $${bdp}` : 'You must log in to buy this item';
    const bdpBtnText = inventoryCount ? hasBdpText : 'Check out our $1 Auctions';
    const btnColor = user ? 'green' : 'blue';

    const handleBdpClick = () => {
      setIsLoading(true);
      if (!bdp) {
        history.push('/');
        return;
      }
      if (!user) {
        history.push('/login');
        return;
      }
      request.post(`buydirect/${auctionId}/accept`).then(
        (response) => {
          history.push(`/payment/methods/${response.data.schedule_id}`)
        },
        (errors) => {
          setErrors(errors);
        }
      );
    }
    return isLoading ? <LoadingButton /> : (
      <BlockButton color={btnColor} onClick={handleBdpClick} disabled={isLoading}>
        { bdpBtnText }
      </BlockButton>
    )
  }

  const alert = (inventoryCount, bdp) => {
    const alertMsg = inventoryCount ? `With one button you can purchase this item for $${bdp}.` : 'Sorry, this item is sold out and not available for Buy Direct.'
    return (
      <Alert color={inventoryCount ? 'success' : 'danger'}>
        <p>{ alertMsg }</p>
      </Alert>
    );
  }

  const primaryImgUrl = (images) => images.filter(img => img.image_type_id == 5)[0].url;

  return data ? (
    <Container css={styles}>
      <Helmet>
        <meta property="og:title" content="PoliceAuctions.com" />
        <meta property="og:url" content={match.url} />
        <meta property="og:image" content={primaryImgUrl(data.auction.control.product_description.images)} />
        <meta property="og:description" content={data.description.name} />
        <meta property="og:site_name" content="PoliceAuctions.com" />
      </Helmet>
      <Row>
        <Col>
          <h3 className="text-left product-title">{ data.auction.control.product_description.short_name}</h3>
        </Col>
        <Col>
          <div className="d-flex flex-row-reverse">
          <CardAuctionStatus showBdp={true} bdp={null} timer={null} auctionId={data.auction.id} />
          </div>
        </Col>
      </Row>
      <Row>
        <ProductImageSlider images={data.auction.control.product_description.images} hasHd={data.auction.control.product_description.has_hd} />
        <Col>
          <ErrorMessage item={errors} />
          <h3>Buy Direct</h3>
          { alert(data.inventory_count, data.auction.bdp) }
          { buyDirectButton(data.user, data.auction.bdp, data.auction.id, data.inventory_count) }
          <ShareIcons />
        </Col>
      </Row>
      <Row>
        <AuctionDetails
          products={get(data, 'description')}
          name={get(data, 'description')}
          productName={get(data, 'description.name')}
          auctionId={get(data, 'auction.id')}
          shippingInfo={parse(data.additional_info.shipping_info.text)}
          billingInfo={parse(data.additional_info.bidding_info_auction.text)}
          returnPolicy={parse(data.additional_info.return_policy.text)}
          endTime={get(data, 'auction.event_end')}
        />
      </Row>
    </Container>
  ) : <Loading />;
};

export default BuyDirect;
