/** @jsx jsx */
import { useState } from 'react';
import { css, jsx } from '@emotion/core';
import {
  Collapse,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap';

type Props = {
  title: string;
  showIcon: boolean;
  opened: boolean;
};

const CollapsibleCard: React.FC<Props> = ({ children, title, showIcon, opened }) => {

  const [collapse, setCollapse] = useState(opened);

  const toggle = () => setCollapse(prevCollapse => ! prevCollapse);

  const icon = collapse ? "down" : "right";

  return (
    <Card css={ style }>
      <CardHeader onClick={ () => toggle() }>
        { showIcon ? <i className={`fas fa-arrow-circle-${ icon }`}></i> : null } { title }
      </CardHeader>
      <Collapse isOpen={ collapse }>
        <CardBody>
          <span className="card-content">{ children }</span>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default CollapsibleCard;

const style = css`
  .card-header {
    font-size: 1.25rem;
  }

  .card-content {
    font-size: 1.25rem;
  }
`;