import React from 'react';
import CardList from './CardList';
import CardListing from './CardListing';

type CardList = {
  name: string;
  display: string;
  is_fire: boolean;
  id: number;
  subList: CardList[];
};

type Props = {
  data: CardList[];
};

const CardSubLists: React.FC<Props> = ({ data }) => (
  <React.Fragment>
    {data
      ? data.map(list => (
          <CardListing key={list.id}>
            <CardList name={list.display} url={`/category/${list.id}`} />
          </CardListing>
        ))
      : null}
  </React.Fragment>
);

export default CardSubLists;