/** @jsx jsx */

import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Formik } from 'formik';
import { Row, Col, Input, Alert, Table, InputGroup } from 'reactstrap';

import { BlockButton, LoadingButton, Timer } from 'components';
import useTimer from 'hooks/timer';
import { OfferNegotiationProps } from 'types/pages/Daily_Deals_View/dailyDealsView';


const OfferNegotiation: React.FC<OfferNegotiationProps> = ({ endTime, onEnded, message, accepting, started, onSubmitOffer, onAcceptOffer, ourOffer, yourOffer, countering, starting }) =>{

  const endDate = new Date(endTime);
  const timeStamp = new Date().getTime();

  const [timer] = useTimer(timeStamp, endDate);

  useEffect(() => {
    if(started && timer <= 0){
      onEnded();
    }
  }, [timer, started]);


  const cannotNegotiate = !(ourOffer === "" || (parseFloat(ourOffer) > parseFloat(yourOffer)));

  const startButton = starting
    ? <LoadingButton>Loading...</LoadingButton>
    : <BlockButton disabled={starting} type="submit">
        Start Negotiations
      </BlockButton>

  const offerButton = countering
    ? <LoadingButton>Loading...</LoadingButton>
    : <BlockButton disabled={countering || cannotNegotiate} type="submit">
        { yourOffer ? "Submit Counter" : "Submit Offer" }
      </BlockButton>

  const acceptButton = accepting
    ? <LoadingButton>Loading...</LoadingButton>
    : <BlockButton onClick={onAcceptOffer} color="green">
        Click to accept our offer of ${ourOffer}
      </BlockButton>

  const note = ourOffer === ''
    ? 'Enter the amount you want to offer and click the blue button to submit a counter offer.'
    : 'Click the green button to accept our offer, or submit a counter offer.';

  return (
    <React.Fragment>

      {!started ?
        <Alert color="info">
          <p>Click the button to make an offer on this item.</p>
        </Alert>  : null}

      {message ?
        <Alert color="info">
          <p>{ message }</p>
        </Alert>  : null}

      <Row>
        <Col>
          <Timer serverTime={timeStamp} endTime={endDate} />
        </Col>
      </Row>

      <Table striped borderless>
        <thead>
          <tr>
            <th>Our Offer</th>
            <th>Your Offer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${ourOffer}</td>
            <td>${yourOffer}</td>
          </tr>
        </tbody>
      </Table>

      <Row>
        <Col>
          <Formik initialValues={{ input: '' }} onSubmit={onSubmitOffer}>
            {({ handleChange, values, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                { started ? <p className="note">Note: {note}</p> : null }
                <div className="d-flex flex-direction-row">
                  <InputGroup className="bid-input-container">
                    <Input
                      name="input"
                      placeholder="Amount"
                      type="number"
                      step="1"
                      className="bid-input"
                      min={0}
                      value={values.input}
                      onChange={handleChange}
                      disabled={cannotNegotiate}
                    />
                  </InputGroup>
                  {started ? offerButton : null}
                  {!started ? startButton : null}
                </div>
              </form>
            )}
          </Formik>
        </Col>
      </Row>

      {parseInt(ourOffer) > 0 ? acceptButton : null}

    </React.Fragment>
  );
};

export default OfferNegotiation;
