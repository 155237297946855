import { useEffect, useContext } from 'react';

import socketInit from 'utils/socketInit';
import { SocketContext } from 'context/SocketProvider';
import { AppContext } from 'context/AppProvider';
import updateBidAmount from 'utils/updateBidAmount';
import socketActions from 'constants/sockets';

const auctionsList = data => data && data.reduce((acc, curr) => acc.concat({ id: curr.id.toString(), endTime: '' }), []);

const useBidStream = (auctionData, user) => {
  const { dispatch, state: { streamData: { bids, id } }, socket } = useContext(SocketContext);
  const updateBids = bids && auctionData ? updateBidAmount({ bids, id }, user.username)(auctionData) : auctionData;
  const items = auctionsList(auctionData);

  const {
    actions
  } = useContext(AppContext);

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  });

  const onVisibilityChange = () => {
    if(document.visibilityState === 'visible') {
      actions.getServerTime();
    }
  };

  useEffect(() => {
    if (socket.connected) {
      socketInit(socket, dispatch, { user: user.id, items });
    }

    return () => {
      if (items && socket) {
        socket.emit('unwatch', JSON.stringify({ user: user.id, items }));
      }
    };
  }, [socket.connected, items]);

  useEffect(() => {
    return () => {
      dispatch({ type: socketActions.UNWATCH_BID_LIST });
    }
  })

  return updateBids;
};

export default useBidStream;
