import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

type Props = {
  title: String
  tooltip?: any | null
};

const TwoColumnRow: React.FC<Props> = ({ title, children, tooltip = null }) => (
  <Fragment>
    <hr/>
    <Row>
      <Col>
        <span>{ title } { tooltip }</span>
      </Col>
      <Col>
        { children }
      </Col>
    </Row>
  </Fragment>
);

export default TwoColumnRow;