/** @jsx jsx */

import { useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Container, Row, Col } from 'reactstrap';

import { FreePlanTable } from './partials/FreePlanTable';
import { MonthlyPlanTable } from './partials/MonthlyPlanTable';
import { YearlyPlanTable } from './partials/YearlyPlanTable';
import style from 'styles/Upgrade';
import { usePaymentFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { Loading } from 'components';

const Upgrade = () => {
  document.title = 'Police Auctions - Upgrade';
  const [{ data, isLoading }] = usePaymentFetch('/subscription/paypal', reducer);
  useEffect(() => {
    return () => {
      if (localStorage.getItem('isNew') !== null) {
        localStorage.removeItem('isNew');
      }
    };
  }, []);
  const loading = isLoading ? <Loading /> : null;
  return data ? (
    <Container fluid={true} css={style}>
      <h1>Upgrade Options</h1>
      <Row>
        {localStorage.getItem('isNew') && (
          <Col className="upgrade-col">
            <FreePlanTable />
          </Col>
        )}
        <Col className="upgrade-col">
          <MonthlyPlanTable {...data.monthly} />
        </Col>
        <Col className="upgrade-col">
          <YearlyPlanTable {...data.yearly} />
        </Col>
      </Row>
    </Container>
  ) : loading;
};

export default Upgrade;
