import React, { useContext, useEffect, useState } from 'react';

import request from 'utils/request';
import history from 'utils/history';
import { AppContext } from 'context/AppProvider';
import { UPDATE_PAYMENT } from 'constants/appContext';
import { PAYPAL_KEY } from 'constants/config';

type OnApproveData = {
  billingToken: null
  facilitatorAccessToken: string;
  orderID: string;
  payerID: string;
};

type PayPalButton = {
  onClick: () => void;
  //TODO Add actions type if actions is needed to be used in the future
  createOrder: (actions?) => void;
  onApprove: (data: OnApproveData, actions?) => void;
}

type PayPal = {
  paypal: {
    Buttons: (args: PayPalButton) => {
      render: (args: string) => void
    }
  }
}

type Props = {
  auction: string | number;
}

const PayPalExpress = ({ auction }: Props) => {
  const { dispatch } = useContext(AppContext);

  const paypalHandler = () => {
    (window as Window & any).paypal
      .Buttons({
        createOrder: () => {
          return request.postPayment('/paypal/orders/create-payment', { auction }).then(({ data: response }) => {
            if (typeof response.error != 'undefined') {
              history.push('/my-wins');
              return false;
            }

            return response.id;
          });
        },
        onApprove: (data) => {
          request
            .postPayment('/paypal/orders/update-payment', { orderID: data.orderID, auction })
            .then(({ data: response }) => {
              dispatch({ type: UPDATE_PAYMENT, payload: { paypal: response } });

              history.push(`/payment/paypal/review/${auction}`);
            });
        },
      })
      .render('#paypal-button-container');
  };

  useEffect(() => {
    const script = Object.assign(document.createElement('script'), {
      src: `https://www.paypal.com/sdk/js?client-id=${PAYPAL_KEY}&commit=false&disable-funding=credit,card`,
    });

    document.body.appendChild(script);
    script.addEventListener('load', paypalHandler);

    return () => {
      script.removeEventListener('load', paypalHandler);
      document.body.removeChild(script);
    }
  }, []);

  return <div id="paypal-button-container"></div>;
};

export default PayPalExpress;
