import { css } from '@emotion/core';

export default css`
  height: 100%;
  max-width: 102.4rem;

  @media screen and (max-width: 992px) {
    .upgrade-col {
      padding: 0;
    }
  }
`;
