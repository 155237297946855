/** @jsx jsx */

import { css, jsx } from '@emotion/core';

import { Container, Row, Col } from 'reactstrap';

const Layout: React.FC =  ({ children, ...rest }) => (
  <Container css={styles} {...rest}>
    <Row>
      <Col sm="12" md={{ size: 8, offset: 2 }}>
        {children}
      </Col>
    </Row>
  </Container>
);

const styles = css`
  min-height: 75vh;
  padding-top: 2rem;
  max-width: 80rem;
`;

export default Layout;