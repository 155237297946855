//@ts-nocheck

import { Dispatch } from 'react';
import request from 'utils/request';
import actions from 'constants/appContext';
import { Login, Register, FBLogin, Logout, AuthActions, Impersonate, AppleLogin } from 'types/actions';
import { User, FBLoginInput, RegisterInput } from 'types/authentication';

const login = (dispatch: Dispatch<Login>) => async (user: User) => {
  dispatch({ type: actions.LOGIN_REQUEST });
  try {
    const {
      data: { token }
    } = await request.post('/login', user);
    localStorage.setItem('token', token);
    dispatch({ type: actions.LOGIN_SUCCESS });
    dispatch({ type: actions.ROUTE_PUSH, payload: '/' });
  } catch (error) {
    const { errors, message } = error.response.data;
    dispatch({ type: actions.LOGIN_FAILURE, payload: { errors, message } });
  }
};

const impersonate = (dispatch: Dispatch<Impersonate>) => async (fields: any) => {
  dispatch({ type: actions.IMPERSONATE_REQUEST });
  try {
    const {
      data: { token }
    } = await request.post('/impersonate', fields);
    localStorage.setItem('token', token);
    dispatch({ type: actions.IMPERSONATE_SUCCESS });
    dispatch({ type: actions.ROUTE_PUSH, payload: '/' });
  } catch (error) {
    const { errors, message } = error.response.data;
    dispatch({ type: actions.IMPERSONATE_FAILURE, payload: { errors, message } });
  }
};

const register = (dispatch: Dispatch<Register>) => async (user: RegisterInput) => {
  dispatch({ type: actions.REGISTER_REQUEST });
  try {
    const {
      data: { token }
    } = await request.post('/register', user);
    localStorage.setItem('token', token);
    localStorage.setItem('isNew', '1');
    (window as any).dataLayer.push({'event': 'Register'});
    dispatch({ type: actions.REGISTER_SUCCESS });
    dispatch({ type: actions.ROUTE_PUSH, payload: '/upgrade' });
  } catch (error) {
    const { errors, message } = error.response.data;
    dispatch({ type: actions.REGISTER_FAILURE, payload: { errors, message } });
  }
};

const fbLogin = (dispatch: Dispatch<FBLogin>) => async (user: FBLoginInput) => {
  dispatch({ type: actions.FB_LOGIN_REQUEST });
  try {
    const affiliate = localStorage.getItem('affiliate');
    const tracking = affiliate ? JSON.parse(affiliate) : null;
    const requestData = {
      ...user,
      affiliate_id: tracking ? tracking.affiliateId : null,
      refer_id: tracking ? tracking.affiliateReferCodeId : null,
      refer_code: tracking ? tracking.affiliateReferCode : null,
    }
    const {
      data: { token, is_new }
    } = await request.post('/social/login', requestData);
    localStorage.setItem('token', token);
    if(is_new) {
      (window as any).dataLayer.push({'event': 'Register'});
    }
    dispatch({ type: actions.FB_LOGIN_SUCCESS });
    dispatch({ type: actions.ROUTE_PUSH, payload: '/' });
  } catch (error) {
    const { errors, message } = error.response.data;
    dispatch({ type: actions.FB_LOGIN_FAILURE, payload: { errors, message } });
  }
};

const appleLogin = (dispatch: Dispatch<AppleLogin>) => async (user: any) => {
  dispatch({ type: actions.APPLE_LOGIN_REQUEST });
  try {
    const isEmailPrivate = user.email.indexOf('privaterelay.appleid') > -1;
    const {
      data: { token, is_new }
    }  = await request.post('/social/login/apple', user);
    const redirect = isEmailPrivate && is_new ? '/edit-username' : '/';
    localStorage.setItem('token', token);
    dispatch({ type: actions.APPLE_LOGIN_SUCCESS });
    dispatch({ type: actions.ROUTE_PUSH, payload: redirect });
  } catch (error) {
    const { errors, message } = error.response.data;
    dispatch({ type: actions.APPLE_LOGIN_FAILURE, payload: { errors, message } });
  }
}

const logout = async (dispatch: Dispatch<Logout>) => {
  dispatch({ type: actions.LOGOUT_REQUEST });
  try {
    await request.post('/logout');
    dispatch({ type: actions.LOGOUT_SUCCESS });
    localStorage.removeItem('token');
  } catch (error) {
    const { errors, message } = error.response.data;
    dispatch({ type: actions.LOGOUT_FAILURE, payload: { errors, message } });
  }
};

export const authActions = (dispatch: Dispatch<AuthActions>) => ({
  login: login(dispatch),
  appleLogin: appleLogin(dispatch),
  fbLogin: fbLogin(dispatch),
  register: register(dispatch),
  impersonate: impersonate(dispatch),
  logout: () => logout(dispatch)
});
