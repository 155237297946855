import React from 'react';
import { Row, Col } from 'reactstrap';

import Carousel  from './Carousel';
import JoinNow  from './JoinNow';

const Banner = () => {
  return (
    <Row>
      <Col lg="9" md="12">
        <Carousel />
      </Col>
      <Col lg="3" md="12">
        <JoinNow />
      </Col>
    </Row>
  );
}

export default Banner;