import { SocketActions } from 'types/actions';
import actions from 'constants/sockets';
import { UserPayload } from 'types/authentication';
import { Socket } from 'socket.io-client';

const socketEvents = (socket: Socket, dispatch: React.Dispatch<SocketActions>, user?: UserPayload) => {
  socket.on('connect', () => {
    dispatch({ type: actions.STREAMING_SUCCESS, payload: { streamError: false }});
  });

  socket.on('connect_error', () => {
    dispatch({ type: actions.STREAMING_ERROR, payload: { streamError: true }});
  });

  socket.on('bidUpdate', data => {
    dispatch({ type: actions.UPDATE_BID_LIST, payload: JSON.parse(data) });
  });

  socket.on('time', data => {
    dispatch({ type: actions.STREAMING_TIME, payload: JSON.parse(data) });
  });

  socket.on('auction-end', data => {
    dispatch({ type: actions.AUCTION_ENDING, payload: data });
  });

  if (user && user.id) {
    socket.on(`user-events-${user.id}`, data => {
      dispatch({ type: actions.USER_EVENT, payload: JSON.parse(data) });
    });
  }
};

export default socketEvents;
