/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/core';

export const List: React.FC<{ title: string }> = ({ title, children }) => (
  <div css={style}>
    <h6 className="footer-title">{title}</h6>
    <ul className="list-unstyled text-center">{children}</ul>
  </div>
);

const style = css`
  .footer-title {
    font-size: 1.4rem;
    font-weight: 350;
  }

  .footer-link {
    color: #337ab7;
  }
`;
