/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { Paginator, Loading } from 'components';
import style from 'styles/Testimonials';
import numberWithCommas from 'utils/numberWithCommas';
import Statement from './partials/Statement';

const Testimonials: React.FC = () => {
  document.title = 'Police Auctions - Testimonials';
  const [page, setPage] = useState(1);
  const [{ data: response, isLoading }, fetch, reset] = useFetch('/testimonials', reducer, { page });

  const pageClick = (page: number) => {
    reset();
    setPage(page);
    fetch(true);
  }

  const total = response ? numberWithCommas(response.total) : '';
  const loading = isLoading ? <Loading /> : null;
  const pagination = response
    ? <Paginator
        pages={ response }
        onClick={ pageClick }
        isLoading={ isLoading }
      />
    : null;

  return (
    <div css={ style }>
      <h1 className="title">Testimonials</h1>
      <h2 className="sub-title">These { total } customers all had a positive experience with PoliceAuctions.com. You can too!</h2>
      <p><Link to="/register">You can register here!</Link></p>
      <hr/>
      <Row>
        <Col>
          { loading }
          { response
            ? response.data.map(statement => (
                <Statement statement={ statement } key={`statement-${statement.id}`} />
              ))
            : null }
          { pagination }
        </Col>
      </Row>
    </div>
  )
}

export default Testimonials;