import React from "react";

import { Card } from 'components';
import ItemDetails from './ItemDetails';
import ItemMessage from './ItemMessage';
import { ItemProps } from "types/pages/My_Wins/myWins";

const Item: React.FC<ItemProps> = ({ item }) => {
  return (
    <Card headerTitle={item.control.product_description.short_name}>
      <ItemMessage item={item} />
      <ItemDetails item={item} />
    </Card>
  );
};

export default Item;