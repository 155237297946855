/** @jsx jsx */

import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/core';
import { Card as CardCategory, CardHeader, CardBody } from 'reactstrap';
import CardListing from './CardListing';
type Props = {
  headerTitle: string;
  headerTitleRight?: ReactNode;
  bodyImage?: string;
  isSold?: boolean;
  style?: any;
};

const Card: React.FC<Props> = ({ headerTitle, bodyImage, headerTitleRight, children, isSold, ...rest }) => (
  <CardCategory css={isSold ? soldTheme : defaultTheme} {...rest}>
    <CardHeader className="card-head d-flex flex-row justify-content-between">
      <p className="header-title">{headerTitle}</p>
      {headerTitleRight ? headerTitleRight : null}
    </CardHeader>
    <CardBody>
      {bodyImage ? <img src={bodyImage} alt="" className="mx-auto d-block" /> : null}
      <CardListing>{children}</CardListing>
    </CardBody>
  </CardCategory>
);

const defaultTheme = css`
  :not(:first-of-type) {
    margin-top: 1rem;
  }

  .card-head {
    font-size: 1.3rem;
    font-weight: bolder;
    max-height: 3.7rem;

    .header-title {
      margin: 0;
    }
  }

  .list-a {
    color: #000000;
  }
`;

const soldTheme = css`
  :not(:first-of-type) {
    margin-top: 1rem;
  }

  border-color: #eed3d7;

  .card-head {
    background-color: #f2dede;
    font-size: 1.3rem;
    font-weight: bolder;
    border-bottom: #eed3d7;
    transition: all 0.25s ease-in-out;

    .header-title {
      margin: 0;
      color: #d43f3a;
    }
  }
`;

export default Card;
