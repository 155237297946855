/** @jsx jsx */

import { ButtonHTMLAttributes } from 'react';
import { ButtonProps } from 'reactstrap';
import { css, jsx } from '@emotion/core';
import BlockButton from 'components/BlockButton';

type Events = {
  onSubmit?: (event: React.FormEvent) => void;
};

type ButtonType = ButtonHTMLAttributes<Events>;

export type Props = ButtonProps<ButtonType>;

/**
 * LoadingButton component is used to indicate some activity is currently working
 */
const LoadingButton: React.FC<Props> = ({ children, ...rest }) => (
  <BlockButton css={styles} disabled={true} { ...rest }>
    <i className="fas fa-spinner fa-pulse"></i> {children}
  </BlockButton>
);

const styles = ({ colors }) => css`
  background-color: ${colors.grayLight};
  border-color: ${colors.grayLight};
  color: #000000;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  font-weight: lighter;

  :hover {
    background-color: ${colors.grayLight};
    color: #ffffff;
    border-color: ${colors.grayLight};
  }
`;

export default LoadingButton;
