import { css } from '@emotion/core';

export default css`
  .header {
    font-size: 2.5rem;
  }

  .header-link {
    padding-right: 1rem;
  }

  .ticket-card {
    margin-bottom: 2rem;
  }
  
  .bold {
    font-weight: 500;
  }

  .size {
    font-size: 1.25rem;
  }

  .badge {
    font-size: 100%;
    font-weight: 350;
  }
`;