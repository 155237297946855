import styled from '@emotion/styled';
import { ListGroup } from 'reactstrap';

type StylesType = {
  bgColor?: string;
  borderColor?: string;
  bgColorOnHover?: string;
};

const ListGroupStyled = styled(ListGroup)<StylesType>(
  {
    fontSize: '1.4rem',
    textAlign: 'center',
    '& > li:first-of-type': {
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      borderTop: 'none'
    }
  },
  ({ bgColor }) => ({
    '@media (min-width: 992px)': {
      '& > li:nth-of-type(4n + 1)': {
        backgroundColor: bgColor || '#e0eaf2'
      }
    },
    '@media (max-width: 992px)': {
      '& > li:nth-of-type(even)': {
        backgroundColor: bgColor || '#e0eaf2'
      },
      '& li': {
        border: 'none',
        fontSize: '1.2rem'
      },
      '.hide-check': {
        display: 'none'
      }
    }
  })
);

export default ListGroupStyled;