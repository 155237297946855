//@ts-nocheck

import { Dispatch } from 'react';
import request from 'utils/request';
import actions from 'constants/alerts';
import { Notification } from 'types/Alerts/row';
import { Action } from 'types/actions';

export const fetchNotifications = async (dispatch: Dispatch<Action>, page: number) => {
  dispatch({ type: actions.NOTIFICATION_REQUEST });
  try {
    let params = { page, read: 0 }
    let response = await request.get('/notification', params);
    dispatch({ type: actions.NOTIFICATION_SUCCESS, payload: response.data });
  } catch (error) {
    const { errors, message } = error.response.data;
    dispatch({ type: actions.NOTIFICATION_FAILURE, payload: { errors, message } });
  }
};

export const markRead = async (dispatch: Dispatch<Action>, notification: Notification) => {
  dispatch({ type: actions.MARK_READ_REQUEST });
  try {
    let response = await request.post(`/notification/${notification.id}/read`);
    dispatch({ type: actions.MARK_READ_SUCCESS, payload: response.data });
  } catch (error) {
    const { errors, message } = error.response.data;
    dispatch({ type: actions.MARK_READ_FAILURE, payload: { errors, message } });
  }
};

export const markAllRead = async (dispatch: Dispatch<Action>) => {
  dispatch({ type: actions.MARK_ALL_REQUEST });
  try {
    let response = await request.post('/notification/read');
    dispatch({ type: actions.MARK_ALL_SUCCESS, payload: response.data });
  } catch (error) {
    const { errors, message } = error.response.data;
    dispatch({ type: actions.MARK_ALL_FAILURE, payload: { errors, message } });
  }
};