/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import GenericLink from './GenericLink';
import { Link } from 'react-router-dom';

const InternalLink = () => (
  <Link to="/upgrade" className="btn btn-primary btn-lg">
    Click Here
  </Link>
);

const RegularLink = ({ url }) => {
  const http = url.indexOf('http') > -1
  const https = url.indexOf('https') > -1;
  return http || https ? <GenericLink url={url}>Click Here</GenericLink> : url
};

const UpradeLink: React.FC<{ url: string }> = ({ url }) =>
  url === '/upgrade' ? <InternalLink /> : <RegularLink url={url} />;

export default UpradeLink;
