/** @jsx jsx */

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { css, jsx } from '@emotion/core';

import { BREAKPOINTS as BP } from 'constants/emotionConfig';
import { Header, Footer, FooterBanner } from 'components';
import { useTimerFetch } from 'hooks/async';
import category from 'reducers/cateogry';

type Props = {
  showAd?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
}

export const EmptyPage: React.FC<Props> = ({ children, showAd = true, showHeader = true, showFooter = true }) => {

  //3600000ms = 60 minutes
  const [{ data }] = useTimerFetch('/category', category, 3600000);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <React.Fragment>
      { showHeader ? <Header categoryListData={data} /> : null }
      <Container css={style} fluid={true}>
        <Row>
          <Col md={12}>{children}</Col>
        </Row>
        {/* { showAd ? <FooterBanner /> : null } */}
      </Container>
      { showFooter ? <Footer /> : null }
    </React.Fragment>
  );
}

const style = css`
  padding-top: 2rem;
  min-height: 75vh;
  padding-left: 8rem;
  padding-right: 8rem;

  @media (max-width: ${BP.MD}) {
    padding: 2rem 2rem;
    width: 100%;
  }
`;
