import React from 'react';
import { Row, Col } from 'reactstrap';

const Header = ({ data }) =>
  <Row>
    <Col>
      <h3>{ data.name }</h3>
      <hr/>
    </Col>
  </Row>

export default Header;