/** @jsx jsx */

import React from 'react';
import { Formik, Form } from 'formik';
import { css, jsx } from '@emotion/core';
import * as Yup from 'yup';

import { Input, Checkbox, CancelButton, SaveButton } from 'components';
import { BREAKPOINTS as BP } from 'constants/emotionConfig';
import { Props } from 'types/pages/Edit_Profile/partials/editProfile';

const validation = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Enter a valid email address')
    .required('email address is required')
});

/**
 *  TODO: Disable the inputs and buttons while getting the user request
 */

const EditProfileForm: React.FC<Props> = ({ user, updateProfile }) => (
  <Formik
    initialValues={user}
    enableReinitialize={true}
    validationSchema={validation}
    validateOnChange={false}
    onSubmit={updateProfile}
    render={({
      handleChange,
      touched,
      errors,
      handleReset,
      values: { username, first_name, last_name, email, newsletter, phone }
    }) => (
      <Form css={style}>
        <Input
          label="Username"
          name="username"
          onChange={handleChange}
          value={username}
          isError={touched.username && errors.username}
          errorMessage={errors.username}
          invalid={errors.username ? true : false}
        />
        <Input
          label="First Name"
          name="first_name"
          onChange={handleChange}
          value={first_name}
          isError={touched.first_name && errors.first_name}
          errorMessage={errors.first_name}
          invalid={errors.first_name ? true : false}
        />
        <Input
          label="Last Name"
          name="last_name"
          onChange={handleChange}
          value={last_name}
          isError={touched.last_name && errors.last_name}
          errorMessage={errors.last_name}
          invalid={errors.last_name ? true : false}
        />
        <Input
          label="Email Address"
          name="email"
          onChange={handleChange}
          value={email}
          isError={touched.email && errors.email}
          errorMessage={errors.email}
          invalid={errors.email ? true : false}
        />
        <Input
          label="Phone"
          name="phone"
          onChange={handleChange}
          value={phone}
          isError={touched.phone && errors.phone}
          errorMessage={errors.phone}
          invalid={errors.phone ? true : false}
        />
        <SaveButton type="submit">Save Changes</SaveButton>
        <CancelButton className="btn-cancel" onClick={handleReset}>
          Cancel
        </CancelButton>
      </Form>
    )}
  />
);

export default EditProfileForm;

const style = css`
  @media (max-width: ${BP.LG}) {
    width: 100%;
  }

  @media (min-width: ${BP.LG}) {
    width: 65%;
  }
`;
