import React from 'react';

import SoldBadge from 'components/badges/SoldBadge';
import CardAuctionStatus from 'components/CardAuctionStatus';

type Props = {
  timer: number;
  bdp: string;
  auctionId: string;
  showBdp: boolean;
};

const SoldAuction: React.FC<Props> = ({ timer, bdp, showBdp, auctionId }) =>
  timer > 0 || isNaN(timer) ? <CardAuctionStatus showBdp={showBdp} bdp={bdp} timer={timer} auctionId={auctionId} /> : <SoldBadge />;

export default SoldAuction;
