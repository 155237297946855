/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/core';

const CardListing: React.FC = ({ children }) => <ul className="card-li" css={style}>{children}</ul>;

const style = css`
  height: 100%;
  .card-li {
    padding-left: 1.3rem;
    height: 100%;
  }
`;

export default CardListing;
