/** @jsx jsx */

import { useContext } from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { ReactFacebookLoginInfo as FBLoginInfo } from 'react-facebook-login';

import {  FacebookButton, BlockButton } from 'components';
import { AppContext } from 'context/AppProvider';
import style from 'styles/Home';

const JoinNow = () => {
  const {
    actions
  } = useContext(AppContext);

  const onFacebookLogin = ({ email, id, accessToken }: FBLoginInfo) => {
    actions.fbLogin({ email, social_provider_user_id: id, access_token: accessToken });
  };

  return (
    <div css={style} className="join-now">
      <h1 className="hidden-sm hidden-xs">Police Auctions</h1>
      <hr/>
      <Link to="/register">
        <BlockButton color="success" className="btn btn-success btn-lg btn-block">JOIN NOW FREE</BlockButton>
      </Link>
      <FacebookButton onFBCallback={onFacebookLogin} value="Join With Facebook" />
    </div>
  );
}

export default JoinNow;