type TagManager = {
  eventID: number;
  id: number | string;
  productCode: string;
  productCategory: string;
  productName: string;
  amount: number;
  tax: number;
  shipping: number;
  total: number;
}

const conversion = ({id, eventID, productCode, productCategory, productName, amount, tax, shipping, total}: TagManager) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({
    eventID,
    event: 'purchase',
    transactionId: id,
    transactionAffiliation: 'policeauctions.com',
    transactionTotal: total,
    transactionTax: tax,
    transactionShipping: shipping,
    transactionProducts: [
      {
        sku: productCode,
        name: productName,
        category: productCategory,
        price: amount,
        quantity: 1
      }
    ]
  });
};

export default {
  conversion
};