/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import style from 'styles/Support';
import SupportForm from './partials/SupportForm';
import SupportHints from './partials/SupportHints';
import { SaveButton } from 'components';
import { useControlFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';

const Support: React.FC = () => {
  document.title = 'Police Auctions - Support';
  const [category, setCategory] = useState("");

  const [{ data: response }, fetchAnswers] = useControlFetch('/knowledgebase', reducer, { category });

  const hints = response && response.data && response.data[0] && response.data[0].knowledge_base
    ? <SupportHints knowledge_base={ response.data[0].knowledge_base } />
    : null;

  const changeHandler = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(value);
    fetchAnswers(true);
  }

  return (
    <Row css={ style }>
      <Col lg={ 8 } sm={ 12 }>
        <h1 className="header">Police Auctions Customer Support</h1>
        <p>Have you had a chance to take a look at our <Link to="/knowledgebase">knowledge base?</Link></p>
        <p>Our Customer Support lines are open Monday - Friday from 8AM - 4PM PST, excluding Holidays and Weekends.</p>
        <p>If you want to return an item, click the link below.</p>
        <Link to="/support/return">
          <SaveButton size="lg">Return an Item</SaveButton>
        </Link>
        <hr/>
        <h4>What do you need help with?</h4>
        <SupportForm changeHandler={ changeHandler } />
      </Col>
      <Col lg={ 4 } sm={ 12 }>
        { hints }
      </Col>
    </Row>
  )
}

export default Support;