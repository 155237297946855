import React, { useState, useEffect } from 'react';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';

import { Props } from 'types/pages/Auction_View/partials/bidHistoryList';

const BidHistoryList: React.FC<Props> = ({ bidUsers }) => {
  const [count, setCount] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if(count > 0 && bidUsers.length > 0 && price != parseInt(bidUsers[0].amount)) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 500);
    }
    setCount(prevCount => prevCount + 1);
    if(bidUsers.length > 0) setPrice(parseInt(bidUsers[0].amount));
  }, [bidUsers]);

  return (
    <ListGroup className="bid-history">
      {bidUsers
        ? bidUsers.map((bidUser, index) => (
          <ListGroupItem className={`bid-history-user animator a-${index} ${(animate ? "animate" : "")}`} key={bidUser.id}>
              {/** Assumes that "bidUser.username" is from stream. May need to be refactored*/}
              {bidUser.username ? bidUser.username : bidUser.user.username}{' '}
              <Badge>${parseInt(bidUser.amount).toFixed(2)} USD</Badge>{' '}
            </ListGroupItem>
          ))
        : null}
    </ListGroup>
  );
}

export default BidHistoryList;
