import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import { TwoRowColumn, BlockButton } from 'components';

const ProductDate: React.FC = () => (
  <Col lg="5" xs="12">
    <TwoRowColumn title="Won On">
      <span>2019-11-10</span>
    </TwoRowColumn>

    <BlockButton color="green" className="mt-5">
      <Link to="/payment/methods/${item.id}" className="link-button">
        <p>Pay for this item</p>
      </Link>
    </BlockButton>
  </Col>
);

export default ProductDate;