/** @jsx jsx */

import { jsx, css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import Badge from 'reactstrap/lib/Badge';

const EndingSoonBadge = withTheme(() => (
  <p css={style2}>
    <Badge color="warning ending-badge">Ending Soon</Badge>
  </p>
));

const style2 = ({ colors }) => css`
  margin: 0;
  .ending-badge {
    color: #ffffff;
    background-color: ${colors.yellow};
    font-weight: 500;
    padding: 0.2rem 0.6rem 0.3rem;
    text-transform: uppercase;
  }
`;

export default EndingSoonBadge;