/** @jsx jsx */
import { css } from '@emotion/core';

export default css`
  .row-image {
    max-height: 15rem;
  }
  .link-button {
    color: #ffffff;
    &:hover {
      text-decoration: none;
    }
  }
`;
