/**@jsx jsx */

import { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import { Navbar, Nav, Container, Collapse, NavbarToggler, UncontrolledDropdown, DropdownToggle } from 'reactstrap';

import { GovernmentDropdown, CategoryType } from './DropdownItems';
import { DropdownHotNavItems } from './DropdownHotNavItems';
import LinkItem from './LinkItem';

type Props = {
  categoryListData: CategoryType[]
}

const initialState = {
  categories: false,
  gov: false
};

const GuestHeader: React.FC<Props> = ({ categoryListData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(initialState);
  const onMenuOpen = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  const dropdownClose = (evt: React.MouseEvent<HTMLSelectElement>) => {
    const dropdownDataset = evt.currentTarget.dataset.dropdown as string;

    //Set the other dropdowns to false, except the current dropdown
    const updateState = Object.keys(dropdownOpen)
      .filter(item => item !== dropdownDataset)
      .reduce((acc, curr) => ({ ...acc, [curr]: false }), {});

    setDropdownOpen(prevState => ({ ...prevState, ...updateState, [dropdownDataset]: !prevState[dropdownDataset] }));
  };

  //Should scroll to top of header when the categories dropdown is set to false
  useEffect(() => {
    if(!dropdownOpen.categories) {
      window.scrollTo(0, 0);
    }
  }, [dropdownOpen.categories]);

  return (
    <header css={style}>
      <Navbar expand="md" light={true}>
        <Container fluid={true}>
          <a href="/">
            <img
              style={{ width: '200px', height: '50px' }}
              src="https://cdn.policeauctions.com/images/policeauction12-half.png"
              alt="police_auction"
            />
          </a>
          <NavbarToggler onClick={onMenuOpen} />
          <Collapse isOpen={isOpen} navbar={true}>
            <Nav className="nav-items ml-auto" navbar={true}>
              <LinkItem href="/login" name="Login" onClick={closeMenu} />
              <LinkItem href="/register" name="Register" onClick={closeMenu} />
              <UncontrolledDropdown
                nav={true}
                inNavbar={true}
                onClick={dropdownClose}
                isOpen={dropdownOpen.categories}
                data-dropdown="categories"
              >
                <DropdownToggle nav caret className="link">
                  Categories
                </DropdownToggle>
                <DropdownHotNavItems onClick={closeMenu} categoryListData={categoryListData}/>
              </UncontrolledDropdown>
              <UncontrolledDropdown
                nav={true}
                inNavbar={true}
                onClick={dropdownClose}
                isOpen={dropdownOpen.gov}
                data-dropdown="gov"
              >
                <DropdownToggle nav caret className="link">
                  Gov Listings
                </DropdownToggle>
                <GovernmentDropdown onClick={closeMenu} />
              </UncontrolledDropdown>
              <LinkItem href="/dailydeals" name="Daily Deals" onClick={closeMenu} />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
export default withTheme(GuestHeader);

const style = ({ colors, gradients }) => css`
  background-color: ${colors.grayLightest};
  border-color: ${colors.grayLight};
  background-image: ${gradients.gray};

  .link {
    font-size: 1.4rem;
    color: ${colors.gray};
    font-weight: 300;
  }

  .link:hover {
    color: ${colors.blueDarkest};
    transition: color ease-in-out 0.2s;
    text-decoration: none;
  }

  .nav-item {
    padding: 0 1.1rem;
  }

  .dropdownmenu-wrapper {
    width: 21.46rem;
    max-height: 50.6rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    overflow: scroll;
    font-size: 1.4rem;
    z-index: 94035;
    max-height: none;
  }

  .dropdownmenu-gov {
    width: 17.4rem;
    max-height: 50.6rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    overflow: scroll;
    font-size: 1.4rem;
  }

  .gov-link {
    text-decoration: none;
  }

  .countries-wrapper {
    max-width: 2rem;
  }

  .hot-item-i {
    color: ${colors.red};
  }

  .dropdownmenu-item {
    font-size: 1.4rem;
  }

  .dropdown-link:hover {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .dropdownmenu-wrapper {
      color: #777777;
      box-shadow: none;
      width: 100%;
    }

    .dropdown-item {
      color: #777777;
      font-size: 2rem;
    }

    .countries-wrapper {
      width: 100%;
      max-width: unset;
    }

    .nav-item {
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      overflow: ;
    }
  }
`;
