/** @jsx jsx */

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { Form } from 'formik';
import { jsx, css } from '@emotion/core';

import { BlockButton, AlertMessage, RadioGroup, PayPalExpress } from 'components';
import { styles } from 'styles/Payment';

const MethodForm = ({ values, handleChange }) => (
  <Form css={styles}>
    <AlertMessage color="warning" show={values.method === 'check'}>
      <p>
        Minimum 14 day hold on all checks. No Checks outside the United States will be accepted. Bidding will be
        suspended until the check is cleared.
      </p>
    </AlertMessage>
    <RadioGroup label="PayPal" name="method" value="paypal" onChange={handleChange} checked={values.method == 'paypal'}>
      <img src="https://cdn.policeauctions.com/images/paypal.png" alt="" />
    </RadioGroup>
    <RadioGroup
      label="Credit Card"
      name="method"
      value="credit-card"
      onChange={handleChange}
      checked={values.method == 'credit-card'}
    >
      <img src="https://cdn.policeauctions.com/images/credit-card.gif" alt="" />
    </RadioGroup>
    <RadioGroup label="Check" name="method" value="check" onChange={handleChange} checked={values.method == 'check'}>
      <img src="https://cdn.policeauctions.com/images/check.png" alt="" />
    </RadioGroup>
    {values.method !== 'paypal' ? (
      <BlockButton type="submit" color="green">
        Continue
      </BlockButton>
    ) : (
      <div>
        <PayPalExpress auction={values.auction} />
      </div>
    )}
  </Form>
);

export default MethodForm;
