import React from 'react';

import Card from 'components/card/Card';
import CardList from 'components/card/CardList';

type Props = {
  isPaidUser: boolean
};

export const HotSpot: React.FC<Props> = ({isPaidUser}) => {
  const handleUrl = () => {
    if (isPaidUser) {
      window.open("https://www.yachtauctions.com/", "_blank");
      return;
    }
    window.location.href = "/upgrade";
  }

  return (
    <Card headerTitle="PoliceAuctions Hotspots" >
      <CardList name="Government Auctions" url="/externalauction" />
      <CardList
        name="$1 Auctions"
        url={{ pathname: '/auction/featured', state: { name: 'Featured', params: { featured: 1 } } }}
      />
      <CardList name="Daily Deals" url="/dailydeals" />
      <CardList name="Car Auctions" url="/carauction" />
      <li className="list-unstyled">
        <a href="" onClick={() => handleUrl()} className="list-a" rel="noopener noreferrer">
          Seized Boats
        </a>
      </li>
      <CardList name="Unclaimed Money" url="/unclaimed" />
    </Card>
  )
};

export default HotSpot;