import { css } from '@emotion/core';

export default css`
  padding-top: 1rem;

  .badge {
    font-size: 100%;
    font-weight: 350;
  }

  .support-message {
    width: 100%;
    margin-bottom: 1rem;
  }

  .box {
    padding: 1.5rem;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
  }

  .left {
    margin-right: 5rem;
  }

  .right {
    margin-left: 5rem;
  }

  .header {
    color: #337ab7;
    font-size: 1.7rem;
  }

  textarea.user-input {
    height: 10rem;
  }
`;