/** @jsx jsx */

import { ButtonHTMLAttributes } from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { css, jsx } from '@emotion/core';

type Events = {
  onSubmit?: (event: React.FormEvent) => void;
};

type ButtonType = ButtonHTMLAttributes<Events>;

type Props = ButtonProps<ButtonType>;

/**
 * CancelButton component is used to reset the form to it's initial state
 */
const CancelButton: React.FC<Props> = ({ children, ...rest }) => (
  <Button {...rest} css={styles}>
    {children}
  </Button>
);

const styles = ({ colors }) => css`
  background-color: transparent;
  border-color: ${colors.grayLight};
  padding: 0.6rem 1.2rem;
  color: #000000;
  margin-left: 1rem;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  font-weight: lighter;

  :hover {
    background-color: ${colors.grayLight};
    color: #ffffff;
    border-color: ${colors.grayLight};
  }
`;

export default CancelButton;
