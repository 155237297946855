/** @jsx jsx */

import { jsx, css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import Badge from 'reactstrap/lib/Badge';

const SoldBadge = withTheme(() => (
  <p css={style}>
    <Badge className="sold-badge">Sold</Badge>
  </p>
));

const style = ({ colors }) => css`
  margin: 0;
  .sold-badge {
    background-color: ${colors.tomato};
    font-weight: 500;
    padding: 0.2rem 0.6rem 0.3rem;
    text-transform: uppercase;
  }
`;

export default SoldBadge;
