/** @jsx jsx */
import { Alert, AlertProps } from 'reactstrap';
import { css, jsx } from '@emotion/core';

type BidStatus = {
  statusAlertColor?: string;
  statusAlertMessage?: string;
};

type Props = AlertProps<BidStatus>;

const BidStatusAlert: React.FC<Props> = ({
  statusAlertColor,
  statusAlertMessage,
  isOpen = false
}) => (
  <Alert css={style} color={statusAlertColor} isOpen={isOpen}>
    <p>{statusAlertMessage}</p>
  </Alert>
);

export default BidStatusAlert;

const style = css`
  padding: 0.4rem;
  margin-bottom: 2rem;
  text-align: center;
`;
