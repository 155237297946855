/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import * as H from 'history';

type Props = {
  name: string;
  isFire?: boolean;
  url: H.LocationDescriptor;
};

const FireItem: React.FC<Omit<Props, 'url'>> = ({ name }) => (
  <React.Fragment>
    <strong>{name}</strong>
    <i className="fa fa-fire hot-item-i" />
  </React.Fragment>
);

const CardList: React.FC<Props> = ({ name, url, isFire }) => (
  <li className="list-unstyled" css={style}>
    <Link to={url} className="list-a">
      {isFire ? <FireItem name={name} /> : name}
    </Link>
  </li>
);

const style = css`
  font-size: 1.4rem;

  .list-a {
    color: #000000;
  }

  .hot-item-i {
    color: #ff0000;
    margin-left: 0.5rem;
  }
`;

export default CardList;
