/** @jsx jsx */

import React from 'react';
import { Alert } from 'reactstrap';
import { jsx } from '@emotion/core';

import { BlockButton, LoadingButton } from 'components';

const OfferStart = ({ onStartClick, loading, errorMessage }) => {

  const button = loading
    ? <LoadingButton>Loading...</LoadingButton>
    : <BlockButton onClick={onStartClick} disabled={loading}>
        Start Negotiations
      </BlockButton>

  const message = errorMessage
    ? <Alert color="danger">
        <p>{ errorMessage }</p>
      </Alert>
    : <Alert color="info">
        <p>Click the button to make an offer on this item.</p>
      </Alert>

  return (
    <React.Fragment>
      {message}
      {button}
    </React.Fragment>
  )
};

export default OfferStart;
