import { pipe } from 'lodash/fp';

const matchUrlParams = (url: string) => url.match(/\/auctions\/\d+/);

const mkUrlToString = (matchedUrl: Pick<string, 'match'>) => (matchedUrl ? matchedUrl.toString() : null);

const getProductUrl = pipe(
  matchUrlParams,
  mkUrlToString
);

export default getProductUrl;
