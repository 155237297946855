import React from 'react';

import { SupportMessageProps } from 'types/pages/SupportMessages/messages';

const SupportMessage: React.FC<SupportMessageProps> = ({ message }) => {
  const { message: response, from_employee, create_time } = message;
  const from = from_employee ? 'Our response' : 'Your message';
  const side = from_employee ? 'right' : 'left';
  return (
    <div className="support-message">
      <div className={`${ side } box`}>
        <div className="header">{`${ from } from ${ create_time }`}</div>
        <p>{ response }</p>
      </div>
    </div>
  );
};

export default SupportMessage;