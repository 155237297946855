/** @jsx jsx */

import { jsx } from '@emotion/core';
import { Row, Col } from 'reactstrap';
import BottomTable from './BottomTable';

const Header = ({ data }) =>
  <Row>
    <Col>
      <BottomTable data={ data } />
    </Col>
  </Row>

export default Header;