import { css } from '@emotion/core';

export default css`

  .form-inline {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .form-group {
    width: 80%;
  }

  .form-control {
    width: 100%;
  }

  .submit-btn {
    height: 3rem;
    width: 20%;
    font-size: 1.2rem;
  }

  .table {
    font-size: 1.55rem;
  }

  .note {
    font-size: 1.25rem;
  }
`;
