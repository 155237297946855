import React, { useEffect } from 'react';

export const TwitterTimeline: React.FC = () => {

  useEffect(() => {
      if(window && (window as any).twttr){
        (window as any).twttr.widgets.load();
      }
  }, []);

  return (
    <div className="twitter-timeline">
      <a
        className="twitter-timeline"
        href="https://twitter.com/1PoliceAuctions"
        data-widget-id="675387865933615104">Tweets by @1PoliceAuctions</a>
    </div>
  )
};

export default TwitterTimeline;