// These polyfills need to be first
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'utils/polyfills';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from 'emotion-theming';

import history from './utils/history';
import theme from './constants/emotionConfig';
import './styles/global.css';
import AppProvider from './context/AppProvider';
import SocketProvider from './context/SocketProvider';

const RootApp: React.FC = () => (
  <AppProvider>
    <SocketProvider>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </SocketProvider>
  </AppProvider>
);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<RootApp />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
