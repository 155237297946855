/** @jsx jsx */

import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';

type Props = {
  slot: string;
  client: string;
  type: string;
};

const AdsenseAd: React.FC<Props> = ({ client, slot, type }) =>{

  useEffect(() => {
    if(window && (window as any).adsbygoogle){
      ((window as any).adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div css={style}>
      <ins
          className={`adsbygoogle ${type}`}
          data-ad-client={client}
          data-ad-slot={slot}
        >
      </ins>
    </div>

  )
};

export default AdsenseAd

const style = css`

  padding: 1px 0px;

  .banner {
    display: inline-block;
    width: 728px;
    height: 90px;
  }

  .mobile {
    display:block;
    height: 300px;
    width: 300px;
  }

  .rectangle-lg {
    display:inline-block;
    width:336px;
    height:280px;
  }

  .skyscraper-lg {
    display:inline-block;
    width:300px;
    height:600px;
  }
`;
