// dependencies
import React, { useRef, useState, useEffect } from "react";

const InlineShareButtons = props => {
  const reference = useRef();
  const [element, setElement] = useState(reference);

  const _updateRef = ref => {
    element.current = ref;
    setElement(element);
  };

  /**
   * ShareThis cuts off the last item in the networks list by setting the last button's display to 'none'
   * This is a fix to change the last button's display to 'inline-block' instead of 'none'
   * 
   * @param lastButton 
   */
  const handleLastButton = lastButton => {
    if (lastButton.style.display === "none") {
      lastButton.style.display = "inline-block";
    }
  };

  // load project wrapper
  const load = (element, product) => {
    // load config
    let config = props.config || { enabled: true };
    config = JSON.parse(JSON.stringify(config));

    // load buttons
    const _onShareThisLoaded = (window as any).onShareThisLoaded;
    let onload = () => {
      let complete = false;
      if (!complete) {
        if (!config.id) {
          const id = "sharethis-" + Date.now();
          config.id = id;
        }
        if (element.current) {
          element.current.id = config.id;
          (window as any).__sharethis__.load(product, config);
          handleLastButton(document.getElementsByClassName("st-last")[0]);
        }
        if (_onShareThisLoaded) {
          _onShareThisLoaded();
        }
        complete = true;
      }
    };
    (window as any).onShareThisLoaded = onload;

    // load sharethis.js
    if (document.getElementById("sharethis-js")) {
      if ((window as any).__sharethis__) {
        (window as any).onShareThisLoaded();
      }
    } else {
      const script = document.createElement("script");
      script.setAttribute("id", "sharethis-js");
      const params = {
        property: config.property || "",
        product: product,
        source: "reactjs"
      };
      const query = Object.keys(params)
        .map(key => key + "=" + params[key])
        .join("&");
      script.src =
        "https://platform-api.sharethis.com/js/sharethis.js?" + query;
      script.async = true;
      document.body.appendChild(script);
    }
  };
  useEffect(() => {
    load(element, "inline-share-buttons");
  }, []);

  return (
    <React.Fragment>
      <div ref={_updateRef}></div>
    </React.Fragment>
  );
};

export default InlineShareButtons;
