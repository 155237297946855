/** @jsx jsx */
import { Col } from 'reactstrap';
import { jsx } from '@emotion/core';

import { PageTitleProps } from 'types/pages/Daily_Deals_View/dailyDealsView';

const PageTitle: React.FC<PageTitleProps> = ({ title }) => (
  <Col>
    <h3 className="text-left product-title">{title}</h3>
  </Col>
)

export default PageTitle;
