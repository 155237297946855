import React from 'react';
import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

type OnClick = (e: React.MouseEvent<HTMLAnchorElement>) => void;

type DropdownLink = {
  to: string;
  name: string;
  onClick: OnClick;
  requiredAuth?: boolean;
};


const InternalLink: React.FC<DropdownLink> = ({ to, name, onClick }) => (
  <Link to={to} className="gov-link" onClick={onClick}>
    <DropdownItem>{name}</DropdownItem>
  </Link>
);

export default InternalLink;
