/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import {
  Form,
  Input,
  Button
} from 'reactstrap';

type EventTarget = {
  target: {
    value: string;
    name: string;
  };
};

type Props = {
  handleZipChange: (event: EventTarget) => void;
  handleTypeChange: (event: EventTarget) => void;
  onSubmit: (event: React.FormEvent) => void;
  disabled: boolean;
  isAutomotive: boolean;
};


const SearchForm: React.FC<Props> = ( props ) => {
  const {
    onSubmit,
    handleZipChange,
    handleTypeChange,
    disabled,
    isAutomotive
  } = props;

  return (
    <Form
      inline
      onSubmit={ onSubmit }
      className="search-form"
    >
      <Input
        type="text"
        placeholder="Zip Code"
        bsSize="lg"
        name="zip"
        onChange={ handleZipChange }
      />

      <Input
        type="select"
        bsSize="lg"
        name="type"
        hidden={ isAutomotive }
        onChange={ handleTypeChange }
      >
        <option value="">All Types</option>
        <option value="13">Antiques</option>
        <option value="15">Art</option>
        <option value="1">Automotive</option>
        <option value="11">Aviation</option>
        <option value="5">Boats</option>
        <option value="21">Books</option>
        <option value="32">Building Materials</option>
        <option value="12">Clothing</option>
        <option value="18">Coins</option>
        <option value="14">Collectibles</option>
        <option value="22">Comics</option>
        <option value="9">Computers</option>
        <option value="10">Consumer Electronics</option>
        <option value="2">Domain Names</option>
        <option value="16">Farming</option>
        <option value="31">Firearms</option>
        <option value="6">Furniture</option>
        <option value="23">Games</option>
        <option value="17">Glassware</option>
        <option value="4">Heavy Equipment</option>
        <option value="7">Jewelry</option>
        <option value="24">Memorabilia</option>
        <option value="25">Military</option>
        <option value="26">Music</option>
        <option value="27">Pottery</option>
        <option value="3">Real Estate</option>
        <option value="20">Rugs</option>
        <option value="19">Sporting Equipment</option>
        <option value="8">Tools</option>
        <option value="28">Toys</option>
        <option value="29">Trading Cards</option>
        <option value="30">Wine and Cigar</option>
      </Input>

      <Button
        color="primary"
        disabled={ disabled }
        className="btn btn-primary btn-lg search-btn"
      >
        <i className="fa fa-search fa-search-icon" /> Search
      </Button>
    </Form>
  )
}

export default SearchForm;