import React from 'react';

const RMAInstructions: React.FC<{ rma_number: number }> = ({ rma_number }) => (
  <React.Fragment>
    <p>
      1. In order to refund / replace this item. You will need to ship the item back to the company for processing. See
      Address Below.
    </p>
    <br />
    <p>PoliceAuctions.com</p>
    <p>3182 Pullman St</p>
    <p>Costa Mesa, CA 92626</p>
    <br />
    <p>2. Must include the RMA # {rma_number} inside and/or on the package</p>
    <p>3. Upon receiving the item, we will process your replacement or refund.</p>
    <br />
  </React.Fragment>
);

export default RMAInstructions;
