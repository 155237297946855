import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Col } from "reactstrap";
import { get } from "lodash";

import { TwoRowColumn } from "components";
import ReceiptModal from "./ReceiptModal";
import { itemStatus } from "constants/auction";
import { ItemProps } from "types/pages/My_Wins/myWins";
import history from "utils/history";
import toUrl from 'utils/toUrlFromProductName';

const MiddleDetails = ({ item }: ItemProps) => {
  const [showReceipt, setShowReceipt] = useState(false);
  const _showReceipt = () => {
    // Toggle the boolean value for showing or hiding the modal
    setShowReceipt(!showReceipt);
  };

  const _navigateToSupport = () => {
    history.push(`/support/return/${item.id}`);
  }

  const _navigateToAuction = () => {
    history.push(`/auctions/${item.id}/${toUrl(item)}`)
  }

  // Check the receipt line can be shown if the item is in a "Paid" status
  const receiptStatuses = [itemStatus.UNCLAIMED, itemStatus.PAYMENT_PENDING];
  const receiptLink = !receiptStatuses.includes(item.item_status_id) ? (
    <TwoRowColumn title="Receipt">
      <a href="js:void();" onClick={_showReceipt} title={`Show receipt for Auction #${item.id}`}>
        View Receipt
      </a>
    </TwoRowColumn>
  ) : null;

  const returnButton = (
    <TwoRowColumn title="Return">
      <a href="js:void();" title={`Return Auction #${item.id}`} onClick={_navigateToSupport}>
        Return this item
      </a>
    </TwoRowColumn>
  );

  // Show the RMA rows if one exists, otherwise show the return link
  const paidStatuses = [itemStatus.CLAIMED, itemStatus.SHIPPED, itemStatus.COMPLETE];
  const rma = get(item, "rmas[0]");
  const rmaRows = rma && rma.rma_status && rma.rma_status.status !== 'Dump' ? (
    <React.Fragment>
      <TwoRowColumn title="RMA #">
        <span>{rma.id}</span>
      </TwoRowColumn>
      <TwoRowColumn title="RMA Received On">
        <span>{get(rma, "received_date", "Not Received")}</span>
      </TwoRowColumn>
      <TwoRowColumn title="Refund">
        <span>{rma.rma_status.status}</span>
      </TwoRowColumn>
    </React.Fragment>
  ) : ( paidStatuses.includes(item.item_status_id) ? returnButton : null);

  const receiptModal = item.paid_transaction_details ? (
    <ReceiptModal
      id={item.id}
      itemName={get(item, 'control.product_description.name', '')}
      category={get(item, 'control.product_description.category.name')}
      transactionDetails={item.paid_transaction_details}
      toggleReceipt={_showReceipt}
      showReceipt={showReceipt}
    />
  ) : null;

  const showItemStatus = item.item_status.item_status;
  const isUnclaimed = showItemStatus === 'Unclaimed';
  const status = showItemStatus === 'Claimed' ? 'Paid -  Pending Shipment' : showItemStatus

  return (
    <React.Fragment>
      <Col lg="5" xs="12">
        <TwoRowColumn title="Auction #">
          <a href="js:void();" title={`Auction #${item.id}`} onClick={_navigateToAuction}>
            {item.id}
          </a>
        </TwoRowColumn>
        <TwoRowColumn title="Your Bid">
          <span>${item.sale_price}</span>
        </TwoRowColumn>
        {receiptLink}
        <TwoRowColumn title="Status">
          {isUnclaimed ? <Link to={`/payment/methods/${item.id}`}>Payment Due</Link> : <span>{status}</span>}
        </TwoRowColumn>
        {rmaRows}
      </Col>
      {receiptModal}
    </React.Fragment>
  );
};

export default MiddleDetails;