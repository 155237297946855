/** @jsx jsx */

import React from 'react';
import { Alert } from 'reactstrap';
import { css, jsx } from '@emotion/core';

type Props = {
  show: boolean;
  color: string;
  className?: string;
};

const AlertMessage: React.FC<Props> = ({ color, show, className, children }) => {
  return (
    <Alert
      color={ color }
      css={ style }
      className={ className }
      isOpen={ (show) } >
        <strong>{ children }</strong>
    </Alert>
  )
};

export default AlertMessage;

const style = css`
  min-height: 4.5rem;
  padding: 1.5rem;
  font-size: 1.2rem;
`;