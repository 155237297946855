/** @jsx jsx */

import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { jsx } from '@emotion/core';
import { FormikActions } from 'formik';

import { Layout, Input, BlockButton, ErrorMessage } from 'components';
import { usePost } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { AppContext } from 'context/AppProvider';
import { RouteComponentProps } from 'react-router';
import { FormFields } from 'types/pages/Forgot_Password_Reset/forgot';
import styles from 'styles/ForgotPassword';
import useRedirect from 'hooks/redirect';

const validation = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  repeat_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirm Password does not match')
    .required('Password confirmation is required')
});

const ForgotPasswordReset: React.FC<RouteComponentProps<{ id: string }>> = ({ match: { params } }) => {
  document.title = 'Police Auctions - Password Reset';
  const token = params.id;
  const [{ data, errors, isLoading }, setSubmit] = usePost('/password/reset', reducer);

  const { dispatch } = useContext(AppContext);

  const onSubmit = (values: FormFields, actions: FormikActions<FormFields>) => {
    setSubmit({ ...values, token });
  };

  useRedirect(dispatch, data, '/forgot/complete');

  return (
    <Layout css={styles}>
      <h2>Reset Your Password</h2>
      <p>Enter your new password that you want to use on policeauctions.com.</p>
      <ErrorMessage item={errors} />
      <Formik
        initialValues={{ password: '', repeat_password: '' }}
        validationSchema={validation}
        onSubmit={onSubmit}
        render={({ handleChange, errors, touched }) => (
          <Form>
            <Input
              label="New Password"
              placeholder="Your Password"
              name="password"
              type="password"
              onChange={handleChange}
              isError={touched.password && errors.password}
              errorMessage={errors.password}
              invalid={errors.password ? true : false}
            />
            <Input
              label="Confirm Password"
              placeholder="Repeat Password"
              name="repeat_password"
              type="password"
              onChange={handleChange}
              isError={touched.repeat_password && errors.repeat_password}
              errorMessage={errors.repeat_password}
              invalid={errors.repeat_password ? true : false}
            />
            <BlockButton value="Submit" type="submit" disabled={ isLoading }>
              Change Password
            </BlockButton>
          </Form>
        )}
      />
    </Layout>
  );
};

export default ForgotPasswordReset;
