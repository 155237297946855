import React, { useState } from 'react';
import { reducer } from 'reducers/asyncGlobal';
import { usePost } from 'hooks/async';
import SaveButton from 'components/form/SaveButton';

type Props = {
  id: number;
  hasTestimonial: boolean;
};

const Testimonial: React.FC<Props> = ({ id, hasTestimonial }) => {
  const [testimonial, setTestimonial] = useState({ submitted: false, text: "" });
  const [data, setData] = usePost("/testimonials", reducer);

  const handleSubmit = () => {
    // Guard to not submit anything if it is empty
    if (testimonial.text === "") {
      return;
    }
    setData({ schedule_id: id, review: testimonial.text, });
    setTestimonial({ ...testimonial, submitted: true });
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTestimonial({ ...testimonial, text: event.currentTarget.value });
  };

  return !hasTestimonial && !testimonial.submitted ? (
    <React.Fragment>
      <div className="form-group">
        <textarea
          name="text"
          id="exampleText"
          className="form-control"
          rows={2}
          placeholder="Tell us about your experience"
          onChange={handleOnChange}
        ></textarea>
      </div>
      <div className="form-group">
        <SaveButton onClick={handleSubmit}>Submit Testimonial</SaveButton>
      </div>
    </React.Fragment>
  ) : null;
};

export default Testimonial;
