/** @jsx jsx */

import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import { jsx } from '@emotion/core';
import { RouteComponentProps } from 'react-router';
import { get } from 'lodash';

import style from 'styles/AuctionListing';
import { DropdownSort, CardAuctionList, Paginator } from 'components';
import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import useTimeStamp from 'hooks/timeStamp';
import { AppContext } from 'context/AppProvider';
import { createLocation, locationContext, featuredLocation } from 'utils/createUrlLocation';
import useBidStream from 'hooks/bidStream';

const paramsState = {
  page: 1,
  order: 'asc'
};

const AuctionTitle: React.FC<{ name: string }> = ({ name }) => (
  <React.Fragment>
    <h1 className="h1-title">
      {name === 'Featured' ? <i className="fas fa-star" /> : null}
      {name}
    </h1>
  </React.Fragment>
);

const AuctionListing = ({ location, history }: RouteComponentProps) => {
  document.title = 'Police Auctions - Auction Listing';
  const {
    state: { user, preloadData }
  } = useContext(AppContext);
  const newLocation =
    location.pathname === '/auction/featured'
      ? featuredLocation()
      : createLocation(location, locationContext, preloadData);
  const [urlParams, setUrlParams] = useState<any>({ ...paramsState, ...newLocation.state.params });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [timeStamp] = useTimeStamp();
  const [{ data, isLoading }, setReload] = useFetch('auctions', reducer, {
    ...urlParams,
    ...queryString.parse(location.search)
  });
  const auctionData = get(data, 'data');
  const bidStream = useBidStream(auctionData, user);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    //Will reset urlParams to initial state when toggling to a different category
    if (location.state) {
      setUrlParams({ ...paramsState, ...newLocation.state.params });
      setReload(true);
    }
    return () => {
      if (history.action === 'POP') {
        setUrlParams({ ...urlParams.page, ...queryString.parse(location.search) });
      }
    };
  }, [location]);

  useEffect(() => {
    if (location.search) {
      setReload(true);
    }
  }, [location.search]);

  useEffect(() => {
    const toQueryStr = queryString.stringify(urlParams);

    if (isUpdated) {
      history.push({
        search: `?${new URLSearchParams(toQueryStr)}`
      });
      setIsUpdated(false);
    }
  }, [urlParams]);

  useEffect(() => {
    if (history.action === 'POP') {
      setIsUpdated(false);
    }
  }, [history.action]);

  const onDropdownClick = event => {
    setUrlParams(prevState => ({ ...prevState, page: 1, ...queryString.parse(event.target.value) }));
    setIsUpdated(true);
  };

  const onDropdownToggle = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const onPageClick = (page: number) => {
    setUrlParams({ ...urlParams, page });
    setIsUpdated(true);
  };

  return (
    <div css={style}>
      <div className="title-container">
        {newLocation.state ? <AuctionTitle name={newLocation.state.name} /> : null}
        <DropdownSort isOpen={dropdownOpen} onClick={onDropdownClick} toggle={onDropdownToggle} />
      </div>
      <hr className="border-line" />
      <CardAuctionList data={bidStream} serverTime={timeStamp} />
      <div className="paginator-container">
        {data ? <Paginator isLoading={isLoading} pages={data} onClick={onPageClick} /> : null}
      </div>
    </div>
  );
};

export default AuctionListing;
