/** @jsx jsx */

import React, { useEffect, useContext } from 'react';
import { jsx } from '@emotion/core';
import { Row, Col } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import parse from 'html-react-parser';

import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import { Loading, CardAuction } from 'components';
import { NoticeProps } from 'types/pages/Notices/notices';
import style from 'styles/Notices';
import filterPrimaryType from 'utils/filterImageType';
import useTimeStamp from 'hooks/timeStamp';
import { fetchUser, AppContext } from 'context/AppProvider';
import toUrl from 'utils/toUrlFromProductName';

const Notice: React.FC<RouteComponentProps<NoticeProps>> = ({ match: { params } }) => {
  document.title = 'Police Auctions - Notice';
  const { dispatch } = useContext(AppContext);
  const [{ data: response }] = useFetch(`/announcements/${params.id}`, reducer);
  const [timeStamp] = useTimeStamp();

  useEffect(() => {
    fetchUser(dispatch);
  }, [response])

  const title = response ? response.announcement.title : '';
  const expireTime = response ? response.announcement.expire_time : null;
  const schedule = response && response.schedule ? response.schedule : null;

  /**
   * TODO: when the timer and links gets finished we need to circle back here.
   *
   * Updated timer prop
   */

  const auction = schedule ? (
    <CardAuction
      headerTitle={schedule.control.product_description.short_name}
      price={schedule.sale_price}
      timerData={{ endTime: expireTime, serverTime: timeStamp }}
      image={filterPrimaryType(schedule.control.product_description.images).url}
      url={`/auctions/${schedule.id}/${toUrl(schedule)}`}
      bdp={schedule.bdp}
      auctionId={schedule.id}
      showBdp={parseInt(schedule.opening_bid) <= 1 && parseInt(schedule.bdp) > 0}
    />
  ) : null;

  return (
    <div css={style}>
      <h1 className="title">Notice { title ? ` - ${title}` : '' }</h1>
      <hr/>
      <Row>
        <Col md="1" sm="12">
          <img src="/support.jpg" className="rounded-circle" alt="support staff"/>
        </Col>
        <Col md="8" sm="12">
          <div className="message">
            { response ? parse(response.announcement.message) : <Loading /> }
          </div>
        </Col>
      </Row>
      <Row>
        { auction }
      </Row>
    </div>
  );
};

export default Notice;
