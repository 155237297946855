/** @jsx jsx */

import React from 'react';
import { FormGroup, Label, Input, InputProps } from 'reactstrap';
import { css, jsx } from '@emotion/core';

type Props = InputProps & {
  label: string;
  check?: boolean;
};

/**
 * TODO: Put this component inside of a partials folder inside of the
 * Notifications directory.
 */
const RadioGroup: React.FC<Props> = ({
  label,
  name,
  check,
  children,
  ...rest
}) => (
  <FormGroup className="form-group" inline={true} css={styles} check={check}>
    <Label className="radio-label">
      <Input type="radio" className="radio-input" name={name} {...rest} />
      <span>{label}</span>
      {children}
    </Label>
  </FormGroup>
);

const styles = css`
  .form-group {
    margin-right: 1rem;
  }
`;

export default RadioGroup