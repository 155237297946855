import React, { useEffect, useRef, useState, useLayoutEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import BraintreeWebDropIn from 'braintree-web-drop-in';
import { get } from 'lodash/fp';

import request from 'utils/request';
import BlockButton from 'components/BlockButton';
import { AppContext } from 'context/AppProvider';
import { UPDATE_PAYMENT } from 'constants/appContext';
import history from 'utils/history';
import { Loading } from 'components';
import GoogleTagManager from 'utils/googleTagManager';
import { PIXEL_ID } from 'constants/config';
import { reducer } from 'reducers/asyncGlobal';
import { useControlFetch } from 'hooks/async';

/**
 * Dummy methods for the default state, doing a check doesn't
 * seem to satisfy.
 */
export const initialInstanceState = {
  requestPaymentMethod: fn => null,
  teardown: () => {}
};

const CreditCardDropIn = ({ id, payment, setErrors }) => {
  const reference = useRef();
  const [token, setToken] = useState<string | null>(null);
  const [element, setElement] = useState(reference);
  const [isLoading, setIsLoading] = useState(true);
  const [braintreePayload, setBraintreePayload] = useState<any>(null);
  const [instance, setInstance] = useState(initialInstanceState);
  const { state, dispatch } = useContext(AppContext);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [{ data }, fetchClaimed] = useControlFetch(`/user/items/${id}`, reducer);

  // On mount call the API for the client token
  useEffect(() => {
    const { first_name, last_name } = payment.selected_address;
    request.postPayment('/processor/token', { first_name, last_name }).then(
      ({ data }) => {
        setToken(decodeURIComponent(data.client_token));
      },
      error => {
        setErrors(error.response.data);
      }
    );
  }, []);

  // On token retrieval instantiate the Braintree instance and setInstance
  // Why I am using useLayoutEffect, https://kentcdodds.com/blog/useeffect-vs-uselayouteffect
  useLayoutEffect(() => {
    if (token) {
      BraintreeWebDropIn.create(
        {
          vaultManager: true,
          authorization: token,
          selector: ReactDOM.findDOMNode(element.current)
        },
        (err, instance) => {
          setInstance(instance);
          setIsLoading(false);
        }
      );
    }
    return () => instance.teardown();
  }, [token]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (paymentSuccess) {
        fetchClaimed(true);
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
    }
  }, [paymentSuccess]);

  useEffect(() => {
    if (!braintreePayload) {
      return;
    }
    request
      .postPayment('/processor/execute', {
        message: { ...braintreePayload },
        auction_id: id,
        address_id: payment.selected_address.id,
        pixel_id: PIXEL_ID
      })
      .then(
        ({ data }) => {
          dispatch({ type: UPDATE_PAYMENT, payload: data });

          setPaymentSuccess(true);
        },
        error => {
          setErrors(error.response.data);
          setIsLoading(false);
        }
      );
  }, [braintreePayload]);

  useEffect(() => {
    if (state.payment.amount > 0.00 && data && data.item_status_id === 6) {
      const { amount, tax, shipping_cost, total } = state.payment;
      GoogleTagManager.conversion({
        eventID: id,
        id,
        productName: get('auction.control.product_description.short_name')(payment),
        productCode: get('auction.control.product_description.item_code.item_code')(payment),
        productCategory: get('auction.control.product_description.category.category')(payment),
        amount: amount,
        tax: tax,
        shipping: shipping_cost,
        total
      });
      history.push(`/payment/credit-card/receipt/${id}`);
    }
  }, [state.payment, data]);

  const _handlePayment = async () => {
    if (isLoading) {
      return;
    }
    await setIsLoading(true)
    instance.requestPaymentMethod((err, payload) => {
      if (err) {
        setIsLoading(false);
        return;
      }
      setBraintreePayload(payload);
    });
  };

  const _updateRef = ref => {
    if (!element.current) {
      element.current = ref;
      setElement(element);
    }
  };

  return (
    <React.Fragment>
      <div className="braintree">
        <div id="dropin-container" style={{display: (isLoading ? 'none' : 'block')}} ref={_updateRef}></div>
          <BlockButton color="green" style={{display: (isLoading ? 'none' : 'block')}} onClick={_handlePayment} disabled={isLoading}>
            <p>
              <i className="fas fa-credit-card"></i> Pay
            </p>
          </BlockButton>
      </div>
    </React.Fragment>
  );
};

export default CreditCardDropIn;
