import { css } from '@emotion/core';
import { BREAKPOINTS as BP } from 'constants/emotionConfig';

export default ({ colors }) => css`

  .overlay {
    background-color: rgba(0,0,0,0.5);
    height: 100%;
  }

  .container {
    padding: 4rem;
  }

  .results {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
  }

  .search-btn {
    height: 5rem;
    width: 10rem;
    font-size: 1.5rem;
  }

  .result-link {
    color:inherit;
    &:hover {
      color:inherit;
      text-decoration: none;
    }
  }

  .row {
    height: auto;
    min-height: 20rem;
    border: 1px solid ${colors.grayLight};
    border-radius: .25rem;
    padding: 2rem;
    margin-bottom: 1rem;
    &:hover {
      background-color: ${colors.grayLightest};
    }
  }

  .image {
    width: 100%;
    height: auto;
    max-height: 25rem;
    max-width: 25rem;
  }

  .view-btn {
    height: 5rem;
    font-size: 1.5rem;
  }

  @media (max-width: ${BP.SM}) {
    .result-link {
      text-align:center;
      color:inherit;
      &:hover {
        color:inherit;
        text-decoration: none;
      }
    }
  }
`;
