import React from 'react';

import { ItemInfoProps } from 'types/pages/Support_Claim/supportClaim';

const ItemInfo: React.FC<ItemInfoProps> = ({ data }) =>  {
  const {
    city,
    country_code,
    full_name,
    name,
    ship_date,
    state_code,
    street1,
    street2,
    zip_code
  } = data;

  return (
    <React.Fragment>
      <p>Shipped To:</p>
      <div className="well">
        <p>{ full_name }</p>
        <p>{ street1 }</p>
        <p>{ street2 }</p>
        <p>{`${ city }, ${ state_code != null ? state_code : "" } ${ country_code } ${ zip_code }`}</p>
      </div>

      <p>Date Item Shipped:</p>
      <div className="well">
        <p>{ ship_date }</p>
      </div>

      <p>Product Description:</p>
      <div className="well">
        <p>{ name }</p>
      </div>
    </React.Fragment>
  )
};

export default ItemInfo;