/** @jsx jsx */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { css, jsx } from '@emotion/core';
import { withTheme } from 'emotion-theming';

import { List } from './List';
import { BREAKPOINTS as BP } from 'constants/emotionConfig';
import FooterLink from './FooterLink';

const Footer: React.FC = () => (
  <footer css={style}>
    <Container className="text-center">
      <Row>
        <Col lg={3}>
          <h6 className="footer-title">Connect With Us</h6>
          <div className="footer-i-wrapper">
            <a
              target="_blank"
              href="https://www.instagram.com/policeauctions"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram footer-i" />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/PoliceAuctions1"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook footer-i" />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/1PoliceAuctions"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter footer-i" />
            </a>
            <a
              target="_blank"
              href="https://www.pinterest.com/PoliceAuctions1/"
              rel="noopener noreferrer"
            >
              <i className="fab fa-pinterest-square footer-i" />
            </a>
          </div>
        </Col>
        <Col lg={3}>
          <List title="Customer Support">
            <FooterLink name="Knowledge Base" url="/knowledgebase" />
            <li className="footer-list">
              <a
                className="footer-link"
                href="https://www.youtube.com/playlist?list=PL-tqoJXzNaK621BDVr_4OcVLEZO57kRO0"
                target="_blank"
                rel="noopener noreferrer"
              >
                How To Videos
              </a>
            </li>
            <FooterLink name="Support" url="/support" />
          </List>
        </Col>
        <Col lg={2}>
          <List title="Your Account">
            <FooterLink name="Terms and Conditions" url="/terms" />
            <FooterLink name="About" url="/about" />
            <FooterLink name="Privacy Policy" url="/privacy" />
            <FooterLink name="Forgot Password" url="/forgot" />
          </List>
        </Col>
        <Col lg={2}>
          <List title="Community">
            <FooterLink name="Testimonials" url="/testimonials" />
            <li className="footer-list">
              <a className="footer-link" href="https://www.facebook.com/PoliceAuctions1" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </li>
            <li className="footer-list">
              <a className="footer-link" href="https://www.pinterest.com/PoliceAuctions1/" target="_blank" rel="noopener noreferrer">
                Pinterest
              </a>
            </li>
            <li className="footer-list">
              <a className="footer-link" href="https://twitter.com/PoliceAuctions1" target="_blank" rel="noopener noreferrer">
                Twitter
              </a>
            </li>
          </List>
        </Col>
        <Col lg={2}>
          <List title="Police Auctions.com">
            <li className="footer-list">
              <a
                className="footer-link"
                href="https://www.youtube.com/channel/UCC0XX_vBkFCA5faNszJEhPQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube Channel
              </a>
            </li>
            <li className="footer-list">
              <a
                className="footer-link"
                href="https://policeauctionsblog.wordpress.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                PoliceAuctions Blog
              </a>
            </li>
          </List>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="footer-legal">
            <h3 className="footer-legal-h3">
              <span>
                Copyright © 2001-{`${new Date().getFullYear()}`}, PoliceAuctions.com
                all rights reserved.
              </span>
            </h3>
            <p className="text-center footer-legal-p">
              No part may be reproduced without prior consent.
              <br />
              PoliceAuctions.com not affiliated with Local, State or Federal
              Government.
              <br />
              This site is protected by reCAPTCHA and the Google &nbsp;
              <a href="https://policies.google.com/privacy">Privacy Policy</a> and &nbsp;
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default withTheme(Footer);

const style = ({ colors }) => css`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: ${colors.grayLightest};
  margin-top: 2rem;
  margin-bottom: -2rem;

  .footer-title {
    font-size: 1.4rem;
    font-weight: 350;
  }

  .footer-legal {
    margin-top: 1rem;
  }

  .footer-legal-h3 {
    font-size: 1.5rem;
    font-weight: normal;
  }

  .footer-legal-p {
    font-size: 1.3rem;
  }

  .footer-i-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2.8rem;
  }

  .footer-i {
    font-size: 3rem;
    color: #333333;
  }

  .footer-link {
    color: #337ab7;
  }

  .footer-list {
    font-size: 1.4rem;
  }

  @media (max-width: ${BP.LG}) {
    .footer-i-wrapper {
      justify-content: center;
    }
    .footer-i {
      margin: 0 1rem;
    }
  }
`;
