import React from "react";
import moment from "moment";

import { AlertMessage } from 'components';
import { itemStatus, insuranceClaimStatus, trackingStatus } from 'constants/auction';
import { ItemProps } from "types/pages/My_Wins/myWins";

const ItemMessage: React.FC<ItemProps> = ({item}) => {
  // Get the case boolean for returning the correct AlertMessage color and message
  const shipping_tracking = item.shipping ? item.shipping.shipping_tracking : null;
  const unclaimed = item.item_status_id === itemStatus.UNCLAIMED;
  const claimed = item.item_status_id === itemStatus.CLAIMED;
  const hasClaim =
      item.insurance_claim &&
      item.insurance_claim.insurance_claim_status_id === insuranceClaimStatus.FILE_RECEIVED &&
      item.rmas[0] &&
      item.rmas[0].rma_status &&
      item.rmas[0].rma_status.status !== 'Dump';
  const claimRefunded = (item.insurance_claim && item.insurance_claim.insurance_claim_status_id === insuranceClaimStatus.REFUNDED);
  const shipped = (shipping_tracking && shipping_tracking.tracking_status_id === trackingStatus.IN_TRANSIT);
  const delivered = (shipping_tracking && shipping_tracking.tracking_status_id === trackingStatus.DELIVERED);

  // Get the expected refund date for the insurance claim
  const refundDate = (item.insurance_claim && item.insurance_claim.date_form_returned !== '0000-00-00') ? moment(item.insurance_claim.date_form_returned).add(17, 'days') : null;
  const claimMessage = (refundDate) ? ' Expected Refund Date: ' + refundDate.format('YYYY-MM-DD') : '';

  // Get the window for delivery
  const expectedDeliveryStart = (item.shipping && item.shipping.shipping_tracking) ? moment(item.shipping.shipping_tracking.expected_delivery_window_start).format('YYYY-MM-DD') : null;
  const expectedDeliveryEnd = (item.shipping && item.shipping.shipping_tracking) ? moment(item.shipping.shipping_tracking.expected_delivery_window_end).format('YYYY-MM-DD') : null;
  const shippingMessage = (expectedDeliveryStart && expectedDeliveryEnd) ? ` Expected Delivery: ${expectedDeliveryStart} - ${expectedDeliveryEnd}` : '';

  switch (true) {
    case claimed:
      return <AlertMessage color="info" show>Thank you for your payment. Your item will ship out within the next business day.</AlertMessage>
    case unclaimed:
      return <AlertMessage color="warning" show>You have won this item! Once you have paid, the item will ship out.</AlertMessage>
    case hasClaim:
      return <AlertMessage color="warning" show>This item's shipment is being investigated.</AlertMessage>
    case claimRefunded:
      return <AlertMessage color="info" show>This item was lost in transit. A refund will be issued.{claimMessage}</AlertMessage>
    case shipped:
      return <AlertMessage color="info" show>Your item is on the way!{shippingMessage}</AlertMessage>
    case delivered:
      return <AlertMessage color="success" show>Your item has been delivered.</AlertMessage>
  }
  return null;
}

export default ItemMessage;