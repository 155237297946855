import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import { BlockButton, Layout } from 'components';
import { usePost } from 'hooks/async';
import reducer from 'reducers/cancelAccount';
import useRedirect from 'hooks/redirect';
import { AppContext } from 'context/AppProvider';

const CancelAccount: React.FC = () => {
  document.title = 'Police Auctions - Cancel Account';
  const { dispatch, actions } = useContext(AppContext);
  const [async, setCancel] = usePost('user/cancel', reducer);

  const onButtonClick = async () => {
    await setCancel({});
    await actions.logout();
  };

  useRedirect(dispatch, async.data, '/');

  return (
    <Layout>
      <Row>
        <Col>
          <Container>
            <div className="mx-auto">
              <h2>Cancel Your Account</h2>
              <p>Are you sure you want to cancel your account?</p>
              <p>By clicking the button below, your account will be cancelled. You will be logged out.</p>
              <p>
                If you would like to contact support,{' '}
                <Link to="/support">please visit this page and fill out the support form</Link>.
              </p>
              <BlockButton type="submit" onClick={onButtonClick} color="red">
                Cancel Your Account
              </BlockButton>
            </div>
          </Container>
        </Col>
      </Row>
    </Layout>
  );
};

export default CancelAccount;
