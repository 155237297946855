import React from 'react';
import parse from 'html-react-parser';

import { Loading } from 'components';
import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';

const CookiePolicy: React.FC = () => {
  document.title = 'Police Auctions - Cookie Policy';

  const [{ data }] = useFetch('/page/10/1', reducer);

  return (
    <React.Fragment>
      { data ?
        <React.Fragment>
          <h1>Cookie Policy</h1>
          <hr />
          { parse(data.text) }
        </React.Fragment>
      : <Loading />}
    </React.Fragment>
  )
};

export default CookiePolicy;
