/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import { Link, withRouter } from 'react-router-dom';

import { BlockButton, Layout } from 'components';
import history from 'utils/history';
import styles from 'styles/ForgotPasswordReset';

const ForgotPasswordComplete: React.FC = () => {
  document.title = 'Police Auctions - Forgot Password Complete';
  return (
    <Layout css={styles}>
      <h2>Your password has been reset.</h2>
      <p>
        You can now use your new password to <Link to="/login">login here</Link>
      </p>
      <BlockButton onClick={() => history.push('/login')}>Go To Login</BlockButton>
    </Layout>
  );
};

export default withRouter(ForgotPasswordComplete);
