/** @jsx jsx */

import React from 'react';
import { Button } from 'reactstrap';
import { css, jsx } from '@emotion/core';
import { ReactFacebookLoginInfo as FBLoginType } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { BREAKPOINTS as BP } from 'constants/emotionConfig';
import { FB_APP_ID } from 'constants/config';

type FacebookButtonPropsType = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onFBCallback?: (response: FBLoginType) => void;
  value: string;
  buttonStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties
};

const FacebookButton: React.FC<FacebookButtonPropsType> = ({
  onFBCallback,
  value,
  buttonStyle,
  iconStyle
}) => (
  <FacebookLogin
    appId={FB_APP_ID}
    autoLoad={false}
    callback={onFBCallback}
    fields="email"
    redirectUri="https://www.policeauctions.com/"
    version="17.0"
    render={({ onClick }) => (
      <Button css={style} size="lg" block={true} onClick={onClick} style={buttonStyle}>
        <i className="fab fa-facebook btn-fb" style={iconStyle} />
        {value}
      </Button>
    )}
  />
);

export default FacebookButton;

const style = ({ colors }) => css`
  font-size: 1.8rem;
  background-color: ${colors.blueFacebook};
  border-color: ${colors.blueFacebook};
  border-radius: 0.5rem;

  &:hover {
    background-color: ${colors.blueFacebook};
  }

  .btn-fb {
    font-size: 3rem;
    width: 5%;
    margin-right: 1.7rem;
    padding-bottom: .5rem;
  }

  @media (max-width: ${BP.SM}) {
    font-size: 2rem;

    .btn-fb {
      font-size: 2rem;
    }
  }
`;
