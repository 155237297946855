import React from 'react';

import { EndedAlert, NoWinAlert, WinAlert } from './Alerts';
import { Props } from 'types/pages/Auction_View/partials/bidFormContainer';

const BidFormContainer: React.FC<Props> = ({ hasEnded, hasNotWon, hasWon, userName, id, children }) => (
  <React.Fragment>
    {/**Will be conditional rendering with form*/}
    <EndedAlert hasEnded={hasEnded} />
    <NoWinAlert hasNotWon={hasNotWon} />
    <WinAlert hasWon={hasWon} userName={userName} id={id} />
    {!hasEnded ? children : null}
  </React.Fragment>
);

export default BidFormContainer;
