import React from 'react';
import { Col } from 'reactstrap';

const ProductImage: React.FC<{ imageSrc: string }> = ({ imageSrc }) => (
  <Col lg="2" xs="12">
    <img src={imageSrc} alt="" className="row-image" />
  </Col>
);

export default ProductImage;
