/** @jsx jsx */

import { Row } from 'reactstrap';
import { jsx } from '@emotion/core';

import { AuctionActiveAlert, Card } from 'components';
import ProductImage from './partials/ProductImage';
import ProductInfo from './partials/ProductInfo';
import ProductDate from './partials/ProductDate';
import styles from 'styles/WatchlistUnpaidItems';

//TODO: Finish implementing the unpaid items

const WatchlistWinning: React.FC<any> = () => {
  document.title = 'Police Auctions - Watchlist Winning';

  return (
    <div css={styles}>
      <br />
      <Row>
        <Card headerTitle="Item 1" style={{ width: '100%' }}>
          <AuctionActiveAlert isActive={true}>
            <p>
              <b>You have won this item! Once you have paid, the item will ship out.</b>
            </p>
          </AuctionActiveAlert>
          <Row>
            <ProductImage imageSrc="https://images.serrf.com/21/5/78806.jpg?1326691696" />
            <ProductInfo />
            <ProductDate />
          </Row>
        </Card>
      </Row>
    </div>
  );
};

export default WatchlistWinning;
