import React from 'react';
import { Row, Col } from 'reactstrap';

import { CardDailyDeal } from 'components';
import filterPrimaryType from 'utils/filterImageType';

type Images = {
  image_type_id: number;
  url: string;
};

type FilterId = {
  image_type_id: number;
  url: string;
};

type DailyDealsData = {
  id: string;
  schedule: {
    control: {
      product_description: {
        short_name: string;
        images: Images[];
      };
    };
  };
};

type Props = {
  data: {
    data: DailyDealsData[];
  };
};

const DailyDealsList: React.FC<Props> = ({ data }) =>
  data && data.data ? (
    <Row>
      {data.data.map(({ id, schedule: { control } }) => (
        <Col sm="12" md="6" lg="4" xl="3" style={{ marginBottom: '2rem' }} key={id}>
          <CardDailyDeal
            headerTitle={control.product_description.short_name}
            url={`/dailydeals/${id}`}
            image={filterPrimaryType(control.product_description.images).url}
          />
        </Col>
      ))}
    </Row>
  ) : null;

export default DailyDealsList;
