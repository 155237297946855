//@ts-ignore

import styled from '@emotion/styled';
import { Button } from 'reactstrap';

type Props = {
  bgColor?: string;
  borderColor?: string;
  bgColorOnHover?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const ButtonStyled = styled<typeof Button, any>(Button)(
  {
    width: '100%',
    fontSize: '1.2rem',
    padding: '0.8rem',
    textTransform: 'uppercase',
    marginBottom: '0.5rem'
  },
  ({ bgColor, borderColor, bgColorOnHover, disabled }) => ({
    borderColor: borderColor || '#4a5f77',
    backgroundColor: bgColor || '#556d88',
    '&:hover': {
      backgroundColor: bgColorOnHover || '#4a5f77',
      borderColor: bgColorOnHover || '#4a5f77'
    },
    ":disabled": {
      disabled: disabled
    }
  })
);

export default ButtonStyled;
