import actions from 'constants/appContext';
import history from 'utils/history';
import { initialState } from 'context/AppProvider';

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.USER_REQUEST: {
      return {
        ...state,
        user: {
          isLoading: true
        }
      };
    }
    case actions.USER_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        user: {
          isLoading: false,
          id,
          ...action.payload
        }
      };
    }
    case actions.USER_FAILURE: {
      return {
        ...state,
        user: {
          isLoading: false,
        },
        errors: { ...action.payload }
      };
    }
    case actions.USER_RESET: {
      return {
        ...state,
        user: {
          isLoading: false,
          id: null
        }
      }
    }
    case actions.LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        errors: null
      };
    }
    case actions.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case actions.LOGIN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errors: { ...action.payload }
      };
    }
    case actions.IMPERSONATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        errors: null
      };
    }
    case actions.IMPERSONATE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case actions.IMPERSONATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errors: { ...action.payload }
      };
    }
    case actions.REGISTER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        errors: null
      };
    }
    case actions.REGISTER_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case actions.REGISTER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errors: { ...action.payload }
      };
    }
    case actions.APPLE_LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        errors: null
      };
    }
    case actions.APPLE_LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case actions.APPLE_LOGIN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errors: { ...action.payload }
      };
    }
    case actions.FB_LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        errors: null
      };
    }
    case actions.FB_LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case actions.FB_LOGIN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errors: { ...action.payload }
      };
    }
    case actions.LOGOUT_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actions.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: {
          isLoading: false,
          id: null
        }
      };
    }
    case actions.LOGOUT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errors: { ...action.payload }
      };
    }
    case actions.ROUTE_PUSH: {
      history.push(action.payload);
      return {
        ...state
      };
    }
    case actions.CURRENCY_FLAG_UPDATE: {
      return {
        ...state,
        currencyFlag: action.payload
      }
    }
    case actions.UPDATE_PAYMENT: {
      return {
        ...state,
        payment: {...state.payment, ...action.payload}
      }
    }
    case actions.CLEAR_PAYMENT: {
      return {
        ...state,
        payment: initialState.payment
      }
    }
    case actions.TIME_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actions.TIME_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        timeStamp: action.payload
      };
    }
    case actions.TIME_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errors: { ...action.payload }
      };
    }
    case actions.PRELOAD_REQUEST: {
      return {
        ...state,
        preloadData: {
          isLoading: true
        }
      };
    }
    case actions.PRELOAD_SUCCESS: {
      return {
        ...state,
        preloadData: {
          isLoading: false,
          testimonials: action.payload.testimonials,
          featured: action.payload.gov_listings,
          categories: action.payload.categories,
          notices: action.payload.notices.data
        }
      };
    }
    case actions.PRELOAD_FAILURE: {
      return {
        ...state,
        errors: { ...action.payload }
      };
    }
    case actions.CLEAR_ERRORS: {
      return {
        ...state,
        errors: null
      };
    }
    case actions.DECREMENT_ALERTS: {
      return {
        ...state,
        user: {
          ...state.user,
          notifications: (state.user.notifications > 0) ? (state.user.notifications - action.payload.num) : 0
        }
      }
    }
    case actions.UPDATE_NEWSLETTER: {
      return {
        ...state,
        user: {
          ...state.user,
          newsletter: action.payload.isSubscribed ? 1 : 0
        }
      }
    }
    case actions.HISTORY_PUSH: {
      const addHistory = state.urlHistory.concat(action.payload).reverse().slice(0, 10)
      return {
        ...state,
        urlHistory: addHistory
      }
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
