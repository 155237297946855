/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { RouteComponentProps } from 'react-router';
import { Container, Alert, Badge } from 'reactstrap';
import { FormikActions } from 'formik';

import status from 'constants/support';
import { reducer } from 'reducers/asyncGlobal';
import { reducer as postReducer } from 'reducers/asyncGlobal';
import { useFetch, usePost } from 'hooks/async';
import { Loading } from 'components';
import { RouteProps, ResponseFormFields, SupportMessage } from 'types/pages/SupportMessages/messages';
import styles from 'styles/SupportTicketChat';
import SupportConversation from './partials/SupportConversation';
import ResponseForm from './partials/ResponseForm';
import filterOutEmojis from 'utils/filterEmojisOnStr';

const SupportTicketChat: React.FC<RouteComponentProps<RouteProps>> = ({ match: { params } }) => {
  document.title = 'Police Auctions - Support Ticket Chat';
  const { id } = params;
  const [{ data, isLoading }] = useFetch(`/support/${id}`, reducer);
  const [{ isLoading: working }, setSubmit] = usePost(`/support/${id}`, postReducer);
  const [messages, setMessages] = useState<Array<SupportMessage>>([]);

  const onSubmit = (values: ResponseFormFields, actions: FormikActions<ResponseFormFields>) => {
    setSubmit({ message: filterOutEmojis(values.message) });

    setMessages(oldMessages => [...oldMessages, {
      message: filterOutEmojis(values.message),
      id: 0,
      from_employee: false,
      create_time: 'just now'
    }]);

    actions.resetForm();
  };

  useEffect(() => {
    if(data && data.messages) {
      setMessages(data.messages);
    }
  }, [data]);

  const badge = data && data.id && data.support_ticket_status_id === status.CLOSED
    ? <Badge color="success">Closed</Badge>
    : <Badge color="primary">Open</Badge>;

  const loading = isLoading ? <Loading /> : null;

  const conversation = data && data.id ? <SupportConversation data={ messages } /> : null;

  const form = data && data.id ? <ResponseForm onSubmit={ onSubmit } loading={ working } /> : null;

  const error = data && data.message ? <Alert color="danger" ><p>{ data.message }</p></Alert> : null;

  return (
    <Container css={ styles }>
      <div className="clearfix">
        <h2 className="float-left">Customer Support Ticket #{ id }</h2>
        <span className="float-right">
          { data ? badge : null }
        </span>
      </div>
      <hr />
      <Alert color="primary">
        <p>Thank you for submitting your support ticket. Our Customer Support department is open Monday - Friday from 8AM - 4PM PST, excluding Holidays and Weekends.</p>
      </Alert>
      { error }
      { loading }
      { conversation }
      { form }
    </Container>
  );
};

export default SupportTicketChat;