import React from 'react';

import PlanTable from './PlanTable';

export const FreePlanTable = (subscriptionData: {}) => {
  const planTableData = {
    planType: {
      name: 'Free Plan',
      cost: 'Free',
      buttonAction: 'Select'
    },
    checkedItems: [0,1,2],
    bgColors: {
      head: "#8da153",
      body: "#e6eada"
    },
    buttonStyles: {
      bgColor: "#66743f",
      borderColor: "#3f491f",
      bgColorOnHover: "#3f491f"
    },
    subscriptionData,
    hasCreditCard: false
  };

  return (
    <PlanTable {...planTableData} />
  );
}
