import socketEmits from 'sockets/emits';
import { Socket } from 'socket.io-client';
import { SocketActions } from 'types/actions';

//TODO: Write the proper types
export const socketInit = <T extends object>(
  socket: Socket,
  dispatch: React.Dispatch<SocketActions>,
  options?: T
) => {
  socketEmits(socket, dispatch, { ...options });
};

export default socketInit;
