import React from 'react';

import { BidAlert } from './BidAlert';

type Props = {
  bidError: {
    message: string;
  }
}

export const BidErrorAlert: React.FC<Props> = ({ bidError }) =>
  bidError ? (
    <BidAlert color="danger">
      <p>{bidError.message}</p>
    </BidAlert>
  ) : null;
