/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import moment from 'moment';

import { ReceiptModalProps } from 'types/pages/My_Wins/myWins';
import { receiptStyles } from 'styles/MyWins';

const ReceiptModal = ({id, itemName, category, transactionDetails, showReceipt, toggleReceipt}: ReceiptModalProps) => {

  // Tax row if tax was included in the payment
  const taxRow = transactionDetails.tax !== '0.00' ? (
    <div className="summary-row summary-shipping">
      <div className="summary-column">Tax</div>
      <div className="summary-column">{' '}</div>
      <div className="summary-column">${transactionDetails.tax}</div>
    </div>
  ) : null;

  // Get custom formatted values for name and total cost
  const parsedName = (itemName.length > 22) ? itemName.substring(0, 21) + '...' : itemName;
  const grandTotal = (parseFloat(transactionDetails.amount) + parseFloat(transactionDetails.freight_revenue) + parseFloat(transactionDetails.tax));

  return (
    <Modal isOpen={showReceipt} centered={true} toggle={toggleReceipt} css={receiptStyles}>
      <ModalBody>
        <h2 className="receipt-header"><span>S</span>errf <span>C</span>orp.</h2>
        <hr />
        <h3 className="receipt-com">www.<span>P</span>olice<span>A</span>uctions.com</h3>
        <p className="receipt-date">{moment(transactionDetails.transaction_time).format('LLLL')}</p>
        <div className="summary">
          <div className="summary-row summary-header">
            <div className="summary-column">Item Name</div>
            <div className="summary-column">Qty</div>
            <div className="summary-column">Price</div>
          </div>
          <div className="summary-row">
            <div className="summary-column">{parsedName}</div>
            <div className="summary-column">1</div>
            <div className="summary-column">${transactionDetails.amount}</div>
          </div>
          {taxRow}
          <div className="summary-row summary-shipping">
            <div className="summary-column">Shipping & Handling</div>
            <div className="summary-column">{' '}</div>
            <div className="summary-column">${transactionDetails.freight_revenue}</div>
          </div>
          <div className="summary-row summary-total">
            <div className="summary-column">Grand Total</div>
            <div className="summary-column">{' '}</div>
            <div className="summary-column">${grandTotal}</div>
          </div>
          <div className="receipt-thanks">
            <h3>Thank you for shopping at PoliceAuctions.com!</h3>
          </div>
          <div className="receipt-reference">
            <div>Reference # {id}</div>
            <div>Category: {category}</div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={() => { window.open(`https://policeauctions.com/print/receipt/${id}`, '_blank') }}>Print</Button>
        <Button color="primary" onClick={toggleReceipt}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReceiptModal;