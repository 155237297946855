export const itemStatus = {
  COMPLETE: 5,
  CLAIMED: 6,
  PAYMENT_PENDING: 8,
  SHIPPED: 9,
  UNCLAIMED: 16
}

export const insuranceClaimStatus = {
  COMPLETE: 2,
  CANCELLED: 3,
  FILE_RECEIVED: 5,
  FILE_SENT: 6,
  REFUNDED: 9
}

export const trackingStatus = {
  IN_TRANSIT: 1,
  DELIVERED: 2
}