import React, { ChangeEvent } from 'react';
import { FormGroup, Input as BootstrapInput, Label } from 'reactstrap';
import { css } from 'emotion';
import FormFeedback from 'reactstrap/lib/FormFeedback';

type Props = {
  label: string;
  placeholder?: string;
  onChange?: (evt: ChangeEvent<HTMLInputElement>, setFieldValue?: any) => void;
  name: string;
  invalid?: boolean;
  disabled?: boolean;
  className?: string;
  errorMessage?: string;
  isError?: string | boolean;
  type?: 'text' | 'password' | 'select' | 'textarea' | 'hidden';
  value?: string | number;
};

const Input: React.FC<Props> = ({
  label,
  isError,
  errorMessage,
  ...rest
}) => (
  <FormGroup className={style}>
    <Label>{label}</Label>
    <BootstrapInput className="user-input" {...rest} />
    {isError && <FormFeedback className="user-input-error">{errorMessage}</FormFeedback>}
  </FormGroup>
);

const style = css`
  font-size: 1.4rem;
  margin-bottom: 1.5rem;

  .user-input {
    padding: 0.6rem 1.2rem;
    height: 3.4rem;
    font-size: 1.4rem;
    background-image: none;
  }

  .user-input::placeholder {
    font-size: 1.4rem;
  }
`;

export default Input;