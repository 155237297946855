import React from 'react';
import { DropdownItem } from 'reactstrap';

const GovLink: React.FC<{ link: string, onClick: Function, isPaidUser: boolean }> = ({ link, children, onClick, isPaidUser }) => {
  const handleUrl = () => {
    if (isPaidUser) {
      window.open(link, "_blank");
      return;
    }
    window.location.href = "/upgrade";
  }
  return (
    <a
      className="gov-link"
      href=""
      rel="noopener noreferrer"
      onClick={() => handleUrl()}
    >
      <DropdownItem onClick={() => onClick()}>{ children }</DropdownItem>
    </a>
  );
}

export default GovLink;
