import React, { useEffect, useContext } from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';

import actions from 'constants/appContext';
import { AppContext } from 'context/AppProvider';

//Routes
import Home from 'pages/Home/Home';
import Login from 'pages/Login/Login';
import Register from 'pages/Register/Register';
import Upgrade from 'pages/Upgrade/Upgrade';
import CancelAccount from 'pages/Cancel_Account/CancelAccount';
import ForgotPassword from 'pages/Forgot_Password/ForgotPassword';
import ForgotPasswordReset from 'pages/Forgot_Password_Reset/ForgotPasswordReset';
import ForgotPasswordSent from 'pages/Forgot_Password_Sent/ForgotPasswordSent';
import ForgotPasswordComplete from 'pages/Forgot_Password_Complete/ForgotPasswordComplete';
import Terms from 'pages/Terms/Terms';
import CookiePolicy from 'pages/Cookie_Policy/CookiePolicy';
import About from 'pages/About_Us/About';
import NoReserve from 'pages/No_Reserve/NoReserve';
import BiddingRules from 'pages/Bidding_Rules/BiddingRules';
import Privacy from 'pages/Privacy/Privacy';
import EditProfile from 'pages/Edit_Profile/EditProfile';
import ChangePassword from 'pages/Change_Password/ChangePassword';
import Notifications from 'pages/Notifications/Notifications';
import SocialSettings from 'pages/Social_Settings/SocialSettings';
import Alerts from 'pages/Alerts/Alerts';
import Unclaimed from 'pages/Unclaimed/Unclaimed';
import GovAuctions from 'pages/Gov_Auctions/GovAuctions';
import GovAuctionDetails from 'pages/Gov_Auction_Details/GovAuctionDetails';
import MyWins from 'pages/My_Wins/MyWins';
import CarAuctions from 'pages/Car_Auctions/CarAuctions';
import Support from 'pages/Support/Support';
import AuctionListing from 'pages/Auction_Listing/AuctionListing';
import KnowledgeBase from 'pages/Knowledge_Base/KnowledgeBase';
import SupportTickets from 'pages/Support_Tickets/SupportTickets';
import SupportReturn from 'pages/Support_Return/SupportReturn';
import SupportTicketChat from 'pages/Support_Ticket_Chat/SupportTicketChat';
import SupportClaim from 'pages/Support_Claim/SupportClaim';
import Testimonials from 'pages/Testimonials/Testimonials';
import Notices from 'pages/Notices/Notices';
import Notice from 'pages/Notice/Notice';
import Impersonate from 'pages/Impersonate/Impersonate';
import Watchlist from 'pages/Watchlist/Watchlist';
import WatchlistGovernment from 'pages/Watchlist_Government/WatchlistGovernment';
import WatchlistWinning from 'pages/Watchlist_Winning/WatchlistWinning';
import WatchlistLosing from 'pages/Watchlist_Losing/WatchlistLosing';
import WatchlistDailyDeals from 'pages/Watchlist_Daily_Deals/WatchlistDailyDeals';
import WatchlistUnpaidItems from 'pages/Watchlist_Unpaid_Items/WatchlistUnpaidItems';
import DailyDealsListing from 'pages/Daily_Deals_Listing/DailyDealsListing';
import DailyDealsView from 'pages/Daily_Deals_View/DailyDealsView';
import { MainPage } from 'Template/MainPage';
import { Settings } from 'Template/Settings';
import { EmptyPage } from 'Template/EmptyPage';
import { WatchlistLayout } from 'Template/WatchlistLayout';
import AuthRoute from './AuthRoute';
import AuctionView from 'pages/Auction_View/AuctionView';
import BuyDirect from 'pages/Buy_Direct/BuyDirect';
import MyBids from 'pages/My_Bids/MyBids';
import Phone from 'pages/Phone/Phone';
import Refunds from 'pages/Refunds/Refunds';
import ManagePayments from 'pages/Manage_Payments/ManagePayments';
import EditUsername from 'pages/Edit_Username/EditUsername';
import DeleteInstructions from 'pages/DeleteInstructions/DeleteInstructions';

// Payment Components
import { PaymentPage } from 'Template/PaymentPage';
import PaymentMethods from 'pages/Payment/PaymentMethods';
import ShippingAddress from 'pages/Payment/ShippingAddress';
import PayPalReceipt from 'pages/Payment/PayPalReceipt';
import PayPalReview from 'pages/Payment/PayPalReview';
import StandardReview from 'pages/Payment/StandardReview';
import StandardReceipt from 'pages/Payment/StandardReceipt';
import { PhonePage } from 'Template/PhonePage';

// Subscription
import BraintreeSubscription from 'pages/Subscription/BraintreeSubscription';
import SubscriptionReceipt from 'pages/Subscription/SubscriptionReceipt';

// Print components
import PrintReceipt from 'pages/Print/PrintReceipt';

/**
 * Note sure if we still need this.... 3/20/20 (John)
 * TODO: Remove each of the JSX element comments after the class components
 * has been converted to functional components.
 */

const SettingsView: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <Settings>
    <Route component={EditProfile} exact path={`${path}/`} />
    <Route component={ChangePassword} path={`${path}/password`} />
    <Route component={Notifications} path={`${path}/notifications`} />
    <Route component={SocialSettings} path={`${path}/social`} />
    <Route component={ManagePayments} path={`${path}/manage_payments`} />
  </Settings>
);

const PageView: React.FC = () => (
  <MainPage>
    <Route component={Home} exact path="(/|/index.php)" />
    <Route component={Terms} path="/terms" />
    <Route component={Terms} path="/site/terms" />
    <Route component={DeleteInstructions} path="/delete-instructions" />
    <Route component={CookiePolicy} path="/site/cookie-policy" />
    <Route component={About} path="/about" />
    <Route component={NoReserve} path="/site/noreserve" />
    <Route component={BiddingRules} path="/site/biddingrules" />
    <Route component={Privacy} path="/privacy" />
    <Route component={Privacy} path="/site/privacy" />
    <AuthRoute component={Alerts} path="/account/notifications" />
    <AuthRoute component={MyBids} path="/account/mybids" />
    <AuthRoute component={MyWins} path="/my-wins" />
    <AuthRoute component={MyWins} path="/myitems" />
    <AuthRoute component={Refunds} path="/my-refunds" />
    <AuthRoute component={Unclaimed} path="/unclaimed" />
    <Route component={Testimonials} path="/testimonials" />
    <Route component={GovAuctions} path="/externalauction" exact />
    <Route component={GovAuctionDetails} path="/externalauction/:id" />
    <Route component={CarAuctions} path="/carauction" exact />
    <Route component={GovAuctionDetails} path="/carauction/:id" />
    <AuthRoute component={Support} path="/(support|supportticket)" />
    <Route component={AuctionListing} path="/(category|tag)/:id" />
    <Route component={AuctionListing} path="/auction/featured" exact />
    <Route component={Notices} path="/notices" exact />
    <Route component={Notice} path="/notices/:id" />
    <AuthRoute component={Upgrade} path="/upgrade" />
    <AuthRoute component={CancelAccount} path="/cancel" />
    <Route component={Impersonate} path="/impersonate" />
    <Route component={Impersonate} path="/site/impersonate" />
    <Route component={DailyDealsListing} path="/dailydeals" exact />
  </MainPage>
);

const SupportPages: React.FC = () => (
  <EmptyPage showAd={false}>
    <AuthRoute component={Support} path="/(support|supportrequest)" exact />
    <AuthRoute component={SupportTicketChat} path="/support/tickets/:id" exact />
    <AuthRoute component={SupportClaim} path="/support/claim/:id" exact/>
    <AuthRoute component={SupportTickets} path="/support/tickets" exact />
    <AuthRoute component={SupportReturn} path="/supportticket/return" exact  />
    <AuthRoute component={SupportReturn} path="/support/return/:id?" exact  />
  </EmptyPage>
);

const KnowledgeBasePages: React.FC = () => (
  <EmptyPage showAd={false}>
    <Route component={KnowledgeBase} path="/knowledgebase" exact />
    <Route component={KnowledgeBase} path="/faq" exact />
  </EmptyPage>
);

const LoginRoutes: React.FC = () => (
  <EmptyPage showAd={false}>
    <Route component={Login} path="/login" />
  </EmptyPage>
);

const RegisterRoutes: React.FC = () => (
  <EmptyPage showAd={false}>
    <Route component={Register} path="/register" />
  </EmptyPage>
);

const EditUsernameRoutes: React.FC = () => (
  <EmptyPage showAd={false}>
    <AuthRoute component={EditUsername} path="/edit-username" />
  </EmptyPage>
);

const ForgotRoutes: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <EmptyPage showAd={false}>
    <Route component={ForgotPassword} exact path={`/forgot`}  />
    <Route component={ForgotPasswordSent} path={`/forgot/sent`}/>
    <Route component={ForgotPasswordReset} path={`/forgot/reset/:id`} />
    <Route component={ForgotPasswordComplete} path={`/forgot/complete`} />
  </EmptyPage>
);

const PaymentPages: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <PaymentPage>
    <Route component={PaymentMethods} path={`${path}/methods/:id`} />
    <Route component={ShippingAddress} path={`${path}/address/:method/:id`} />
    <Route component={StandardReview} path={`${path}/review/:method/:id`} />
    <Route component={PayPalReview} exact path={`${path}/paypal/review/:id`} />
    <Route component={PayPalReceipt} exact path={`${path}/receipt/paypal/:id`} />
    <Route component={StandardReceipt} path={`${path}/:method/receipt/:id`} />
  </PaymentPage>
);

const SubscriptionPages: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <EmptyPage showAd={false}>
    <Route component={BraintreeSubscription} path={`${path}/credit-card`} />
    <Route component={SubscriptionReceipt} path={`${path}/receipt`} />
  </EmptyPage>
);

const AuctionViewer: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <EmptyPage>
    <Route component={AuctionView} path={path} exact />
  </EmptyPage>
);

const DailyDealViewer: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <EmptyPage>
    <Route component={DailyDealsView} path={path} exact />
  </EmptyPage>
);

const BuyDirectViewer: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <EmptyPage>
    <Route component={BuyDirect} path={path} exact />
  </EmptyPage>
);

const PhoneViewer: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <PhonePage>
    <Route component={Phone} path={path} exact />
  </PhonePage>
);

const WatchlistPages: React.FC<RouteComponentProps> = ({ match: { path } }) => (
  <WatchlistLayout>
    <AuthRoute component={Watchlist} exact path={`${path}`} />
    <AuthRoute component={WatchlistWinning} path={`${path}/winning`} />
    <AuthRoute component={WatchlistLosing} path={`${path}/losing`} />
    <AuthRoute component={WatchlistDailyDeals} path={`${path}/dailydeals`} />
    <AuthRoute component={WatchlistGovernment} path={`${path}/listings`} />
    <AuthRoute component={WatchlistUnpaidItems} path={`${path}/unpaid`} />
  </WatchlistLayout>
);

const PrintPages: React.FC<RouteComponentProps> = ({ match: { path }}) => (
  <EmptyPage showAd={false} showHeader={false} showFooter={false}>
    <Route component={PrintReceipt} path={`${path}/receipt/:id`} />
  </EmptyPage>
);

const Body: React.FC<RouteComponentProps> = ({ location }) => {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    const { pathname, search } = location;

    dispatch({ type: actions.HISTORY_PUSH, payload: pathname.concat(search) })
  }, [location]);

  return (
    <Switch>
      <AuthRoute component={SettingsView} path="/account/settings" />
      <AuthRoute component={PaymentPages} path="/payment" />
      <AuthRoute component={SubscriptionPages} path="/subscription" />
      <AuthRoute component={PrintPages} path="/print" />
      <Route component={SupportPages} path="/(support|supportrequest)" />
      <Route component={KnowledgeBasePages} path="/(knowledgebase|faq)" />
      <Route component={AuctionViewer} path="/auctions/:id/:slug?" />
      <Route component={AuctionViewer} path="/(auction/:id|auctions/:id)" />
      <Route component={DailyDealViewer} path="/dailydeals/:id" />
      <Route component={BuyDirectViewer} path="/buydirect/:id" />
      <Route component={WatchlistPages} path="/account/watchlist" />
      <Route component={ForgotRoutes} path="/forgot" />
      <Route component={LoginRoutes} path="/login" />
      <Route component={RegisterRoutes} path="/register" />
      <Route component={EditUsernameRoutes} path="/edit-username" />
      <Route component={PhoneViewer} path="/phone" />
      <Route component={PageView} path="/" />
    </Switch>
  );
};

export default withRouter(Body);
