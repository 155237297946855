/** @jsx jsx */

import React, { useState, useContext, useEffect } from 'react';
import { PIXEL_ID } from 'constants/config';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { default as jwt_decode } from 'jwt-decode';

import { AppContext, clearErrors } from 'context/AppProvider';
import { RegisterForm } from './partials/RegisterForm';
import { FacebookButton, Layout } from 'components';
import { ErrorMessage } from 'components';
import styles from 'styles/Register';
import { RegisterInput } from 'types/authentication';
import { RECAPTCHA_KEY } from 'constants/config';
import AppleSignIn, { AppleSignInResponse } from 'components/AppleSignInButton';

const Register: React.FC = () => {
  document.title = 'Police Auctions - Register';
  const {
    actions,
    dispatch,
    state: { errors, isLoading }
  } = useContext(AppContext);

  const onAppleSignIn: AppleSignInResponse = (appleRes) => {
    const { id_token, code } = appleRes.authorization;
    const { email, sub } = jwt_decode<{ email: string, sub: string }>(id_token);

    actions.appleLogin({
      ...appleRes.user,
      authorizationCode: code,
      identityToken: id_token,
      email,
      user: sub
    });
  };

  const onFacebookSignIn = ({ email, id, accessToken }: ReactFacebookLoginInfo) => {
    actions.fbLogin({ email, social_provider_user_id: id, access_token: accessToken });
  };

  const [formErrors, setFormErrors] = useState({});

  const onRegisterSubmit = async (values: RegisterInput) => {
    const { username, email, password, confirmPassword, newsletter } = values;
    const affiliate = localStorage.getItem('affiliate');
    const tracking = affiliate ? JSON.parse(affiliate) : null;

    actions.register({
      username,
      email,
      password,
      'repeat-password': confirmPassword,
      terms: 1,
      newsletter: newsletter ? 1 : 0,
      affiliate_id: tracking ? tracking.affiliateId : null,
      refer_id: tracking ? tracking.affiliateReferCodeId : null,
      refer_code: tracking ? tracking.affiliateReferCode : null,
      pixel_id: PIXEL_ID
    });
  };

  useEffect(() => {
    return () => {
      clearErrors(dispatch);
    }
  }, []);

  useEffect(() => {
    if(Object.keys(formErrors).length || errors) {
      window.scrollTo(0, 0);
    }
  }, [formErrors, errors]);

  const onErrors = (errors) => {
    setFormErrors(errors);
  }

  return (
    <Layout css={styles}>
      <h2>Get Started with a Free Account.</h2>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <AppleSignIn
          clientId="com.policeauctions.web"
          redirectURI="https://www.policeauctions.com/apple-sign-in"
          usePopup={true}
          scope="email"
          appleSignInResponse={onAppleSignIn}
          buttonStyle={{
            width: '37.5rem',
            height: '4.1rem'
          }}
        />
        <FacebookButton
          value="Sign In With Facebook"
          onFBCallback={onFacebookSignIn}
          buttonStyle={{
            maxWidth: '37.5rem',
            height: '4.1rem',
            borderRadius: '0.7rem',
            marginTop: '1.2rem',
            fontSize: '1.7rem'
          }}
          iconStyle={{
            fontSize: '2rem',
            marginRight: '0.7rem' }}
        />
      </div>
      <hr className="form-border" />
      <ErrorMessage item={errors} />
      {/* <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}> */}
      <RegisterForm onSubmit={onRegisterSubmit} isLoading={isLoading} onErrors={onErrors} />
      {/* </GoogleReCaptchaProvider> */}
      <p>
        Already have an account? &nbsp;
        <Link to="/login">Login to your Account!</Link>
      </p>
    </Layout>
  );
};

export default Register;
