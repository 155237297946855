import React from 'react';

import { BidAlert } from './BidAlert';
import bidStatusMessages from 'utils/bidStatusMessages';

export const BidStatusAlert = ({ bidStatus, bidMessage }) =>
  bidStatus ? (
    <BidAlert color={bidStatusMessages(bidStatus) || ''}>
      <p>{bidMessage}</p>
    </BidAlert>
  ) : null;
