/** @jsx jsx */

import { jsx } from '@emotion/core';

import PrimaryTable from './PrimaryTable';
import { Row, Col } from 'reactstrap';

const FirstRow = ({ data }) => {
  const asset =  'no_auction_image';
  const image = data.has_image
    ? `https://images.serrf.com/externalauctions/${data.id}.jpg`
    : `https://cdn.policeauctions.com/images/${asset}.png`;

  return (
    <Row>
      <Col xs="12" sm="6" className="image-container">
        <img
          className="image"
          src={ image }
          alt="Auction Item"
        />
      </Col>
      <Col xs="12" sm="6">
        <PrimaryTable data={ data } />
      </Col>
    </Row>
  )
}

export default FirstRow;