import React from 'react';
import { Alert } from 'reactstrap';

type Props = {
  message: null | string;
};

const SuccessMessage: React.FC<Props> = ({ message }) => {
  return message ? (
    <Alert color="success">
      <p>{message} </p>
    </Alert>
  ) : null;
};

export default SuccessMessage;
