type BidAuctions = {
  user_id: number;
  hasBidBefore: boolean;
  username: string | undefined;
};

type IsWinning = boolean | null;

const hasBidded = (hasBidBefore: boolean) => {
  const message = 'You have not bid on this item';

  return hasBidBefore === false ? { color: 'warning', message } : null;
};

const hasWon = (userId: number, userBidId: number) => (userId ? userId == userBidId : null);

const hasWonStream = (username: string, streamUsername: string | undefined) => (username ? username === streamUsername : null);

const bidStatusMessage = (isWinning: IsWinning, wonMessage: string, lostMessage: string) =>
  isWinning ? wonMessage : lostMessage;

const dynamicAlertMessage = (user: any, { user_id, hasBidBefore, username }: BidAuctions) => {
  const wonMessage = 'You are currently winning this bid';
  const outBidMessage = 'You have been outbid';
  const hasBid = hasBidded(hasBidBefore);
  const isWinning = hasWon(user, user_id);
  const isWinningStream = username ? hasWonStream(user, username) : null;

  const alertStreamMessage = isWinningStream ? bidStatusMessage(isWinningStream, wonMessage, outBidMessage) : null;
  const alertStreamColor = isWinningStream ? bidStatusMessage(isWinningStream, 'success', 'danger') : null;
  const alertMessage = alertStreamMessage ? alertStreamMessage : bidStatusMessage(isWinning, wonMessage, outBidMessage);
  const alertColor = alertStreamColor ? alertStreamColor : bidStatusMessage(isWinning, 'success', 'danger');

  return hasBid === null ? { message: alertMessage, color: alertColor } : hasBid;
};

export default dynamicAlertMessage;
