const steps = [
  {
    element: '.price-wrapper',
    intro: 'The current price of the item. This will go up whenever someone bids on this item.ƒ'
  },
  {
    element: '.timer-container',
    intro: 'The time left. Once this runs out, the winner is announced!'
  },
  {
    element: '.bid-input-container',
    intro:
      'Here you can adjust the amount you want to bid. If you enter an amount greater than the current maximum bid, you are guaranteed to win until someone else bids more that price.'
  },
  {
    element: '.bid-button',
    intro: 'Click this button to submit your bid!'
  },
  {
    element: '.bid-history',
    intro: 'Here you can see the latest winning bidders'
  }
];

const stepIniitalState = {
  stepsEnabled: false,
  initialStep: 0,
  steps
};

export default stepIniitalState;
