/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import Header from './Header';
import FirstRow from './FirstRow';
import SecondRow from './SecondRow';
import AdditionalInfo from './AdditionalInfo';

const Breakdown: React.FC<{ data:any }> = ( props:any ) => {

  const { data } = props;

  return (
    <React.Fragment>
      <Header data={ data } />
      <FirstRow data={ data } />
      <SecondRow data={ data } />
      <AdditionalInfo data={ data } />
    </React.Fragment>
  )
}

export default Breakdown;