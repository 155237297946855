/** @jsx jsx */

import { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';

type Props = {
  price: string;
};

const AuctionPrice: React.FC<Props> = ({ price }) => {
  const [priceUpdatedClass, setPriceUpdatedClass] = useState('');
  const [priceState, setPriceState] = useState<any>(null);

  const onAnimationEnded = () => {
    setPriceUpdatedClass('');
  };

  useEffect(() => {
    if (price) {
      setPriceState(price);
    }
    if (priceState && priceState !== price) {
      setPriceUpdatedClass('h2-price-update');
    }
  }, [price]);
  return (
    <div className="price-wrapper" css={styles}>
      <h2 className={priceUpdatedClass} onAnimationEnd={onAnimationEnded}>
        ${parseInt(price).toFixed(2)} USD
      </h2>
    </div>
  );
};

export default AuctionPrice;

const styles = css`
  font-weight: 400;
  color: green;
  font-size: 2.5rem;
  text-align: center;

  .h2-price-update {
    animation: fadeBackground 1s ease reverse;
    background-color: rgba(229, 163, 39, 1);
    animation-fill-mode: forwards;
  }

  @keyframes fadeBackground {
    0% {
      background-color: rgba(229, 163, 39, 0);
    }
    50%,
    100% {
      background-color: rgba(229, 163, 39, 1);
    }
  }
`;
