import React, { ReactNode } from 'react';

type Children = ReactNode[] | ReactNode;

//TODO: Add types for this file

export const multipleChildren = (children: Children): children is ReactNode[] =>
  (children as ReactNode[]).length !== undefined;

export const setPosition = (children, num) => (children.length === 2 ? 1 : num);

/**
 *  Change the length to the of the children if the children length is two,
 *  due to the transition working improperly when previous is clicked
 */
export const getChildLength = children => (children.length === 2 ? 3 : children.length);

export const generateChildren = children => {
  const childrenElements = React.Children.toArray(children);
  const firstElement = React.cloneElement(childrenElements[0]);
  const generateArr = children.length === 2 ? childrenElements.concat(firstElement) : children;

  return generateArr;
};
