import React from 'react';

/** @jsx jsx */

import { Col, Progress } from 'reactstrap';
//import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/src/core/styles.scss';
import { jsx, css } from '@emotion/core';

import { map } from 'lodash';
import AuctionSlider from 'components/auction_slider/AuctionSlider';

/**
 * TODO: Add types for this file
 */
const progressColors = (currentTime: number) => ({
  success: currentTime > 75,
  warning:   currentTime > 50 && currentTime < 75,
  danger: currentTime >= 0 && currentTime < 50
});

const switchColors = (currentTime: ReturnType<typeof progressColors>) =>
  Object.entries(currentTime).reduce((acc, [key, value]) => value ? key : acc, '');

const Images = ({ images }) =>
  images.length !== 1 ? (
    <AuctionSlider>
      {map(images, (image) => (
        <img src={image.url} key={image.id} css={style} className="slider-img" />
      ))}
    </AuctionSlider>
  ) : (
    <React.Fragment>
      {map(images, (image) => (
        <img src={image.url} key={image.id} css={style} className="slider-img" />
      ))}
    </React.Fragment>
  );

const ImageProgressBar: React.FC<{ timer: number }> = ({timer}) => {
  const timerToPercentage = (timer / 59) * 100;

  return (
    <Progress
    color={switchColors(progressColors(timerToPercentage))}
    value={timerToPercentage}
    style={{width: '100%'}}
    />
    )
  }

  const ProductImage = ({ images, hasHd, timer }) => {
    const selectedImg = (hasHd && images.length > 2) ? images.filter(img => img.image_type_id !== 5) : images;
    return (
      <Col xs={12} md={6}>
        <div className="d-flex align-items-center justify-content-center h-100 flex-column ">
          <Images images={selectedImg} />
          {timer < 60 && timer >= 0 ? <ImageProgressBar timer={timer} /> : null}
        </div>
      </Col>
    );
}

const style = css`
  max-width: 90%;

  @media screen and (max-width: 1199px) {
    max-width: 70%;
  }
`;

export default ProductImage;
