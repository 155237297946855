const formatStrToTitle = (text: string) =>
  text
    .split(/_/g)
    .map(item =>
      item
        .charAt(0)
        .toUpperCase()
        .concat(item.slice(1))
    )
    .join(' ');

export default formatStrToTitle;
