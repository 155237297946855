/** @jsx jsx */

import React from 'react';
import parse from 'html-react-parser';
import { Row, Col } from 'reactstrap';
import { jsx } from '@emotion/core';

import style from 'styles/SitePage';
import { Loading } from 'components';
import { useFetch } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';

const NoReserve: React.FC = () => {
  document.title = 'Police Auctions - No Reserve Policy';
  const [{ data }] = useFetch('/page/5/1', reducer);
  return (
    <Row css={style}>
      <Col lg={8}>
        <h1>PoliceAuctions.com No Reserve Policy</h1>
        <hr/>
        {data ? parse(data.text) : <Loading />}
      </Col>
    </Row>
  );
};

export default NoReserve;