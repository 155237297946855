/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';

import { useFetch } from 'hooks/async';
import { Loading, AuctionActiveAlert, Paginator } from 'components';
import { reducer } from 'reducers/asyncGlobal';
import styles from 'styles/WatchlistDailyDeals';
import DailyDealsList from './partials/DailyDealsList';

const WatchlistDailyDeals: React.FC = () => {
  document.title = 'Police Auctions - Watchlist Daily Deals';
  const [page, setPage] = useState(1);

  const [{ data, isLoading }, fetch, reset] = useFetch('/watchlist/dailydeals', reducer, {
    page
  });

  const onPageClick = (page: number) => {
    reset();
    setPage(page);
    fetch(true);
  };

  const loading = isLoading ? <Loading /> : null;

  return (
    <div css={styles}>
      <h2>
        <i className="fa fa-bullhorn" /> Daily Deals
      </h2>
      <hr />

      <AuctionActiveAlert isActive={data && !data.data.length}>
        <p>You are not watching any daily deals yet.</p>
      </AuctionActiveAlert>
      {loading}
      <DailyDealsList data={data} />
      <div className="paginator-container">
        {data ? <Paginator isLoading={isLoading} pages={data} onClick={onPageClick} /> : null}
      </div>
    </div>
  );
};

export default WatchlistDailyDeals;
