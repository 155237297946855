import request from 'utils/request';
import { Action } from 'types/actions';

//TODO: Add some types
export const fetchServerTime = (dispatch: React.Dispatch<Action>) => {
  dispatch({ type: 'TIME_REQUEST' });
  return request
    .get('/time')
    .then(({ data }) => {
      dispatch({ type: 'TIME_SUCCESS', payload: data });
    })
    .catch(error => {
      dispatch({ type: 'TIME_FAILURE', payload: { ...error } });
    });
};

export const serverTimeActions = (dispatch: React.Dispatch<Action>) => ({
  getServerTime: () => fetchServerTime(dispatch)
});
