import { css } from '@emotion/core';

export default css`
  .card-li {
    padding: 0;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .sort-dropdown {
      font-size: 1.4rem;
      padding: 0.6rem 1.2rem;
    }

    .h1-title {
      .fa-star {
        margin-right: 1rem;
      }
      font-size: 2rem;
    }
  }

  .border-line {
    margin: 2rem 0;
  }

  .paginator-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
