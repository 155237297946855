/** @jsx jsx */

import React from 'react';
import { Row, Col } from 'reactstrap';
import { jsx, css } from '@emotion/core';

import { Props } from 'types/pages/Auction_View/partials/auctionBidding';
import BidFormContainer from './BidFormContainer';
import BidHistoryList from './BidHistoryList';
import BidHistoryContainer from './BidHistoryContainer';
import BidForm from './BidForm';
import WatchListButton from './WatchListButton';
import ShareIcons from 'components/ShareIcons';
import { BidErrorAlert } from './BidErrorAlert';
import { BidStatusAlert } from './BidStatusAlert';
import { Timer, ProductImageSlider } from 'components';
import Price from './Price';
import { EndingAlert, OpeningBidAlert } from './Alerts';
import { BREAKPOINTS as BP } from 'constants/emotionConfig';
import ProductImage from './ProductImage';
import RefreshInfo from './RefreshInfo';

//Static for now and will be reusable. Place inside of 'components'

const AuctionBidding: React.FC<Props> = ({
  price,
  userBids,
  hasEnded,
  hasNotWon,
  hasWon,
  images,
  userLoggedIn,
  onBidSubmit,
  bidStatus,
  bidMessage,
  isBidding,
  bidError,
  serverTime,
  endTime,
  isEnding,
  setMax,
  salePrice,
  isWatching,
  postUpdateStatus,
  maxBidAMount,
  myGreatestPrice,
  id,
  userName,
  openingBid,
  userId,
  notStreaming,
  streamingError,
  hasHd,
  timer
}) => (
  <Row css={style}>
    <ProductImage images={images} hasHd={hasHd} timer={timer} />
    <Col xs={12} sm={12} md={6} className="bidding-info">
      <Price price={price} />
      <BidFormContainer
        hasEnded={hasEnded}
        hasNotWon={hasNotWon}
        hasWon={hasWon}
        bidMessage={bidMessage}
        bidStatus={bidStatus}
        bidError={bidError}
        userName={userName}
        id={id}
      >
        <Timer serverTime={serverTime} endTime={endTime} />
        <OpeningBidAlert amount={salePrice} openingBid={openingBid} userId={userId} notStreaming={notStreaming}/>
        <EndingAlert isEnding={isEnding} />
        <BidErrorAlert bidError={bidError} />
        <BidStatusAlert bidStatus={bidStatus} bidMessage={bidMessage} />
        <BidForm onBidSubmit={onBidSubmit} isBidding={isBidding} />
      </BidFormContainer>
      <BidHistoryContainer
        setMax={setMax}
        currentSetMax={setMax}
        salePrice={salePrice}
        userLoggedIn={userLoggedIn}
        postUpdateStatus={postUpdateStatus}
        maxBidAmount={maxBidAMount}
        myGreatestPrice={myGreatestPrice}
        itemAmount={price}
      >
        {streamingError ? <RefreshInfo /> : <BidHistoryList bidUsers={userBids} />}
        <WatchListButton userLoggedIn={userLoggedIn} isWatching={isWatching} id={id}/>
      </BidHistoryContainer>
      <ShareIcons />
    </Col>
  </Row>
);

const style = css`
  .slider-img {
    max-width: none;
    width: 100%;
  }

  .refresh-container {
    min-width: 25rem;
    min-height: 25rem;
  }

  @media screen and (max-width: 899px) {
    .col-md-6 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .bidding-info {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    .bidding-info {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: ${BP.SM}) {
    .col-md-6 {
      padding: 0;
    }
    .slider-img {
      max-width: 90%;
    }
  }
`;

export default AuctionBidding;
