import { css } from '@emotion/core';

export default css`
  .contact {
    font-weight: bold;
    color: red;
  }

  @media screen and (max-width: 768px) {
    .forgotPassword-wrapper {
      padding: 0 2rem;
    }
  }
`;
