import _ from 'lodash/fp';

//TODO: Add types for this file
const getIsKeys = objKeys => {
  const keyRef = objKeys;

  return Object.keys(objKeys)
    .filter(item => item.indexOf('is_') > -1 && item !== 'is_fire')
    .reduce((acc, curr) => {
      acc[curr] = keyRef[curr];

      return acc;
    }, {});
};

const generateLink = data =>
  Object.keys(data).reduce((acc, curr) => {
    if (data[curr] === true) {
      acc = `${curr + '/'}`;
    }

    if (data['is_virtual'] === true) {
      acc = 'is_category/';
    }

    return acc.replace(/^is_/, '/');
  }, '');

const changeSymbols = name =>
  name
    .replace(/\s/g, '+')
    .replace(/&/g, '%26')
    .toLowerCase();

const generateLinks = item => name =>
  `${_.pipe(
    getIsKeys,
    generateLink
  )(item)}${changeSymbols(name)}`;

export default generateLinks;
