/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Formik, Form, FormikActions } from 'formik';
import * as Yup from 'yup';

import { usePost } from 'hooks/async';
import { SupportRequestFields, SupportFormProps } from 'types/pages/Support/support';
import { reducer } from 'reducers/asyncGlobal';
import {
  Input,
  SaveButton,
  SuccessMessage
} from 'components';

const validation = Yup.object().shape({
  topic: Yup.string().required('Please choose a type'),
  message: Yup.string().required('Please enter a description')
});

const SupportForm: React.FC<SupportFormProps> = ({ changeHandler }) => {

  const [{ data, isLoading }, makeRequest] = usePost('/support', reducer);

  const onSubmit = (values: SupportRequestFields, actions: FormikActions<SupportRequestFields>) => {
    makeRequest({ ...values });
    actions.resetForm();
  };

  const success = data && data.success
    ? <SuccessMessage message="We are sorry you are having trouble. We will get back to you as soon as possible."/>
    : null;

  return (
    <div css={style}>
      {success}
      <Formik
        initialValues={{ message: '', type: '' }}
        onSubmit={ onSubmit }
        validationSchema={ validation }
        validateOnChange={ false }
        render={({ handleChange, touched, errors, values }) => (
          <Form>
            <Input
              label="Choose a Topic"
              name="topic"
              type="select"
              onChange={event => {
                //Trigger formik change event, and Support component change handler to load hints.
                changeHandler(event);
                handleChange(event);
              }}
              isError={ touched.type && errors.type }
              errorMessage={ errors.type }
              invalid={ errors.type ? true : false }
            >
              <option value="">Choose A Topic</option>
              <option value="2">Account</option>
              <option value="1">Bidding</option>
              <option value="21">Bidding Restricted</option>
              <option value="17">Car/gov Auctions</option>
              <option value="19">Claims</option>
              <option value="5">Contact A Representative </option>
              <option value="20">Customs And Duties</option>
              <option value="3">Emails</option>
              <option value="18">Error Message</option>
              <option value="14">Foreclosure</option>
              <option value="9">Items</option>
              <option value="12">Login/logout</option>
              <option value="10">Missing/damaged Items</option>
              <option value="6">Payment</option>
              <option value="11">Policeauctions.com Information</option>
              <option value="13">Receipt</option>
              <option value="15">Returns</option>
              <option value="4">Second Chance Offers</option>
              <option value="8">Shipping</option>
              <option value="7">Strike Through Price</option>
              <option value="16">Undeliverable</option>
            </Input>
            <Input
              label="Describe your issue"
              name="message"
              type="textarea"
              onChange={ handleChange}
              isError={ touched.message && errors.message }
              errorMessage={ errors.message }
              invalid={ errors.message ? true : false }
              value={ values.message }
            />
            <SaveButton type="submit" disabled={ isLoading }>Submit Form</SaveButton>
          </Form>
        )}
      />
    </div>
  )
}

export default SupportForm;

const style = css`
  textarea.user-input {
    height: 15rem;
  }
`;
