import { REQUEST, SUCCESS, FAILURE } from 'constants/async';

const notifications = (state, action) => {
  switch (action.type) {
    case REQUEST: {
      return {
        ...state,
        errors: null,
        isLoading: true
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload }
      };
    }
    case FAILURE: {
      const { message, errors } = action.payload;
      return {
        ...state,
        isLoading: false,
        errors: {
          message,
          errors
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default notifications;
