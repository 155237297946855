import { css } from '@emotion/core';
import { BREAKPOINTS as BP } from 'constants/emotionConfig';

export default css`
  .table {
    font-size: 1.2rem;
  }

  .image {
    max-width: 35rem;
    margin-bottom: 1rem;
  }

  .title {
    font-weight: bold;
  }

  @media (max-width: ${BP.SM}) {
    padding: 0rem 1rem;

    .image-container {
      text-align:center;
    }

    .image {
      width: 100%;
      height: auto;
      max-height: 40rem;
      max-width: 40rem;
    }

    .info {
      text-align: right;
    }

    .right-banner {
      display: none;
    }
  }
`;
