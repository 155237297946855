import React, { useContext, useEffect, useState, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import BraintreeWebDropIn from 'braintree-web-drop-in';

import { AppContext } from 'context/AppProvider';
import request from 'utils/request';
import { initialInstanceState } from 'components/CreditCardDropIn';
import { BlockButton } from 'components';

const PaymentMethods = () => {
  const {
    state: {
      user: { first_name, last_name },
    },
  } = useContext(AppContext);
  const reference = useRef();
  const [element, setElement] = useState(reference);
  const [instance, setInstance] = useState(initialInstanceState);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<null | string>(null);

  useEffect(() => {
    const firstName = first_name || '';
    const lastName = last_name || '';

    request.postPayment('/processor/token', { first_name: firstName, last_name: lastName }).then(({ data }) => {
      setToken(decodeURIComponent(data.client_token));
    });
  }, []);

  useLayoutEffect(() => {
    if (token) {
      BraintreeWebDropIn.create(
        {
          vaultManager: true,
          authorization: token,
          selector: ReactDOM.findDOMNode(element.current),
        },
        (_, instance) => {
          setInstance(instance);
          setLoading(false);
        }
      );
    }

    return () => {
      instance.teardown();
    };
  }, [token]);

  const _updateRef = (ref) => {
    if (!element.current) {
      element.current = ref;
      setElement(element);
    }
  };

  const _handlePayment = async () => {
    instance.requestPaymentMethod((err, payload) => {
      if (err) {
        setLoading(false);
        return;
      }
    });
  };

  return (
    <React.Fragment>
      <div id="dropin-container" ref={_updateRef}></div>
      <BlockButton
        color="green"
        disabled={loading}
        onClick={_handlePayment}
        className={`${loading ? 'd-none' : 'd-block'}`}
      >
        <p>
          <i className="fas fa-credit-card"></i> Save
        </p>
      </BlockButton>
    </React.Fragment>
  );
};

export default PaymentMethods;
