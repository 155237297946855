/**@jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';

import CardList from './CardList';
import CardSubLists from './CardSubLists';
import generateLinks from 'utils/generateSidebarLinks';
import generateQueryParam from 'utils/generateQueryParam';

type CardList = {
  name: string;
  display: string;
  is_fire: boolean;
  id: number;
  is_virtual: boolean;
  subList: CardList[];
};

type Props = {
  data: CardList[];
};

const NestedCards: React.FC<Pick<CardList, 'is_virtual'>> = ({ is_virtual, children }) =>
  is_virtual ? <ul css={{ paddingLeft: '4rem' }}>{children![0]}</ul> : children![1];

const ShowCardList: React.FC<Props> = ({ data }) => (
  <React.Fragment>
    {data
      ? data.map((card, index) => {
          const links = generateLinks(card)(card.name);
          const categoryType = generateQueryParam(card);
          const path = { pathname: links, state: { name: card.name, params: { [categoryType]: card.id } } };

          return (
            <React.Fragment key={`card-${index}-${card.id}`}>
              <NestedCards is_virtual={card.is_virtual}>
                <CardList name={card.display} isFire={card.is_fire} url={path} />
                <CardList name={card.display} isFire={card.is_fire} url={path} />
              </NestedCards>
              <CardSubLists data={card.subList} />
            </React.Fragment>
          );
        })
      : null}
  </React.Fragment>
);

export default ShowCardList;
