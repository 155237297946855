/** @jsx jsx */

import { Col } from 'reactstrap';
//import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/src/core/styles.scss';
import { jsx, css } from '@emotion/core';

import { map } from 'lodash';
import AuctionSlider from './auction_slider/AuctionSlider';

/**
 * TODO: Add types for this file
 */

const Images = ({ images }) => (
  <AuctionSlider>
    {map(images, image => (
      <img src={image.url} key={image.id}  css={style} className="slider-img"/>
    ))}
  </AuctionSlider>
);

const ImageSlider = ({ images, hasHd }) => {
  const selectedImg = (hasHd && images.length > 2) ? images.filter(img => img.image_type_id !== 5) : images;
  return (
    <Col xs={12} md={6}>
      <div className="d-flex align-items-center justify-content-center h-100">
        <Images images={selectedImg} />
      </div>
    </Col>
  );
}

const style = css`
  max-width: 90%;

  @media screen and (max-width: 1199px) {
    max-width: 70%;
  }
`;

export default ImageSlider;
