import React from 'react';

const ChecksPayable = ({ id, date, method }: {id: number, date: string, method: string}) => (
  <React.Fragment>
        <div className="receipt-row">
          <div className="receipt-half">
            <p className="text-bold">{(method == 'check') ? 'Checks / Money orders payable to:' : ''}</p>
            <p className="text-bold">{(method == 'check') ? 'Serrf Corp.' : ''}</p>
          </div>
          <div className="receipt-half">
            <p>Date: {date}</p>
            <p>Receipt #: {id}</p>
          </div>
        </div>
        { method == 'check' ? (
        <div className="receipt-row">
          <div className="receipt-full">
            <p className="text-bold padding-top">Mailing Address:</p>
            <p className="text-bold">PoliceAuctions.com</p>
            <p>3182 Pullman St.</p>
            <p>Costa Mesa, CA 92626</p>
            <p className="padding-top">Phone: (714) 556-4900</p>
          </div>
        </div>
        ) : null }
  </React.Fragment>
);

export default ChecksPayable;