/** @jsx jsx */

import React, { ReactNode } from 'react';
import { jsx } from '@emotion/core';
import { Card as CardCategory, CardHeader } from 'reactstrap';

type Props = {
  headerTitle: string;
  headerTitleRight?: ReactNode;
  isSold?: boolean;
  style?: any;
  onTitleClick?: (e: React.MouseEvent<HTMLParagraphElement>) => void;
};

//TODO: Add withTheme for sold auction card
const CardCollapse: React.FC<Props> = ({ onTitleClick, headerTitle, headerTitleRight, children }) => (
  <CardCategory>
    <CardHeader className="card-head d-flex flex-row justify-content-between">
      <p className="header-title" style={{ fontWeight: 'normal' }} onClick={onTitleClick}>
        {headerTitle}
      </p>
      {headerTitleRight ? headerTitleRight : null}
    </CardHeader>
    {children}
  </CardCategory>
);


export default CardCollapse;
