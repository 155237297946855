/** @jsx jsx */
import { jsx } from '@emotion/core';

import { VideoProps } from 'types/pages/Knowledge_Base/KnowledgeBase';

const Video: React.FC<VideoProps> = ({ video }) =>  {
  return (
    <div className="video">
      <h4>{ video.title }</h4>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          src={`https://www.youtube.com/embed/${ video.provider_video_uid }`}
          frameBorder="0"
          title={video.title}
        />
      </div>
      <p>{ video.description }</p>
    </div>
  )
};

export default Video;