import styled from '@emotion/styled';

type StylesType = {
  bgColor?: string;
  borderColor?: string;
  bgColorOnHover?: string;
};

export default styled('div')<StylesType>(
  {
    padding: '3.5rem',
    fontSize: '1.4rem',
    color: '#ffffff',
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
    textAlign: 'center',
    height: '18.8rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgba(0, 0, 0, 0.125)',
    '@media (max-width: 992px)': {
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '@media (max-width: 768px)': {
      padding: '1.8rem'
    },
    '@media (max-width: 576px)': {
      padding: '1.8rem 0.8rem;'
    }
  },
  ({ bgColor }) => ({
    backgroundColor: bgColor || '#6999c1'
  })
);
