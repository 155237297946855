/** @jsx jsx */

import { css, jsx } from '@emotion/core';

/**
 * Just a temporary loading element utility for now whenever
 * making slow api calls
 */
const Loading = () =>
  <div css={style}>
    <img src="/logo.svg" className="logo" alt="" />
    <p>Loading...</p>
  </div>




export default Loading;

const style = css`
  animation: showLoading 0s 1s forwards;
  opacity: 0;
  text-align: center;

  .logo {
    max-height: 5rem;
    max-width: 5rem;
  }

  @keyframes showLoading {
    100% {
      opacity: 1;
    }
  }
`;
