import React from 'react';
import parse from 'html-react-parser';

import { Loading } from 'components';
import { useFetch } from 'hooks/async';
import reducer from 'reducers/privacy';

const Privacy: React.FC = () => {
  document.title = 'Police Auctions - Privacy';
  const [{ data }] = useFetch('/page/1/1', reducer);

  return <React.Fragment>{data ? parse(data.text) : <Loading />}</React.Fragment>;
};

export default Privacy;
