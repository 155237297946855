/** @jsx jsx */

import { css, jsx } from '@emotion/core';

import { BlockButton, LoadingButton } from 'components';

type Props = {
  isBidding: boolean;
  values: {
    bid: string;
  };
};

const BidSubmitButton: React.FC<Props> = ({ isBidding, values }) =>
  isBidding ?
    <LoadingButton css={css`font-size: 1.8rem;`}>Bid</LoadingButton>
   :
    <BlockButton className="bid-button" color="blue" type="submit" disabled={!values.bid}>Bid</BlockButton>

export default BidSubmitButton