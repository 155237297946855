import { useEffect } from 'react' ;

const checkUserLoading = ({ user: { isLoading } }, storage: string | null) => (dispatch: React.Dispatch<boolean>) => {
  useEffect(() => {
    if (!storage) {
      dispatch(false);
    }

    if (isLoading === false) {
      dispatch(false);
    }
  }, [isLoading]);

  return isLoading;
};

export default checkUserLoading;
