/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx, css } from '@emotion/core';

import Card from './Card';
import BlockButton from 'components/BlockButton';
import { usePost } from 'hooks/async';
import { reducer } from 'reducers/asyncGlobal';
import history from 'utils/history';
import AuctionPrice from 'components/AuctionPrice';

type Props = {
  headerTitle: string;
  price: string;
  image: string;
  id: number;
};

const CardSco: React.FC<Props> = ({ headerTitle, id, price, image, ...rest }) => {

  const [disabled, setDisabled] = useState(false);
  const [hidden, setHidden] = useState(false);

  const [acceptResponse, setAccept] = usePost(`/sco/${id}/accept`, reducer);
  const [rejectResponse, setReject] = usePost(`/sco/${id}/reject`, reducer);

  const onAcceptClick = () => {
    setDisabled(true);
    setAccept(true);
  };

  const onRejectClick = () => {
    setDisabled(true);
    setReject(true);
  };

  useEffect(() => {
    if(acceptResponse && acceptResponse.data){
      history.push('/my-wins');
    }
  }, [acceptResponse]);

  useEffect(() => {
    if(rejectResponse && rejectResponse.data){
      setHidden(true);
    }
  }, [rejectResponse]);

  const card = !hidden ?
    <Card headerTitle={headerTitle} style={{ height: '100%' }} {...rest}>
      <div css={style} className="auction-container item-col">
        <AuctionPrice price={price} />
        <div className="row-fluid text-center product-image-container">
          <div className="product-image-row">
              <div className="product-image-cell">
                <img alt="some_img" src={image} className="img-responsive center-block preview-image" />
              </div>
          </div>
        </div>
        <div className="bottom-container">
          <BlockButton onClick={onAcceptClick} color="green" disabled={disabled} className='sco-action-btn'>
            Accept for ${price}
          </BlockButton>
          <BlockButton onClick={onRejectClick} color="red" disabled={disabled} className='sco-action-btn'>
            Deny
          </BlockButton>
        </div>
      </div>
    </Card> : null;

  return (
    <React.Fragment>
      { card }
    </React.Fragment>
  )
}



const style = css`
  .auction-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    text-align: center;
  }

  .card-body {
    height: 100%;
  }

  .bottom-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    
  }

  .item-col * {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .product-image-container {
    display: table;
    min-height: 400px;
    text-align: center;
    width: 100%;
    padding-bottom: 5px;
  }

  img {
    max-height: 375px;
    max-width: 375px;
    margin: 0 auto;
  }

  .product-image-row {
    display: table-row;
    height: 100%;
    text-align: center;
  }

  .product-image-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .sco-action-btn {
    height: 3.4rem;
    font-size: 1.43rem;
    padding: 0;
    margin-bottom: 0;
  }

  @media screen and (max-width: 2080px) {
    img {
      max-height: 300px;
      max-width: 345px;
    }
    .product-image-container {
      min-height: 323px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 2013px) {
    img {
      max-height: 300px;
      max-width: 265px;
    }
  }

  @media screen and (max-width: 1646px) {
    img {
      max-height: 260px;
      max-width: 230px;
    }
    .product-image-container {
      min-height: 309px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 1448px) {
    img {
      max-height: 216px;
      max-width: 230px;
    }
    .product-image-container {
      min-height: 270px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 1448px) and (min-width: 1199px){
    .item-col.col-lg-3 {
      width: 33.333%
    }
  }

  @media screen and (max-width: 1333px) {
    img {
      max-height: 216px;
      max-width: 188px;
    }
    .product-image-container {
      min-height: 230px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 1199px) {
    img {
      max-height: 375px;
      max-width: 202px;
    }
    .product-image-container {
      min-height: 306px;
    }
  }

  @media screen and (max-width: 767px) {
    img {
      max-height: none;
      max-width: 355px;
    }

    .product-image-container {
      min-height: 100%;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 780px) {
    .navbar-nav > li > a {
      padding: 13px 12px;
    }
  }

  @media screen and (max-width: 400px) {
    img {
      max-height: none;
      max-width: 290px;
    }
  }

  @media screen and (max-width: 370px) {
    img {
      max-height: none;
      max-width: 260px;
    }
  }
`;

export default CardSco;
