import React, { useContext } from 'react';
import { AppContext } from 'context/AppProvider';

import GuestHeader from './GuestHeader';
import UserHeader from './UserHeader';
import { SocketContext } from 'context/SocketProvider';
import { CategoryType } from './DropdownItems';

type Props = {
  categoryListData: CategoryType[];
}

const Header: React.FC<Props> = ({ categoryListData }) => {
  const {
    state: { user },
    actions: { logout }
  } = useContext(AppContext);

  const {
    state: {
      streamData: { userStream }
    }
  } = useContext(SocketContext);

  return user.id ? <UserHeader user={user} logout={logout} userStream={userStream} categoryListData={categoryListData} /> : <GuestHeader categoryListData={categoryListData} />;
};

export default Header;
