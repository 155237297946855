import styled from "@emotion/styled";

type Props = {
  order: number;
}

const ImageContainer = styled.div<Props>`
  display: flex;
  flex: 1 0 100%;
  -ms-flex: 1;
  -ms-flex-negative: 0;
  -ms-flex-preferred-size: 100%;
  order: ${({ order }) => order};
  -ms-flex-order: ${({ order }) => order};
  align-items: center;
  justify-content: center;
`

export default ImageContainer;
