import React from 'react';
import { Row, Col } from 'reactstrap';

export type SummaryRowProps = {
  title: string;
  value: string | number | null;
  predicate?: boolean | null;
};

const SummaryRow = ({ title, value, predicate = true }: SummaryRowProps) => {
  return predicate ? (
    <Row>
      <Col>
        <p>{title}</p>
      </Col>
      <Col>
        <p>{value}</p>
      </Col>
    </Row>
  ) : null;
};

export default SummaryRow;
