import React from 'react';

import PlanTable from './PlanTable';
import { SubscriptionData } from 'types/pages/Upgrade/upgrade';

export const YearlyPlanTable = (subscriptionData: SubscriptionData) => {
  const planTableData = {
    planType: {
      name: 'Yearly',
      cost: '$39.95 / Per Year',
      buttonAction: null
    },
    checkedItems: [0,1,2,3,4,5,6,7,8,9,10],
    bgColors: {
      head: "#e59f3d",
      body: "#f8ead3"
    },
    buttonStyles: {
      bgColor: "#c77f28",
      borderColor: "#a0651e",
      bgColorOnHover: "#a0651e"
    },
    subscriptionData,
    hasCreditCard: true
  };

  return (
    <PlanTable {...planTableData} />
  );

};
