/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';

import history from 'utils/history';

//This will be a link to the auction view page
const BuyDirectPrice: React.FC<{ bdp: number | false; auctionId: string }> = ({ bdp, auctionId }) => (
  <p css={styles}>
    <a
      onClick={() => {
        history.push(`/buydirect/${auctionId}`);
      }}
    >
      ${bdp}
    </a>
  </p>
);

export default BuyDirectPrice;

const styles = css`
  color: red;
  font-size: 1.7rem;
  text-decoration: line-through;
  font-weight: 500;
  margin: 0;
  a {
    cursor: pointer;
  }
`;
