import React from 'react';
import { Row } from 'reactstrap';
import _, { filter, isObject } from 'lodash';
import moment from 'moment-timezone';

import { ProductDescription } from 'components';
import CardCollapseContent from './CardCollapseContent';
import PaymentInfo from './PaymentInfo';

/**
 * TODO: Add types to this file
 */

const onlyObjects = products => filter(products, product => isObject(product));

const ProductFullName = ({ name }) => (name ? <p>{name}</p> : null);

const auctionEndTime = (endTime) => {
  const convertTime = moment(endTime, 'x').tz(moment.tz.guess())

  const endTimeFormat = convertTime.format('MMMM Do YYYY, h:mm:ss A z');

  return endTime ? endTimeFormat : 'Loading Data'
}

const AuctionDetails = ({
  products,
  name,
  productName,
  productId,
  auctionId,
  shippingInfo,
  billingInfo,
  returnPolicy,
  endTime
}: any) => (
  <React.Fragment>
    <Row>
      <CardCollapseContent
        headerTitle="Product Description"
        headerTitleRight={<p className="auction-id">Auction ID: {auctionId}</p>}
      >
        {/**TODO: Add in moment */}
        <strong className="list-header">This Auction Ends: {auctionEndTime(endTime)}</strong>
        <br />
        <br />
        <ProductFullName name={productName} />
        <ProductDescription
          productId={productId}
          products={onlyObjects({ ...products })}
          name={name}
        />
      </CardCollapseContent>
      {/**The payment info does not exist in the server*/}
      <CardCollapseContent headerTitle="Payment Information">
        <PaymentInfo />
      </CardCollapseContent>
      <CardCollapseContent headerTitle="Shipping Information">{shippingInfo}</CardCollapseContent>
      <CardCollapseContent headerTitle="Bidding Information">{billingInfo}</CardCollapseContent>
      <CardCollapseContent headerTitle="Return Policy">{returnPolicy}</CardCollapseContent>
    </Row>
  </React.Fragment>
);

export default AuctionDetails;
