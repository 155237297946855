/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Row, Col, FormGroup } from 'reactstrap';
import { css, jsx } from '@emotion/core';

import { Checkbox } from 'components';
import { RadioGroup } from 'components';
import { Method, Setting, Settings, Props, Values } from 'types/pages/Notifications/partials/notificationsForm';

const isChecked = (setting: Setting, method: Method) => method.id === setting.user_notification_method_id;

const checks = (values: Values, setting: Setting, method: Method) => {
  const valueSettings = parseInt(values[setting.name]);
  const valuesEqualId = valueSettings === method.id;
  return valuesEqualId || (!valueSettings && isChecked(setting, method));
};

const defaultValues = (settings: Settings) =>
  settings.reduce(
    (acc, curr) => {
      acc[curr.name] = curr.user_notification_method_id;
      return acc;
    },
    {} as Values
  );

const getNewsletter = (newsletter: number) => (newsletter === 1 ? true : false);

const NotificationsForm: React.FC<Props> = ({ notificationList, user, updateNotifications, updateNewsletter }) => {
  const { settings, methods } = notificationList;
  const [newsletterState, setNewsletterState] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setNewsletterState(getNewsletter(user.newsletter));
    }
  }, [user.newsletter]);

  const handleCheckboxChange = (state: boolean) => {
    setNewsletterState(prevNewsletter => !prevNewsletter);
    updateNewsletter({ subscribe: state ? 0 : 1 });
  };

  // Hide push notification selection if user has no app install
  const filteredMethods = user.hasAppInstall ? methods : methods.filter(item => item.name !== 'Push Notification');

  return (
    <Formik
      initialValues={{ ...defaultValues(settings), newsletter: newsletterState }}
      enableReinitialize={true}
      onSubmit={() => {}}
      render={({ values, setFieldValue }) => (
        <Form css={style}>
          <Row>
            <Col>
              <p>Sign up for our newsletters</p>
            </Col>
            <Col>
              <FormGroup check={true} inline={true}>
                <Checkbox
                  textElement="Subscribed"
                  name="newsletter"
                  checked={values.newsletter}
                  onChange={() => handleCheckboxChange(newsletterState)}
                />
              </FormGroup>
            </Col>
          </Row>
          {settings.map(setting => (
            <Row key={setting.id}>
              <Col>
                <p>{setting.description}</p>
              </Col>
              <Col>
                {filteredMethods.map(method => (
                  <RadioGroup
                    key={method.id}
                    label={method.name}
                    name={setting.name}
                    check={true}
                    checked={checks(values, setting, method)}
                    value={method.id}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(setting.name, parseInt(event.target.value));
                      updateNotifications({ setting_id: setting.id, method_id: method.id });
                    }}
                  />
                ))}
              </Col>
            </Row>
          ))}
        </Form>
      )}
    />
  );
};

export default NotificationsForm;

const style = css`
  max-width: 55rem;
  .form-group {
    margin-right: 1rem;
  }
`;
