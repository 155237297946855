/** @jsx jsx */
import { jsx } from '@emotion/core';

import { SectionProps } from 'types/pages/Knowledge_Base/KnowledgeBase';
import { CollapsibleCard } from 'components';
import Video from "./Video";

const Section: React.FC<SectionProps> = ({ category }) =>  {
  return (
    <div id={ category.id } className="section">
      <h2>{ category.name }</h2>
      { category
        ? category.knowledge_base.map(kb => (
            <CollapsibleCard title={ kb.question } opened={false} showIcon={true}>
              <p>{ kb.answer }</p>
              { kb.knowledge_base_videos.length > 0
                ? <Video video={ kb.knowledge_base_videos[0].video } />
                : null }
            </CollapsibleCard>
          ))
        : null }
    </div>
  )
};

export default Section;