import React from "react";
import InlineShareButtons from "components/ShareThis";

const ShareIcons: React.FC = () => (
  <div className="share-container d-flex justify-content-center">
    <InlineShareButtons
      config={{
        enabled: true,
        networks: [
          "facebook",
          "twitter",
          "pinterest",
          "email",
        ],
        radius: 4,
        show_total: false,
      }}
    />
  </div>
);

export default ShareIcons;