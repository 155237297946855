import React from 'react';
import { Alert } from 'reactstrap';

import { Props } from 'types/pages/Auction_View/partials/alerts';
import { BlockButton } from 'components';
import history from 'utils/history';

export const EndedAlert: React.FC<Pick<Props, 'hasEnded'>> = ({ hasEnded }) =>
  hasEnded ? (
    <Alert color="danger" className="alert-msg">
      <p>The Auction has Ended.</p>
    </Alert>
  ) : null;

export const NoWinAlert: React.FC<Pick<Props, 'hasNotWon'>> = ({ hasNotWon }) =>
  hasNotWon ? (
    <Alert color="success" className="alert-msg">
      <p>Sorry you didn't win this time.</p>
    </Alert>
  ) : null;

export const WinAlert: React.FC<Pick<Props, 'hasWon' | 'userName' | 'id'>> = ({ hasWon, userName, id }) =>
  hasWon ? (
    <React.Fragment>
      <Alert color="success" className="alert-msg">
        <p>Congratulations {userName} you won the item!</p>
      </Alert>
      <BlockButton className="pay-now-btn" onClick={() => { history.push(`/payment/methods/${id}`) }}>
        Pay Now
      </BlockButton>
    </React.Fragment>
  ) : null;

export const OpeningBidAlert = ({ notStreaming, userId, openingBid, amount }) =>
  !userId && notStreaming && parseInt(openingBid) > 1 || amount === 0 ? (
    <Alert color="success" className="alert-msg">
      <p>Opening Bid is ${openingBid}</p>
    </Alert>
  ) : null;

export const EndingAlert = ({ isEnding }) =>
  isEnding ? (
    <Alert color="warning" className="alert-msg">
      <p className="alert-msg-p">
        <i className="fa fa-cog fa-spin alert-msg-i" />
        <span className="alert-msg-span">Auction ending. Please wait..</span>
      </p>
    </Alert>
  ) : null;
