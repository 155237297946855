/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx } from '@emotion/core';
import { get } from 'lodash/fp';
import { Alert, Col } from 'reactstrap';
import moment from 'moment';

import { receiptStyles } from 'styles/Payment';
import history from 'utils/history';
import { AppContext } from 'context/AppProvider';
import BlockButton from 'components/BlockButton';

const SubscriptionReceipt = ({match}) => {
  document.title = 'Police Auctions - Receipt';
  const {state} = useContext(AppContext);

  // Verify the state contains the selected address otherwise, reroute to the MyWins page
  if (! state.payment.amount || state.payment.amount === 0.00) {
    history.push('/upgrade');
  }

  // Get the amounts for the receipt and total and todays date in a specific format
  const {amount} = state.payment;
  const today = moment().format('YYYY-MM-DD LTS');
  return (
    <Col lg={6} md={8} sm={8} style={{margin: '0 auto'}}>
      <Alert color="info"><p>We recommend that you print this receipt for your own records.</p></Alert>
      <div css={receiptStyles}>
        <div className="receipt-row padding-top">
          <div className="table-row">
            <div className="product-column text-bold"><p>Products</p></div>
            <div className="misc-column"><p>Units</p></div>
            <div className="misc-column"><p>Price</p></div>
            <div className="misc-column"><p>Total</p></div>
          </div>
          <hr />
          <div className="table-row">
            <div className="product-column"><p>1 Year Subscription (Non-Recurring)</p></div>
            <div className="misc-column"><p>1</p></div>
            <div className="misc-column"><p>${amount}</p></div>
            <div className="misc-column"><p>${amount}</p></div>
          </div>
          <hr />
          <div className="table-row total-text">
            <div className="product-column"><p></p></div>
            <div className="misc-column"><p></p></div>
            <div className="misc-column text-bold"><p>Total:</p></div>
            <div className="misc-column"><p>${amount}</p></div>
          </div>
        </div>
      </div>
      <BlockButton color='green' style={{marginTop: '10px'}} onClick={() => history.push('/')}>
        Return Home
      </BlockButton>
    </Col>
  );
};

export default SubscriptionReceipt;