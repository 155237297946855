/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const CookieBar: React.FC = () => {

  const [show, setShow] = useState(false);

  const cookie_name = 'accept_cookies';
  const cookie_value = 'accepted';

  const onAccept = () => {
    localStorage.setItem(cookie_name, cookie_value);
    setShow(false);
  };

  useEffect(() => {
    if(cookie_value !== localStorage.getItem(cookie_name)){
      setShow(true);
    }
  }, []);

  return (
    <div css={ style }>
      { show ?
        <div className={show ? 'cookiebar cookiebar-active' : 'cookbar-inactive'}>
          <div className="row-fluid">
            <Col xs={12} >
              <span className="cookie-msg">This site uses cookies. By using this site you agree to the use of cookies. <Link to="/site/cookie-policy">Learn More.</Link></span>

              <Button className="cookie-btn btn-lg" onClick={onAccept}>
                  Accept
              </Button>
            </Col>
          </div>
        </div>
        : null }
    </div>
  )
};

export default CookieBar;


const style = css`
  .cookiebar {
    display: block;
    position: fixed;
    min-width: 100%;
    height: auto;
    z-index: 99999;
    font-size: 13px;
    line-height: 20px;
    left: 0;
    bottom: 0;
    text-align: center;
    background-color: #f5f5f5;
    border-top: 1px solid #dddddd;
    box-shadow: 0px 0 1px rgba(0, 0, 0, 0.8);
  }

  .cookie-msg {
    margin-top:5px;
    vertical-align: middle;
  }

  .cookie-btn {
    margin: 5px;
  }

  .cookie-btn:hover, .cookie-btn:focus, .cookie-btn:active {
    color: #ffffff;
    background-color: #1c1d1d;
    border-color: #080808;
  }

  .cookiebar-inactive {
    visibility: hidden;
  }
`;